import {
    ConfigurazioneColonna,
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../../../shared/components/table/model/generic-table-model";
import {
    AuthorityType,
    OnboardingEsternoStatus,
    PageUserEsternoViewImpl,
    UserEsternoViewImpl,
} from "../../../../../api-clients/generated/services";
import {get} from "lodash";
import {Translation} from "@ngneat/transloco";
import moment from "moment";
import {APP_DEFAULT_PAGE_SIZE} from "../../../../shared/service/local-storage.service";


function buildEsterniTooltip(esterno: UserEsternoViewImpl, translation: Translation, isAreaAmm: boolean) {
    switch (esterno?.stato) {
        case OnboardingEsternoStatus.DASINCRONIZZARE:
            return !!esterno?.replyTimestamp ?
                (
                    get(translation, 'cycle_doctorate.esterni.registered_on', null)
                    + ' ' + moment(esterno?.replyTimestamp).format('DD/MM/YYYY HH:mm')
                ) : get(translation, 'cycle_doctorate.esterni.status.to_synchronize', null);
        case OnboardingEsternoStatus.INVITATO:
            return get(translation, 'cycle_doctorate.esterni.status.invited', null)
        case OnboardingEsternoStatus.SINCRONIZZATO:
            return isAreaAmm ? get(translation, 'cycle_doctorate.esterni.status.synchronized', null)
                : get(translation, 'cycle_doctorate.esterni.status.synchronized_gestionale', null)
        default:
            return '';
    }
}

function hasRoleForSelectedCourseAndCycle(esterno: UserEsternoViewImpl, currentCorso: string, currentCiclo: string, isAmmSection?: boolean) {
    return esterno.ruoliUtenteCorsiDiStudi?.filter(
        r => (isAmmSection || r?.corsoDiStudi?.codiceEsse3 === currentCorso)
            && r.cicliAbilitati?.find(c => String(c.numeroCiclo) == currentCiclo)
    )?.length > 0;
}

export type OnboardingEsternoStatusForAmm = 'INVITATO' | 'DA_SINCRONIZZARE' | 'SINCRONIZZATO' | 'LOGGATO' | 'GIA_PRESENTE';

export const OnboardingEsternoStatusForAmm = {
    INVITATO: 'INVITATO' as OnboardingEsternoStatusForAmm,
    DASINCRONIZZARE: 'DA_SINCRONIZZARE' as OnboardingEsternoStatusForAmm,
    SINCRONIZZATO: 'SINCRONIZZATO' as OnboardingEsternoStatusForAmm,
    LOGGATO: 'LOGGATO' as OnboardingEsternoStatusForAmm,
    GIAPRESENTE: 'GIA_PRESENTE' as OnboardingEsternoStatusForAmm
};

export function buildEsterniTableConfiguration(pageEsterni: PageUserEsternoViewImpl,
                                                      pageSize: number = APP_DEFAULT_PAGE_SIZE,
                                                      page: number = 0,
                                                      currentRuolo: AuthorityType,
                                                      currentSottoRuolo: AuthorityType,
                                                      translation: Translation,
                                                      openNoProfileInDepartmentDialog: () => void,
                                                      currentCorso: string,
                                                      currentCiclo: string,
                                                      rolesAndSubrolesWhichCanEditInGestionale: AuthorityType[],
                                                      currentAdminRuoli: AuthorityType[],
                                                      isAreaAdministrator?: boolean): GenericTableConfigurationModel {
    const esterniForUI = pageEsterni?.content?.map((esterno: UserEsternoViewImpl) =>
        ({
            ...esterno,
            nomeCognome: (esterno.cognome + ' ' + esterno.nome).toUpperCase(),
            email: esterno?.mail?.toLowerCase(),
            hasRoleForSelectedCourseAndCycle: esterno.stato === OnboardingEsternoStatus.GIAPRESENTE || hasRoleForSelectedCourseAndCycle(esterno, currentCorso, currentCiclo, isAreaAdministrator),
            codiceFiscale: esterno?.codiceFiscale?.toUpperCase(),
        }));
    const colDef: ConfigurazioneColonna[] = [
        {
            tipo: TipoColonnaEnum.ICON,
            icon: [{
                nameIcon: 'warning_amber',
                colorIcon: 'warn',
                classes: 'cursor-pointer text-amber-500',
                tootlip: get(translation, 'users_list.extern_no_role_for_cycle_course', null),
                onClick: () => openNoProfileInDepartmentDialog(),
                showIf: (hasRoleForSelectedCourseAndCycle) => !hasRoleForSelectedCourseAndCycle
            }],
            nomeColonna: ' ',
            colonnaKey: 'hasRoleForSelectedCourseAndCycle',
            flex: 5,
        },
        {
            tipo: TipoColonnaEnum.STRING,
            nomeColonna: 'common.nominative',
            colonnaKey: 'nomeCognome',
            flex: 20,
        },
        {
            tipo: TipoColonnaEnum.STRING,
            nomeColonna: 'user_list.email',
            colonnaKey: 'email',
            flex: 20,
        },
        {
            tipo: TipoColonnaEnum.STRING,
            nomeColonna: 'sign-up.fiscal_code',
            colonnaKey: 'codiceFiscale',
            flex: 20,
        },
        {
            tipo: TipoColonnaEnum.STRING,
            nomeColonna: 'common.nationality',
            colonnaKey: 'nazionalita',
            flex: 15,
        },
        {
            tipo: TipoColonnaEnum.CHIP_STATUS,
            nomeColonna: 'common.status',
            colonnaKey: 'stato',
            flex: 15,
            statusType: isAreaAdministrator ? OnboardingEsternoStatusForAmm : OnboardingEsternoStatus,
            tooltip: (stato, element) => buildEsterniTooltip(element, translation, isAreaAdministrator),
        },

    ];
    if (!isAreaAdministrator) {
        colDef.push({
            nomeColonna: ' ',
            colonnaKey: 'actions',
            flex: 10,
            tipo: TipoColonnaEnum.ACTION_MENU,
            valueCheck: 'l',
            show: (value, element) => (rolesAndSubrolesWhichCanEditInGestionale.includes(currentSottoRuolo)
                || rolesAndSubrolesWhichCanEditInGestionale.includes(currentRuolo))
                && element.stato === OnboardingEsternoStatus.INVITATO,
            button: [
                {
                    nameIconButton: 'edit',
                    click: TipoClickEnum.EDIT,
                    tooltip: 'cycle_doctorate.esterni.edit_esterno',
                    color: 'accent',
                    show: (value, element) => (rolesAndSubrolesWhichCanEditInGestionale.includes(currentSottoRuolo)
                            || rolesAndSubrolesWhichCanEditInGestionale.includes(currentRuolo))
                        && element.stato === OnboardingEsternoStatus.INVITATO,
                    valueShow: 'stato'
                },
                {
                    nameIconButton: 'send',
                    click: TipoClickEnum.EXTERN_RESEND_INVITE,
                    tooltip: 'cycle_doctorate.esterni.resend_invite',
                    color: 'accent',
                    show: (value, element) => (rolesAndSubrolesWhichCanEditInGestionale.includes(currentSottoRuolo)
                            || rolesAndSubrolesWhichCanEditInGestionale.includes(currentRuolo))
                        && element.stato === OnboardingEsternoStatus.INVITATO,
                    valueShow: 'stato'
                },
                {
                    nameIconButton: 'delete',
                    click: TipoClickEnum.EXTERN_CANCEL_INVITE,
                    tooltip: 'cycle_doctorate.esterni.cancel_invite',
                    color: 'accent',
                    show: (value, element) => (rolesAndSubrolesWhichCanEditInGestionale.includes(currentSottoRuolo)
                            || rolesAndSubrolesWhichCanEditInGestionale.includes(currentRuolo))
                        && element.stato === OnboardingEsternoStatus.INVITATO,
                    valueShow: 'stato'
                },
            ]
        });
    }
    if (isAreaAdministrator) {
        const administrationRolesWhichCanEdit = [AuthorityType.SUPERADMIN, AuthorityType.PTAATENEOSYNCESTERNI];
        colDef.push({
            nomeColonna: ' ',
            colonnaKey: 'actions',
            flex: 10,
            tipo: TipoColonnaEnum.ACTION_MENU,
            valueCheck: 'l',
            show: (value, element) => currentAdminRuoli?.find(r => administrationRolesWhichCanEdit.includes(r))
                && (element.stato === OnboardingEsternoStatus.SINCRONIZZATO || element.stato === OnboardingEsternoStatus.DASINCRONIZZARE),
            button: [
                {
                    nameIconButton: 'sync',
                    click: TipoClickEnum.SYNC_EXTERN,
                    tooltip: 'cycle_doctorate.esterni.sync_esterno',
                    color: 'accent',
                    show: (value, element) => currentAdminRuoli?.find(r => administrationRolesWhichCanEdit.includes(r))
                        && element.stato === OnboardingEsternoStatus.DASINCRONIZZARE,
                    valueShow: 'stato'
                },
                {
                    nameIconButton: 'sync_disabled',
                    click: TipoClickEnum.DESYNC_EXTERN,
                    tooltip: 'cycle_doctorate.esterni.desync_esterno',
                    color: 'accent',
                    show: (value, element) => currentAdminRuoli?.find(r => administrationRolesWhichCanEdit.includes(r))
                        && element.stato === OnboardingEsternoStatus.SINCRONIZZATO,
                    valueShow: 'stato'
                },
            ]
        });
    }
    return {
        configuration: {
            data: esterniForUI,
            totalElements: pageEsterni?.totalElements,
            pageSize: pageSize,
            pageBE: page,
            pageSizeOptions: [10, 20, 30, 40, 50],
            isPaginatedBE: true,
            sticky: true,
            hidePaginator: false,
            configurazioneTabella: colDef,
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: 'custom_table.no_data_esterni'
        }
    };

}
