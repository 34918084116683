<ng-container *transloco="let t">
    <app-dialog-wrap class="w-full" [data]="data">

        <mat-dialog-content>

            <!-- DELIBERA -->
<!--            <form class="w-full flex flex-col md:flex-row gap-3 items-start mt-2" [formGroup]="form">-->

<!--                <mat-form-field appearance="outline"-->
<!--                                class="w-full add-mobilita-mat-form-field"-->
<!--                                *ngIf="form?.get('deliberaCollegio') as fieldCtrl">-->
<!--                    <mat-label><strong>{{t('mobility.collegio_approval')}}</strong></mat-label>-->
<!--                    <mat-select formControlName='deliberaCollegio'>-->
<!--                        <mat-option *ngFor="let delibera of delibere" [value]="delibera.id">-->
<!--                            {{t('mobility.delibera_name')}} {{delibera.numero}} - {{formatDeliberaDate(delibera?.data)}}-->
<!--                        </mat-option>-->
<!--                    </mat-select>-->
<!--                    &lt;!&ndash;                    <mat-error *ngIf="fieldCtrl.hasError('required')">-->
<!--                                            {{t('form.required')}}-->
<!--                                        </mat-error>&ndash;&gt;-->

<!--                </mat-form-field>-->

<!--                <button [color]="'primary'"-->
<!--                        mat-raised-button-->
<!--                        type="button"-->
<!--                        class="add-delibera-button"-->
<!--                        (click)="this.openAddNewDeliberaDialog()">-->
<!--                    <mat-icon class="mr-2">add</mat-icon>-->
<!--                    {{t('common.add_delibera')}}-->
<!--                </button>-->

<!--            </form>-->

            <app-form [formGroupConfig]="documentsFormConfig"
                      [valueForm]="documentsFormValue"
                      [touched]="touched"
                      [noBottomPadding]="true"
                      [noXPadding]="true"
            ></app-form>

            <!--DOTTORANDI-->
            <div class="w-full flex flex-col gap-4 items-center mt-4" *ngIf="!!studentsListConfiguration">

                <div class="w-full">
                    <fuse-alert class="w-full"
                                [showIcon]="true"
                                [appearance]="'outline'"
                    >{{t('mobility.edit_students')}}</fuse-alert>
                </div>

                <app-studenti-bar-filter-container class="w-full"
                                                   [ngClass]="{'hidden': !this.showTopbar}"
                                                   (closeBar)="closeBar()">
                </app-studenti-bar-filter-container>

                <!-- VISUALIZZA FILTRI BUTTON -->
                <div class="flex items-center w-full flex-row justify-end mt-2" *ngIf="!this.showTopbar">
                    <button mat-flat-button color="primary"
                            [disabled]="showMiniLoader"
                            (click)="getSupervisoriAndCosupervisori$()">
                        <mat-spinner *ngIf="showMiniLoader" [diameter]="20" color="accent"></mat-spinner>
                        <mat-icon class="mr-2" *ngIf="!showMiniLoader">manage_search</mat-icon>
                        {{t('menu_filter.advanced_search')}}
                    </button>
                </div>

                <div class="flex flex-row justify-between pl-2 mr-2 w-full" *ngIf="currentFilterListChipLabels?.length > 0">

                    <div class="mt-0.5 px-2 py-4 flex flex-row gap-4">
                        <!-- TESTO FILTRI APPLICATI -->
                        <div class=" text-2xl font-semibold mt-[0.35rem]">{{t('students_list.applied_filters')}}</div>
                        <!--RESET BUTTON -->
                        <button mat-flat-button
                                (click)="resetFilters()"
                                [color]="'accent'">
                            <mat-icon class="mr-2">delete</mat-icon>
                            <span>{{t('menu_filter.reset')}}</span>
                        </button>
                    </div>


                </div>

                <div class="w-full flex flex-row justify-center items-center" *ngIf="currentFilterListChipLabels?.length > 0">
                    <!-- CHIPS -->
                    <div class="w-full flex items-center">

                        <div class="flex flex-col gap-1.5">

                            <mat-chip-list class="w-full">
                                <div class="w-full flex flex-row gap-1.5 p-2 flex-wrap">
                                    <ng-container *ngFor="let chip of currentFilterListChipLabels">
                                        <mat-chip color="primary" class="justify-between">
                                            {{chip | titlecase}}
                                        </mat-chip>
                                    </ng-container>
                                </div>
                            </mat-chip-list>
                        </div>
                    </div>


                </div>

                <!-- TABELLA STUDENTI -->
                <div class="w-full mt-4 mb-6">
                    <app-generic-table [configuration]="studentsListConfiguration"
                                       (clickAction)="tableClickAction($event)"
                                       (pageAction)="pageAction($event)"
                                       [righeSelezionate]="studentiSelectedForTable"
                                       (tableDataEmitter)="onStudentDataTableChanged($event)">
                    </app-generic-table>
                </div>

            </div>

        </mat-dialog-content>

    </app-dialog-wrap>

    <div
        class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">
        <button color="accent" mat-flat-button [matDialogClose]="'cancelled'">
            <mat-icon class="mr-2">close</mat-icon>
            {{t('common.close')}}
        </button>
        <!-- Confirm -->
        <button mat-flat-button
                (click)="editRequest()"
                [color]="'primary'">
            <span>{{t('dialog.confirm')}}</span>
        </button>
    </div>
</ng-container>
