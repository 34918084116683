/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipologie di Modalità di Firma.
 */
export type ModalitaDiFirmaType = 'CARICAMENTO_FILE' | 'FIRMA_OTP' | 'FIRMA_DIGITALE_USIGN';

export const ModalitaDiFirmaType = {
    CARICAMENTOFILE: 'CARICAMENTO_FILE' as ModalitaDiFirmaType,
    FIRMAOTP: 'FIRMA_OTP' as ModalitaDiFirmaType,
    FIRMADIGITALEUSIGN: 'FIRMA_DIGITALE_USIGN' as ModalitaDiFirmaType
};