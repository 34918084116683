import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Subject} from 'rxjs';
import {
    AuthorityType,
    MobilitaRequestSelectFormValuesDTO,
    PeriodoRegistroAttivitaStatus
} from 'api-clients/generated/services';
import {LocalStorageService} from "../../../../../shared/service/local-storage.service";
import {ApplyFiltersQuicklyStudentsI} from "../../../students-list/students-list.component";



export interface RegistroAttivitaFilters {
    nominativoStudente?: string;
    cfStudente?: string;
    matricolaStudente?: string;
    statiRegistro?: PeriodoRegistroAttivitaStatus[];
    showOnlyApprovableRequests?: boolean;
}

@Injectable({
    providedIn: "root"
})
export class RegistroAttivitaFilterService {

    mainFormGroup: FormGroup | undefined;
    selectValues: MobilitaRequestSelectFormValuesDTO;
    checkAggiornamentoSelectValues$ = new BehaviorSubject(undefined);
    reset$ = new BehaviorSubject(false);
    checkApplyFilterClick$ = new BehaviorSubject(false);
    currentSottoRuolo: AuthorityType | undefined;
    filterApplied$ = new BehaviorSubject(undefined);
    filterApplied = undefined;
    page = 0;
    defaultFormPathList = [
        'nominativoStudente',
        'cfStudente',
        'matricolaStudente',
        'statiRegistro',
        'showOnlyApprovableRequests',
    ];
    fieldsLabelMap = new Map<string, string>([
        ['statiRegistro', 'budget.state'],
        ['nominativoStudente', 'common.nominative'],
        ['cfStudente', 'common.fiscal_code'],
        ['matricolaStudente', 'common.enrollment_number'],
        ['showOnlyApprovableRequests', 'menu_filter.only_approvable_by_me'],

    ]);

    openDrawer = new Subject();
    applyFiltersQuickly: ApplyFiltersQuicklyStudentsI;


    constructor(public localStorageService: LocalStorageService,
                public formBuilder: FormBuilder) {
    }


    createDefaultFormGroup(): FormGroup {
        return this.formBuilder.group({
            statiRegistro: null,
            nominativoStudente: null,
            cfStudente: null,
            matricolaStudente: null,
            showOnlyApprovableRequests: null,

        });
    }


    getFieldsByRole(): string[] {
        return this.defaultFormPathList;
    }

    getCtrlByPath(path: string): FormControl<any> {
        return this.mainFormGroup?.get(path) as FormControl;
    }

    init(force?: boolean): void {
        const sottoruolo = this.localStorageService.dipartimentoRuoloCiclo?.sottoruolo;
        if ((this.currentSottoRuolo !== sottoruolo) || !this.currentSottoRuolo) {
            this.currentSottoRuolo = sottoruolo;
            this.buildForm();
        } else if(force){
            this.buildForm();
            this.filterApplied = this.mainFormGroup.getRawValue();
        }

        if(this.applyFiltersQuickly?.statiRegistro) {
            this.mainFormGroup.get('statiRegistro').setValue(this.applyFiltersQuickly?.statiRegistro);
            this.filterApplied = this.mainFormGroup.getRawValue();
        }
        if(this.applyFiltersQuickly?.showOnlyApprovableRequests) {
            this.mainFormGroup.get('showOnlyApprovableRequests').setValue(this.applyFiltersQuickly?.showOnlyApprovableRequests);
            this.filterApplied = this.mainFormGroup.getRawValue();
        }
    }

    private buildForm() {
        if (!!this.mainFormGroup) {
            this.mainFormGroup.reset();
        } else {
            this.mainFormGroup = this.createDefaultFormGroup();
        }
    }






    reset(): void {
        this.page = 0;

        this.mainFormGroup.reset();
        this.filterApplied = this.mainFormGroup.getRawValue();
        this.checkApplyFilterClick$.next(true);
    }


    restore(): void {
        this.page = 0;

        this.mainFormGroup.reset();
        this.buildForm();
        this.filterApplied = this.mainFormGroup.getRawValue();
        this.checkApplyFilterClick$.next(true);
    }
}
