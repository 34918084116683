import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ClickEvent} from "../../../../../shared/components/table/model/generic-table-model";
import {
    AuthorityType,
    ChiaveFlussoEnum,
    ChiaveOperazioneEnum,
    DeliberaInfoView,
    DeliberaInfoViewImpl,
    DelibereService,
    MobilitaRequestSelectFormValuesDTO,
    PeriodiDiMobilitaService,
    PeriodoDiMobilitaStudenteInfoView,
    TipoPeriodoEnum
} from "../../../../../../api-clients/generated/services";
import {BehaviorSubject, finalize, startWith, Subscription, switchMap, take, takeUntil, tap} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {TranslocoService} from "@ngneat/transloco";
import * as moment from "moment/moment";
import {formGroupConfigInterface} from "../../../../../layout/common/generic-components/generic-components.interface";
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CAN_GO_AHEAD$, CURRENT_PROFILE, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {MatDialog} from "@angular/material/dialog";
import {filter} from "rxjs/operators";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {CustomValidators} from "../../../../../shared/validators/custom-validators";
import {FormComponent} from "../../../../../layout/common/generic-components/form/form.component";
import {CicloConfigurationService, DocumentDataType} from "../../../../../shared/service/ciclo-configuration.service";
import {ApprovazioneConclusioneMobilitaMassivaDataI} from "../approvazione-conclusione-mobilita-steps-interface";
import {StudentDetailsService} from "../../../student-details/student-details.service";


@Component({
    selector: 'app-info-approvazione-conclusione-mobilita-stepper',
    templateUrl: './compila-info-approvazione-conclusione-mobilita.component.html',
    styleUrls: ['./compila-info-approvazione-conclusione-mobilita.component.scss']
})
export class CompilaInfoApprovazioneConclusioneMobilitaComponent extends AbstractDefaultComponent implements OnInit {

    loading: boolean;
    formConfig: formGroupConfigInterface[];
    valueForm: any;
    form: FormGroup;
    periodiSelected: PeriodoDiMobilitaStudenteInfoView[];
    delibere: DeliberaInfoViewImpl[];
    outputFormatData = 'DD/MM/YYYY';
    periodiDetailsFormArray: FormArray;
    formSelectValues: MobilitaRequestSelectFormValuesDTO;
    protected readonly AuthorityType = AuthorityType;
    protected readonly TipoPeriodoEnum = TipoPeriodoEnum;
    documentsFormConfig: formGroupConfigInterface[];
    documentsFormValue: DocumentDataType;
    _docsFormComponent: FormComponent;
    docsFormComponentSub: Subscription;
    @ViewChild(FormComponent) set docsFormComponent(docsFormComp: FormComponent) {
        this._docsFormComponent = docsFormComp;
        // subscribe to document form value change
        this.subscribeToDocsFormValueChange();
    };

    private subscribeToDocsFormValueChange() {
        this.canGoNext$.next(this._docsFormComponent.formGroup.valid && this.periodiDetailsFormArray.valid);
        this.docsFormComponentSub?.unsubscribe();
        this.docsFormComponentSub = this._docsFormComponent?.formGroup?.valueChanges
            ?.pipe(startWith(this._docsFormComponent?.formGroup?.value)).subscribe(docsFormValue => {
            this.canGoNext$.next(this._docsFormComponent.formGroup.valid && this.periodiDetailsFormArray.valid);
            const documents = this.cicloConfigurationService.prepareDocsSubmitObject(
                ChiaveFlussoEnum.RichiestaMobilita,
                this.currentProfile === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneConclusioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneConclusioneSupervisore,
                docsFormValue,
            );
            this.operazioneMassivaData$.next({
                ...this.operazioneMassivaData$?.getValue(),
                //deliberaCollegio: this.delibere?.find(d => d.id === deliberaId),
                documentiAllegati: documents
            });
        });
    }

    constructor(private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private appInitService: AppInitService,
                private delibereService: DelibereService,
                private fb: FormBuilder,
                private dialog: MatDialog,
                private periodiDiMobilitaService: PeriodiDiMobilitaService,
                private cicloConfigurationService: CicloConfigurationService,
                private studentDetailsService: StudentDetailsService,
                @Inject(CURRENT_PROFILE) protected currentProfile: AuthorityType,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneConclusioneMobilitaMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneConclusioneMobilitaMassivaDataI) => {
            // if confirm step force refresh set as not completed the step
            if(inputOutputData.refreshPeriodi){
                this.canGoNext$.next(false);
                this.periodiDetailsFormArray = undefined;
            }
            // if studenti selected changes reset delibera form and get plans for selected students
            if(inputOutputData.periodiSelected !== this.periodiSelected){
                this.periodiSelected = inputOutputData?.periodiSelected;
                //this.form?.get('deliberaCollegio')?.setValue(inputOutputData.deliberaCollegio?.id, {emitEvent: false});
                this._docsFormComponent?.formGroup?.reset({emitEvent: false});
                this.buildPeriodiDetailsFormGroup(this.periodiSelected);
            }
            this.buildCompileInfoComponent();
        });
        // get form select values
        this.getFormSelectValues();
    }

   private buildCompileInfoComponent() {
        if(!!this.documentsFormConfig){
            return
        }
        this.documentsFormConfig =  [
            ...this.cicloConfigurationService.getDocsFormConfig(
                ChiaveFlussoEnum.RichiestaMobilita,
                this.currentProfile === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneConclusioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneConclusioneSupervisore,
                (fileUrl) => this.downloadDocument(fileUrl),
                undefined,
            )
        ];
        this.documentsFormValue = {
            ...this.cicloConfigurationService.getDocsFormValues(
                ChiaveFlussoEnum.RichiestaMobilita,
                this.currentProfile === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneConclusioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneConclusioneSupervisore,
                [],
            )
        };
    }

    downloadDocument(urlFile: string, deliberaData?: DeliberaInfoView) {
        if(deliberaData) {
            this.fuseConfirmationService.openDialogDelibera({
                delibera: deliberaData,
                codiceCorsoDiStudiEsse3: this.studentDetailsService.studentDetails?.codiceCorsoDiStudiEsse3,
            });
        }

    }

    formatDay(date: string) {
        return moment(new Date(date)).format('DD/MM/YYYY');
    }

    private getFormSelectValues() {
        this.fuseConfirmationService.showLoader();
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.periodiDiMobilitaService.getMobilitaRequestSelectFormValues()),
            tap(selectValues => this.formSelectValues = selectValues),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: () => {
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {},() => this.getFormSelectValues(),
                    'dialog.cancel',
                    err?.error?.message);
            }
        });
    }

    tableClickAction($event: ClickEvent) {

    }


    private buildPeriodiDetailsFormGroup(selectedPeriods: PeriodoDiMobilitaStudenteInfoView[]) {
        this.periodiDetailsFormArray = this.fb.array([]);
        // subscribe to form group change
        this.periodiDetailsFormArray.valueChanges?.pipe(startWith(this.periodiDetailsFormArray.value)).subscribe(fa => {
            console.log('details value changed', fa)
            this.canGoNext$.next(this.periodiDetailsFormArray.valid && this._docsFormComponent?.formGroup?.valid);
            this.operazioneMassivaData$.next({
                ...this.operazioneMassivaData$?.getValue(),
                compileInfo: fa?.map(value => ({}))
            });
        })
    }

    private getPeriodoDetailsForm(period: PeriodoDiMobilitaStudenteInfoView) {
        return this.fb.group({});
    }

    clearDateField(fieldCtrl: AbstractControl) {
        fieldCtrl.setValue(undefined);
        fieldCtrl.setErrors(null);
    }


}
