import {Component, Inject, OnInit, Pipe} from '@angular/core';
import {DialogWrapI} from "../../../layout/common/dialog-wrap/dialog-wrap.component";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogSelectDeliberaComponent} from "../dialog-select-delibera/dialog-select-delibera.component";
import {ButtonType} from "../custom-button/custom-button.component";
import {TypeDialogFormEnum} from "../../../layout/common/generic-components/generic-components-enum";
import {FormControl} from "@angular/forms";
import {
    ChiaveDocumentoEnum,
    ChiaveFlussoEnum, ChiaveOperazioneEnum,
    DocumentoInfoView,
    ModalitaDiFirmaType
} from "../../../../api-clients/generated/services";
import {getFormattedDocumentName} from "../document/document.component";

export interface DocumentToSignI {
    documentoInfo: DocumentoInfoView;
    userId: string;
    labelNoTranslate?: string;
    label?: string;
    ctrl: FormControl;
    download: () => void;
    formattedDocName: string;
}

export interface DialogSignDocumentI extends DialogWrapI {
    availableSignMethods: ModalitaDiFirmaType[];
    documents: DocumentToSignI[];
    chiaveDocumento: ChiaveDocumentoEnum;
    chiaveFlusso: ChiaveFlussoEnum;
    chiaveOperazione: ChiaveOperazioneEnum;
    documentsSignedSuccessfullyCallback: () => void;
}

@Component({
    selector: 'app-dialog-sign-document',
    templateUrl: './dialog-sign-document.component.html',
    styleUrls: ['./dialog-sign-document.component.scss']
})
export class DialogSignDocumentComponent implements OnInit {

    //NB use ONLY for documents of the same flow/operation

    loading: boolean;
    availableSignMethodsFiltered: ModalitaDiFirmaType[];
    hideChooseMethod: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogSignDocumentI,
                public dialogRef: MatDialogRef<DialogSelectDeliberaComponent>,) {
    }

    ngOnInit(): void {
        // removing caricamento file if files are more than one
        this.availableSignMethodsFiltered = this.data?.availableSignMethods
            ?.filter(m => m != ModalitaDiFirmaType.CARICAMENTOFILE || this.data.documents?.length == 1);
    }

    closeDialog() {
        this.dialogRef.close(true);
    }

    protected readonly ButtonType = ButtonType;
    selectedMode: ModalitaDiFirmaType;
    protected readonly TypeDialogFormEnum = TypeDialogFormEnum;

    getSignMethodString(selectedMode: ModalitaDiFirmaType) {
        switch (selectedMode){
            case ModalitaDiFirmaType.FIRMAOTP:
                return 'document_sign.otp_sign';
            case ModalitaDiFirmaType.CARICAMENTOFILE:
                return 'document_sign.upload_file_sign';
        }
    }

    protected readonly ModalitaDiFirmaType = ModalitaDiFirmaType;

    chooseAnotherMethod() {
        if(!this.loading){
            this.selectedMode = undefined;
        }
    }

    documentsSignedSuccessfully({closeDialog, errorsForAllDocuments}: {closeDialog: boolean, errorsForAllDocuments: boolean}) {
        if(!errorsForAllDocuments){
            this.data.documentsSignedSuccessfullyCallback();
        }
        if(closeDialog) {
            this.dialogRef.close();
        }
    }

    protected readonly getFormattedDocumentName = getFormattedDocumentName;
}
