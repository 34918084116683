import {
    cannotRemoveExtraFromPlan,
    getCfuOttentutiFromApprovedPlan,
    getCfuPrevisti,
    getDataSuperamentoValueFromApprovedPlan,
    getMotivazioneRifiutoValueFromApprovedPlan,
    getNotaSuperamentoValueFromApprovedPlan,
    getStatoAttivitaExtraFromApprovedPlan,
    getSuperatoValueFromApprovedPlan,
    unifyDocentiAndExtra
} from '../../study-plan-utils';
import {
    AggiornamentoPianoDiStudiInfoView, AnnoRiferimentoValues,
    AttivitaExtraPianoDiStudiStatus,
    CategoriaInAggiornamentoInPianoDiStudiInfoView,
    CategoriaInPianoDiStudiInfoView,
    DatiCorsoOffertaFormativa,
    PianoDiStudiInfoViewImpl
} from '../../../../../../../api-clients/generated/services';
import {
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from '../../../../../../shared/components/table/model/generic-table-model';
import {get, sortBy, trim} from "lodash";
import {
    createPercorsoDiStudiListString,
    getPresumedNumbers,
    getPresumedNumbersForDraft
} from "./configurazione-corsi-nuova-presentazione";
import {
    TrainingOfferCategoryDataUI
} from "../../../../training-offer-study-plan-shared-module/components/activities-categories/activities-categories.component";
import {Translation} from "@ngneat/transloco";
import {UIActivityI} from "../../study-plans-categories-utils";
import {annoRiferimentoFromRomanNumeral} from "../../../../../../shared/utils/utils";
import {filterActivitiesAccordingArchived} from "../study-plan-configurations-utils";


export function buildConfigurationForExtraActivitiesInNewPresentationReadMode(translation: Translation,
                                                                              category: CategoriaInPianoDiStudiInfoView | CategoriaInAggiornamentoInPianoDiStudiInfoView,
                                                                              year: AnnoRiferimentoValues,
                                                                              approvedPlan?: PianoDiStudiInfoViewImpl,
                                                                              showingOfferta?: boolean): GenericTableConfigurationModel {
    const coursesPlan = category.corsi;

    //count get presumed numbers
    const presumedNumbers = getPresumedNumbersForDraft(category);

    let tableData = coursesPlan?.map(element => ({
        ...(element?.datiCorsoOffertaFormativa ?? element),
        isArchiviato: element?.datiCorsoOffertaFormativa.isArchiviato || element?.datiCorsoOffertaFormativa.isAssociazioneArchiviata,
    }));

    tableData =
        tableData?.map(item => ({
            ...item,
            includedInPreviousPlan: !!approvedPlan.categorie
                ?.find(c => c.idCategoriaOffertaFormativa === category.idCategoriaOffertaFormativa)?.corsi
                ?.find(a => a?.idCorsoInOffertaFormativa === item.idCorsoInOffertaFormativa && a?.annoInsegnamento === year),
            cfuRatio: getCfuOttentutiFromApprovedPlan(item, approvedPlan, category.idCategoriaOffertaFormativa) + '/' + getCfuPrevisti(item, approvedPlan, category.idCategoriaOffertaFormativa),
            organizzatori: item?.organizzatori?.map(organizzatore => ({nome: organizzatore})),
            cfuPrevisti: getCfuPrevisti(item, approvedPlan, category.idCategoriaOffertaFormativa),
            cfuOttenuti: getCfuOttentutiFromApprovedPlan(item, approvedPlan, category.idCategoriaOffertaFormativa),
            docentiTutti: unifyDocentiAndExtra(item),
            statoAttivitaExtra: getStatoAttivitaExtraFromApprovedPlan(item, approvedPlan, category.idCategoriaOffertaFormativa),
            idCategoriaOffertaFormativa: category.idCategoriaOffertaFormativa,
            superato:  getSuperatoValueFromApprovedPlan(item, approvedPlan, category.idCategoriaOffertaFormativa),
            notaSuperamento: getNotaSuperamentoValueFromApprovedPlan(item, approvedPlan, category.idCategoriaOffertaFormativa),
            dataSuperamento: getDataSuperamentoValueFromApprovedPlan(item, approvedPlan, category.idCategoriaOffertaFormativa),
            motivazioneRifiuto: getMotivazioneRifiutoValueFromApprovedPlan(item, approvedPlan, category.idCategoriaOffertaFormativa),
            id: item.id ?? item.idAttivitaProposta ?? item.idAttivitaPresunta,
            denominazione: item.denominazione ?? item.denominazioneAttivitaPresunta,
            denominazioneFormatted: (item.idAttivitaPresunta && !item.denominazione) ? (get(translation, 'study_plan_status.presumed_activity') + ' ' + presumedNumbers.pop()) : item.denominazione,
        }));

    tableData = sortBy(tableData, [
        attivita => !attivita.idAttivitaProposta,
        attivita => !attivita.idAttivitaPresunta,
        attivita => attivita?.denominazioneFormatted?.toLowerCase()?.trim(),
    ], ['asc', 'asc', 'asc',]);

    return {
        configuration: {
            data: tableData,
            totalElements: tableData?.length,
            isPaginatedBE: false,
            sticky: true,
            selection: showingOfferta ? {
                isSelectable: true,
                selectedColumnKeyToShow: 'denominazione',
                selectedColumnKeyPrimaryKey: 'id',
            } : undefined,
            configurazioneTabella: [

                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.activity_denomination',
                    colonnaKey: 'denominazioneFormatted',
                    flex: 37,
                    alsoShowChipIf: (activity) => {
                        if(activity.idAttivitaProposta){
                            return {
                                chipText: 'study_plan_status.proposed_activity',
                                iconName: 'school',
                                classes: 'text-blue-600'
                            };
                        } else if (activity.idAttivitaPresunta){
                            return {
                                chipText: activity.denominazione ? 'study_plan_status.presumed_activity_defined' : 'study_plan_status.presumed_activity_not_defined',
                                iconName: 'question_mark',
                                classes: activity.denominazione ? 'text-green-600' : 'text-orange-600'
                            };
                        } else {
                            return undefined;
                        }
                    },
                    // showAlsoIconIf: (activity) => isCorsoArchivedRemovableForTable(activity) ? {
                    //     name: 'warning_amber',
                    //     tooltipText: 'study_plan_status.archived_to_remove',
                    //     color: 'warn'
                    // } : undefined,
                },
                {
                    tipo: TipoColonnaEnum.NUMBER,
                    nomeColonna: 'common.cfu',
                    colonnaKey: 'cfu',
                    flex: 5,
                },
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'cycle_doctorate.final_check',
                    colonnaKey: 'verificaFinale',
                    flex: 15,
                    show: (_, activity) => !activity.idAttivitaProposta && !activity.idAttivitaPresunta,
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no',
                    hideColonna: true
                },
                {
                    tipo: TipoColonnaEnum.CHIP_USER,
                    nomeColonna: 'cycle_doctorate.organizer',
                    colonnaKey: 'organizzatori',
                    isChipDisabled: true,
                    flex: 25,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'common.status',
                    colonnaKey: 'statoAttivitaExtra',
                    flex: 20,
                    statusType: AttivitaExtraPianoDiStudiStatus,
                    show: (_, activity) => (activity.includedInPreviousPlan || activity.idAttivitaProposta) && (!activity.idAttivitaPresunta || activity.denominazione),
                },
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'cycle_doctorate.mandatory',
                    colonnaKey: 'isObbligatorio',
                    flex: 8,
                    show: (_, activity) => !activity.idAttivitaProposta && !activity.idAttivitaPresunta,
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                },
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'study_plan_status.archived_activity',
                    colonnaKey: 'isArchiviato',
                    flex: 8,
                    show: (_, activity) => !activity.idAttivitaProposta && !activity.idAttivitaPresunta,
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                },
                {
                    tipo: TipoColonnaEnum.ACTION,
                    nomeColonna: 'common.detail',
                    colonnaKey: 'descrizione',
                    flex: 10,
                    button: [{
                        nameIconButton: 'info',
                        click: TipoClickEnum.SHOW,
                        tooltip: 'common.show_detail',
                        color: 'accent'
                    }]
                },
            ],
            hidePaginator: true,
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: 'custom_table.no_data_activities_for_year'
        }
    };
}

export function buildConfigurationForExtraActivitiesInNewPresentationEditMode(translation: Translation,
                                                                              category: TrainingOfferCategoryDataUI,
                                                                              draft?: AggiornamentoPianoDiStudiInfoView,
                                                                              approvedPlan?: PianoDiStudiInfoViewImpl,
                                                                              showingOfferta?: boolean,
                                                                              selectedAtt?: { key: string, data: UIActivityI }[]): GenericTableConfigurationModel {

    const activities = category.attivitaCategoriaAssociations;

    //count get presumed numbers
    const presumedNumbers = getPresumedNumbers(category);

    let tableData: any = activities?.map(item => ({
        ...item?.attivitaOffertaFormativa,
        // activity can be archived globally or in category-activity association
        isArchiviato: item.isArchiviato || item?.attivitaOffertaFormativa.isArchiviato,
        isArchiviatoActivity: item?.attivitaOffertaFormativa.isArchiviato,
        isArchiviatoAssociazione: item.isArchiviato
    }));

    tableData =
        tableData?.map(item => ({
            ...item,
            includedInPreviousPlan: !!approvedPlan.categorie
                ?.find(c => c.idCategoriaOffertaFormativa === category.id)?.corsi
                ?.find(a => a?.idCorsoInOffertaFormativa === item.id && a?.annoInsegnamento === annoRiferimentoFromRomanNumeral(category.year)),
            curriculumRiferimento: createPercorsoDiStudiListString(item?.percorsiDiStudi),
            organizzatori: item?.organizzatori?.map(organizzatore => ({nome: organizzatore})),
            id: item.id ?? (item as DatiCorsoOffertaFormativa).idAttivitaProposta ?? (item as DatiCorsoOffertaFormativa).idAttivitaPresunta,
            denominazione: item.denominazione ?? item.denominazioneAttivitaPresunta,
            denominazioneFormatted: (item.idAttivitaPresunta && !item.denominazione) ? (get(translation, 'study_plan_status.presumed_activity') + ' ' + presumedNumbers.pop()) : item.denominazione,
            statoAttivitaExtra: getStatoAttivitaExtraFromApprovedPlan(item, approvedPlan, category.id),
            idCategoriaOffertaFormativa: category.id,
            cfuPrevisti: getCfuPrevisti(item, approvedPlan, category.id),
            cfuOttenuti: getCfuOttentutiFromApprovedPlan(item, approvedPlan, category.id),
            superato:  getSuperatoValueFromApprovedPlan(item, approvedPlan, category.id),
            notaSuperamento: getNotaSuperamentoValueFromApprovedPlan(item, approvedPlan, category.id),
            dataSuperamento: getDataSuperamentoValueFromApprovedPlan(item, approvedPlan, category.id),
            motivazioneRifiuto: getMotivazioneRifiutoValueFromApprovedPlan(item, approvedPlan, category.id),
            selected: selectedAtt.find(selAtt => selAtt.key === item.id)
        }) as any);

    // showing archived only if they are in the current approved plan
    tableData = tableData.filter(a => (!a.isArchiviatoActivity && !a.isArchiviatoAssociazione) || a.includedInPreviousPlan);

    tableData = filterActivitiesAccordingArchived(tableData);

    tableData = sortBy(tableData, [
        attivita => !(attivita as DatiCorsoOffertaFormativa).idAttivitaProposta,
        attivita => !(attivita as DatiCorsoOffertaFormativa).idAttivitaPresunta,
        attivita => attivita.selected,
        attivita => trim(attivita?.denominazioneFormatted?.toLowerCase()),
    ], ['asc', 'asc', 'asc', ]);

    return {
        configuration: {
            data: tableData,
            totalElements: tableData?.length,
            isPaginatedBE: false,
            sticky: true,
            selection: showingOfferta ? {
                isSelectable: true,
                selectedColumnKeyToShow: 'denominazione',
                selectedColumnKeyPrimaryKey: 'id',
                selectedAndDisableByDefaultIf: (activity) => {
                    //if (activity.isObbligatorio && !approvedPlan.sottomissionePianoNonValidoSbloccata && selectedAtt.find(sa => sa.key === activity.id)){
                    //    return 'study_plan_status.cant_remove_because_mandatory';
                    //} else
                    if (cannotRemoveExtraFromPlan(activity)){
                        return 'study_plan_status.cant_remove_because_superato';
                    }
                },
                selectedByDefaultIfAndCallbackOnUnselect: (activity) => activity.idAttivitaProposta || activity.idAttivitaPresunta,
            } : undefined,
            disabledCssClass: 'generic-table-disabled-row',
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.activity_denomination',
                    colonnaKey: 'denominazioneFormatted',
                    flex: 30,
                    alsoShowChipIf: (activity) => {
                        if(activity.isArchiviato || activity.isArchiviatoAssociazione){
                            return {
                                chipText: 'study_plan_status.archived_globally',
                                iconName: 'inventory_2',
                                classes: 'text-gray-600'
                            };
                        } else if(activity.idAttivitaProposta){
                            return {
                                chipText: 'study_plan_status.proposed_activity',
                                iconName: 'school',
                                classes: 'text-blue-600'
                            };
                        } else if (activity.idAttivitaPresunta){
                            return {
                                chipText: activity.denominazione ? 'study_plan_status.presumed_activity_defined' : 'study_plan_status.presumed_activity_not_defined',
                                iconName: 'question_mark',
                                classes: activity.denominazione ? 'text-green-600' : 'text-orange-600'
                            };
                        } else {
                            return undefined;
                        }
                    },
                    // showAlsoIconIf: (activity) => isCorsoArchivedRemovableForTable(activity) ? {
                    //     name: 'warning_amber',
                    //     tooltipText: 'study_plan_status.archived_to_remove',
                    //     color: 'warn'
                    // } : undefined,
                },
                {
                    tipo: TipoColonnaEnum.NUMBER,
                    nomeColonna: 'common.cfu',
                    colonnaKey: 'cfu',
                    flex: 5,
                },
                {
                    tipo: TipoColonnaEnum.NUMBER,
                    nomeColonna: 'total_hour_table.column_total_hour',
                    colonnaKey: 'ore',
                    flex: 3,
                },
/*                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.disbursement_period',
                    colonnaKey: 'periodoErogazione',
                    flex: 10,
                    hideColonna: true
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.reference_curriculum',
                    colonnaKey: 'curriculumRiferimento',
                    flex: 35,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.ssd',
                    colonnaKey: 'ssd',
                    flex: 8,
                    hideColonna: true
                },*/
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'cycle_doctorate.final_check',
                    colonnaKey: 'verificaFinale',
                    flex: 10,
                    show: (_, activity) => !activity.idAttivitaProposta && !activity.idAttivitaPresunta,
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no',
                    hideColonna: true,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_USER,
                    nomeColonna: 'cycle_doctorate.organizer',
                    colonnaKey: 'organizzatori',
                    isChipDisabled: true,
                    show: (_, activity) => !activity.idAttivitaProposta && !activity.idAttivitaPresunta,
                    flex: 25,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.organizational_authority',
                    colonnaKey: 'enteOrganizzativo',
                    show: (_, activity) => !activity.idAttivitaProposta && !activity.idAttivitaPresunta,
                    flex: 10,
                    hideColonna: true
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'common.status',
                    colonnaKey: 'statoAttivitaExtra',
                    flex: 20,
                    statusType: AttivitaExtraPianoDiStudiStatus
                },
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'cycle_doctorate.mandatory',
                    colonnaKey: 'isObbligatorio',
                    flex: 8,
                    show: (_, activity) => !activity.idAttivitaProposta && !activity.idAttivitaPresunta,
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                },
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'study_plan_status.archived_activity',
                    colonnaKey: 'isArchiviato',
                    flex: 8,
                    show: (_, activity) => !activity.idAttivitaProposta && !activity.idAttivitaPresunta,
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                },
                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 8,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    button: [
                        {
                            nameIconButton: 'info',
                            click: TipoClickEnum.SHOW,
                            tooltip: 'common.show_detail',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'edit',
                            click: TipoClickEnum.EDIT,
                            tooltip: 'common.edit',
                            valueShow: 'id',
                            show: (_, activity) => (activity.idAttivitaPresunta || activity.idAttivitaProposta) && !cannotRemoveExtraFromPlan(activity) && !category.isArchiviata,
                            color: 'accent',
                        },
                        {
                            nameIconButton: 'done',
                            click: TipoClickEnum.CONFERMA,
                            tooltip: 'study_plan_status.fill_presumed',
                            valueShow: 'id',
                            show: (_, activity) => (activity.idAttivitaPresunta && !activity.denominazione),
                            color: 'accent',
                        },
                        {
                            nameIconButton: 'delete',
                            click: TipoClickEnum.DELETE,
                            valueShow: 'id',
                            show: (_, activity) => (activity.idAttivitaPresunta || activity.idAttivitaProposta) && !cannotRemoveExtraFromPlan(activity),
                            tooltip: 'common.delete',
                            color: 'accent',
                        },
                    ]
                }
            ],
            hidePaginator: false,
            pageSizeOptions: [10, 20, 30, 40, 50],
            pageSize: 10,
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: 'custom_table.no_data_activities_for_year'
        }
    };
}
