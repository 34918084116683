<ng-container *transloco="let t">

    <app-dialog-wrap [data]="data"
                     [class.readonly-all]="loading$ | async"
                     (close$)="closeDialog()">

        <div class="flex flex-col items-center pb-2 gap-2">

            <ng-container *ngIf="data.topAlertTextLabel">
                <fuse-alert
                    class="w-full fuse-alert-reduced-padding-medium"
                    [appearance]="'outline'"
                    [type]="'warning'">
                    {{ t(data.topAlertTextLabel) }}
                </fuse-alert>
            </ng-container>

            <mat-stepper class="w-full sticky-stepper mat-stepper-add-category "
                         [ngClass]="{'stepper-bg-config-0': categoryStepperSelectedStepIndex == 0,
                                     'stepper-bg-config-1': categoryStepperSelectedStepIndex == 1,
                                     'stepper-bg-config-2': categoryStepperSelectedStepIndex == 2,
                                     'stepper-bg-config-3': categoryStepperSelectedStepIndex == 3 ,
                                     'stepper-bg-config-4': categoryStepperSelectedStepIndex == 4 }"
                         [linear]="true"
                         #categoryStepper
                         [(selectedIndex)]="categoryStepperSelectedStepIndex"
                         (selectionChange)="stepperSelectionChange($event)"
                         [animationDuration]="0">
                <!-- INFO GENERALI -->
                <mat-step [stepControl]="infoGeneraliFormGroup" class="bg-slate-100" [completed]="data.wantsToEditConfig">
                    <form [formGroup]="infoGeneraliFormGroup">
                        <ng-template matStepLabel>
                            <div class="flex flex-row gap-5 items-center">
                                <span>{{ t('dialog_add_edit_categories.info') }}</span>
                                <mat-icon
                                    *ngIf="infoGeneraliFormGroup.invalid"
                                    class="icon-size-5 text-amber-500"
                                    [matTooltip]="t('form.not_valid')">
                                    warning_amber
                                </mat-icon>
                            </div>
                        </ng-template>
                        <fuse-alert
                            class="mb-3 w-full mt-4 fuse-alert-reduced-padding-medium"
                            [appearance]="'outline'"
                            [type]="'info'">
                            {{ t('dialog_add_edit_categories.info_message') }}
                        </fuse-alert>
                        <!--DENOMINAZIONE-->
                        <mat-form-field appearance="outline" class="w-full add-course-mat-form-field"
                                        *ngIf="infoGeneraliFormGroup?.get('denominazione') as fieldCtrl">
                            <mat-label>{{ t('dialog_add_edit_categories.denomination') }}</mat-label>
                            <input matInput
                                   [placeholder]="t('dialog_add_edit_categories.denomination')"
                                   [formControlName]="'denominazione'">
                            <mat-error *ngIf="fieldCtrl.hasError('required')"
                                       class="pb-2">
                                {{ t('form.required') }}
                            </mat-error>
                        </mat-form-field>

                        <!--DESCRIZIONE-->
                        <mat-form-field class="w-full add-course-mat-form-field" appearance="outline">
                            <mat-label>{{ t('dialog_add_edit_categories.description') }}</mat-label>
                            <textarea
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="4"
                                cdkAutosizeMaxRows="4"
                                (blur)="descrizioneCtrl.setValue(descrizioneCtrl?.value?.trim())"
                                [placeholder]="t('dialog_add_edit_categories.description')"
                                [formControl]="descrizioneCtrl"
                                matInput
                            ></textarea>
                        </mat-form-field>

                        <!-- NOTE -->
                        <mat-form-field class="w-full add-course-mat-form-field" appearance="outline">
                            <mat-label>{{ t('common.note') }}</mat-label>
                            <textarea
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="4"
                                cdkAutosizeMaxRows="4"
                                (blur)="noteCtrl.setValue(noteCtrl?.value?.trim())"
                                [placeholder]="t('common.note')"
                                [formControl]="noteCtrl"
                                matInput
                            ></textarea>
                        </mat-form-field>

                        <div class="flex flex-row justify-end w-full div-button-sticky-add-edit-category pb-4 bg-slate-100">
                            <app-custom-button
                                [type]="ButtonType.NEXT"
                                [disableDirective]="true"
                                (clickHandler)="nextCategoryStep(infoGeneraliFormGroup.invalid)">
                            </app-custom-button>
                        </div>
                    </form>
                </mat-step>
                <!-- CONFIGS  -->
                <mat-step *ngFor="let configFormGroup of configFormGroups; let configIndex = index; trackBy: trackByFnConfigs"
                          [completed]="data.wantsToEditConfig"
                          [stepControl]="configFormGroup">
                    <ng-template matStepLabel>
                        <div class="flex flex-row gap-5 items-center">
                            <span *ngIf="configFormGroup.get('anni')?.value?.length > 0; else newConfigTmpl">
                                <span>{{ t('dialog_add_edit_categories.configuration') }}</span>
                                <span>
                                 {{ ' (' + (configFormGroup.get('anni')?.value | annoInsegnamentoToNumeriRomani)  + ' ' + t('common.year') + ')' }}
                                </span>
                            </span>
                            <ng-template #newConfigTmpl>
                                <span>{{ t('dialog_add_edit_categories.configuration') }}</span>
                            </ng-template>
                            <mat-icon
                                *ngIf="configFormGroup.invalid"
                                class="icon-size-5 text-amber-500"
                                [matTooltip]="t('form.not_valid')">
                                warning_amber
                            </mat-icon>
                            <button
                                *ngIf="canDeleteSomeConfig && (configIndex + 1) == categoryStepperSelectedStepIndex"
                                mat-raised-button
                                class="shadow-none"
                                style="min-height: 30px; max-width: 85px; height: 30px;"
                                [matTooltip]="'Elimina configurazione'"
                                color="warn"
                                type="button"
                                (click)="removeConfigAtIndex(configIndex); $event.stopPropagation();">
                                <mat-icon class="mr-1 icon-size-4">delete</mat-icon>
                                {{ t('dialog_add_edit_categories.delete') }}
                            </button>
                        </div>
                    </ng-template>
                    <form [formGroup]="configFormGroup">

                        <ng-container *ngIf="anniRiferimentoAvailable[configIndex]?.length > 0; else noRemainingYearsTmpl">
                            <mat-stepper class="w-full sticky-stepper-configs bg-slate-100 stepper-content-no-padding mat-stepper-add-category-unset pt-2"
                                         [linear]="true"
                                         #configStepper>

                                <!-- ANNI -->
                                <mat-step [completed]="data.wantsToEditConfig"
                                          [stepControl]="configFormGroup.get('anni')">
                                    <ng-template matStepLabel>
                                        <div class="flex flex-row gap-5 items-center">
                                            <span>{{ t('dialog_add_edit_categories.configuration_years') }}</span>
                                            <mat-icon
                                                *ngIf="configFormGroup.get('anni').invalid"
                                                class="icon-size-5 text-amber-500"
                                                [matTooltip]="t('form.not_valid')">
                                                warning_amber
                                            </mat-icon>
                                        </div>
                                    </ng-template>
                                    <fuse-alert
                                        class="mb-3 w-full mt-2 fuse-alert-reduced-padding-medium"
                                        [appearance]="'outline'"
                                        [type]="'info'">
                                        {{ t('dialog_add_edit_categories.years_configuration_message') }}
                                    </fuse-alert>
                                    <mat-form-field style="width:100%" class="mt-1">
                                        <mat-label>{{ t('dialog_add_edit_categories.configuration_years') }}</mat-label>
                                        <mat-select multiple formControlName="anni">
                                            <mat-option *ngFor="let anno of anniRiferimentoAvailable[configIndex]" [value]="anno">
                                                {{ (anno | annoInsegnamentoToNumeriRomani)  + ' ' + t('common.year')}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="configFormGroup.get('anni').hasError('required')">{{ t('dialog_add_edit_categories.at_least_one_year') }}</mat-error>
                                    </mat-form-field>


                                    <div class="flex flex-row justify-between w-full div-button-sticky-add-edit-category py-4 bg-slate-100">
                                        <app-custom-button
                                            [type]="ButtonType.PREVIOUS"
                                            [disableDirective]="true"
                                            (clickHandler)="categoryCreationStepper.previous()">
                                        </app-custom-button>
                                        <div class="flex flex-row gap-2">
                                            <app-custom-button
                                                [type]="ButtonType.NEXT"
                                                [disableDirective]="true"
                                                (clickHandler)="nextConfigStep(configStepper, configFormGroup.get('anni').invalid)">
                                            </app-custom-button>
                                        </div>
                                    </div>
                                </mat-step>

                                <!-- ATT OBBLIGATORIE E CFU MINIMI -->
                                <mat-step [completed]="data.wantsToEditConfig"
                                          [stepControl]="configFormGroup.get('numero_attivita_obbligatorie')">
                                    <ng-template matStepLabel>
                                        <div class="flex flex-row gap-5 items-center">
                                            <span>{{ t('dialog_add_edit_categories.activities_and_cfu_min') }}</span>
                                            <mat-icon
                                                *ngIf="configFormGroup.get('numero_attivita_obbligatorie').invalid"
                                                class="icon-size-5 text-amber-500"
                                                [matTooltip]="t('form.not_valid')">
                                                warning_amber
                                            </mat-icon>
                                        </div>
                                    </ng-template>

                                    <fuse-alert
                                        class="mb-3 w-full mt-2 fuse-alert-reduced-padding-medium"
                                        [appearance]="'outline'"
                                        [type]="'info'">
                                        {{ t('dialog_add_edit_categories.activities_and_cfu_min_message') }}
                                    </fuse-alert>

                                    <!-- NUMERO ATT OBBLIGATORIE -->
                                    <mat-form-field appearance="outline" class="w-full add-course-mat-form-field mt-2"
                                                    *ngIf="configFormGroup?.get('numero_attivita_obbligatorie') as fieldCtrl">
                                        <mat-label>{{ t('dialog_add_edit_categories.number_of_mandatory_activities') }}</mat-label>
                                        <mat-icon
                                            class="icon-size-5 mb-3 mr-2 cursor-pointer hidden lg:flex" style="color: #A5AFBB"
                                            [matTooltip]="t('dialog_add_edit_categories.number_of_mandatory_activities_tooltip')"
                                            matTooltipClass="text-base"
                                            matTooltipPosition="before"
                                            matPrefix>info
                                        </mat-icon>
                                        <input matInput
                                               appOnlyPositiveNumbers
                                               type="number"
                                               [min]="0"
                                               [formControlName]="'numero_attivita_obbligatorie'">
                                        <mat-error *ngIf="fieldCtrl.hasError('min') && (configFormGroup?.dirty || configFormGroup?.touched)"
                                                   class="pb-2">
                                            {{ t('form.must_be_positive') }}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="fieldCtrl.hasError('required') && (configFormGroup?.dirty || configFormGroup?.touched)"
                                            class="pb-2">
                                            {{ t('form.required') }}
                                        </mat-error>
                                    </mat-form-field>

                                    <!-- CFU MINIMO -->
                                    <mat-form-field appearance="outline" class="w-full"
                                                    *ngIf="configFormGroup.get('numero_cfu_minimo') as fieldCtrl">
                                        <mat-label>{{ t('dialog_add_edit_categories.minimum_number_of_credits') }}</mat-label>
                                        <mat-icon
                                            class="icon-size-5 mb-3 mr-2 cursor-pointer hidden lg:flex" style="color: #A5AFBB"
                                            [matTooltip]="t('dialog_add_edit_categories.minimum_number_of_credits_tooltip')"
                                            matTooltipClass="text-base"
                                            matTooltipPosition="before"
                                            matPrefix>info
                                        </mat-icon>
                                        <input matInput
                                               appOnlyDecimalNumbers
                                               [numOfDecimal]="1"
                                               type="text"
                                               [min]="0"
                                               [formControlName]="'numero_cfu_minimo'">
                                        <mat-error *ngIf="fieldCtrl.hasError('required')" class="w-full">
                                            {{ t('form.required') }}
                                        </mat-error>
                                        <span matSuffix class="text-sm text-secondary">CFU</span>
                                    </mat-form-field>

                                    <div class="flex flex-row justify-between w-full div-button-sticky-add-edit-category py-4 bg-slate-100">
                                        <app-custom-button
                                            [type]="ButtonType.PREVIOUS">
                                        </app-custom-button>
                                        <div class="flex flex-row gap-2">
                                            <app-custom-button
                                                [type]="ButtonType.NEXT"
                                                [disableDirective]="true"
                                                (clickHandler)="nextConfigStep(configStepper, configFormGroup.get('numero_attivita_obbligatorie').invalid)">
                                            </app-custom-button>
                                        </div>
                                    </div>

                                </mat-step>

                                <!-- ATT PROPOSTE -->
                                <mat-step [completed]="data.wantsToEditConfig"
                                          [stepControl]="configFormGroup?.get('attivita_proposte_dottorando_group')">

                                    <div class="flex flex-col w-full">
                                        <ng-template matStepLabel>
                                            <div class="flex flex-row gap-5 items-center">
                                                <span>{{ t('dialog_add_edit_categories.proposed_activity') }}</span>
                                                <mat-icon
                                                    *ngIf="configFormGroup.get('attivita_proposte_dottorando_group').invalid"
                                                    class="icon-size-5 text-amber-500"
                                                    [matTooltip]="t('form.not_valid')">
                                                    warning_amber
                                                </mat-icon>
                                            </div>
                                        </ng-template>

                                        <!-- ATT PROPOSTE DOTTORANDO GROUP -->
                                        <div class="flex flex-col  w-full gap-2 mb-2">

                                            <div
                                                class="truncate text-lg font-medium leading-6 tracking-tight"> {{ t('dialog_add_edit_categories.proposed_activity') }}
                                            </div>

                                            <fuse-alert
                                                class="mb-3 w-full mt-2 fuse-alert-reduced-padding-medium"
                                                [appearance]="'outline'"
                                                [type]="data.activityType !== ElementoOffertaFormativaType.CORSO ? 'info' : 'warning'">
                                                {{ t(data.activityType !== ElementoOffertaFormativaType.CORSO ? 'dialog_add_edit_categories.proposed_activity_message' : 'dialog_add_edit_categories.proposed_activity_course_message') }}
                                            </fuse-alert>

                                            <!-- ATT PROPOSTE DOTTORANDO -->
                                            <div class="flex flex-row items-center w-full font-medium min-h-10"
                                                 *ngIf="configFormGroup?.get('attivita_proposte_dottorando') as fieldCtrl">

                                                <mat-radio-group [formControlName]="'attivita_proposte_dottorando'"
                                                                 [disabled]="data.activityType === ElementoOffertaFormativaType.CORSO"
                                                                 class="w-full"
                                                                 color="primary">
                                                    <mat-radio-button [value]="true" class="mb-1 w-full">
                                                    <span>
                                                        {{ t('dialog_add_edit_categories.yes') }}
                                                    </span>
                                                    </mat-radio-button>
                                                    <mat-radio-button [value]="false" class="w-full">
                                                    <span>
                                                        {{ t('dialog_add_edit_categories.no') }}
                                                    </span>
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                            </div>

                                            <ng-container *ngIf="!!configFormGroup.get('attivita_proposte_dottorando')?.value && data.activityType !== ElementoOffertaFormativaType.CORSO">

                                                <ng-container formGroupName="attivita_proposte_dottorando_group"
                                                              *ngIf="configFormGroup?.get('attivita_proposte_dottorando_group') as fieldGroup">

                                                    <!-- CFU PER ATTIVITA -->
                                                    <fuse-alert
                                                        class="mb-1 w-full mt-6 fuse-alert-reduced-padding-medium"
                                                        [appearance]="'outline'"
                                                        [type]="'info'">
                                                        {{ t('dialog_add_edit_categories.choose_cfu_or_desc_cfu') }}
                                                    </fuse-alert>
                                                    <mat-radio-group [formControlName]="'cfuIsDescrizione'"
                                                                     class="w-full"
                                                                     color="primary">
                                                        <mat-radio-button [value]="false" class="mb-1 w-full">
                                                    <span>
                                                        {{ t('dialog_add_edit_categories.is_cfu') }}
                                                    </span>
                                                        </mat-radio-button>
                                                        <mat-radio-button [value]="true" class="w-full">
                                                    <span>
                                                        {{ t('dialog_add_edit_categories.is_desc_cfu') }}
                                                    </span>
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                    <ng-container *ngIf="!fieldGroup.get('cfuIsDescrizione').value">
                                                        <mat-form-field appearance="outline" class="w-full mt-2"
                                                                        *ngIf="fieldGroup.get('cfu') as fieldCtrl">
                                                            <mat-label>{{ t('dialog_add_edit_categories.is_cfu') }}</mat-label>
                                                            <mat-icon
                                                                class="icon-size-5 mb-3 mr-2 cursor-pointer hidden lg:flex" style="color: #A5AFBB"
                                                                [matTooltip]="t('dialog_add_edit_categories.cfu_tooltip')"
                                                                matTooltipClass="text-base"
                                                                matTooltipPosition="before"
                                                                matPrefix>info
                                                            </mat-icon>
                                                            <input matInput
                                                                   appOnlyDecimalNumbers
                                                                   [numOfDecimal]="1"
                                                                   type="number"
                                                                   [min]="0.1"
                                                                   [formControlName]="'cfu'">
                                                            <mat-error *ngIf="fieldCtrl.hasError('required')" class="w-full">
                                                                {{ t('form.required') }}
                                                            </mat-error>
                                                            <mat-error  *ngIf="fieldCtrl.hasError('min')" class="w-full">
                                                                {{t('form.must_be_greater_than_zero')}}
                                                            </mat-error>
                                                            <span matSuffix class="text-sm text-secondary">CFU</span>
                                                        </mat-form-field>
                                                    </ng-container>
                                                    <ng-container *ngIf="fieldGroup.get('cfuIsDescrizione').value">
                                                        <mat-form-field appearance="outline" class="w-full mt-2"
                                                                        *ngIf="fieldGroup.get('cfu') as fieldCtrl">
                                                            <mat-label>{{ t('dialog_add_edit_categories.is_desc_cfu') }}</mat-label>
                                                            <mat-icon
                                                                class="icon-size-5 mb-3 mr-2 cursor-pointer hidden lg:flex" style="color: #A5AFBB"
                                                                [matTooltip]="t('dialog_add_edit_categories.cfu_description_tooltip')"
                                                                matTooltipClass="text-base"
                                                                matTooltipPosition="before"
                                                                matPrefix>info
                                                            </mat-icon>
                                                            <input matInput
                                                                   [formControlName]="'cfu'">
                                                            <mat-error *ngIf="fieldCtrl.hasError('required')" class="w-full">
                                                                {{ t('form.required') }}
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </ng-container>

                                                    <!-- ANNI -->
                                                    <div class="flex flex-col gap-3 items-center mb-2 years-div bg-white border-white">

                                                        <div class="text-center">
                                                            {{ t('dialog_add_edit_categories.select_proposed_years') }}
                                                        </div>

                                                        <div class="flex flex-row gap-6 items-center">

                                                            <ng-container *ngIf="configFormGroup.get('anni').value.includes(AnnoRiferimentoValues.PRIMO)">
                                                                <div class="flex flex-row items-center"
                                                                     *ngIf="fieldGroup?.get('primoAnno') as fieldCtrl">
                                                                    <mat-checkbox [formControlName]="'primoAnno'"
                                                                                  color="primary">
                                                                    </mat-checkbox>
                                                                    <mat-label><strong>{{ t('study_plan_status.first_year') }}</strong></mat-label>
                                                                </div>
                                                            </ng-container>

                                                            <ng-container *ngIf="configFormGroup.get('anni').value.includes(AnnoRiferimentoValues.SECONDO)">
                                                                <div class="flex flex-row items-center"
                                                                     *ngIf="fieldGroup?.get('secondoAnno') as fieldCtrl">
                                                                    <mat-checkbox [formControlName]="'secondoAnno'"
                                                                                  color="primary">
                                                                    </mat-checkbox>
                                                                    <mat-label><strong>{{ t('study_plan_status.second_year') }}</strong></mat-label>
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngIf="configFormGroup.get('anni').value.includes(AnnoRiferimentoValues.TERZO)">
                                                                <div class="flex flex-row items-center"
                                                                     *ngIf="fieldGroup?.get('terzoAnno') as fieldCtrl">
                                                                    <mat-checkbox [formControlName]="'terzoAnno'"
                                                                                  color="primary">
                                                                    </mat-checkbox>
                                                                    <mat-label><strong>{{ t('study_plan_status.third_year') }}</strong></mat-label>
                                                                </div>
                                                            </ng-container>

                                                        </div>

                                                        <mat-error class="text-sm"
                                                            *ngIf="fieldGroup.errors?.yearsRequired">
                                                            {{ t('training_offer.year_required') }}
                                                        </mat-error>

                                                    </div>

                                                    <!-- DOCUMENTI PER ATT TRASVERSALE -->
                                                    <ng-container *ngIf="data.activityType === ElementoOffertaFormativaType.ATTIVITATRASVERSALE">
                                                        <div class="flex flex-row items-start mb-1 flags-div mt-2"
                                                             *ngIf="fieldGroup?.get('presenzaDeliberaCoordinatore') as fieldCtrl">
                                                            <mat-checkbox [formControlName]="'presenzaDeliberaCoordinatore'"
                                                                          color="primary">
                                                            </mat-checkbox>
                                                            <mat-label>{{ t('cycle_doctorate.coordinator_required_extended') }}</mat-label>
                                                        </div>
                                                        <div class="flex flex-col gap-2 w-full flags-div mb-1"
                                                             [formGroupName]="'documentoDottorando'">
                                                            <div class="flex flex-row items-start"
                                                                 *ngIf="fieldGroup?.get('documentoDottorando').get('presenza') as fieldCtrl">
                                                                <mat-checkbox [formControlName]="'presenza'"
                                                                              color="primary">
                                                                </mat-checkbox>
                                                                <mat-label>{{ t('cycle_doctorate.student_document_required_extended') }}</mat-label>
                                                            </div>
                                                            <mat-form-field appearance="outline" class="w-full add-course-mat-form-field mt-1"
                                                                            *ngIf="fieldGroup?.get('documentoDottorando').get('presenza').value">
                                                                <mat-label><strong>{{ t('study_plan_status.document_type') }}</strong></mat-label>
                                                                <input matInput
                                                                       [formControlName]="'tipo'">
                                                            </mat-form-field>
                                                            <mat-error class="text-sm"
                                                                       *ngIf="fieldGroup.get('documentoDottorando').errors?.tipoDocumentoRequired && (fieldGroup?.dirty || fieldGroup?.touched)">
                                                                {{ t('training_offer.document_type_required') }}
                                                            </mat-error>
                                                        </div>
                                                        <div class="flex flex-col gap-2 w-full flags-div" [formGroupName]="'documentoSupervisore'">
                                                            <div class="flex flex-row items-start"
                                                                 *ngIf="fieldGroup.get('documentoSupervisore').get('presenza') as fieldCtrl">
                                                                <mat-checkbox [formControlName]="'presenza'"
                                                                              color="primary">
                                                                </mat-checkbox>
                                                                <mat-label>{{ t('cycle_doctorate.supervisor_document_required_extended') }}</mat-label>
                                                            </div>
                                                            <mat-form-field appearance="outline" class="w-full add-course-mat-form-field mt-1"
                                                                            *ngIf="fieldGroup?.get('documentoSupervisore').get('presenza').value">
                                                                <mat-label><strong>{{ t('study_plan_status.document_type') }}</strong></mat-label>
                                                                <input matInput
                                                                       [formControlName]="'tipo'">
                                                            </mat-form-field>
                                                            <mat-error class="text-sm"
                                                                       *ngIf="fieldGroup.get('documentoSupervisore').errors?.tipoDocumentoRequired && (fieldGroup?.dirty || fieldGroup?.touched)">
                                                                {{ t('training_offer.document_type_required') }}
                                                            </mat-error>
                                                        </div>
                                                    </ng-container>

                                                </ng-container>

                                            </ng-container>

                                        </div>
                                        <div class="flex flex-row justify-between w-full div-button-sticky-add-edit-category py-4 bg-slate-100">
                                            <app-custom-button
                                                [type]="ButtonType.PREVIOUS">
                                            </app-custom-button>
                                            <div class="flex flex-row gap-2">
                                                <app-custom-button
                                                    [type]="ButtonType.NEXT"
                                                    [disableDirective]="true"
                                                    (clickHandler)="nextConfigStep(configStepper, configFormGroup?.get('attivita_proposte_dottorando_group').invalid)">
                                                </app-custom-button>
                                            </div>
                                        </div>
                                    </div>

                                </mat-step>

                                <!-- ATT PRESUNTE -->
                                <mat-step [completed]="data.wantsToEditConfig"
                                          [stepControl]="configFormGroup?.get('attivita_presunte_group')">

                                    <div class="flex flex-col w-full">

                                        <ng-template matStepLabel>
                                            <div class="flex flex-row gap-5 items-center">
                                                <span>{{ t('dialog_add_edit_categories.presumed_activity') }}</span>
                                                <mat-icon
                                                    *ngIf="configFormGroup.get('attivita_presunte_group').invalid"
                                                    class="icon-size-5 text-amber-500"
                                                    [matTooltip]="t('form.not_valid')">
                                                    warning_amber
                                                </mat-icon>
                                            </div>
                                        </ng-template>

                                        <!-- ATT PRESUNTE GROUP -->
                                        <div class="flex flex-col w-full gap-2 mb-2">

                                            <!-- ATT PRESUNTE -->
                                            <div class="flex flex-col w-full font-medium min-h-10"
                                                 *ngIf="configFormGroup?.get('attivita_presunte') as fieldCtrl">
                                                <div
                                                    class="truncate text-lg font-medium leading-6 tracking-tight"> {{ t('dialog_add_edit_categories.presumed_activity') }}
                                                </div>

                                                <fuse-alert
                                                    class="mb-3 w-full mt-2 fuse-alert-reduced-padding-medium"
                                                    [appearance]="'outline'"
                                                    [type]="'info'">
                                                    {{ t(data.activityType === ElementoOffertaFormativaType.CORSO ? 'dialog_add_edit_categories.presumed_activity_course_message' : 'dialog_add_edit_categories.presumed_activity_other_message') }}
                                                </fuse-alert>
                                                <mat-radio-group [formControlName]="'attivita_presunte'"
                                                                 class="w-full"
                                                                 color="primary">
                                                    <mat-radio-button [value]="true" class="mb-1 w-full">
                                                        <span>
                                                            {{ t('dialog_add_edit_categories.yes') }}
                                                        </span>
                                                    </mat-radio-button>
                                                    <mat-radio-button [value]="false" class="w-full">
                                                        <span>
                                                            {{ t('dialog_add_edit_categories.no') }}
                                                        </span>
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                            </div>

                                            <ng-container *ngIf="!!configFormGroup?.get('attivita_presunte')?.value">

                                                <ng-container formGroupName="attivita_presunte_group"
                                                              *ngIf="configFormGroup?.get('attivita_presunte_group') as fieldGroup">

                                                    <!-- CFU PER ATTIVITA -->
                                                    <fuse-alert
                                                        class="mb-1 w-full mt-6 fuse-alert-reduced-padding-medium"
                                                        [appearance]="'outline'"
                                                        [type]="'info'">
                                                        {{ t('dialog_add_edit_categories.choose_cfu_or_desc_cfu') }}
                                                    </fuse-alert>
                                                    <mat-radio-group [formControlName]="'cfuIsDescrizione'"
                                                                     class="w-full"
                                                                     color="primary">
                                                        <mat-radio-button [value]="false" class="mb-1 w-full">
                                                        <span>
                                                            {{ t('dialog_add_edit_categories.is_cfu') }}
                                                        </span>
                                                        </mat-radio-button>
                                                        <mat-radio-button [value]="true" class="w-full">
                                                        <span>
                                                            {{ t('dialog_add_edit_categories.is_desc_cfu') }}
                                                        </span>
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                    <ng-container *ngIf="!fieldGroup.get('cfuIsDescrizione').value">
                                                        <mat-form-field appearance="outline" class="w-full mt-2"
                                                                        *ngIf="fieldGroup.get('cfu') as fieldCtrl">
                                                            <mat-label>{{ t('dialog_add_edit_categories.is_cfu') }}</mat-label>
                                                            <mat-icon
                                                                class="icon-size-5 mb-3 mr-2 cursor-pointer hidden lg:flex" style="color: #A5AFBB"
                                                                [matTooltip]="t('dialog_add_edit_categories.cfu_tooltip')"
                                                                matTooltipClass="text-base"
                                                                matTooltipPosition="before"
                                                                matPrefix>info
                                                            </mat-icon>
                                                            <input matInput
                                                                   appOnlyDecimalNumbers
                                                                   [numOfDecimal]="1"
                                                                   type="number"
                                                                   [min]="0.1"
                                                                   [formControlName]="'cfu'">
                                                            <mat-error *ngIf="fieldCtrl.hasError('required')" class="w-full">
                                                                {{ t('form.required') }}
                                                            </mat-error>
                                                            <mat-error  *ngIf="fieldCtrl.hasError('min')" class="w-full">
                                                                {{t('form.must_be_greater_than_zero')}}
                                                            </mat-error>
                                                            <span matSuffix class="text-sm text-secondary">CFU</span>
                                                        </mat-form-field>
                                                    </ng-container>
                                                    <ng-container *ngIf="fieldGroup.get('cfuIsDescrizione').value">
                                                        <mat-form-field appearance="outline" class="w-full mt-2"
                                                                        *ngIf="fieldGroup.get('cfu') as fieldCtrl">
                                                            <mat-label>{{ t('dialog_add_edit_categories.is_desc_cfu') }}</mat-label>
                                                            <mat-icon
                                                                class="icon-size-5 mb-3 mr-2 cursor-pointer hidden lg:flex" style="color: #A5AFBB"
                                                                [matTooltip]="t('dialog_add_edit_categories.cfu_description_tooltip')"
                                                                matTooltipClass="text-base"
                                                                matTooltipPosition="before"
                                                                matPrefix>info
                                                            </mat-icon>
                                                            <input matInput
                                                                   [formControlName]="'cfu'">
                                                            <mat-error *ngIf="fieldCtrl.hasError('required')" class="w-full">
                                                                {{ t('form.required') }}
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </ng-container>

                                                    <!-- ANNI -->
                                                    <div class="flex flex-col gap-3 items-center mb-2 years-div bg-white border-white">
                                                        <div
                                                            class="truncate font-medium leading-6 tracking-tight"> {{ t('dialog_add_edit_categories.select_category_years') }}
                                                        </div>

                                                        <div class="flex flex-row gap-6 items-center">

                                                            <ng-container *ngIf="configFormGroup.get('anni').value.includes(AnnoRiferimentoValues.PRIMO)">
                                                                <div class="flex flex-row items-center"
                                                                     *ngIf="fieldGroup?.get('primoAnno') as fieldCtrl">
                                                                    <mat-checkbox [formControlName]="'primoAnno'"
                                                                                  color="primary">
                                                                    </mat-checkbox>
                                                                    <mat-label><strong>{{ t('study_plan_status.first_year') }}</strong></mat-label>
                                                                </div>
                                                            </ng-container>

                                                            <ng-container *ngIf="configFormGroup.get('anni').value.includes(AnnoRiferimentoValues.SECONDO)">
                                                                <div class="flex flex-row items-center"
                                                                     *ngIf="fieldGroup?.get('secondoAnno') as fieldCtrl">
                                                                    <mat-checkbox [formControlName]="'secondoAnno'"
                                                                                  color="primary">
                                                                    </mat-checkbox>
                                                                    <mat-label><strong>{{ t('study_plan_status.second_year') }}</strong></mat-label>
                                                                </div>
                                                            </ng-container>

                                                            <ng-container *ngIf="configFormGroup.get('anni').value.includes(AnnoRiferimentoValues.TERZO)">
                                                                <div class="flex flex-row items-center"
                                                                     *ngIf="fieldGroup?.get('terzoAnno') as fieldCtrl">
                                                                    <mat-checkbox [formControlName]="'terzoAnno'"
                                                                                  color="primary">
                                                                    </mat-checkbox>
                                                                    <mat-label><strong>{{ t('study_plan_status.third_year') }}</strong></mat-label>
                                                                </div>
                                                            </ng-container>
                                                        </div>

                                                        <mat-error class="text-sm"
                                                            *ngIf="fieldGroup?.errors?.yearsRequired">
                                                            {{ t('training_offer.year_required') }}
                                                        </mat-error>

                                                    </div>

                                                    <!-- DOCUMENTI PER ATT TRASVERSALE -->
                                                    <ng-container *ngIf="data.activityType === ElementoOffertaFormativaType.ATTIVITATRASVERSALE">
                                                        <div class="flex flex-row items-start mb-1 flags-div mt-2"
                                                             *ngIf="fieldGroup?.get('presenzaDeliberaCoordinatore') as fieldCtrl">
                                                            <mat-checkbox [formControlName]="'presenzaDeliberaCoordinatore'"
                                                                          color="primary">
                                                            </mat-checkbox>
                                                            <mat-label>{{ t('cycle_doctorate.coordinator_required_extended') }}</mat-label>
                                                        </div>
                                                        <div class="flex flex-col gap-2 w-full flags-div mb-1"
                                                             [formGroupName]="'documentoDottorando'">
                                                            <div class="flex flex-row items-start"
                                                                 *ngIf="fieldGroup?.get('documentoDottorando').get('presenza') as fieldCtrl">
                                                                <mat-checkbox [formControlName]="'presenza'"
                                                                              color="primary">
                                                                </mat-checkbox>
                                                                <mat-label>{{ t('cycle_doctorate.student_document_required_extended') }}</mat-label>
                                                            </div>
                                                            <mat-form-field appearance="outline" class="w-full add-course-mat-form-field mt-1"
                                                                            *ngIf="fieldGroup?.get('documentoDottorando').get('presenza').value">
                                                                <mat-label><strong>{{ t('study_plan_status.document_type') }}</strong></mat-label>
                                                                <input matInput
                                                                       [formControlName]="'tipo'">
                                                            </mat-form-field>
                                                            <mat-error class="text-sm"
                                                                       *ngIf="fieldGroup.get('documentoDottorando').errors?.tipoDocumentoRequired && (fieldGroup?.dirty || fieldGroup?.touched)">
                                                                {{ t('training_offer.document_type_required') }}
                                                            </mat-error>
                                                        </div>
                                                        <div class="flex flex-col gap-2 w-full flags-div" [formGroupName]="'documentoSupervisore'">
                                                            <div class="flex flex-row items-start"
                                                                 *ngIf="fieldGroup.get('documentoSupervisore').get('presenza') as fieldCtrl">
                                                                <mat-checkbox [formControlName]="'presenza'"
                                                                              color="primary">
                                                                </mat-checkbox>
                                                                <mat-label>{{ t('cycle_doctorate.supervisor_document_required_extended') }}</mat-label>
                                                            </div>
                                                            <mat-form-field appearance="outline" class="w-full add-course-mat-form-field mt-1"
                                                                            *ngIf="fieldGroup?.get('documentoSupervisore').get('presenza').value">
                                                                <mat-label><strong>{{ t('study_plan_status.document_type') }}</strong></mat-label>
                                                                <input matInput
                                                                       [formControlName]="'tipo'">
                                                            </mat-form-field>
                                                            <mat-error class="text-sm"
                                                                       *ngIf="fieldGroup.get('documentoSupervisore').errors?.tipoDocumentoRequired && (fieldGroup?.dirty || fieldGroup?.touched)">
                                                                {{ t('training_offer.document_type_required') }}
                                                            </mat-error>
                                                        </div>
                                                    </ng-container>

                                                </ng-container>

                                            </ng-container>

                                        </div>

                                        <div class="flex flex-row justify-between w-full div-button-sticky-add-edit-category py-4 bg-slate-100">
                                            <app-custom-button
                                                [type]="ButtonType.PREVIOUS">
                                            </app-custom-button>
                                            <div class="flex flex-row gap-2">
                                                <app-custom-button
                                                    *ngIf="anniRiferimentoAvailable?.[configIndex+1]?.length > 0 && configIndex == configFormGroups?.length - 1"
                                                    [type]="ButtonType.ADD"
                                                    [textButton]="'Aggiungi nuova configurazione'"
                                                    (clickHandler)="addNewConfig(configFormGroup.invalid || configFormGroups?.[configIndex-1]?.invalid || configFormGroups?.[configIndex-2]?.invalid)">
                                                </app-custom-button>
                                                <app-custom-button
                                                    [type]="ButtonType.NEXT"
                                                    [textButton]="t((configIndex < configFormGroups.length - 1) ? 'common.next' : 'dialog_add_edit_categories.go_to_recap')"
                                                    [disableDirective]="true"
                                                    (clickHandler)="nextCategoryStep(configFormGroup.invalid || configFormGroups?.[configIndex-1]?.invalid || configFormGroups?.[configIndex-2]?.invalid)">
                                                </app-custom-button>
                                            </div>
                                        </div>

                                    </div>
                                </mat-step>


                            </mat-stepper>
                        </ng-container>

                        <ng-template #noRemainingYearsTmpl>
                            <div class="w-full p-4 bg-slate-100">
                                <fuse-alert
                                    class="mb-3 w-full mt-2 fuse-alert-reduced-padding-medium"
                                    [appearance]="'outline'"
                                    [showIcon]="true"
                                    [type]="'warning'">
                                    <div class="flex w-full flex-row gap-2 items-center">
                                        <span class="w-full text-center">
                                            {{ t('dialog_add_edit_categories.no_more_years') }}
                                        </span>
                                    </div>
                                </fuse-alert>
                            </div>
                            <div class="flex flex-row justify-between w-full div-button-sticky-add-edit-category p-4 bg-slate-100">
                                <app-custom-button
                                    [type]="ButtonType.PREVIOUS"
                                    [disableDirective]="true"
                                    (clickHandler)="categoryCreationStepper.previous()">
                                </app-custom-button>
                                <div class="flex flex-row gap-2">
                                    <app-custom-button
                                        [type]="ButtonType.NEXT"
                                        [disableDirective]="true"
                                        (clickHandler)="nextCategoryStep(configFormGroup.invalid)">
                                    </app-custom-button>
                                </div>
                            </div>
                        </ng-template>

                    </form>
                </mat-step>
                <!-- RIEPILOGO -->
                <mat-step aria-label="recap" class="bg-slate-100">
                    <ng-template matStepLabel>{{ t('dialog_add_edit_categories.recap') }}</ng-template>
                    <div class="w-full flex flex-col">
                        <div class="flex flex-col gap-6 w-full items-center my-4">
                            <!-- DENOMINAZIONE -->
                            <div class="w-full flex flex-col break-words gap-2"
                                 style="word-break: break-word">
                                <div class="text-left w-full font-semibold text-lg">
                                    {{infoGeneraliFormGroup.get('denominazione').value}}
                                </div>
                                <!-- DESCRIPTION -->
                                <div class="text-left w-full text-secondary text-sm"
                                     *ngIf="infoGeneraliFormGroup.get('descrizione').value">
                                    {{infoGeneraliFormGroup.get('descrizione').value}}
                                </div>
                                <!-- NOTE -->
                                <div class="text-left w-full text-secondary text-sm"
                                     *ngIf="infoGeneraliFormGroup.get('note').value">
                                    <span class="font-semibold">{{t('common.note')}}:</span> {{infoGeneraliFormGroup.get('note').value}}
                                </div>
                            </div>
                            <div class="flex flex-col w-full items-center">
                                <div class="font-semibold w-full text-center">{{ t('dialog_add_edit_categories.configurations') }}</div>
                                <app-activity-category-configurations
                                    [configurations]="configurationsForRecap"
                                    [activityType]="data.activityType"
                                    class="w-full mt-4 text-secondary"
                                ></app-activity-category-configurations>
                            </div>
                        </div>

                        <div class="flex flex-row justify-between w-full div-button-sticky-add-edit-category p-4 bg-slate-100">
                            <app-custom-button
                                [disabled]="loading$ | async"
                                [type]="ButtonType.PREVIOUS">
                            </app-custom-button>
                            <app-custom-button
                                [type]="ButtonType.CONFIRM"
                                [disableDirective]="true"
                                [loading]="loading$ | async"
                                (clickHandler)="save()">
                            </app-custom-button>
                        </div>
                    </div>
                </mat-step>
            </mat-stepper>

        </div>

    </app-dialog-wrap>

</ng-container>

