import {TemplateCalcoloMaggiorazioneMobilitaEnum} from "../../../../../api-clients/generated/services";


export interface TemplateConfigCalcoloMaggiorazioneMobilita {
    infoMessage: string;
    nameOfTheAmountPlural: string;
    addPartialRequestMessage: string;
}

const templateMaggiorazioneMobilitaConfigs = new Map<TemplateCalcoloMaggiorazioneMobilitaEnum, TemplateConfigCalcoloMaggiorazioneMobilita>(
    [
        [
            TemplateCalcoloMaggiorazioneMobilitaEnum.GIORNALIEROSENZAMINIMOGIORNITRASCORSI,
            {
                infoMessage: 'mobility.increase_amount_daily_info_message',
                nameOfTheAmountPlural: 'mobility.daily_amount',
                addPartialRequestMessage: 'mobility.add_partial_validation_message',
            }
        ],
        [
            TemplateCalcoloMaggiorazioneMobilitaEnum.MENSILEEMINIMO15GIORNITRASCORSI,
            {
                infoMessage: 'mobility.increase_amount_monthly_info_message',
                nameOfTheAmountPlural: 'mobility.monthly_amount',
                addPartialRequestMessage: 'mobility.add_partial_validation_message_mensile',
            }
        ],
    ]
);

export function getMaggiorazioneMobilitaTemplateConfig(tipoMaggiorazione: TemplateCalcoloMaggiorazioneMobilitaEnum): TemplateConfigCalcoloMaggiorazioneMobilita{
    return templateMaggiorazioneMobilitaConfigs.get(tipoMaggiorazione);
}
