import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {get, isEqual} from 'lodash';
import {TranslocoService} from '@ngneat/transloco';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppInitService} from '../../../../../shared/service/app-init.service';
import {LocalStorageService} from '../../../../../shared/service/local-storage.service';
import {takeUntil} from 'rxjs';

import {
    AbstractDefaultComponent
} from '../../../../../shared/abstracts/abstract-default-component/abstract-default-component';
import {PathEnum} from "../../../../../app.routing";
import {Router} from "@angular/router";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {LogoutService} from "../../../../../shared/service/logout.service";
import {DialogWrapI} from "../../../../common/dialog-wrap/dialog-wrap.component";

export interface DialogSetCicloComponentI extends  DialogWrapI {
    cicli: any[],
}

@Component({
    selector: 'app-dialog-set-ciclo',
    templateUrl: './dialog-set-ciclo.component.html',
    styleUrls: ['./dialog-set-ciclo.component.scss']
})
export class DialogSetCicloComponent extends AbstractDefaultComponent {
    formGroup: FormGroup;
    dialogButtonConfirm: string;
    dialogButtonCancel: string;
    showAnnullaButton: boolean;
    cicli: any[];

    constructor(private appInitService: AppInitService,
                private fb: FormBuilder,
                private localStorageService: LocalStorageService,
                private _translocoService: TranslocoService,
                private dialog: MatDialog,
                @Inject(MAT_DIALOG_DATA) public data: DialogSetCicloComponentI,
                private router: Router,
                private fuseConfirmationService: FuseConfirmationService,
                private logoutService: LogoutService,
                public dialogRef: MatDialogRef<DialogSetCicloComponent>,
    ) {
        super();
        this.createForm();
        this.cicli = this.data.cicli?.sort();
        this._translocoService.selectTranslate('common.save').subscribe(value => this.dialogButtonConfirm = value);
        this._translocoService.selectTranslate('common.close').subscribe(value => this.dialogButtonCancel = value);
    }


    get cicloFormControl(): FormControl {
        return this.formGroup?.get('ciclo') as FormControl;
    }


    createForm(): void {
        const cicloAmm = this.localStorageService.getCicloAmm();
        this.formGroup = this.fb.group({
            ciclo: [undefined, Validators.required],
        });
        if (!!cicloAmm) {
            this.setCicloFromStorage(cicloAmm);
        } else {
            this.showAnnullaButton = !!this.appInitService?.cicloCorsoRuoloSelected?.ruolo;
        }
    }


    applica(): void {

        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            return;
        }

        const ciclo = this.cicloFormControl.value;

        this.appInitService.setCicloAmmSelected$(ciclo, true).subscribe({
            next: _ => {
                const activeLang = this._translocoService.getActiveLang();
                const translation = this._translocoService.getTranslation().get(activeLang);
                this.router.navigate([PathEnum.ADMINISTRATOR, PathEnum.HOME]);
                this.localStorageService.setCicloAmm(ciclo);
                this.dialog.closeAll();
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'common.operation_success', null),
                    type: SnackbarTypes.Success,
                });
            }
        });


    }


    setCicloFromStorage(ciclo: any): void {
        this.cicloFormControl.setValue(ciclo);
        this.showAnnullaButton = true;
    }


    logout() {
        this.logoutService.logout();
    }

    get checkModificaForm(): boolean {
        const formValue = this.formGroup?.getRawValue();
        const serviceValue = this.appInitService.cicloAmmSelected;
        if (!serviceValue) {
            return false;
        }
        return isEqual(formValue, {
            ciclo: serviceValue,
        });
    }


    closeDialog() {
        this.dialogRef.close(true);
    }
}
