export const CUSTOM_DATE_FORMAT = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const optionFile= {
    sizeMb: 100,
    listaAccepted: ['pdf', 'jpeg', 'jpg', 'png', 'rar', 'zip'],
    listaAcceptedForBorsaHeaderFooter: ['jpeg', 'jpg']
};


export enum YesOrNotEnum {
    YES = 'YES',
    NO = 'NO',
}
