import {MatDialogModule} from '@angular/material/dialog';
import {GenericDialogComponent} from './generic-dialog.component';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {SharedModule} from 'app/shared/shared.module';
import {FuseCardModule} from '../../../../../@fuse/components/card';
import {FlexModule} from '@angular/flex-layout';
import {TranslocoModule} from '@ngneat/transloco';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {LoadingScreenModule} from '../../loading-screen/loading-screen.module';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import {CommonModule, TitleCasePipe} from '@angular/common';

import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {FormModule} from 'app/layout/common/generic-components/form/form.module';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {FuseAlertModule} from '../../../../../@fuse/components/alert';
import {InfoWrapModule} from '../../info-dialog/info-wrap.module';
import {DialogWrapModule} from '../../dialog-wrap/dialog-wrap.module';
import {RemoveUnderscoresPipe} from "../../../../shared/pipe/remove-underscores.pipe";
import {GenericTableModule} from "../../../../shared/components/table/generic-table/generic-table.module";


@NgModule({
    declarations: [
        GenericDialogComponent
    ],
    imports: [
        MatButtonModule,
        MatIconModule,
        SharedModule,
        FuseCardModule,
        FlexModule,
        TranslocoModule,
        MatTableModule,
        MatSortModule,
        LoadingScreenModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        CommonModule,
        TranslocoModule,
        FormModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDialogModule,
        DialogWrapModule,
        FuseAlertModule,
        InfoWrapModule,
        GenericTableModule
    ],
    exports: [
        GenericDialogComponent
    ],
    providers: [
        TitleCasePipe,
        RemoveUnderscoresPipe,
    ]
})
export class GenericDialogModule {
}
