/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BodyPart { 
    nome?: string;
    tipoElementoBody?: BodyPart.TipoElementoBodyEnum;
    valore?: string;
}
export namespace BodyPart {
    export type TipoElementoBodyEnum = 'RAW' | 'TESTO' | 'JSON' | 'FILE';
    export const TipoElementoBodyEnum = {
        RAW: 'RAW' as TipoElementoBodyEnum,
        TESTO: 'TESTO' as TipoElementoBodyEnum,
        JSON: 'JSON' as TipoElementoBodyEnum,
        FILE: 'FILE' as TipoElementoBodyEnum
    };
}