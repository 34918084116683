/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Enum di template di calcolo maggiorazione mobilità
 */
export type TemplateCalcoloMaggiorazioneMobilitaEnum = 'CALCOLO_CON_IMPORTO_GIORNALIERO_SENZA_MINIMO_GIORNI_TRASCORSI' | 'CALCOLO_CON_IMPORTO_MENSILE_E_MINIMO_15_GIORNI_TRASCORSI';

export const TemplateCalcoloMaggiorazioneMobilitaEnum = {
    GIORNALIEROSENZAMINIMOGIORNITRASCORSI: 'CALCOLO_CON_IMPORTO_GIORNALIERO_SENZA_MINIMO_GIORNI_TRASCORSI' as TemplateCalcoloMaggiorazioneMobilitaEnum,
    MENSILEEMINIMO15GIORNITRASCORSI: 'CALCOLO_CON_IMPORTO_MENSILE_E_MINIMO_15_GIORNI_TRASCORSI' as TemplateCalcoloMaggiorazioneMobilitaEnum
};