import {Component, Inject, OnInit} from '@angular/core';
import {
    AuthorityType,
    CorsoPianoDiStudiPerExportViewImpl,
    DeliberaApprovaPeriodiDiRicercaResponse,
    DeliberaMassivaAttivitaTrasversaliRequest,
    EsitoOperazione, EsitoSingolaOperazioneSuAttivitaTrasversale, OperazioneMassivaSuAttivitaTrasversaliResponse,
    OperazioniMassiveService, SuperamentoMassivoAttivitaTrasversaliDottorandiRequest
} from "../../../../../../api-clients/generated/services";
import {filter, switchMap, tap} from "rxjs/operators";
import {BehaviorSubject, finalize, take, takeUntil} from "rxjs";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {ApprovazioneTrasversaliMassivaDataI} from "../approvazione-trasversale-steps-interface";
import {CAN_GO_AHEAD$, CURRENT_PROFILE, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {cloneDeep} from "lodash-es";
import {get} from "lodash";
import {TranslocoService} from "@ngneat/transloco";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import moment from "moment/moment";


@Component({
    selector: 'app-confirm-approvazione-trasversali-stepper',
    templateUrl: './confirm-approvazione-trasversali.component.html',
    styleUrls: ['./confirm-approvazione-trasversali.component.scss']
})
export class ConfirmApprovazioneTrasversaliComponent extends AbstractDefaultComponent implements OnInit {

    approvazioneAttivitaData: ApprovazioneTrasversaliMassivaDataI;
    operationSucceed: boolean;
    operationError: boolean;
    operationEsiti: Array<EsitoSingolaOperazioneSuAttivitaTrasversale> | undefined;
    resultsAttivitaSelected: CorsoPianoDiStudiPerExportViewImpl[];

    constructor(private appInitService: AppInitService,
                private operazioniMassiveService: OperazioniMassiveService,
                private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                @Inject(CURRENT_PROFILE) protected currentProfile: AuthorityType,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneTrasversaliMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneTrasversaliMassivaDataI) => {
            if(inputOutputData?.compileInfo?.length > 0 || this.currentProfile === AuthorityType.COORDINATORE){
                this.approvazioneAttivitaData = inputOutputData;
            }
            // if there are some trasversali selected reset the succeed and error flags
            if (this.approvazioneAttivitaData?.attivitaSelected?.length > 0) {
                this.operationSucceed = false;
                this.operationError = false;
            }
        })
    }


    confirmOperationCoordinator() {
        this.operationError = undefined;
        this.operationSucceed = undefined;
        this.fuseConfirmationService.showLoader();
        const requestBody: DeliberaMassivaAttivitaTrasversaliRequest = {
            id_delibera: this.approvazioneAttivitaData?.deliberaCollegio?.id,
            attivita_trasversali_ids: this.approvazioneAttivitaData?.attivitaSelected?.map(a => ({
                id_attivita_trasversale: a.idAttivitaTrasversale,
                id_attivita_presunta: a.idAttivitaPresunta,
                id_attivita_proposta: a.idAttivitaProposta,
            }))
        };
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.operazioniMassiveService.deliberaMassivaAttivitaTrasversali(requestBody)),
            takeUntil(this.destroy$),
            tap((approvaAttivitaResponse: OperazioneMassivaSuAttivitaTrasversaliResponse) => {
                this.resultsAttivitaSelected = cloneDeep(this.approvazioneAttivitaData?.attivitaSelected);
                // forcing the refresh of trasversali
                this.operazioneMassivaData$.next({
                    attivitaSelected: undefined,
                    deliberaCollegio: undefined,
                    refreshData: true,
                });
            }),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (approvaTrasv: OperazioneMassivaSuAttivitaTrasversaliResponse) => {
                    this.operationSucceed = true;
                    this.operationEsiti = approvaTrasv?.esiti_operazioni;
                },
                error: (err) => {
                    this.operationError = true;
                    console.log(err)
                }
            }
        );

    }

    confirmOperationSupervisor() {
        this.operationError = undefined;
        this.operationSucceed = undefined;
        this.fuseConfirmationService.showLoader();
        const requestBody: SuperamentoMassivoAttivitaTrasversaliDottorandiRequest = {
            specifiche: this.approvazioneAttivitaData?.attivitaSelected?.map(att => {
                const compileInfo = this.approvazioneAttivitaData?.compileInfo?.find(
                    ci => ci.id === att.idAttivitaTrasversale ||  ci.id === att.idAttivitaPresunta ||  ci.id === att.idAttivitaProposta);
                return {
                    id_attivita_trasversale: att.idAttivitaTrasversale,
                    id_attivita_presunta: att.idAttivitaPresunta,
                    id_attivita_proposta: att.idAttivitaProposta,
                    singola_request: {
                        ore: compileInfo.ore,
                        cfu_ottenuti: compileInfo.cfu,
                        nota_superamento: compileInfo.nota,
                        data_superamento: moment(compileInfo.data).format('DD/MM/YYYY'),
                        id_categoria_offerta_formativa: att.idCategoriaOffertaFormativa,
                    },
                    nome_file_supervisore: this.approvazioneAttivitaData?.compileInfoFiles?.get(compileInfo.id)?.name
                }
            })
        };
        const fileAllegati = [...(this.approvazioneAttivitaData?.compileInfoFiles?.values() ?? [])];
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.operazioniMassiveService.superamentoMassivoAttivitaTrasversaliDottorandiForm(fileAllegati, requestBody)),
            takeUntil(this.destroy$),
            tap((approvaAttivitaResponse: OperazioneMassivaSuAttivitaTrasversaliResponse) => {
                this.resultsAttivitaSelected = cloneDeep(this.approvazioneAttivitaData?.attivitaSelected);
                // forcing the refresh of trasversali
                this.operazioneMassivaData$.next({
                    attivitaSelected: undefined,
                    deliberaCollegio: undefined,
                    refreshData: true,
                });
            }),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (approvaTrasv: OperazioneMassivaSuAttivitaTrasversaliResponse) => {
                    this.operationSucceed = true;
                    this.operationEsiti = approvaTrasv?.esiti_operazioni;
                },
                error: (err) => {
                    this.operationError = true;
                    console.log(err)
                }
            }
        );
    }

    protected readonly EsitoOperazione = EsitoOperazione;

    getStudenteByEsito(esito: EsitoSingolaOperazioneSuAttivitaTrasversale) {
        const studente = this.resultsAttivitaSelected?.find(a =>
            a.idAttivitaTrasversale === esito.id_attivita_trasversale
            || a.idAttivitaPresunta === esito.id_attivita_presunta
            || a.idAttivitaProposta === esito.id_attivita_proposta
        );
        return studente?.utenteCognome + ' ' + studente?.utenteNome;
    }


    getDettaglioFromEsito(esito: EsitoSingolaOperazioneSuAttivitaTrasversale) {
        return this.resultsAttivitaSelected?.find(a =>
            a.idAttivitaTrasversale === esito.id_attivita_trasversale
            || a.idAttivitaPresunta === esito.id_attivita_presunta
            || a.idAttivitaProposta === esito.id_attivita_proposta
        )?.dettaglioAttivitaTrasversale;
    }

    getDenominazioneFromEsito(esito: EsitoSingolaOperazioneSuAttivitaTrasversale) {
        return this.resultsAttivitaSelected?.find(a =>
            a.idAttivitaTrasversale === esito.id_attivita_trasversale
            || a.idAttivitaPresunta === esito.id_attivita_presunta
            || a.idAttivitaProposta === esito.id_attivita_proposta
        )?.denominazione;
    }

    openConfirmOperationDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: get(translation, 'dialog.attention', null),
                message: get(translation, 'massive_operations.confirm_operation_attention_message', null),
                icon: {
                    name: 'mat_outline:warning',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'common.confirm', null), icon: 'check',
                        function: () => [AuthorityType.COSUPERVISORE, AuthorityType.SUPERVISORE].includes(this.currentProfile) ?
                            this.confirmOperationSupervisor() : this.confirmOperationCoordinator()
                    }]
            }
        );
    }

    protected readonly AuthorityType = AuthorityType;
}
