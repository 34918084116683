/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Enum delle chiavi documento
 */
export type ChiaveDocumentoEnum = 'attestato_conclusione_mobilita' | 'allegato_richiesta' | 'convenzione' | 'delibera_collegio' | 'giudizio_commissione' | 'lettera_di_invito' | 'liberatoria' | 'periodo_registro_firmato_dottorando' | 'periodo_registro_firmato_supervisore' | 'periodo_registro_firmato_coordinatore' | 'presentazione_fine_anno_dottorando' | 'relazione_fine_anno_dottorando' | 'relazione_fine_anno_supervisore' | 'registro_attivita' | 'registro_attivita_supervisore';

export const ChiaveDocumentoEnum = {
    AttestatoConclusioneMobilita: 'attestato_conclusione_mobilita' as ChiaveDocumentoEnum,
    AllegatoRichiesta: 'allegato_richiesta' as ChiaveDocumentoEnum,
    Convenzione: 'convenzione' as ChiaveDocumentoEnum,
    DeliberaCollegio: 'delibera_collegio' as ChiaveDocumentoEnum,
    GiudizioCommissione: 'giudizio_commissione' as ChiaveDocumentoEnum,
    LetteraDiInvito: 'lettera_di_invito' as ChiaveDocumentoEnum,
    Liberatoria: 'liberatoria' as ChiaveDocumentoEnum,
    PeriodoRegistroFirmatoDottorando: 'periodo_registro_firmato_dottorando' as ChiaveDocumentoEnum,
    PeriodoRegistroFirmatoSupervisore: 'periodo_registro_firmato_supervisore' as ChiaveDocumentoEnum,
    PeriodoRegistroFirmatoCoordinatore: 'periodo_registro_firmato_coordinatore' as ChiaveDocumentoEnum,
    PresentazioneFineAnnoDottorando: 'presentazione_fine_anno_dottorando' as ChiaveDocumentoEnum,
    RelazioneFineAnnoDottorando: 'relazione_fine_anno_dottorando' as ChiaveDocumentoEnum,
    RelazioneFineAnnoSupervisore: 'relazione_fine_anno_supervisore' as ChiaveDocumentoEnum,
    RegistroAttivita: 'registro_attivita' as ChiaveDocumentoEnum,
    RegistroAttivitaSupervisore: 'registro_attivita_supervisore' as ChiaveDocumentoEnum
};