import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {get} from "lodash";
import {SnackbarTypes} from "../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {FuseConfirmationService} from "../../../../../@fuse/services/confirmation";
import {TranslocoService} from "@ngneat/transloco";
import {AbstractDefaultComponent} from "../../../abstracts/abstract-default-component/abstract-default-component";
import {delay, finalize, of, takeUntil} from "rxjs";
import {ButtonType} from "../../custom-button/custom-button.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {optionFile} from "../../../costants/app-constants";
import {DocumentToSignI} from "../dialog-sign-document.component";
import {
    ChiaveDocumentoEnum, ChiaveFlussoEnum, ChiaveOperazioneEnum, DocumentoService,
    EsitoOperazione,
    FirmaDocumentiConOTPRequest, FirmaDocumentoConCaricamentoFileRequest
} from "../../../../../api-clients/generated/services";
import {tr} from "date-fns/locale";

@Component({
    selector: 'app-sign-with-file-upload',
    templateUrl: './sign-with-file-upload.component.html',
    styleUrls: ['./sign-with-file-upload.component.scss']
})
export class SignWithFileUploadComponent extends AbstractDefaultComponent implements OnInit {

    signLoading = false;
    formGroup: FormGroup;

    @Input() documentData: DocumentToSignI;
    @Input() loading: boolean;
    @Output() loadingChange = new EventEmitter<boolean>();
    @Output() documentsSignedSuccessfully = new EventEmitter<{closeDialog: boolean, errorsForAllDocuments: boolean}>();
    @Input() chiaveDocumento: ChiaveDocumentoEnum;
    @Input() chiaveOperazione: ChiaveOperazioneEnum;
    @Input() chiaveFlusso: ChiaveFlussoEnum;

    constructor(
        private fuseConfirmationService: FuseConfirmationService,
        private translocoService: TranslocoService,
        private fb: FormBuilder,
        private documentoService: DocumentoService,
    ) {
        super();
        this.formGroup = this.fb.group({
            signedFile: [undefined, Validators.required]
        })

    }

    ngOnInit(): void {
    }

    protected readonly undefined = undefined;


    sign() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.signLoading = true;
        this.loadingChange.emit(this.signLoading);
        this.formGroup.disable();
        const requestBody: FirmaDocumentoConCaricamentoFileRequest = {
            chiaveDocumento: this.chiaveDocumento,
            chiaveFlusso: this.chiaveFlusso,
            chiaveOperazione: this.chiaveOperazione,
            userIdAndDocumentoInfoIdDTO: {
                documentoInfoId: this.documentData?.documentoInfo?.id,
                userId: this.documentData?.userId,
            },
        };
        const fileAllegato = this.formGroup?.get('signedFile')?.value;
        this.documentoService.firmaDocumentoConCaricamentoFileForm(fileAllegato, requestBody).pipe(
            finalize(() => {
                this.signLoading = false;
                this.loadingChange.emit(this.signLoading);
                this.formGroup.enable();
            }),
            takeUntil(this.destroy$)
        ).subscribe({
            next: (esiti) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'document_sign.document_signed_successfully', null),
                    type: SnackbarTypes.Success,
                });
                this.documentsSignedSuccessfully.emit({
                    closeDialog: true,
                    errorsForAllDocuments: false,
                });
            },
            error: err => {
                this.fuseConfirmationService.openSnackBar({
                    message: `${get(translation, 'document_sign.error_during_sign', null)}: ${err.error.message}`,
                    error: err, type:
                    SnackbarTypes.Error,
                });
            },
        });

    }

    protected readonly ButtonType = ButtonType;


    protected readonly optionFile = optionFile;

    getUploadedFile(file: File, nameControl: string) {
        this.formGroup.get(nameControl).setValue(file);
    }
}
