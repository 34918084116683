<ng-container *transloco="let t ">
    <app-dialog-wrap [data]="data" (close$)="dialogRef.close()">

        <ng-container *ngIf="data?.alert">
            <fuse-alert
                class="mx-6 my-2"
                [type]="data?.alert?.type"
                [appearance]="'outline'"
            >{{data?.alert?.message}}</fuse-alert>
        </ng-container>

        <ng-container *ngIf="formExist">

            <app-form [ngClass]="{'hidden': !formContaisSomeVisibleField}"
                      [formGroupConfig]="data.formConfig"
                      [validatorFns]="data.formValidator"
                      [valueForm]="data.valueForm"
                      [touched]="touched"
                      [readOnly]="data.readOnly"
                      [chiaveOperazione]="data.chiaveOperazione"
                      [chiaveFlusso]="data.chiaveFlusso"
                      (someDocumentSignedSuccessfully)="someDocumentSignedSuccessfully()"
            ></app-form>


            <ng-container *ngIf="data?.table && data?.table?.configuration" >
                <div class="mb-3 mx-6 flex flex-col gap-2">
                    <mat-label class="w-full font-semibold text-center"
                               *ngIf="data?.table?.title">
                        {{data?.table?.title}}
                    </mat-label>
                    <div style="border-radius: 6px;  border: 1px solid #e2e8f0;">
                        <app-generic-table [configuration]="data?.table?.configuration">
                        </app-generic-table>
                    </div>
                </div>
            </ng-container>

            <fuse-alert
                class="fuse-alter mt-8 "
                *ngIf="alert$ | async"
                [appearance]="'outline'"
                [type]="'error'"
                [@shake]="true"
            >
                {{alert$ | async}}
            </fuse-alert>

            <ng-container *ngIf="data?.alertBottom">
                <fuse-alert
                    class="mx-6 mt-[-12px]"
                    [type]="data?.alertBottom?.type"
                    [appearance]="'outline'">
                    {{data?.alertBottom?.message}}
                    <div *ngIf="data?.alertBottom?.messageHtml"
                         [innerHTML]="data?.alertBottom?.messageHtml">
                    </div>
                </fuse-alert>
            </ng-container>

        </ng-container>


    </app-dialog-wrap>
    <!--Form-->


    <!-- Actions -->
    <ng-container *ngIf="data.actions.confirm.show || data.actions.cancel.show || data.actions.confirm2?.show">
        <div
            class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

            <!-- Cancel -->
            <ng-container *ngIf="data.actions.cancel.show">
                <button color="accent" mat-flat-button [matDialogClose]="'cancelled'"
                        [disabled]="formComponent?.formGroup?.disabled && !data.readOnly">
                    <mat-icon class="mr-2">close</mat-icon>
                    {{data.actions.cancel.label || t('dialog.cancel')}}
                </button>
            </ng-container>

            <!-- Confirm -->
            <ng-container *ngIf="data.actions.confirm.show && !data.readOnly">
                <button mat-flat-button
                        (click)=" saveForm()"
                        [disabled]="formComponent?.formGroup?.disabled"
                        [color]="data.actions.confirm.color">
                    <mat-progress-spinner
                        class="mr-2"
                        *ngIf="formComponent?.formGroup?.disabled"
                        [diameter]="24"
                        [mode]="'indeterminate'"></mat-progress-spinner>
                    <mat-icon class="mr-2"
                              *ngIf="!formComponent?.formGroup?.disabled"
                    >{{data.actions.confirm.icon || 'check'}}</mat-icon>
                                    <span>
                    {{data.actions.confirm.label || t('dialog.confirm')}}
                                    </span>

                </button>

            </ng-container>

            <ng-container *ngIf="data.actions.confirm2?.show && !data.readOnly">
                <button mat-flat-button
                        (click)="saveForm2()"
                        [disabled]="formComponent?.formGroup?.disabled"
                        [color]="data.actions.confirm2.color">
                    <mat-progress-spinner
                        class="mr-2"
                        *ngIf="formComponent?.formGroup?.disabled"
                        [diameter]="24"
                        [mode]="'indeterminate'"></mat-progress-spinner>
                    <mat-icon class="mr-2"
                              *ngIf="!formComponent?.formGroup?.disabled"
                    >{{data.actions.confirm2.icon || 'check'}}</mat-icon>
                                    <span>
                    {{data.actions.confirm2.label || t('dialog.confirm')}}
                                    </span>

                </button>

            </ng-container>

        </div>
    </ng-container>


</ng-container>
