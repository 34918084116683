/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipi di documento.
 */
export type DocumentoType = 'ALLEGATO_RICHIESTA' | 'ATTESTATO' | 'ATTESTATO_CONCLUSIONE_MOBILITA' | 'CONVENZIONE' | 'DOCUMENTO_DI_SPESA' | 'DELIBERA' | 'GIUDIZIO_COMMISSIONE' | 'LETTERA_DI_INVITO' | 'LIBERATORIA' | 'REGISTRO_ATTIVITA' | 'PRESENTAZIONE_DI_FINE_ANNO_DOTTORANDO' | 'REGISTRO_ATTIVITA_SUPERVISORE' | 'RELAZIONE_DI_FINE_ANNO_DOTTORANDO' | 'RELAZIONE_DI_FINE_ANNO_SUPERVISORE' | 'ALTRO';

export const DocumentoType = {
    ALLEGATORICHIESTA: 'ALLEGATO_RICHIESTA' as DocumentoType,
    ATTESTATO: 'ATTESTATO' as DocumentoType,
    ATTESTATOCONCLUSIONEMOBILITA: 'ATTESTATO_CONCLUSIONE_MOBILITA' as DocumentoType,
    CONVENZIONE: 'CONVENZIONE' as DocumentoType,
    DOCUMENTODISPESA: 'DOCUMENTO_DI_SPESA' as DocumentoType,
    DELIBERA: 'DELIBERA' as DocumentoType,
    GIUDIZIOCOMMISSIONE: 'GIUDIZIO_COMMISSIONE' as DocumentoType,
    LETTERADIINVITO: 'LETTERA_DI_INVITO' as DocumentoType,
    LIBERATORIA: 'LIBERATORIA' as DocumentoType,
    REGISTROATTIVITA: 'REGISTRO_ATTIVITA' as DocumentoType,
    PRESENTAZIONEDIFINEANNODOTTORANDO: 'PRESENTAZIONE_DI_FINE_ANNO_DOTTORANDO' as DocumentoType,
    REGISTROATTIVITASUPERVISORE: 'REGISTRO_ATTIVITA_SUPERVISORE' as DocumentoType,
    RELAZIONEDIFINEANNODOTTORANDO: 'RELAZIONE_DI_FINE_ANNO_DOTTORANDO' as DocumentoType,
    RELAZIONEDIFINEANNOSUPERVISORE: 'RELAZIONE_DI_FINE_ANNO_SUPERVISORE' as DocumentoType,
    ALTRO: 'ALTRO' as DocumentoType
};