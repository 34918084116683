import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {of, Subject, switchMap, takeUntil} from 'rxjs';
import {FuseMediaWatcherService} from '@fuse/services/media-watcher';
import {FuseNavigationService, FuseVerticalNavigationComponent} from '@fuse/components/navigation';
import {Navigation} from 'app/core/navigation/navigation.types';
import {LogoutService} from '../../../../shared/service/logout.service';
import {PathEnum} from '../../../../app.routing';
import {LocalStorageService} from '../../../../shared/service/local-storage.service';
import {
    AuthorityType, ChiaveFunzionalitaEnum,
    CicloAbilitatoViewImpl,
    FunzionalitaStatus,
    UserView
} from '../../../../../api-clients/generated/services';
import {AppInitService} from '../../../../shared/service/app-init.service';
import {CicloCorsoRuoloInterface} from '../../../../shared/interface/CicloCorsoRuoloInterface';
import {MatDialog} from '@angular/material/dialog';
import {get, groupBy, head} from 'lodash';
import {FuseConfirmationService} from '../../../../../@fuse/services/confirmation';
import {TranslocoService} from '@ngneat/transloco';
import {tap} from 'rxjs/operators';
import {versionInfo} from '../../../../../../version-info';
import {uniqBy} from "lodash-es";
import {
    AbstractDefaultComponent
} from "../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {
    DialogLicensesComponent,
    DialogLicensesI
} from "../../../../shared/dialogs/dialog-licenses/dialog-licenses.component";
import {MobilitaERicercaGuard} from "../../../../core/guards/mobilita-e-ricerca.guard";
import {SynchronizationsGuard} from "../../../../core/guards/synchronizations.guard";
import {GestioneDocumentiGuard} from "../../../../core/guards/gestione-documenti.guard";
import {AuditGuard} from "../../../../core/guards/audit.guard";
import {UsersListGuard} from "../../../../core/guards/users-list.guard";
import {ConfigurationsGuard} from "../../../../core/guards/configurations.guard";
import {DocentiEsterniListGuard} from "../../../../core/guards/docenti-esterni-list.guard";
import {ConfigurationsGestionaleGuard} from "../../../../core/guards/configurations-gestionale.guard";
import {StudentsListGuard} from "../../../../core/guards/students-list.guard";
import {
    OperazioniMassiveCaricamentoDelibereGuard
} from "../../../../core/guards/operazioni-massive-caricamento-delibere.guard";
import {StudyPlanManagementGuard} from "../../../../core/guards/study-plan-management.guard";
import {TransversalManagementGuard} from "../../../../core/guards/transversal-management.guard";
import {CoursesManagementGuard} from "../../../../core/guards/courses-management.guard";
import {MobReserachManagementGuard} from "../../../../core/guards/mob-research-management.guard";
import {SpeseManagementGuard} from "../../../../core/guards/spese-management.guard";
import {SpesePtaManagementGuard} from "../../../../core/guards/spese-pta-management.guard";
import {BudgetEditPtaManagementGuard} from "../../../../core/guards/budget-edit-pta-management.guard";
import {ActivityLogManagementGuard} from "../../../../core/guards/activity-log-management.guard";
import {MissionsManagementGuard} from "../../../../core/guards/missions-management.guard";
import {YearPassingManagementGuard} from "../../../../core/guards/year-passing-management.guard";
import {ExtraActivitiesManagementGuard} from "../../../../core/guards/extra-activities-management.guard";
import {CicloConfigurationService} from "../../../../shared/service/ciclo-configuration.service";
import {BudgetAdministrationGuard} from "../../../../core/guards/budget-administration.guard";
import {EsterniInvitiAdministrationGuard} from "../../../../core/guards/esterni-inviti-administration.guard";
import {MissionsAdministrationGuard} from "../../../../core/guards/missions-administration.guard";
import {MobilitaAdministrationGuard} from "../../../../core/guards/mobilita-administration.guard";
import {TrainingOfferAdministrationGuard} from "../../../../core/guards/training-offer-administration.guard";
import {TrainingOfferGuard} from "../../../../core/guards/training-offer.guard";
import {StudentsNoMatricolaListGuard} from "../../../../core/guards/students-no-matricola-list.guard";
import {
    StudentsNoMatricolaListGestionaleGuard
} from "../../../../core/guards/students-no-matricola-list-gestionale.guard";

interface Dictionary<T> {
    [Key: string]: T;
}

@Component({
    selector: 'classic-layout',
    templateUrl: './classic.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ClassicLayoutComponent extends AbstractDefaultComponent implements OnInit, OnDestroy {
    @ViewChild(FuseVerticalNavigationComponent) fuseVerticalNavigationComponent: FuseVerticalNavigationComponent;
    @Input() isAreaAdministrator: boolean;

    isScreenSmall: boolean;
    user: UserView;
    cicloCorsoRuolo: CicloCorsoRuoloInterface;
    currentCorsoStudi: string;
    currentRuolo: AuthorityType;
    currentCiclo: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    AuthorityType = AuthorityType;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    versionInfo = versionInfo;
    tagFE: string | undefined;
    tagBE: string | undefined;

    listaCicliCorsiRuoli: CicloAbilitatoViewImpl[] = [];
    listaCicliCorsiRuoliGroupByCiclo: Dictionary<CicloAbilitatoViewImpl[]>;
    showButtonEdit: boolean;
    currentSottoruolo: AuthorityType | undefined;
    sottoruoliUtente: AuthorityType[];
    cicloAmmSelected: number;
    userAmministrazioneRolesFiltered: AuthorityType[];

    constructor(
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private logoutService: LogoutService,
        private localStorageService: LocalStorageService,
        public appInitService: AppInitService,
        private _changeDetectorRef: ChangeDetectorRef,
        private dialog: MatDialog,
        private fuseConfirmationService: FuseConfirmationService,
        private _translocoService: TranslocoService,
        private router: Router,
        private cicloConfigurationService: CicloConfigurationService,
        private translocoService: TranslocoService,
    ) {
        super();
        this.appInitService.cicloCorsoRuoloSelected$.pipe(takeUntil(this.destroy$)).subscribe(value => this.cicloCorsoRuolo = value);
        this.appInitService.listaRuoliCodiciDipartimentiObs.pipe(takeUntil(this.destroy$)).subscribe((val) => {
            this.listaCicliCorsiRuoli = val;
            this.listaCicliCorsiRuoliGroupByCiclo = groupBy(this.listaCicliCorsiRuoli, 'numeroCiclo');
            this.checkShowButtonEdit();
        });
        this.appInitService.cicloAmmSelected$.pipe(takeUntil(this.destroy$)).subscribe((val) => {
            this.cicloAmmSelected = val;
        });
    }


    get currentYear(): number {
        return new Date().getFullYear();
    }

    ngOnInit(): void {

        this.tagFE = this.appInitService.FEversion;
        this.tagBE = this.appInitService.BEversion;

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        if (this.localStorageService.getProfileResponse()) {
            this.user = this.localStorageService.getProfileResponse();
        }
        console.log('isAdministrator', this.isAreaAdministrator)
        if (this.isAreaAdministrator) {
            this.buildMenuAmministrazione();
        } else {
            this.checkGestionale();
        }

        if(this.appInitService.isAreaAdministrator) {
            this.userAmministrazioneRolesFiltered = this.appInitService.userAmministrazioneInfoCicli?.roles?.filter(
                r => [AuthorityType.PTAATENEOSYNCESTERNI, AuthorityType.PTAATENEOMOBILITA, AuthorityType.PTAATENEOCSVANS, AuthorityType.PTAATENEODOTTNOMATRICOLA].includes(r)
            );
        }


    }

    private checkGestionale(): void {

        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            tap((value: CicloCorsoRuoloInterface) => console.log('Dipartimento ruolo ciclo', value)),
            tap((value: CicloCorsoRuoloInterface) => {
                this.currentCorsoStudi = value?.codiceCorsoStudi;
                this.currentRuolo = value?.ruolo;
                this.currentCiclo = value?.ciclo;
                console.log(value)
            }),
            takeUntil(this.destroy$),
            switchMap(() =>
                (this.currentRuolo === AuthorityType.PERSONALEPTA || this.currentRuolo === AuthorityType.DOCENTE || this.currentRuolo === AuthorityType.ESTERNO)
                    ? this.appInitService.currentSottoruoliUtente$ : of(null)),
        ).subscribe({
            next: (sottoruoli: AuthorityType[]) => {
                if (!!sottoruoli) {
                    this.sottoruoliUtente = sottoruoli
                } else {
                    this.sottoruoliUtente = [];
                }
                this.buildMenuGestionale();
                this.fuseVerticalNavigationComponent?.refresh();
                this._changeDetectorRef.detectChanges();
                console.log(this.appInitService.cicloCorsoRuoloSelected, 'this.appInitService.cicloCorsoRuoloSelected');
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => this.logoutService.logout(),
                    () => undefined,
                    'common.logout',
                    err?.error?.message);
            }
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        super.ngOnDestroy();
    }

    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    signOut(): void {
        this.logoutService.logout();
    }

    hideCourseMenuGroup(): boolean {
        return !(!!this.currentCiclo && !!this.currentRuolo
            && !!this.currentCorsoStudi && (this.currentRuolo !== AuthorityType.STUDENTE));
    }

    hideOperativityMenuGroup(): boolean {
        return !(!!this.currentCiclo && !!this.currentRuolo
            && !!this.currentCorsoStudi && (this.currentRuolo !== AuthorityType.STUDENTE && this.currentRuolo !== AuthorityType.COMMISSIONEDIAUTOVALUTAZIONE));
    }

    navigation: Navigation;


    openModalModificaCicloCorsoRuolo(): void {
        this.appInitService.openModalModificaCicloCorsoRuolo();
    }

    openPrivacyOrTerminiECondizioni(isPrivacy: boolean): void {
        const url = this.router.serializeUrl(
            this.router.createUrlTree([isPrivacy ? PathEnum.PRIVACY : PathEnum.TERMS_CONDITIONS])
        );
        window.open(url, '_blank');
    }


    checkShowButtonEdit(): void {
        if (Object.keys(this.listaCicliCorsiRuoliGroupByCiclo)?.length === 1) {
            const ciclo = head(Object.keys(this.listaCicliCorsiRuoliGroupByCiclo));
            const codici = uniqBy(this.listaCicliCorsiRuoliGroupByCiclo[ciclo]?.map(el => el.ruoloUtenteCorsoDiStudi?.corsoDiStudi), 'codiceEsse3');
            const ruoli = this.listaCicliCorsiRuoliGroupByCiclo[ciclo]?.filter(el => (el.ruoloUtenteCorsoDiStudi?.corsoDiStudi?.codiceEsse3 === head(codici)?.codiceEsse3)).map((el => el.ruoloUtenteCorsoDiStudi?.ruolo));
            if (codici.length === 1) {
                this.showButtonEdit = ruoli.length !== 1;
            } else {
                this.showButtonEdit = true;
            }
        } else {
            this.showButtonEdit = true;
        }
    }


    navigateToSouth(): void {
        window.open('https://www.southengineering.it', '_blank', 'noopener, noreferrer');
    }

    openDialogLicenses() {
        const data: DialogLicensesI = {
            title: 'common.licenses',
            icon: {
                name: 'mat_solid:info',
                color: 'basic'
            },
            tagFE: this.tagFE,
            tagBE: this.tagBE
        };
        this.dialog.open(DialogLicensesComponent, {
            data,
            panelClass: 'dialog-responsive-full-screen',
            minWidth: '15rem',
        });
    }

    private buildMenuAmministrazione() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.navigation = {
            default: [
                {
                    title: 'common.home',
                    type: 'basic',
                    link: '/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.HOME,
                    icon: 'mat_outline:home',
                    classes: {
                        title: 'color-white',
                        subtitle: 'color-white',
                        icon: 'color-white',
                        wrapper: 'color-white'
                    }
                },
                {
                    title: 'common.profile',
                    type: 'basic',
                    icon: 'heroicons_outline:user',
                    link: '/' + PathEnum.ADMINISTRATOR + '/' +  PathEnum.PROFILE,
                    classes: {
                        title: 'color-white',
                        subtitle: 'color-white',
                        icon: 'color-white',
                        wrapper: 'color-white'
                    }
                },
                {
                    title: 'sidebar.audit',
                    hidden: item => !AuditGuard.accessCondition(this.appInitService.userAmministrazioneInfoCicli?.roles),
                    type: 'basic',
                    link: '/' + PathEnum.ADMINISTRATOR + '/' +  PathEnum.AUDIT,
                    icon: 'mat_outline:rule',
                    classes: {
                        title: 'color-white',
                        subtitle: 'color-white',
                        icon: 'color-white',
                        wrapper: 'color-white'
                    }
                },
                /*{
                    title: 'sidebar.manual',
                    hidden: item => !ManualGuard.accessCondition(this.cicloCorsoRuolo),
                    type: 'basic',
                    link: '/' + PathEnum.ADMINISTRATOR + '/' +  PathEnum.MANUAL,
                    icon: 'heroicons_outline:book-open',
                    classes: {
                        title: 'color-white',
                        subtitle: 'color-white',
                        icon: 'color-white',
                        wrapper: 'color-white'
                    }
                },*/
                {
                    title: 'home.synchronizations',
                    hidden: item => !SynchronizationsGuard.accessCondition(this.appInitService.userAmministrazioneInfoCicli?.roles),
                    type: 'basic',
                    icon: 'mat_outline:sync',
                    link: '/' + PathEnum.ADMINISTRATOR + '/' +  PathEnum.SYNCHRONIZATIONS,
                    classes: {
                        title: 'color-white',
                        subtitle: 'color-white',
                        icon: 'color-white',
                        wrapper: 'color-white'
                    }
                },
                {
                    title: 'common.courses',
                    type: 'group',
                    classes: {
                        title: 'color-white',
                        subtitle: 'color-white',
                        icon: 'color-white',
                        wrapper: 'color-white'
                    },
                    children: [
                        {
                            title: 'common.information',
                            type: 'basic',
                            link: '/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.INFORMATION,
                            icon: 'mat_outline:info',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'cycle_doctorate.training_offer',
                            type: 'basic',
                            link: '/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.TRAINING_OFFER,
                            disabled: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.AreaDidattica)?.stato === FunzionalitaStatus.DISABILITATA,
                            tooltip: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.AreaDidattica)?.stato === FunzionalitaStatus.DISABILITATA ?
                                get(translation, 'common.functionality_not_available') : undefined,
                            hidden: item => !TrainingOfferAdministrationGuard.accessCondition(this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                            icon: 'mat_outline:library_books',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'sidebar.configurations',
                            type: 'basic',
                            link: '/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.CONFIGURAZIONI,
                            hidden: item => !ConfigurationsGuard.accessCondition(this.appInitService.userAmministrazioneInfoCicli?.roles),
                            icon: 'mat_outline:settings',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        }
                    ]
                },
                {
                    title: 'sidebar.aggregate_data',
                    type: 'group',
                    classes: {
                        title: 'color-white',
                        subtitle: 'color-white',
                        icon: 'color-white',
                        wrapper: 'color-white'
                    },
                    children: [
                        {
                            title: 'sidebar.budget',
                            type: 'basic',
                            link: '/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.BUDGET_LIST,
                            disabled: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.Spese)?.stato === FunzionalitaStatus.DISABILITATA,
                            tooltip: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.Spese)?.stato === FunzionalitaStatus.DISABILITATA ?
                                get(translation, 'common.functionality_not_available') : undefined,
                            hidden: item => !BudgetAdministrationGuard.accessCondition(this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                            icon: 'mat_outline:account_balance_wallet',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'sidebar.document_dashboard',
                            type: 'basic',
                            link: '/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.GESTIONE_DOCUMENTI,
                            icon: 'mat_outline:description',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'home.student_list',
                            type: 'basic',
                            icon: 'mat_outline:school',
                            link: '/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.STUDENTS,
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'home.student_list_no_matricola',
                            type: 'basic',
                            icon: 'mat_outline:school',
                            link: '/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.STUDENTS_NO_MATRICOLA,
                            disabled: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.GestioneDottNoMatricola)?.stato === FunzionalitaStatus.DISABILITATA,
                            tooltip: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.GestioneDottNoMatricola)?.stato === FunzionalitaStatus.DISABILITATA ?
                                get(translation, 'common.functionality_not_available') : undefined,
                            hidden: item => !StudentsNoMatricolaListGuard.accessCondition(this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'sidebar.users_list',
                            type: 'basic',
                            link: '/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.ESTERNI_INVITI_LIST,
                            disabled: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.GestioneEsterni)?.stato === FunzionalitaStatus.DISABILITATA,
                            tooltip: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.GestioneEsterni)?.stato === FunzionalitaStatus.DISABILITATA ?
                                get(translation, 'common.functionality_not_available') : undefined,
                            hidden: item => !EsterniInvitiAdministrationGuard.accessCondition(this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                            icon: 'mat_outline:people',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'sidebar.docenti_esterni_list',
                            type: 'basic',
                            link: '/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.DOCENTI_ESTERNI_LIST,
                            icon: 'mat_outline:groups',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'sidebar.missions',
                            type: 'basic',
                            link: '/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.MISSIONS,
                            disabled: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.Missioni)?.stato === FunzionalitaStatus.DISABILITATA,
                            tooltip: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.Missioni)?.stato === FunzionalitaStatus.DISABILITATA ?
                                get(translation, 'common.functionality_not_available') : undefined,
                            hidden: item => !MissionsAdministrationGuard.accessCondition(this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                            icon: 'mat_outline:work_outline',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'sidebar.mobility_administration',
                            type: 'basic',
                            link: '/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.MOBILITA,
                            disabled: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.Mobilita)?.stato === FunzionalitaStatus.DISABILITATA,
                            tooltip: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.Mobilita)?.stato === FunzionalitaStatus.DISABILITATA ?
                                get(translation, 'common.functionality_not_available') : undefined,
                            hidden: item => !MobilitaAdministrationGuard.accessCondition(this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                            icon: 'mat_outline:card_travel',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'cycle_doctorate.type_of_scholarship',
                            type: 'basic',
                            link: '/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.SCHOLARSHIP,
                            icon: 'heroicons_outline:briefcase',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                    ],
                }
            ]
        }
    }

    buildMenuGestionale() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.navigation = {
            default: [
                {
                    title: 'common.home',
                    type: 'basic',
                    link: '/' + PathEnum.HOME,
                    icon: 'mat_outline:home',
                    classes: {
                        title: 'color-white',
                        subtitle: 'color-white',
                        icon: 'color-white',
                        wrapper: 'color-white'
                    }
                },
                {
                    title: 'common.profile',
                    type: 'basic',
                    icon: 'heroicons_outline:user',
                    link: '/' + PathEnum.PROFILE,
                    classes: {
                        title: 'color-white',
                        subtitle: 'color-white',
                        icon: 'color-white',
                        wrapper: 'color-white'
                    }
                },
/*                {
                    title: 'sidebar.manual',
                    hidden: item => !ManualGuard.accessCondition(this.cicloCorsoRuolo),
                    type: 'basic',
                    link: '/' +  PathEnum.MANUAL,
                    icon: 'heroicons_outline:book-open',
                    classes: {
                        title: 'color-white',
                        subtitle: 'color-white',
                        icon: 'color-white',
                        wrapper: 'color-white'
                    }
                },*/
                {
                    title: 'common.course',
                    type: 'group',
                    hidden: item => this.hideCourseMenuGroup(),
                    classes: {
                        title: 'color-white',
                        subtitle: 'color-white',
                        icon: 'color-white',
                        wrapper: 'color-white'
                    },
                    children: [
                        {
                            title: 'common.information',
                            hidden: item => this.hideCourseMenuGroup(),
                            type: 'basic',
                            link: '/' + PathEnum.INFORMATION,
                            icon: 'mat_outline:info',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'cycle_doctorate.training_offer',
                            hidden: item => this.hideCourseMenuGroup()
                                || !TrainingOfferGuard.accessCondition(this.cicloCorsoRuolo, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                            disabled: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.AreaDidattica)?.stato === FunzionalitaStatus.DISABILITATA,
                            tooltip: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.AreaDidattica)?.stato === FunzionalitaStatus.DISABILITATA ?
                               get(translation, 'common.functionality_not_available') : undefined,
                            type: 'basic',
                            link: '/' + PathEnum.TRAINING_OFFER,
                            icon: 'mat_outline:library_books',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'cycle_doctorate.type_of_scholarship',
                            hidden: item => this.hideCourseMenuGroup(),
                            type: 'basic',
                            link: '/' + PathEnum.SCHOLARSHIP,
                            icon: 'heroicons_outline:briefcase',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'cycle_doctorate.curriculum',
                            hidden: item => this.hideCourseMenuGroup(),
                            type: 'basic',
                            link: '/' + PathEnum.CURRICULUM,
                            icon: 'mat_outline:assignment_ind',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'sidebar.configurations',
                            type: 'basic',
                            link: '/' + PathEnum.CONFIGURAZIONI,
                            hidden: item => !ConfigurationsGestionaleGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente),
                            icon: 'mat_outline:settings',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        }
                    ]
                },
                {
                    title: 'common.operativity',
                    type: 'group',
                    hidden: item => this.hideOperativityMenuGroup(),
                    classes: {
                        title: 'color-white',
                        subtitle: 'color-white',
                        icon: 'color-white',
                        wrapper: 'color-white'
                    },
                    children: [
                        {
                            title: 'home.student_list',
                            hidden: item =>  !StudentsListGuard.accessCondition(this.cicloCorsoRuolo) || StudentsNoMatricolaListGestionaleGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                            type: 'basic',
                            icon: 'mat_outline:group',
                            link: '/' + PathEnum.STUDENTS,
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            id: 'sidebar.student_lists',
                            title: 'sidebar.student_lists',
                            type: 'collapsable',
                            icon: 'mat_outline:school',
                            hidden: item =>
                                !StudentsNoMatricolaListGestionaleGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            },
                            children: [
                                {
                                    title: 'home.student_list',
                                    hidden: item =>  !StudentsListGuard.accessCondition(this.cicloCorsoRuolo),
                                    type: 'basic',
                                    icon: 'mat_outline:group',
                                    link: '/' + PathEnum.STUDENTS,
                                    classes: {
                                        title: 'color-white',
                                        subtitle: 'color-white',
                                        icon: 'color-white',
                                        wrapper: 'color-white'
                                    }
                                },
                                {
                                    title: 'home.student_list_no_matricola',
                                    type: 'basic',
                                    icon: 'mat_outline:group',
                                    link: '/' + PathEnum.STUDENTS_NO_MATRICOLA,
                                    disabled: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.GestioneDottNoMatricola)?.stato === FunzionalitaStatus.DISABILITATA,
                                    tooltip: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.GestioneDottNoMatricola)?.stato === FunzionalitaStatus.DISABILITATA ?
                                        get(translation, 'common.functionality_not_available') : undefined,
                                    hidden: item => !StudentsNoMatricolaListGestionaleGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                                    classes: {
                                        title: 'color-white',
                                        subtitle: 'color-white',
                                        icon: 'color-white',
                                        wrapper: 'color-white'
                                    }
                                },
                            ],
                        },
                        {
                            id: 'main_hub.requests',
                            title: 'sidebar.requests',
                            type: 'collapsable',
                            icon: 'mat_outline:manage_accounts',
                            hidden: item =>
                                !StudyPlanManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA])
                                && !TransversalManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA])
                                && !CoursesManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA])
                                && !ExtraActivitiesManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA])
                                && !MobReserachManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA])
                                && !MissionsManagementGuard.accessCondition(this.cicloCorsoRuolo, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA], this.sottoruoliUtente, true, true)
                                && !SpeseManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA])
                                && !ActivityLogManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA], true, true)
                                && !SpesePtaManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA])
                                && !BudgetEditPtaManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA])
                                && !MobilitaERicercaGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            },
                            children: [
                                {
                                    title: 'common.teaching',
                                    type: 'group',
                                    hidden: item =>
                                        !StudyPlanManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA])
                                        && !TransversalManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA])
                                        && !CoursesManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA])
                                        && !ExtraActivitiesManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                                    classes: {
                                        title: 'color-white',
                                        subtitle: 'color-white',
                                        icon: 'color-white',
                                        wrapper: 'color-white'
                                    },
                                    children: [
                                        {
                                            title: 'statistics.study_plan',
                                            disabled: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.AreaDidattica)?.stato === FunzionalitaStatus.DISABILITATA,
                                            tooltip: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.AreaDidattica)?.stato === FunzionalitaStatus.DISABILITATA ?
                                                get(translation, 'common.functionality_not_available') : undefined,
                                            hidden: item => !StudyPlanManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                                            type: 'basic',
                                            icon: 'mat_outline:library_books',
                                            link: PathEnum.STUDENTS_MANAGEMENT + '/' + PathEnum.STUDY_PLAN,
                                            classes: {
                                                title: 'color-white',
                                                subtitle: 'color-white',
                                                icon: 'color-white',
                                                wrapper: 'color-white'
                                            }
                                        },
                                        {
                                            title: 'sidebar.didactic_activities',
                                            disabled: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.AreaDidattica)?.stato === FunzionalitaStatus.DISABILITATA,
                                            tooltip: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.AreaDidattica)?.stato === FunzionalitaStatus.DISABILITATA ?
                                                get(translation, 'common.functionality_not_available') : undefined,
                                            hidden: item => !CoursesManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                                            type: 'basic',
                                            icon: 'mat_outline:article',
                                            link: PathEnum.STUDENTS_MANAGEMENT + '/' + PathEnum.COURSES,
                                            classes: {
                                                title: 'color-white',
                                                subtitle: 'color-white',
                                                icon: 'color-white',
                                                wrapper: 'color-white'
                                            }
                                        },
                                        {
                                            title: 'sidebar.extra_activities',
                                            disabled: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.AreaDidattica)?.stato === FunzionalitaStatus.DISABILITATA,
                                            tooltip: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.AreaDidattica)?.stato === FunzionalitaStatus.DISABILITATA ?
                                                get(translation, 'common.functionality_not_available') : undefined,
                                            hidden: item => !ExtraActivitiesManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                                            type: 'basic',
                                            icon: 'mat_outline:auto_stories',
                                            link: PathEnum.STUDENTS_MANAGEMENT + '/' + PathEnum.EXTRA_ACTIVITIES,
                                            classes: {
                                                title: 'color-white',
                                                subtitle: 'color-white',
                                                icon: 'color-white',
                                                wrapper: 'color-white'
                                            }
                                        },
                                        {
                                            title: 'sidebar.transversal_activities',
                                            disabled: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.AreaDidattica)?.stato === FunzionalitaStatus.DISABILITATA,
                                            tooltip: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.AreaDidattica)?.stato === FunzionalitaStatus.DISABILITATA ?
                                                get(translation, 'common.functionality_not_available') : undefined,
                                            hidden: item => !TransversalManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                                            type: 'basic',
                                            icon: 'mat_outline:menu_book',
                                            link: PathEnum.STUDENTS_MANAGEMENT + '/' + PathEnum.TRANSVERSAL_ACTIVITIES,
                                            classes: {
                                                title: 'color-white',
                                                subtitle: 'color-white',
                                                icon: 'color-white',
                                                wrapper: 'color-white'
                                            }
                                        },
                                    ]
                                },
                                {
                                    title: 'common.path',
                                    type: 'group',
                                    hidden: item =>
                                        !MobReserachManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA])
                                        && !MissionsManagementGuard.accessCondition(this.cicloCorsoRuolo, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA], this.sottoruoliUtente, true, false)
                                        && !SpeseManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA])
                                        && !ActivityLogManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA], true, false)
                                        && !YearPassingManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                                    classes: {
                                        title: 'color-white',
                                        subtitle: 'color-white',
                                        icon: 'color-white',
                                        wrapper: 'color-white'
                                    },
                                    children: [
                                        {
                                            title: 'sidebar.mobility',
                                            disabled: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.Mobilita)?.stato === FunzionalitaStatus.DISABILITATA,
                                            tooltip: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.Mobilita)?.stato === FunzionalitaStatus.DISABILITATA ?
                                                get(translation, 'common.functionality_not_available') : undefined,
                                            hidden: item => !MobReserachManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                                            type: 'basic',
                                            icon: 'mat_outline:event',
                                            link: PathEnum.STUDENTS_MANAGEMENT + '/' + PathEnum.PERIODS_OF_MOBILITY_AND_RESEARCH,
                                            classes: {
                                                title: 'color-white',
                                                subtitle: 'color-white',
                                                icon: 'color-white',
                                                wrapper: 'color-white'
                                            }
                                        },
                                        {
                                            title: 'sidebar.missions',
                                            disabled: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.Missioni)?.stato === FunzionalitaStatus.DISABILITATA,
                                            tooltip: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.Missioni)?.stato === FunzionalitaStatus.DISABILITATA ?
                                                get(translation, 'common.functionality_not_available') : undefined,
                                            hidden: item => !MissionsManagementGuard.accessCondition(this.cicloCorsoRuolo, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA], this.sottoruoliUtente, true, false),
                                            type: 'basic',
                                            icon: 'mat_outline:work_outline',
                                            link: PathEnum.STUDENTS_MANAGEMENT + '/' + PathEnum.MISSIONS,
                                            classes: {
                                                title: 'color-white',
                                                subtitle: 'color-white',
                                                icon: 'color-white',
                                                wrapper: 'color-white'
                                            }
                                        },
                                        {
                                            title: 'sidebar.budget',
                                            disabled: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.Spese)?.stato === FunzionalitaStatus.DISABILITATA,
                                            tooltip: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.Spese)?.stato === FunzionalitaStatus.DISABILITATA ?
                                                get(translation, 'common.functionality_not_available') : undefined,
                                            hidden: item => !SpeseManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                                            type: 'basic',
                                            icon: 'mat_outline:account_balance_wallet',
                                            link: PathEnum.STUDENTS_MANAGEMENT + '/' + PathEnum.BUDGET,
                                            classes: {
                                                title: 'color-white',
                                                subtitle: 'color-white',
                                                icon: 'color-white',
                                                wrapper: 'color-white'
                                            }
                                        },
                                        {
                                            title: 'sidebar.activity_logs',
                                            disabled: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.RegistroAttivita)?.stato === FunzionalitaStatus.DISABILITATA,
                                            tooltip: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.RegistroAttivita)?.stato === FunzionalitaStatus.DISABILITATA ?
                                                get(translation, 'common.functionality_not_available') : undefined,
                                            hidden: item => !ActivityLogManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA], true, false),
                                            type: 'basic',
                                            icon: 'mat_outline:assignment',
                                            link: PathEnum.STUDENTS_MANAGEMENT + '/' + PathEnum.ACTIVITY_LOG,
                                            classes: {
                                                title: 'color-white',
                                                subtitle: 'color-white',
                                                icon: 'color-white',
                                                wrapper: 'color-white'
                                            }
                                        },
                                        {
                                            title: 'sidebar.year_passing_plural',
                                            disabled: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.SuperamentoAnno)?.stato === FunzionalitaStatus.DISABILITATA,
                                            tooltip: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.SuperamentoAnno)?.stato === FunzionalitaStatus.DISABILITATA ?
                                                get(translation, 'common.functionality_not_available') : undefined,
                                            hidden: item => !YearPassingManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                                            type: 'basic',
                                            icon: 'mat_outline:event_available',
                                            link: PathEnum.STUDENTS_MANAGEMENT + '/' + PathEnum.SUPERAMENTO_ANNO,
                                            classes: {
                                                title: 'color-white',
                                                subtitle: 'color-white',
                                                icon: 'color-white',
                                                wrapper: 'color-white'
                                            }
                                        },
                                    ]
                                },
                                {
                                    title: 'common.requests',
                                    type: 'group',
                                    hidden: item =>
                                        !SpesePtaManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA])
                                        && !BudgetEditPtaManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                                    classes: {
                                        title: 'color-white',
                                        subtitle: 'color-white',
                                        icon: 'color-white',
                                        wrapper: 'color-white'
                                    },
                                    children: [
                                        {
                                            title: 'sidebar.budget',
                                            disabled: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.Spese)?.stato === FunzionalitaStatus.DISABILITATA,
                                            tooltip: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.Spese)?.stato === FunzionalitaStatus.DISABILITATA ?
                                                get(translation, 'common.functionality_not_available') : undefined,
                                            hidden: item => !SpesePtaManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                                            type: 'basic',
                                            icon: 'mat_outline:account_balance_wallet',
                                            link: PathEnum.REQUESTS_MANAGEMENT + '/' + PathEnum.BUDGET,
                                            classes: {
                                                title: 'color-white',
                                                subtitle: 'color-white',
                                                icon: 'color-white',
                                                wrapper: 'color-white'
                                            }
                                        },
                                        {
                                            title: 'sidebar.budget_edit',
                                            disabled: this.cicloConfigurationService.getSubFunctionalityEnablingState(ChiaveFunzionalitaEnum.Spese, ChiaveFunzionalitaEnum.ModificaBudgetDottorando)?.stato === FunzionalitaStatus.DISABILITATA,
                                            tooltip: this.cicloConfigurationService.getSubFunctionalityEnablingState(ChiaveFunzionalitaEnum.Spese, ChiaveFunzionalitaEnum.ModificaBudgetDottorando)?.stato === FunzionalitaStatus.DISABILITATA ?
                                                get(translation, 'common.functionality_not_available') : undefined,
                                            hidden: item => !BudgetEditPtaManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                                            type: 'basic',
                                            icon: 'mat_outline:account_balance',
                                            link: PathEnum.REQUESTS_MANAGEMENT + '/' + PathEnum.BUDGET_EDIT,
                                            classes: {
                                                title: 'color-white',
                                                subtitle: 'color-white',
                                                icon: 'color-white',
                                                wrapper: 'color-white'
                                            }
                                        },
                                    ],
                                },
                                {
                                    title: 'common.recap',
                                    type: 'group',
                                    hidden: item =>
                                        !MissionsManagementGuard.accessCondition(this.cicloCorsoRuolo, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA], this.sottoruoliUtente, false, true)
                                        && !ActivityLogManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA], false, true)
                                        && !MobilitaERicercaGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                                    classes: {
                                        title: 'color-white',
                                        subtitle: 'color-white',
                                        icon: 'color-white',
                                        wrapper: 'color-white'
                                    },
                                    children: [
                                        {
                                            title: 'sidebar.missions',
                                            disabled: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.Missioni)?.stato === FunzionalitaStatus.DISABILITATA,
                                            tooltip: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.Missioni)?.stato === FunzionalitaStatus.DISABILITATA ?
                                                get(translation, 'common.functionality_not_available') : undefined,
                                            hidden: item =>
                                                this.cicloCorsoRuolo?.ruolo === AuthorityType.SUPERADMIN ||
                                                this.cicloCorsoRuolo?.ruolo === AuthorityType.ADMIN ||
                                                !MissionsManagementGuard.accessCondition(this.cicloCorsoRuolo, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA], this.sottoruoliUtente, false, true),
                                            type: 'basic',
                                            icon: 'mat_outline:work_outline',
                                            link: PathEnum.STUDENTS_MANAGEMENT + '/' + PathEnum.MISSIONS,
                                            classes: {
                                                title: 'color-white',
                                                subtitle: 'color-white',
                                                icon: 'color-white',
                                                wrapper: 'color-white'
                                            }
                                        },
                                        {
                                            title: 'sidebar.mobility',
                                            disabled: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.Mobilita)?.stato === FunzionalitaStatus.DISABILITATA,
                                            tooltip: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.Mobilita)?.stato === FunzionalitaStatus.DISABILITATA ?
                                                get(translation, 'common.functionality_not_available') : undefined,
                                            hidden: item => !MobilitaERicercaGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                                            type: 'basic',
                                            link: '/' + PathEnum.MOBILITA,
                                            icon: 'mat_outline:card_travel',
                                            classes: {
                                                title: 'color-white',
                                                subtitle: 'color-white',
                                                icon: 'color-white',
                                                wrapper: 'color-white'
                                            }
                                        },
                                        {
                                            title: 'sidebar.activity_logs',
                                            disabled: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.RegistroAttivita)?.stato === FunzionalitaStatus.DISABILITATA,
                                            tooltip: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.RegistroAttivita)?.stato === FunzionalitaStatus.DISABILITATA ?
                                                get(translation, 'common.functionality_not_available') : undefined,
                                            hidden: item =>
                                                this.cicloCorsoRuolo?.ruolo === AuthorityType.SUPERADMIN ||
                                                this.cicloCorsoRuolo?.ruolo === AuthorityType.ADMIN ||
                                                !ActivityLogManagementGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA], false, true),
                                            type: 'basic',
                                            icon: 'mat_outline:assignment',
                                            link: PathEnum.STUDENTS_MANAGEMENT + '/' + PathEnum.ACTIVITY_LOG,
                                            classes: {
                                                title: 'color-white',
                                                subtitle: 'color-white',
                                                icon: 'color-white',
                                                wrapper: 'color-white'
                                            }
                                        },
                                    ],
                                }
                            ],
                        },
                        {
                            id: 'sidebar.documents_management',
                            title: 'sidebar.documents_management',
                            type: 'collapsable',
                            icon: 'mat_outline:description',
                            hidden: item =>
                                !GestioneDocumentiGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente)
                                && !OperazioniMassiveCaricamentoDelibereGuard.accessCondition(this.cicloCorsoRuolo),
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            },
                            children: [
                                {
                                    title: 'sidebar.document_dashboard',
                                    hidden: item => !GestioneDocumentiGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente),
                                    type: 'basic',
                                    link: '/' + PathEnum.GESTIONE_DOCUMENTI,
                                    icon: 'mat_outline:folder',
                                    classes: {
                                        title: 'color-white',
                                        subtitle: 'color-white',
                                        icon: 'color-white',
                                        wrapper: 'color-white'
                                    }
                                },
                                {
                                    title: 'sidebar.operazioni_massive_load_delibere',
                                    hidden: item => !OperazioniMassiveCaricamentoDelibereGuard.accessCondition(this.cicloCorsoRuolo),
                                    type: 'basic',
                                    link: '/' + PathEnum.OPERAZIONI_MASSIVE_CARICAMENTO_DELIBERE,
                                    icon: 'mat_outline:post_add',
                                    classes: {
                                        title: 'color-white',
                                        subtitle: 'color-white',
                                        icon: 'color-white',
                                        wrapper: 'color-white'
                                    }
                                },
                            ],
                        },
                        {
                            title: 'sidebar.users_list',
                            disabled: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.GestioneEsterni)?.stato === FunzionalitaStatus.DISABILITATA,
                            tooltip: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.GestioneEsterni)?.stato === FunzionalitaStatus.DISABILITATA ?
                                get(translation, 'common.functionality_not_available') : undefined,
                            hidden: item => !UsersListGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente, this.cicloConfigurationService, [FunzionalitaStatus.ABILITATA, FunzionalitaStatus.DISABILITATA]),
                            type: 'basic',
                            link: '/' + PathEnum.ESTERNI_INVITI_LIST,
                            icon: 'mat_outline:people',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'sidebar.docenti_esterni_list',
                            hidden: item => !DocentiEsterniListGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente),
                            type: 'basic',
                            link: '/' + PathEnum.DOCENTI_ESTERNI_LIST,
                            icon: 'mat_outline:groups',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                    ]
                },
            ]
        };
    }

    openModalModificaCicloAmm() {
        this.appInitService.openModalModificaCicloAmm();
    }

    openAdministrationRoleInfoDialog(adminRole: AuthorityType) {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
            title: get(translation, 'administration.' + adminRole + '_title', null),
            message: get(translation, 'administration.' + adminRole, null),
            icon: {
                name: 'mat_outline:info',
                color: 'info'
            },
            onBackdrop: {
                show: false,
                backdrop: true
            },
            actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                ]
            });
    }
}
