<ng-container *transloco="let t">


    <div class="flex flex-col w-full min-h-[20rem] items-center justify-center"
         *ngIf="loading; else stepTemplate">
        <app-loading-screen class="w-full h-full">
        </app-loading-screen>
    </div>


    <ng-template #stepTemplate>

        <div class="mt-4 mb-4">
            <fuse-alert class="w-full"
                        [showIcon]="true"
                        [appearance]="'outline'"
            >{{t('massive_operations.approvazione_registro_attivita.hint_compile_info')}}</fuse-alert>
        </div>

        <div class="w-full flex flex-col items-center">

            <!-- TABELLA STUDENTI -->
            <div class="lg:w-[95%] flex flex-col items-center gap-5 bg-gray-50 rounded-xl p-3"
                 *ngIf="!!periodiCompileInfos">
                <div class="flex flex-col gap-0 w-full items-center">
                    <div class="w-full rounded-lg bg-slate-200 text-slate-600 px-4 py-2 grid grid-cols-8 font-semibold items-center justify-center text-center text-sm">
                        <div>{{t('mobility.request_date')}}</div>
                        <div>{{t('common.start_date')}}</div>
                        <div>{{t('common.end_date')}}</div>
                        <div>{{t('mobility.student')}}</div>
                        <div class="col-span-4">{{t(currentProfile === AuthorityType.COORDINATORE ? 'mobility.approve_coordinator_request_documents' : 'mobility.approve_supervisor_request_documents' )}}</div>
                    </div>
                    <div class="w-full grid grid-cols-8 px-4 py-2 min-h-18 text-center items-center gap-2"
                         *ngFor="let compileInfo of periodiCompileInfos">
                        <div>{{compileInfo.dataRichiestaFormatted}}</div>
                        <div>{{t(compileInfo.dataInizioFormatted)}}</div>
                        <div>{{t(compileInfo.dataFineFormatted)}}</div>
                        <div class="font-semibold">{{t(compileInfo.studenteUIMapped?.utenteCognome)}} {{t(compileInfo.studenteUIMapped?.utenteNome)}}</div>
                        <div class="col-span-4">
                            <app-form class="w-full"
                                      [noPadding]="true"
                                      [formGroupConfig]="getDocumentsFormConfig(compileInfo.utenteId, compileInfo.id)"
                                      [valueForm]="getDocumentsFormValue(compileInfo.utenteId, compileInfo.id)"
                                      (someDocumentSignedSuccessfully)="someDocumentSignedSuccessfully()"
                                      [chiaveFlusso]="ChiaveFlussoEnum.ValidazionePeriodoRegistroAttivita"
                                      [chiaveOperazione]="currentProfile === AuthorityType.COORDINATORE ?
                                        ChiaveOperazioneEnum.ApprovazioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneSupervisore"
                            ></app-form>
                        </div>
                    </div>
                    <div *ngIf="someDocNeedSign" class="w-full flex flex-row justify-end items-center border-t-[1.5px] border-t-slate-200 border-solid pt-3">
                        <!-- ONLY IF DOCUMENTS NEEDED USES SAME SIGN METHODS -->
                        <app-custom-button
                            (clickHandler)="openSignAllRegistroDocumentsDialog()"
                            [textButton]="'document_sign.sign_all_documents'"
                            [type]="ButtonType.SIGN">
                        </app-custom-button>
                    </div>
                </div>
            </div>

        </div>



    </ng-template>



</ng-container>
