import {AfterViewInit, Component, Inject, OnInit, QueryList, ViewChildren} from '@angular/core';
import {
    AttivitaOffertaFormativaInfoView,
    AuthorityType, ChiaveDocumentoEnum,
    ChiaveFlussoEnum,
    ChiaveOperazioneEnum,
    DeliberaInfoViewImpl, ModalitaDiFirmaType, PagePeriodoRegistroAttivitaInfoView, PeriodiRegistroAttivitaService,
    PeriodoRegistroAttivitaInfoView,
    RegistroStudentiCicloService,
    SpeseStudentiCicloService
} from "../../../../../../api-clients/generated/services";
import {BehaviorSubject, finalize, startWith, Subscription, takeUntil} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {TranslocoService} from "@ngneat/transloco";
import * as moment from "moment/moment";
import {formGroupConfigInterface} from "../../../../../layout/common/generic-components/generic-components.interface";
import {FormControl, FormArray} from "@angular/forms";
import {CAN_GO_AHEAD$, CURRENT_PROFILE, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {get} from "lodash";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {makeFilename, openFileInBlankWindow} from "../../../../../shared/utils/utils";
import {HttpResponse} from "@angular/common/http";
import {ApprovazioneRegistroAttivitaMassivaDataI} from "../approvazione-registro-attivita-steps-interface";
import {optionFile} from "../../../../../shared/costants/app-constants";
import {FormComponent} from "../../../../../layout/common/generic-components/form/form.component";
import {
    CicloConfigurationService,
    DocumentDataType,
    isDocumentNotSignedWithSignRequiredCondition, isDocumentSigned
} from "../../../../../shared/service/ciclo-configuration.service";
import {TypeDialogFormEnum} from "../../../../../layout/common/generic-components/generic-components-enum";
import {ButtonType} from "../../../../../shared/components/custom-button/custom-button.component";
import {
    DialogSignDocumentComponent,
    DialogSignDocumentI
} from "../../../../../shared/components/dialog-sign-document/dialog-sign-document.component";
import {MatDialog} from "@angular/material/dialog";
import {SignableDocumentsData} from "../../../../../shared/components/document/document.component";
import {DocumentNameFromUrlPipe} from "../../../../../shared/components/document/pipes/document-name-from-url.pipe";
import {buildConfigurationForRegistriAttivitaRequest} from "../../../requests/requests-utils";
import {LogoutService} from "../../../../../shared/service/logout.service";


@Component({
    selector: 'app-info-approvazione-registro-stepper',
    templateUrl: './compila-info-approvazione-registro.component.html',
    styleUrls: ['./compila-info-approvazione-registro.component.scss']
})
export class CompilaInfoApprovazioneRegistroComponent extends AbstractDefaultComponent implements OnInit, AfterViewInit {

    loading: boolean;
    periodiCompileInfos: PeriodiCompileInfo[];
    formConfig: formGroupConfigInterface[];
    valueForm: any;
    corso: AttivitaOffertaFormativaInfoView = undefined;
    periodiSelected: PeriodoRegistroAttivitaInfoView[];
    delibere: DeliberaInfoViewImpl[];
    outputFormatData = 'DD/MM/YYYY';
    documentsFormConfigs: { formConfig: formGroupConfigInterface[], utenteId: string, periodoId: string }[];
    documentsFormValues: DocumentDataType[];
    _docsFormComponents: FormComponent[];
    docsFormComponentSubs: Subscription[] = [];
    @ViewChildren(FormComponent) formComponentsQL: QueryList<FormComponent>;

    ngAfterViewInit() {
        this.formComponentsQL.changes
            ?.pipe(startWith(this.formComponentsQL))
            ?.subscribe((ql: QueryList<FormComponent>) => {
                this._docsFormComponents = ql.toArray();
                this.subscribeToDocsFormsValueChange();
            });
    }

    private subscribeToDocsFormsValueChange() {
        const someNotSignedDocExists = this.documentsFormValues
            ?.find(formValue => Object.values(formValue?.signableDocumentsData ?? {})?.find((docSignData: SignableDocumentsData) => isDocumentNotSignedWithSignRequiredCondition(docSignData)));
        this.canGoNext$.next(this._docsFormComponents?.every(fc => fc.formGroup?.valid) && !someNotSignedDocExists);
        this.docsFormComponentSubs?.forEach(fcs => fcs.unsubscribe());
        const documentiAllegati = [];
        this.docsFormComponentSubs = this._docsFormComponents?.map(fc =>
            (fc?.formGroup?.valueChanges
            ?.pipe(startWith(fc?.formGroup?.value))
                .subscribe(docsFormValue => {
                    const someNotSignedDocExists = this.documentsFormValues
                        ?.find(formValue => Object.values(formValue?.signableDocumentsData ?? {})?.find((docSignData: SignableDocumentsData) => isDocumentNotSignedWithSignRequiredCondition(docSignData)));
                    this.canGoNext$.next(this._docsFormComponents?.every(fc => fc.formGroup?.valid) && !someNotSignedDocExists);
                    const documents = this.cicloConfigurationService.prepareDocsSubmitObject(
                        ChiaveFlussoEnum.ValidazionePeriodoRegistroAttivita,
                        this.currentProfile === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneSupervisore,
                        docsFormValue,
                    );
                    let existingDocs = documentiAllegati?.find(d => d.id_periodo_registro_attivita === fc.formGroup?.value?.id_periodo_registro_attivita && d.id_utente === fc.formGroup?.value?.id_utente);
                    if(existingDocs){
                        existingDocs.documents = (documents ?? {});
                    } else {
                        documentiAllegati.push({
                            id_periodo_registro_attivita: fc.formGroup?.value?.id_periodo_registro_attivita,
                            id_utente: fc.formGroup?.value?.id_utente,
                            documents: (documents ?? {}),
                        });
                    }
            })));
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$?.getValue(),
            documentiAllegati: documentiAllegati?.map(da => ({
                id_periodo_registro_attivita: da?.id_periodo_registro_attivita,
                id_utente: da?.id_utente,
                ...(da?.documents ?? {})
            }))
        });

    }

    constructor(private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private logOutService: LogoutService,
                private cicloConfigurationService: CicloConfigurationService,
                private dialog: MatDialog,
                private registroStudentiCicloService: RegistroStudentiCicloService,
                private periodiRegistroAttivitaService: PeriodiRegistroAttivitaService,
                @Inject(CURRENT_PROFILE) protected currentProfile: AuthorityType,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneRegistroAttivitaMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.initForm();
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneRegistroAttivitaMassivaDataI) => {
            this.initForm();
            // if confirm step force refresh set as not completed the step
            if (inputOutputData.refreshData) {
                this.canGoNext$.next(false);
            }
            // if periodi selected changes reset delibera form
            if (inputOutputData.periodiSelected !== this.periodiSelected) {
                this.periodiSelected = inputOutputData?.periodiSelected;
                this._docsFormComponents?.forEach(fc => fc?.formGroup?.reset({emitEvent: false}));
                this.buildCompileInfoComponent();
                this.periodiCompileInfos = this.buildPeriodiCompileInfoConfiguration(this.periodiSelected || []);

            }


        });
    }

    private buildCompileInfoComponent() {
        this.documentsFormConfigs = this.periodiSelected?.map(period => ({
            formConfig: [
                {
                    show: false,
                    name: 'id_periodo_registro_attivita',
                    transloco: 'id_periodo_registro_attivita',
                    required: true,
                    type: TypeDialogFormEnum.TEXT
                },
                {
                    show: false,
                    name: 'id_utente',
                    transloco: 'id_utente',
                    required: true,
                    type: TypeDialogFormEnum.TEXT
                },
                ...this.cicloConfigurationService.getDocsFormConfig(
                    ChiaveFlussoEnum.ValidazionePeriodoRegistroAttivita,
                    this.currentProfile === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneSupervisore,
                    (filename) => this.downloadPeriodDocument(filename, period.id, period.studenteCiclo?.utente?.id),
                    undefined,
                    period.allegatiPeriodoRegistroAttivita,
                )
            ],
            utenteId: period?.studenteCiclo?.utente?.id,
            periodoId: period.id,
        }));

        this.documentsFormValues = this.periodiSelected?.map(period => ({
            id_periodo_registro_attivita: period.id,
            id_utente: period?.studenteCiclo?.utente?.id,
            ...this.cicloConfigurationService.getDocsFormValues(
                ChiaveFlussoEnum.ValidazionePeriodoRegistroAttivita,
                this.currentProfile === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneSupervisore,
                period.allegatiPeriodoRegistroAttivita,
                undefined,
                period?.studenteCiclo?.utente?.id,
            )
        }));

        this.someDocNeedSign =
            !!this.documentsFormConfigs?.find(fc =>
                fc?.formConfig?.find(fci =>
                    fci.signModes?.filter(mode => mode !== ModalitaDiFirmaType.CARICAMENTOFILE)?.length > 0)
            ) && !!this.documentsFormValues?.find(fv =>
                Object.values(fv?.signableDocumentsData ?? {})
                    ?.find((docSignData: SignableDocumentsData) => !isDocumentSigned(docSignData))
            );
    }

    getDocumentsFormValue(utenteId: string, periodId: string) {
        return this.documentsFormValues?.find(dfv => dfv.id_periodo_registro_attivita === periodId && dfv.id_utente);
    }

    getDocumentsFormConfig(utenteId: string, periodId: string) {
        return this.documentsFormConfigs?.find(dfc => dfc.periodoId === periodId && dfc.utenteId === utenteId)?.formConfig;
    }

    formatDay(date: string) {
        return moment(new Date(date)).format('DD/MM/YYYY');
    }


    private initForm() {
        //this.canGoNext$.next(true);
    }


    private buildPeriodiCompileInfoConfiguration(periodoRegistroAttivitaInfoViews: PeriodoRegistroAttivitaInfoView[]): PeriodiCompileInfo[] {
        const periodi = periodoRegistroAttivitaInfoViews
            ?.map(periodo => ({
                id: periodo.id,
                utenteId: periodo?.studenteCiclo?.utente?.id,
                studenteUIMapped: {
                    utenteNome: periodo?.studenteCiclo?.utente?.nome,
                    utenteCognome: periodo?.studenteCiclo?.utente?.cognome,
                    nome: periodo?.studenteCiclo?.utente?.nome,
                    cognome: periodo?.studenteCiclo?.utente?.cognome,
                    utenteMail: periodo?.studenteCiclo?.utente?.mail?.toLowerCase(),
                    studenteCodiceFiscale: periodo?.studenteCiclo?.utente?.codiceFiscale,
                    codiceFiscale: periodo?.studenteCiclo?.utente?.codiceFiscale,
                    studenteMatricola: periodo?.studenteCiclo?.matricola,
                    urlImmagineProfilo: periodo?.studenteCiclo?.utente?.urlImmagineProfilo,
                    utenteTelefono: periodo?.studenteCiclo?.utente?.telefono
                },
                dataInizioFormatted: periodo.dataInizio ? moment(periodo.dataInizio, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
                dataFineFormatted: periodo.dataFine ? moment(periodo.dataFine, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
                dataRichiestaFormatted: periodo.dataRichiesta ? moment(periodo.dataRichiesta).format('DD/MM/YYYY HH:mm') : undefined,
                dataInizioAndFineFormatted: (periodo.dataInizio ? moment(periodo.dataInizio, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined) + ' - '
                    + (periodo.dataFine ? moment(periodo.dataFine, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined)
            }));
        return periodi ? [...periodi] : undefined;
    }

    protected readonly optionFile = optionFile;


    protected readonly AuthorityType = AuthorityType;
    protected readonly ButtonType = ButtonType;
    someDocNeedSign: boolean;

    openSignAllRegistroDocumentsDialog() {
        const chiaveDocumento = this.currentProfile === AuthorityType.COORDINATORE ?
            ChiaveDocumentoEnum.PeriodoRegistroFirmatoCoordinatore : ChiaveDocumentoEnum.PeriodoRegistroFirmatoSupervisore;
        // using the config of the first student as the configs are all the same
        const documentoFormConfig = this.documentsFormConfigs?.[0]
            ?.formConfig?.find(fc => fc.chiaveDocumento === chiaveDocumento);
        const data: DialogSignDocumentI = {
            title: 'document_sign.sign_documents',
            icon: {
                name: 'mat_solid:draw',
                color: 'info'
            },
            chiaveFlusso: ChiaveFlussoEnum.ValidazionePeriodoRegistroAttivita,
            chiaveOperazione: this.currentProfile === AuthorityType.COORDINATORE ?
                ChiaveOperazioneEnum.ApprovazioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneSupervisore,
            chiaveDocumento: chiaveDocumento,
            availableSignMethods: documentoFormConfig?.signModes,
            documentsSignedSuccessfullyCallback: () => this.someDocumentSignedSuccessfully(),
            documents: this.documentsFormValues
                // for each signable document
                ?.flatMap(dfv => Object.entries(dfv.signableDocumentsData ?? {})?.map(entry => [dfv, ...entry]))
                // take only not signed ones
                ?.filter(([dfv, url, signData]) => !isDocumentSigned(signData))
                ?.map(([dfv, url, signData]) => {
                    // getting period and user data
                    const periodo = this.periodiSelected?.find(p =>
                        p.studenteCiclo?.utente?.id === dfv?.id_utente && p.id === dfv.id_periodo_registro_attivita);
                    const userNameSurname = (periodo.studenteCiclo?.utente?.cognome + ' ' + periodo.studenteCiclo?.utente?.nome)?.toUpperCase();
                    const periodDates = moment(periodo.dataInizio).format('DD/MM/YYYY') + '-' + moment(periodo.dataFine).format('DD/MM/YYYY');
                    // getting document control
                    const ctrl = (this._docsFormComponents?.find(dfc =>
                        dfc.valueForm?.id_utente === dfv?.id_utente && dfc.valueForm?.id_periodo_registro_attivita === dfv?.id_periodo_registro_attivita)
                        ?.formGroup?.get(chiaveDocumento) as FormArray)?.controls?.[0];
                    return {
                        labelNoTranslate: documentoFormConfig?.noTransloco + ' - ' + userNameSurname + ' - ' + periodDates,
                        label: documentoFormConfig?.noTransloco,
                        ctrl: ctrl as FormControl,
                        download: () => this.downloadPeriodDocument(url, dfv?.id_periodo_registro_attivita, dfv?.id_utente),
                        documentoInfo: signData.documentoInfo,
                        userId: signData.utenteId,
                        formattedDocName: new DocumentNameFromUrlPipe().transform(ctrl.value) as string
                    }
                })
        };
        this.dialog.open(DialogSignDocumentComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: true,
        });
    }

    someDocumentSignedSuccessfully() {
        // refresh selected documents
        this.fuseConfirmationService.showLoader();
        this.periodiRegistroAttivitaService.searchPeriodiRegistroAttivitaStudentiForm(
            undefined,
            undefined,
            this.periodiSelected?.map(p => p.id),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined).pipe(
                takeUntil(this.destroy$),
                finalize(() => {
                        this.fuseConfirmationService.hideLoader();
                    }
                )
        ).subscribe({
            next: (periodiSelectedPage: PagePeriodoRegistroAttivitaInfoView) => {
                // replacing selected periods
                this.operazioneMassivaData$.next({
                    ...this.operazioneMassivaData$?.getValue(),
                    periodiSelected: periodiSelectedPage.content,
                    refreshPage: true,
                })
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {this.logOutService.goToHome()},
                    () => this.someDocumentSignedSuccessfully(),
                    'common.go_to_home',
                    err?.error?.message);
            }
        });
    }

    downloadPeriodDocument(nomeFile: string, idPeriodo: string, idUtente: string) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.showLoader();
        this.registroStudentiCicloService.getAllegatoPeriodoRegistroAttivitaStudente(idUtente, idPeriodo, nomeFile, 'response').pipe(
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (fileResponse: HttpResponse<Blob>) => {
                const fileName = fileResponse.headers?.get('Content-Disposition')?.split('=').pop();
                const fileNameToDownload = makeFilename(fileName);
                openFileInBlankWindow(fileResponse?.body, fileNameToDownload);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'budget.file_show_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'student.file_download_error', null),
                    type: SnackbarTypes.Warning,
                });
            }
        });
    }

    protected readonly ChiaveOperazioneEnum = ChiaveOperazioneEnum;
    protected readonly ChiaveFlussoEnum = ChiaveFlussoEnum;
}

export interface PeriodiCompileInfo {
    id: string;
    utenteId: string;
    studenteUIMapped: any;
    dataInizioFormatted: string;
    dataFineFormatted: string;
    dataRichiestaFormatted: string;
    dataInizioAndFineFormatted: string;
}
