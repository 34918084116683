import {Injectable} from '@angular/core';
import {
    ChiaveFlussoEnum,
    ChiaveOperazioneEnum, DeliberaDiProgrammazioneMobilitaInfoView,
    PeriodoDiMobilitaStudenteInfoView, PeriodoRegistroAttivitaInfoView,
    RichiestaDiRetribuzioneMobilitaInfoView
} from "../../../api-clients/generated/services";
import {
    DialogAttachmentsInfoComponent,
    DialogAttachmentsInfoI
} from "../components/dialog-attachments-info/dialog-attachments-info.component";
import {MatDialog} from "@angular/material/dialog";
import moment from "moment";
import {get} from "lodash";
import {TranslocoService} from "@ngneat/transloco";

@Injectable({
    providedIn: 'root'
})
export class AttachmentsInfoDialogService {

    constructor(
        private dialog: MatDialog,
        private translocoService: TranslocoService,
    ) {
    }

    openMobilityRequestAttachmentsDialog(periodo: PeriodoDiMobilitaStudenteInfoView, downloadDocumentFn: (idAllegato, nomeFile, delibera) => void) {
        const data: DialogAttachmentsInfoI = {
            title: periodo.codiceMobilita,
            icon: {
                name: 'mat_outline:info',
                color: 'info'
            },
            documentSections: [
                {
                    sectionTitle: 'mobility.student_documents',
                    requestAttachmentsListKey: 'documentiDottorandoList',
                    operation: ChiaveOperazioneEnum.RichiestaDottorando
                },
                {
                    sectionTitle: 'mobility.approve_supervisor_request_documents',
                    requestAttachmentsListKey: 'documentiSupervisoreList',
                    operation: ChiaveOperazioneEnum.ApprovazioneSupervisore
                },
                {
                    sectionTitle: 'mobility.approve_coordinator_request_documents',
                    requestAttachmentsListKey: 'documentiCoordinatoreList',
                    operation: ChiaveOperazioneEnum.ApprovazioneCoordinatore
                },
                {
                    sectionTitle: 'mobility.student_documents_conclusion',
                    requestAttachmentsListKey: 'documentiDottorandoConclusioneList',
                    operation: ChiaveOperazioneEnum.RichiestaConclusioneDottorando
                },
                {
                    sectionTitle: 'mobility.approve_supervisor_request_documents_conclusion',
                    requestAttachmentsListKey: 'documentiSupervisoreConclusioneList',
                    operation: ChiaveOperazioneEnum.ApprovazioneConclusioneSupervisore
                },
                {
                    sectionTitle: 'mobility.approve_coordinator_request_documents_conclusion',
                    requestAttachmentsListKey: 'documentiCoordinatoreConclusioneList',
                    operation: ChiaveOperazioneEnum.ApprovazioneConclusioneCoordinatore
                }
            ],
            requestData: {
                ...periodo,
                documentiDottorandoList: periodo.allegatiPeriodoDiMobilita?.filter(
                    doc => doc.chiaveFlusso === ChiaveFlussoEnum.RichiestaMobilita &&  doc.chiaveOperazione === ChiaveOperazioneEnum.RichiestaDottorando
                ),
                documentiSupervisoreList: periodo.allegatiPeriodoDiMobilita?.filter(
                    doc => doc.chiaveFlusso === ChiaveFlussoEnum.RichiestaMobilita &&  doc.chiaveOperazione === ChiaveOperazioneEnum.ApprovazioneSupervisore
                ),
                documentiCoordinatoreList: periodo.allegatiPeriodoDiMobilita?.filter(
                    doc => doc.chiaveFlusso === ChiaveFlussoEnum.RichiestaMobilita &&  doc.chiaveOperazione === ChiaveOperazioneEnum.ApprovazioneCoordinatore
                ),
                documentiDottorandoConclusioneList: periodo.allegatiPeriodoDiMobilita?.filter(
                    doc => doc.chiaveFlusso === ChiaveFlussoEnum.RichiestaMobilita &&  doc.chiaveOperazione === ChiaveOperazioneEnum.RichiestaConclusioneDottorando
                ),
                documentiSupervisoreConclusioneList: periodo.allegatiPeriodoDiMobilita?.filter(
                    doc => doc.chiaveFlusso === ChiaveFlussoEnum.RichiestaMobilita &&  doc.chiaveOperazione === ChiaveOperazioneEnum.ApprovazioneConclusioneSupervisore
                ),
                documentiCoordinatoreConclusioneList: periodo.allegatiPeriodoDiMobilita?.filter(
                    doc => doc.chiaveFlusso === ChiaveFlussoEnum.RichiestaMobilita &&  doc.chiaveOperazione === ChiaveOperazioneEnum.ApprovazioneConclusioneCoordinatore
                ),
            },
            flusso: ChiaveFlussoEnum.RichiestaMobilita,
            downloadDocumentFn: (idAllegato, nomeFile, delibera) => downloadDocumentFn(idAllegato, nomeFile, delibera)
        };
        this.dialog.open(DialogAttachmentsInfoComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            autoFocus: false,
            width: '90vw'
        });
    }

    openActivityLogValidationRequestAttachmentsDialog(periodo: PeriodoRegistroAttivitaInfoView, downloadDocumentFn: (idAllegato, nomeFile, delibera) => void) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const data: DialogAttachmentsInfoI = {
            title: `${get(translation, 'activity_log.validation_period')} ${moment(periodo.dataInizio).format('DD/MM/YYYY')}-${moment(periodo.dataFine).format('DD/MM/YYYY')}`,
            icon: {
                name: 'mat_outline:info',
                color: 'info'
            },
            documentSections: [
                {
                    sectionTitle: 'mobility.student_documents',
                    requestAttachmentsListKey: 'documentiDottorandoList',
                    operation: ChiaveOperazioneEnum.RichiestaDottorando
                },
                {
                    sectionTitle: 'mobility.student_documents_request_validation',
                    requestAttachmentsListKey: 'documentiRichiestaValidazioneDottorandoList',
                    operation: ChiaveOperazioneEnum.ApprovazioneDottorando
                },
                {
                    sectionTitle: 'mobility.approve_supervisor_request_documents',
                    requestAttachmentsListKey: 'documentiSupervisoreList',
                    operation: ChiaveOperazioneEnum.ApprovazioneSupervisore
                },
                {
                    sectionTitle: 'mobility.approve_coordinator_request_documents',
                    requestAttachmentsListKey: 'documentiCoordinatoreList',
                    operation: ChiaveOperazioneEnum.ApprovazioneCoordinatore
                },
            ],
            requestData: {
                ...periodo,
                documentiDottorandoList: periodo.allegatiPeriodoRegistroAttivita?.filter(
                    doc => doc.chiaveFlusso === ChiaveFlussoEnum.ValidazionePeriodoRegistroAttivita
                        &&  doc.chiaveOperazione === ChiaveOperazioneEnum.RichiestaDottorando
                ),
                documentiSupervisoreList: periodo.allegatiPeriodoRegistroAttivita?.filter(
                    doc => doc.chiaveFlusso === ChiaveFlussoEnum.ValidazionePeriodoRegistroAttivita
                        &&  doc.chiaveOperazione === ChiaveOperazioneEnum.ApprovazioneSupervisore
                ),
                documentiCoordinatoreList: periodo.allegatiPeriodoRegistroAttivita?.filter(
                    doc => doc.chiaveFlusso === ChiaveFlussoEnum.ValidazionePeriodoRegistroAttivita
                        &&  doc.chiaveOperazione === ChiaveOperazioneEnum.ApprovazioneCoordinatore
                ),
                documentiRichiestaValidazioneDottorandoList: periodo.allegatiPeriodoRegistroAttivita?.filter(
                    doc => doc.chiaveFlusso === ChiaveFlussoEnum.ValidazionePeriodoRegistroAttivita
                        &&  doc.chiaveOperazione === ChiaveOperazioneEnum.ApprovazioneDottorando
                ),
            },
            flusso: ChiaveFlussoEnum.ValidazionePeriodoRegistroAttivita,
            downloadDocumentFn: (idAllegato, nomeFile, delibera) => downloadDocumentFn(idAllegato, nomeFile, delibera)
        };
        this.dialog.open(DialogAttachmentsInfoComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            autoFocus: false,
            width: '90vw'
        });
    }

    openMobilityRetributionRequestAttachmentsDialog(retributionRequest: RichiestaDiRetribuzioneMobilitaInfoView,
                                                    periodo: PeriodoDiMobilitaStudenteInfoView,
                                                    downloadDocumentFn: (idAllegato, nomeFile, delibera) => void) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const periodiFormatted = retributionRequest.periodi?.sort(
            (p1, p2) =>
                moment(p1.dataInizio, 'YYYY-MM-DD').unix() - moment(p2.dataInizio, 'YYYY-MM-DD').unix()
        )?.map(p =>
            get(translation, 'common.from', null) + ' ' +
            moment(p?.dataInizio, 'YYYY-MM-DD').format('DD/MM/YYYY') + ' ' +
            get(translation, 'common.to', null) + ' ' +
            moment(p?.dataFine, 'YYYY-MM-DD').format('DD/MM/YYYY'))?.
        join(', ')
        const data: DialogAttachmentsInfoI = {
            title: `${periodo.codiceMobilita} - ${periodiFormatted}`,
            icon: {
                name: 'mat_outline:info',
                color: 'info'
            },
            documentSections: [
                {
                    sectionTitle: 'mobility.student_documents',
                    requestAttachmentsListKey: 'documentiDottorandoList',
                    operation: ChiaveOperazioneEnum.RichiestaDottorando
                },
                {
                    sectionTitle: 'mobility.approve_supervisor_request_documents',
                    requestAttachmentsListKey: 'documentiSupervisoreList',
                    operation: ChiaveOperazioneEnum.ApprovazioneSupervisore
                },
                {
                    sectionTitle: 'mobility.approve_coordinator_request_documents',
                    requestAttachmentsListKey: 'documentiCoordinatoreList',
                    operation: ChiaveOperazioneEnum.AutorizzazioneCoordinatore
                },
                {
                    sectionTitle: 'mobility.approve_pta_request_documents',
                    requestAttachmentsListKey: 'documentiPTAList',
                    operation: ChiaveOperazioneEnum.ApprovazionePta
                },

            ],
            requestData: {
                ...retributionRequest,
                documentiDottorandoList: retributionRequest.allegatiRichiestaDiRetribuzioneMobilita?.filter(
                    doc => doc.chiaveFlusso === ChiaveFlussoEnum.RichiestaValidazioneSottoperiodiMobilita &&  doc.chiaveOperazione === ChiaveOperazioneEnum.RichiestaDottorando),
                documentiSupervisoreList: retributionRequest.allegatiRichiestaDiRetribuzioneMobilita?.filter(
                    doc => doc.chiaveFlusso === ChiaveFlussoEnum.RichiestaValidazioneSottoperiodiMobilita &&  doc.chiaveOperazione === ChiaveOperazioneEnum.ApprovazioneSupervisore),
                documentiCoordinatoreList: retributionRequest.allegatiRichiestaDiRetribuzioneMobilita?.filter(
                    doc => doc.chiaveFlusso === ChiaveFlussoEnum.RichiestaValidazioneSottoperiodiMobilita &&  doc.chiaveOperazione === ChiaveOperazioneEnum.ApprovazioneCoordinatore),
                documentiPTAList: retributionRequest.allegatiRichiestaDiRetribuzioneMobilita?.filter(
                    doc => doc.chiaveFlusso === ChiaveFlussoEnum.RichiestaValidazioneSottoperiodiMobilita &&  doc.chiaveOperazione === ChiaveOperazioneEnum.ApprovazionePta
                ),

            },
            flusso: ChiaveFlussoEnum.RichiestaValidazioneSottoperiodiMobilita,
            downloadDocumentFn: (idAllegato, nomeFile, delibera) => downloadDocumentFn(idAllegato, nomeFile, delibera)
        };
        this.dialog.open(DialogAttachmentsInfoComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            autoFocus: false,
            width: '90vw'
        });
    }

    openMobilityPreauthRequestAttachmentsDialog(deliberaPreauth: DeliberaDiProgrammazioneMobilitaInfoView,
                                                downloadDocumentFn: (idAllegato, nomeFile, delibera) => void) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const data: DialogAttachmentsInfoI = {
            title: `${get(translation, 'common.delibera')} ${deliberaPreauth.delibera?.numero} - ${moment(deliberaPreauth.delibera?.data, 'YYYY-MM-DD').format('DD/MM/YYYY')}`,
            icon: {
                name: 'mat_outline:info',
                color: 'info'
            },
            documentSections: [
                {
                    sectionTitle: 'mobility.preauth_coordinator_request_documents',
                    requestAttachmentsListKey: 'documentiPTAList',
                    operation: ChiaveOperazioneEnum.PreautorizzazionePta
                }
            ],
            requestData: {
                ...deliberaPreauth,
                documentiPTAList: deliberaPreauth.allegatiPreautorizzazioneMobilita?.filter(
                    doc => doc.chiaveFlusso === ChiaveFlussoEnum.PreautorizzazioneRichiesteMobilita &&  doc.chiaveOperazione === ChiaveOperazioneEnum.PreautorizzazionePta
                ),
            },
            flusso: ChiaveFlussoEnum.PreautorizzazioneRichiesteMobilita,
            downloadDocumentFn: (idAllegato, nomeFile, delibera) => downloadDocumentFn(idAllegato, nomeFile, delibera)
        };
        this.dialog.open(DialogAttachmentsInfoComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            autoFocus: false,
            width: '90vw'
        });
    }
}
