<ng-container *transloco="let t">
    <div class="w-full h-full flex justify-center items-center">
        <div class="w-full">
            <app-dialog-wrap [data]="data">

                <div class="w-full flex flex-col gap-2 items-center pt-2">

                    <mat-form-field *ngFor="let doc of data.documents"
                                    class="w-full mb-1 mat-form-field-no-padding" [appearance]="'outline'">
                        <mat-label>
                            {{ doc.labelNoTranslate || t(doc.label)}}
                        </mat-label>

                        <input matInput
                               readonly
                               hidden
                               [formControl]="doc.ctrl"
                        >

                        <input matInput
                               readonly
                               class="cursor-pointer"
                               [value]="doc.formattedDocName">

                        <button
                            mat-icon-button
                            type="button"
                            (click)="doc.download()"
                            matSuffix>
                            <mat-icon
                                class="icon-size-5"
                                [svgIcon]="'mat_outline:download'"></mat-icon>
                        </button>

                    </mat-form-field>

                    <div class="w-full flex flex-col gap-2 items-center p-6 rounded-xl bg-gray-50" *ngIf="!selectedMode">

                        <div>{{t('document_sign.choose_mode')}}</div>

                        <div class="flex flex-col gap-3 items-center py-3" >

                            <ng-container *ngFor="let signMethod of availableSignMethodsFiltered; let last = last">

                                <div class="px-20">
                                    <app-custom-button
                                        [disabled]="loading"
                                        (clickHandler)="selectedMode = signMethod"
                                        [textButton]="signMethod === ModalitaDiFirmaType.FIRMAOTP ? 'document_sign.sign_with_otp' :
                                            (signMethod === ModalitaDiFirmaType.CARICAMENTOFILE ? 'document_sign.sign_with_file_loading' :
                                            'document_sign.sign_with_using')"
                                        [icon]="signMethod === ModalitaDiFirmaType.FIRMAOTP ? 'pin' :
                                            (signMethod === ModalitaDiFirmaType.CARICAMENTOFILE ? 'file_upload' :
                                            'history_edu') "
                                        [type]="ButtonType.SIGN">
                                    </app-custom-button>
                                </div>

                                <div *ngIf="!last"
                                     class="flex flex-row items-center w-full">
                                    <div class="border-t-[1.5px] border-solid flex w-full"></div>
                                    <div class="px-2 text-sm text-secondary">{{t('common.or')}}</div>
                                    <div class="border-t-[1.5px] border-solid flex w-full"></div>
                                </div>

                            </ng-container>



                        </div>

                    </div>

                    <div class="w-full flex flex-col gap-4 items-center p-6 rounded-xl bg-gray-50" *ngIf="selectedMode">

                        <div class="w-full flex flex-row justify-end gap-2 divide-x-[1px] divide-solid divide-slate-500 text-xs text-gray-500 items-center pb-1"
                             *ngIf="!hideChooseMethod">
                            <div>{{t('document_sign.choose_to_sign_with')}} {{t(getSignMethodString(selectedMode))}}</div>
                            <div class="cursor-pointer font-semibold pl-2 flex flex-row gap-1 items-center"
                                 (click)="chooseAnotherMethod()">
                                <mat-icon class="icon-size-4 text-gray-500">arrow_back</mat-icon>
                                <span>{{t('document_sign.choose_another_method')}}</span>
                            </div>
                        </div>

                        <div class="w-full py-4">
                            <ng-container [ngSwitch]="selectedMode">

                                <ng-container *ngSwitchCase="ModalitaDiFirmaType.FIRMAOTP">
                                    <app-sign-with-otp [(loading)]="loading"
                                                       [chiaveDocumento]="data.chiaveDocumento"
                                                       [chiaveFlusso]="data.chiaveFlusso"
                                                       [chiaveOperazione]="data.chiaveOperazione"
                                                       [documentsData]="data.documents"
                                                       (documentsSignedSuccessfully)="documentsSignedSuccessfully($event)"
                                                       (hideChooseMethod)="hideChooseMethod = $event">
                                    </app-sign-with-otp>
                                </ng-container>

                                <ng-container *ngSwitchCase="ModalitaDiFirmaType.CARICAMENTOFILE">
                                    <app-sign-with-file-upload [(loading)]="loading"
                                                               [chiaveDocumento]="data.chiaveDocumento"
                                                               [chiaveFlusso]="data.chiaveFlusso"
                                                               [chiaveOperazione]="data.chiaveOperazione"
                                                               [documentData]="data.documents?.[0]"
                                                               (documentsSignedSuccessfully)="documentsSignedSuccessfully($event)">
                                    </app-sign-with-file-upload>
                                </ng-container>

                            </ng-container>
                        </div>

                    </div>

                </div>


            </app-dialog-wrap>

            <!-- Actions -->
            <div
                class="flex flex-col justify-end lg:flex-row px-4 py-2 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10 mt-2">

                <div class="flex flex-row justify-center sm:justify-end px-4 py-2 space-x-3">
                    <!-- Cancel -->
                    <button color="accent"
                            [disabled]="loading"
                            (click)="closeDialog()"
                            mat-flat-button>
                        <mat-icon class="mr-2">close</mat-icon>
                        {{ t('common.close') }}
                    </button>

                </div>

            </div>
        </div>
    </div>
</ng-container>

