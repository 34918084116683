import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {GenericTableConfigurationModel} from "../../../../shared/components/table/model/generic-table-model";
import {
    AuthorityType,
    MobilitaRequestSelectFormValuesDTO,
    PeriodiDiMobilitaService,
    TipoPeriodoEnum
} from "../../../../../api-clients/generated/services";

import {MatDrawer} from "@angular/material/sidenav";
import {
    MobilitaSidebarFilterContainerComponent
} from "../../mobility-dashboard/mobilita-sidebar-filter-container/mobilita-sidebar-filter-container.component";
import {
    RicercaSidebarFilterContainerComponent
} from "../../mobility-dashboard/ricerca-sidebar-filter-container/ricerca-sidebar-filter-container.component";
import {
    AutorizzazioniSidebarFilterContainerComponent
} from "../../../../shared/components/autorizzazioni-sidebar-filter-container/autorizzazioni-sidebar-filter-container.component";
import {finalize, switchMap, take, takeUntil} from "rxjs";
import {filter, tap} from "rxjs/operators";
import {OperazioneMassivaTipo} from "../../operazioni-massive/operazioni-massive-config";
import {
    MobilitaFilterManagerRequestService
} from "./mobilita-sidebar-filter-container/mobilita-filter-manager-request.service";
import {
    RicercaFilterManagerRequestService
} from "./ricerca-sidebar-filter-container-request/ricerca-filter-manager-request.service";
import {
    AutorizzazioniFilterManagerRequestService
} from "./autorizzazioni-sidebar-filter-container/autorizzazioni-filter-manager-request.service";
import {OperazioniMassiveComponent} from "../../operazioni-massive/operazioni-massive.component";
import {AppInitService} from "../../../../shared/service/app-init.service";
import {FuseConfirmationService} from "../../../../../@fuse/services/confirmation";
import {TranslocoService} from "@ngneat/transloco";
import {PathEnum} from "../../../../app.routing";
import {Router} from "@angular/router";
import {FormGroup} from "@angular/forms";
import {ApplyFiltersQuicklyStudentsI} from "../../students-list/students-list.component";
import {
    AbstractDefaultComponent
} from "../../../../shared/abstracts/abstract-default-component/abstract-default-component";


export class PeriodsTabDataRequest {
    tabLabel: string;
    tabAriaLabel: string;
    tableConfiguration: GenericTableConfigurationModel;
    tipologiaPeriodi: TipoMobilitaDashboardTabRequest;
    page: number;
    pageSize: number;
}

export enum TipoMobilitaDashboardTabRequest {
    SOGGIORNOESTERO = "SOGGIORNO_ESTERO",
    RICERCA = "RICERCA",
    AUTORIZZAZIONI = "AUTORIZZAZIONI",
}




@Component({
    selector: 'app-approvazione-periodi-di-mobilita-ricerca',
    templateUrl: './approvazione-periodi-di-mobilita-ricerca.component.html',
    styleUrls: ['./approvazione-periodi-di-mobilita-ricerca.component.scss']
})
export class ApprovazionePeriodiDiMobilitaRicercaComponent extends AbstractDefaultComponent implements OnInit, OnDestroy {
    @ViewChild(OperazioniMassiveComponent) operazioniMassiveComponent: OperazioniMassiveComponent;
    @ViewChild('drawer') drawer: MatDrawer;
    mobilitaSidebarContainerComponent: MobilitaSidebarFilterContainerComponent;
    ricercaSidebarContainerComponent: RicercaSidebarFilterContainerComponent;
    autorizzazioniSidebarContainerComponent: AutorizzazioniSidebarFilterContainerComponent;
    selectedTabTipo: TipoMobilitaDashboardTabRequest = TipoMobilitaDashboardTabRequest.SOGGIORNOESTERO;
    TipoMobilitaDashboardTabRequest = TipoMobilitaDashboardTabRequest;
    operazioneMassivaTipo = OperazioneMassivaTipo.APPROVAZIONE_MOBILITA;
    selectValuesMobilita: MobilitaRequestSelectFormValuesDTO;
    selectValuesRicerca: MobilitaRequestSelectFormValuesDTO;
    isFirstTime: boolean;
    currentProfile: AuthorityType;

    @ViewChild('mobilitaSidebarContainer', {static: false}) set mobilitaSidebarContainer(c: MobilitaSidebarFilterContainerComponent) {
        if (!!c) {
            this.mobilitaSidebarContainerComponent = c;
        }
    }

    @ViewChild('ricercaSidebarContainer', {static: false}) set ricercaSidebarContainer(c: RicercaSidebarFilterContainerComponent) {
        if (!!c) {
            this.ricercaSidebarContainerComponent = c;
        }
    }

    @ViewChild('autorizzazioniSidebarContainer', {static: false}) set autorizzazioniSidebarContainer(c: AutorizzazioniSidebarFilterContainerComponent) {
        if (!!c) {
            this.autorizzazioniSidebarContainerComponent = c;
        }
    }


    drawerOpened: boolean = false;
    canRenderContent = false;

    constructor(private mobilitaFilterManagerService: MobilitaFilterManagerRequestService,
                private ricercaFilterManagerService: RicercaFilterManagerRequestService,
                private periodiDiMobilitaService: PeriodiDiMobilitaService,
                private appInitService: AppInitService,
                private fuseConfirmationService: FuseConfirmationService,
                private translocoService: TranslocoService,
                private router: Router,
                private autorizzazioniFilterManagerService: AutorizzazioniFilterManagerRequestService,) {
        super();
        this.appInitService.cicloCorsoRuoloSelected$.pipe(takeUntil(this.destroy$)).subscribe(value => this.currentProfile = value?.sottoruolo);
        const state = router.getCurrentNavigation()?.extras?.state as ApplyFiltersQuicklyStudentsI;
        if (state?.tipoPeriodoEnum === TipoPeriodoEnum.SOGGIORNOESTERO) {
            this.mobilitaFilterManagerService.applyFiltersQuickly = router.getCurrentNavigation()?.extras?.state as ApplyFiltersQuicklyStudentsI;
            this.mobilitaFilterManagerService.selectedIndex = 0;
            this.selectedTabTipo = TipoMobilitaDashboardTabRequest.SOGGIORNOESTERO;
        }
        if (state?.tipoPeriodoEnum === TipoPeriodoEnum.RICERCA) {
            this.ricercaFilterManagerService.applyFiltersQuickly = router.getCurrentNavigation()?.extras?.state as ApplyFiltersQuicklyStudentsI;
            this.mobilitaFilterManagerService.selectedIndex = 1;
            this.selectedTabTipo = TipoMobilitaDashboardTabRequest.RICERCA;
        }
        if (!this.router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString().includes(PathEnum.MOBILITA)) {
            this.getMainFormGroup(this.selectedTabTipo)?.reset({emitEvent: false});
        }
    }

    getCurrentProfileCanAccess() {
        return (profile: AuthorityType) => [
            AuthorityType.COORDINATORE,
            AuthorityType.GRUPPODICOORDINAMENTO,
            AuthorityType.DIRETTOREDIDIPARTIMENTO,
            AuthorityType.SUPERVISORE,
            AuthorityType.COSUPERVISORE,
            AuthorityType.COLLEGIODOCENTI,
        ].includes(profile);
    }


    ngOnInit(): void {
        this.mobilitaFilterManagerService.openDrawer
            .asObservable()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.openDrawer();
            });

        this.ricercaFilterManagerService.openDrawer
            .asObservable()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.openDrawer();
            });

        this.autorizzazioniFilterManagerService.openDrawer
            .asObservable()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.openDrawer();
            });

    }

    getSidebarByTypo(tipo: TipoMobilitaDashboardTabRequest) {
        return tipo === TipoMobilitaDashboardTabRequest.SOGGIORNOESTERO ? this.mobilitaSidebarContainerComponent : this.ricercaSidebarContainerComponent;
    }

    setOperazioneMassiva(modeFlags?: { validation: boolean, conclusion: boolean }) {

        switch (this.selectedTabTipo) {
            case TipoMobilitaDashboardTabRequest.AUTORIZZAZIONI:
                this.operazioneMassivaTipo = OperazioneMassivaTipo.AUTORIZZAZIONE_MOBILITA;
                break;
            case TipoMobilitaDashboardTabRequest.RICERCA:
                if (modeFlags?.validation) {
                    this.operazioneMassivaTipo = OperazioneMassivaTipo.APPROVAZIONE_VALIDAZIONE_RICERCA;
                    this.validationConclusionApprovalMode = true;
                    this.periodsApprovalMode = false;
                } else if (modeFlags?.conclusion) {
                    this.operazioneMassivaTipo = OperazioneMassivaTipo.APPROVAZIONE_CONCLUSIONE_RICERCA;
                    this.validationConclusionApprovalMode = true;
                    this.periodsApprovalMode = false;
                } else {
                    this.operazioneMassivaTipo = OperazioneMassivaTipo.APPROVAZIONE_RICERCA;
                    this.validationConclusionApprovalMode = false;
                    this.periodsApprovalMode = true;
                }
                break;
            case TipoMobilitaDashboardTabRequest.SOGGIORNOESTERO:
                if (modeFlags?.validation) {
                    this.operazioneMassivaTipo = OperazioneMassivaTipo.APPROVAZIONE_VALIDAZIONE_MOBILITA;
                    this.validationConclusionApprovalMode = true;
                    this.periodsApprovalMode = false;
                } else if (modeFlags?.conclusion) {
                    this.operazioneMassivaTipo = OperazioneMassivaTipo.APPROVAZIONE_CONCLUSIONE_MOBILITA;
                    this.validationConclusionApprovalMode = true;
                    this.periodsApprovalMode = false;
                } else {
                    this.operazioneMassivaTipo = OperazioneMassivaTipo.APPROVAZIONE_MOBILITA;
                    this.validationConclusionApprovalMode = false;
                    this.periodsApprovalMode = true;
                }
                break;
        }
        console.log(this.selectedTabTipo)
        if (this.approvalMode) {
            this.isFirstTime = true;
        }
        this.approvalMode = !this.approvalMode;

    }

    changeTab(selectedTabTipo: TipoMobilitaDashboardTabRequest) {
        this.selectedTabTipo = selectedTabTipo;
    }

    reset(tipoPeriodo: TipoMobilitaDashboardTabRequest): void {
        this.getSidebarByTypo(tipoPeriodo)?.resetFilterApplied();
    }

    openDrawer(): void {
        if ((this.selectedTabTipo === TipoMobilitaDashboardTabRequest.SOGGIORNOESTERO && !this.selectValuesMobilita) || (this.selectedTabTipo === TipoMobilitaDashboardTabRequest.RICERCA && !this.selectValuesRicerca)) {
            this.fuseConfirmationService.showLoader();
            this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
                filter(Boolean),
                take(1),
                switchMap(() => this.periodiDiMobilitaService.getMobilitaRequestSelectFormValues()),
                tap((selectValues: MobilitaRequestSelectFormValuesDTO) => {
                    if (this.selectedTabTipo === TipoMobilitaDashboardTabRequest.SOGGIORNOESTERO) {
                        this.selectValuesMobilita = selectValues;
                        this.mobilitaFilterManagerService.setSelectValues(selectValues);
                    } else if (this.selectedTabTipo === TipoMobilitaDashboardTabRequest.RICERCA) {
                        this.selectValuesRicerca = selectValues;
                        this.ricercaFilterManagerService.setSelectValues(selectValues);
                    }

                }),
                takeUntil(this.destroy$),
                finalize(() => this.fuseConfirmationService.hideLoader())
            ).subscribe({
                next: () => {
                    this.getSidebarByTypo(this.selectedTabTipo)?.setFilterApplied();
                    this.drawer.toggle();
                },
                error: (err) => {
                    if (this.selectedTabTipo === TipoMobilitaDashboardTabRequest.SOGGIORNOESTERO) {
                        this.mobilitaFilterManagerService.setSelectValues(undefined);
                    } else if (this.selectedTabTipo === TipoMobilitaDashboardTabRequest.RICERCA) {
                        this.ricercaFilterManagerService.setSelectValues(undefined);
                    }
                    this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                        () => {
                        },
                        () => this.openDrawer(),
                        'auth.cancel',
                        err?.error?.message);
                }
            });
        } else {
            this.getSidebarByTypo(this.selectedTabTipo)?.setFilterApplied();
            this.drawer.toggle();
        }

    }

    getMainFormGroup(tipoPeriodo: TipoMobilitaDashboardTabRequest): FormGroup<any> {
        return tipoPeriodo === TipoMobilitaDashboardTabRequest.SOGGIORNOESTERO ? this.mobilitaFilterManagerService?.mainFormGroup :
            (tipoPeriodo === TipoMobilitaDashboardTabRequest.RICERCA ? this.ricercaFilterManagerService?.mainFormGroup : this.autorizzazioniFilterManagerService?.mainFormGroup);
    }

    ngOnDestroy() {
        this.mobilitaFilterManagerService.selectedIndex = 0;
        this.mobilitaFilterManagerService.mainFormGroup?.reset();
        this.ricercaFilterManagerService.mainFormGroup?.reset();
        this.autorizzazioniFilterManagerService.mainFormGroup?.reset();
        super.ngOnDestroy();
    }

    protected readonly AuthorityType = AuthorityType;

    approvalMode = false;
    periodsApprovalMode = false;
    validationConclusionApprovalMode = false;


    changeRuolo(): void {
        this.operazioniMassiveComponent?.changeRuolo();
    }

    resetStep(): void {
        this.operazioniMassiveComponent?.resetStep();
    }

    closeDrawer(): void {
        this.drawer?.close();
    }




    toogleFilterContainer(resp: { hasToogleInternalMenu: boolean }): void {
        if (resp.hasToogleInternalMenu) {
            this.drawer.toggle();
        }
    }
}
