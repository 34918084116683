import {Component, Inject, OnInit} from '@angular/core';
import {
    AutorizzazioneMassivaMobilitaDottorandiResponse,
    CaricamentoMassivoDeliberaPianiDiStudiRequest,
    DeliberaMassivaPianiDiStudiResponse,
    EsitoOperazione,
    EsitoSingolaDeliberaPianoDiStudi, EsitoSingolaOperazioneInOperazioneMassiva,
    OperazioniMassiveService,
    StudenteCicloUtenteViewImpl, UtenteIdsDTO
} from "../../../../../../api-clients/generated/services";
import {filter, switchMap, tap} from "rxjs/operators";
import {BehaviorSubject, finalize, take, takeUntil} from "rxjs";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {AutorizzazioneMobilitaMassivaDataI} from "../autorizzazione-mobilita-steps-interface";
import {cloneDeep} from "lodash-es";
import {get} from "lodash";
import {TranslocoService} from "@ngneat/transloco";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {CAN_GO_AHEAD$, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";


@Component({
    selector: 'app-confirm-autorizzazione-mobilita-stepper',
    templateUrl: './confirm-autorizzazione-mobilita.component.html',
    styleUrls: ['./confirm-autorizzazione-mobilita.component.scss']
})
export class ConfirmAutorizzazioneMobilitaComponent extends AbstractDefaultComponent implements OnInit {

    autorizzazioneMobilitaData: AutorizzazioneMobilitaMassivaDataI;
    operationSucceed: boolean;
    operationError: boolean;
    operationEsiti: Array<EsitoSingolaOperazioneInOperazioneMassiva> | undefined;
    resultsStudentiSelected: StudenteCicloUtenteViewImpl[];

    constructor(private appInitService: AppInitService,
                private operazioniMassiveService: OperazioniMassiveService,
                private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<AutorizzazioneMobilitaMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.operazioneMassivaData$.subscribe((inputOutputData: AutorizzazioneMobilitaMassivaDataI) => {
            this.autorizzazioneMobilitaData = inputOutputData;
            // when there are studenti selected reset succeed and error flags
            if(this.autorizzazioneMobilitaData?.studentiSelected?.length > 0) {
                this.operationSucceed = false;
                this.operationError = false;
            }
        })
    }


    confirmOperation() {
        this.operationError = undefined;
        this.operationSucceed = undefined;
        this.fuseConfirmationService.showLoader();
        const requestBody: UtenteIdsDTO = {
            utente_ids: this.autorizzazioneMobilitaData?.studentiSelected?.map(s => s.idUtente),
        }
        console.log(this.autorizzazioneMobilitaData)
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.operazioniMassiveService.autorizzazioneMassivaMobilitaDottorandi(
                requestBody,)),
            takeUntil(this.destroy$),
            tap((autorizzazioneMassivaMobilitaDottorandiResponse: AutorizzazioneMassivaMobilitaDottorandiResponse) => {
                this.resultsStudentiSelected  = cloneDeep(this.autorizzazioneMobilitaData?.studentiSelected);
                // forcing the refresh of studenti
                this.operazioneMassivaData$.next({
                    studentiSelected: undefined,
                    refreshData: true,
                    documentiAllegati: undefined,
                });
            }),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (autorizzazioneMassivaMobilitaDottorandiResponse: AutorizzazioneMassivaMobilitaDottorandiResponse) => {
                    this.operationSucceed = true;
                    this.operationEsiti = autorizzazioneMassivaMobilitaDottorandiResponse?.esiti_operazioni;
                },
                error: (err) => {
                    this.operationError = true;
                    console.log(err)
                }
            }
        );

    }

    protected readonly EsitoOperazione = EsitoOperazione;

    getStudenteById(id_utente: string) {
        const studente = this.resultsStudentiSelected?.find(s => s.idUtente === id_utente);
        return studente?.cognome + ' ' + studente?.nome;
    }

    openConfirmOperationDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: get(translation, 'dialog.attention', null),
                message: get(translation, 'massive_operations.confirm_operation_attention_message', null),
                icon: {
                    name: 'mat_outline:warning',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'common.confirm', null), icon: 'check',
                        function: () => this.confirmOperation()
                    }]
            }
        );
    }

}
