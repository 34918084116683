import {Component, Inject, Input, OnInit, Optional} from '@angular/core';
import {ChiaveFlussoEnum, DettaglioCalcoloImportoMobilita} from "../../../../api-clients/generated/services";
import {INFO_BOTTOM_COMPONENT_DATA} from "../../../layout/common/info-dialog/info-wrap.component";
import {CicloConfigurationService} from "../../service/ciclo-configuration.service";
import {
    getMaggiorazioneMobilitaTemplateConfig, TemplateConfigCalcoloMaggiorazioneMobilita
} from "../../../modules/landing/student-details/mobility-and-research/maggiorazione-mobilita-utils";


@Component({
  selector: 'app-info-maggiorazione',
  templateUrl: './info-maggiorazione.component.html',
  styleUrls: ['./info-maggiorazione.component.scss']
})
export class InfoMaggiorazioneComponent implements OnInit {
    @Input() dettaglioImporto: DettaglioCalcoloImportoMobilita;
    @Input() anteprimaMode: boolean;
    maggiorazioneMobilitaTemplateConfig: TemplateConfigCalcoloMaggiorazioneMobilita;

    constructor(@Optional() @Inject(INFO_BOTTOM_COMPONENT_DATA) public injectedDettaglioImporto: DettaglioCalcoloImportoMobilita,
                private cicloConfigurationService: CicloConfigurationService) {
        this.dettaglioImporto = this.dettaglioImporto || this.injectedDettaglioImporto;
        this.maggiorazioneMobilitaTemplateConfig = getMaggiorazioneMobilitaTemplateConfig(
            this.cicloConfigurationService.getFlussoCicloConfig(ChiaveFlussoEnum.RichiestaValidazioneSottoperiodiMobilita)?.template_calcolo_maggiorazione_mobilita
        );
    }

    ngOnInit(): void {

    }

}
