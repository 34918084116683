<ng-container *transloco="let t">

    <div class="flex flex-col gap-6 items-center px-20 w-full">

        <div>{{t('document_sign.upload_signed_file_message')}}</div>

        <app-input-file class="w-full"
                        [label]="'document_sign.signed_document'"
                        [listFileAccepted]="optionFile?.listaAccepted"
                        [sizeMB]="optionFile?.sizeMb"
                        [form]="formGroup?.get('signedFile')"
                        (uploadImgEmitter)="getUploadedFile($event, 'signedFile')">
        </app-input-file>

        <app-custom-button
            [disabled]="formGroup?.invalid || signLoading || loading"
            [loading]="signLoading"
            (clickHandler)="sign()"
            [type]="ButtonType.SIGN">
        </app-custom-button>

    </div>

</ng-container>
