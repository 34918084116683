import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RemoveUnderscoresPipe} from './pipe/remove-underscores.pipe';
import {CustomTitleCasePipe} from './pipe/custom-title-case.pipe';
import {StatusEnumToLanguagePathPipe} from './pipe/status-enum-to-language-path.pipe';
import {StatusEnumToColorThemePipe} from './pipe/status-enum-to-color-theme.pipe';
import {DynamicPipe} from "./pipe/dynamic.pipe";
import {CurrencyEuroPipe} from "./pipe/currency-euro.pipe";
import {OnlyIntegerNumbersDirective} from "./directives/number/only-integer-numbers.directive";
import {OnlyPositiveNumbersDirective} from "./directives/number/only-positive-numbers.directive";
import {OnlyDecimalNumbersDirective} from "./directives/number/only-decimal-numbers.directive";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatButtonModule} from "@angular/material/button";
import {FuseAlertModule} from "../../@fuse/components/alert";
import {DialogWrapModule} from "../layout/common/dialog-wrap/dialog-wrap.module";
import {SurveyModule} from "survey-angular-ui";
import {CurriculumAccordionComponent} from './components/curriculum-accordion/curriculum-accordion.component';
import {FlexModule} from "@angular/flex-layout";
import {MatExpansionModule} from "@angular/material/expansion";
import {TranslocoModule} from "@ngneat/transloco";
import {DownloadUserInfoComponent} from './components/download-user-info/download-user-info.component';
import {MatChipsModule} from "@angular/material/chips";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {InputUploadComponent} from "../modules/landing/student-details/anagrafica/input-upload/input-upload.component";
import {MatInputModule} from "@angular/material/input";
import {ScrollRetainerDirective} from "./directives/scroll-retainer/scroll-retainer.directive";
import {DialogLinkDeliberaComponent} from "./components/dialog-link-delibera/dialog-link-delibera.component";
import {MatSelectModule} from "@angular/material/select";
import {GuideCompilazioneComponent} from "./components/guide-compilazione/guide-compilazione.component";
import {MatTabsModule} from "@angular/material/tabs";
import {DialogAreaSelectionComponent} from './components/dialog-area-selection/dialog-area-selection.component';
import {DialogErrorsListComponent} from "./dialogs/dialog-errors-list/dialog-errors-list.component";
import {GraphicErrorRetryComponent} from './components/statistics/graphic-error-retry/graphic-error-retry.component';
import {
    StudentiBarFilterContainerComponent
} from "./components/studenti-topbar-filter-container/studenti-bar-filter-container.component";
import {MaxDatePipe} from "./pipe/max-date.pipe";
import {InfoMaggiorazioneComponent} from "./components/info-maggiorazione/info-maggiorazione.component";
import {
    MobilitaValidazioneDettaglioComponent
} from "./components/mobilita-validazione-dettaglio/mobilita-validazione-dettaglio.component";
import {
    MobilitaRicercaBarFilterContainerComponent
} from "./components/mobilita-ricerca-topbar-filter-container/mobilita-ricerca-bar-filter-container.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDividerModule} from "@angular/material/divider";
import {InfoDeliberaCaricataComponent} from "./components/info-delibera-caricata/info-delibera-caricata.component";
import {
    TrasversaliBarFilterContainerComponent
} from "./components/trasversali-bar-filter-container/trasversali-bar-filter-container.component";
import {
    SpeseBarFilterContainerComponent
} from "./components/spese-bar-filter-container/spese-bar-filter-container.component";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {ValueOrDashPipe} from "./pipe/value-or-dash.pipe";
import {
    CategoriaSpesaRichiestaLiquidazioneToLanguagePathPipe
} from "./pipe/categoria-spesa-liquidazione-missione-to-language-path.pipe";
import {
    AutorizzazioniSidebarFilterContainerComponent
} from "./components/autorizzazioni-sidebar-filter-container/autorizzazioni-sidebar-filter-container.component";
import {AnnoInsegnamentoToNumeriRomaniPipe} from "./pipe/anno-insegnamento-to-numeri-romani.pipe";
import {ValueShowPipe} from "./pipe/value-show.pipe";
import {ValueForStatusPipe} from "./pipe/value-for-status.pipe";
import {GetButtonPipe} from "./pipe/get-button.pipe";
import {BuildNomeCompletoPipe} from "./pipe/build-nomde-completo.pipe";
import {GetValuePipe} from "./pipe/get-value.pipe";
import {OperationSucceedWithAllSuccessPipe} from "./pipe/operation-succeed-with-all-success.pipe";
import {OperationSucceedWithAllErrorPipe} from "./pipe/operation-succeed-with-all-error.pipe";
import {OperationSucceedWithSomeErrorPipe} from "./pipe/operation-succeed-with-some-error.pipe";
import {CustomButtonModule} from "./components/custom-button/custom-button.module";
import {NgOtpInputModule} from "ng-otp-input";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SurveyModule,
        FlexModule,
        FuseAlertModule,
        MatExpansionModule,
        TranslocoModule,
        MatButtonModule,
        MatChipsModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatTooltipModule,
        MatInputModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        DialogWrapModule,
        MatTabsModule,
        MatCheckboxModule,
        MatDividerModule,
        MatSlideToggleModule,
        CustomButtonModule,
        NgOtpInputModule,
    ],
    declarations: [
        RemoveUnderscoresPipe,
        CustomTitleCasePipe,
        StatusEnumToLanguagePathPipe,
        StatusEnumToColorThemePipe,
        DynamicPipe,
        CurrencyEuroPipe,
        ValueShowPipe,
        ValueForStatusPipe,
        OnlyIntegerNumbersDirective,
        OnlyPositiveNumbersDirective,
        OnlyDecimalNumbersDirective,
        AnnoInsegnamentoToNumeriRomaniPipe,
        CurriculumAccordionComponent,
        DownloadUserInfoComponent,
        InputUploadComponent,
        ScrollRetainerDirective,
        DialogLinkDeliberaComponent,
        GuideCompilazioneComponent,
        DialogErrorsListComponent,
        DialogAreaSelectionComponent,
        GraphicErrorRetryComponent,
        StudentiBarFilterContainerComponent,
        MaxDatePipe,
        GetButtonPipe,
        GetValuePipe,
        BuildNomeCompletoPipe,
        InfoMaggiorazioneComponent,
        MobilitaRicercaBarFilterContainerComponent,
        MobilitaValidazioneDettaglioComponent,
        InfoDeliberaCaricataComponent,
        TrasversaliBarFilterContainerComponent,
        SpeseBarFilterContainerComponent,
        ValueOrDashPipe,
        AutorizzazioniSidebarFilterContainerComponent,
        CategoriaSpesaRichiestaLiquidazioneToLanguagePathPipe,
        OperationSucceedWithAllSuccessPipe,
        OperationSucceedWithAllErrorPipe,
        OperationSucceedWithSomeErrorPipe,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RemoveUnderscoresPipe,
        CustomTitleCasePipe,
        StatusEnumToLanguagePathPipe,
        StatusEnumToColorThemePipe,
        DynamicPipe,
        CurrencyEuroPipe,
        OnlyIntegerNumbersDirective,
        OnlyPositiveNumbersDirective,
        OnlyDecimalNumbersDirective,
        AnnoInsegnamentoToNumeriRomaniPipe,
        SurveyModule,
        CurriculumAccordionComponent,
        DownloadUserInfoComponent,
        InputUploadComponent,
        ScrollRetainerDirective,
        GraphicErrorRetryComponent,
        DialogLinkDeliberaComponent,
        GuideCompilazioneComponent,
        DialogErrorsListComponent,
        StudentiBarFilterContainerComponent,
        MaxDatePipe,
        InfoMaggiorazioneComponent,
        MobilitaValidazioneDettaglioComponent,
        MobilitaRicercaBarFilterContainerComponent,
        InfoDeliberaCaricataComponent,
        TrasversaliBarFilterContainerComponent,
        SpeseBarFilterContainerComponent,
        ValueOrDashPipe,
        AutorizzazioniSidebarFilterContainerComponent,
        CategoriaSpesaRichiestaLiquidazioneToLanguagePathPipe,
        ValueShowPipe,
        ValueForStatusPipe,
        GetButtonPipe,
        GetValuePipe,
        BuildNomeCompletoPipe,
        OperationSucceedWithAllSuccessPipe,
        OperationSucceedWithAllErrorPipe,
        OperationSucceedWithSomeErrorPipe,
    ]
})
export class SharedModule
{
}
