import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {get, trim} from "lodash";
import {DialogWrapI} from "../../../../../layout/common/dialog-wrap/dialog-wrap.component";
import {
    AuthorityType,
    ChiaveFlussoEnum,
    ChiaveOperazioneEnum,
    CollegioDocentiInfoView,
    DeliberaDiProgrammazioneMobilitaInfoView,
    DeliberaInfoView,
    GetAllegatoPreautorizzazioneMobilitaRequest,
    PeriodiDiMobilitaService,
    StudentiCicloService
} from "../../../../../../api-clients/generated/services";
import {italianDateWithFormat} from 'app/shared/utils/utils-date';
import moment from "moment";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {PathEnum} from "../../../../../app.routing";
import {Router} from "@angular/router";
import {StudentDetailsService} from "../../../student-details/student-details.service";
import {CicloConfigurationService} from "../../../../../shared/service/ciclo-configuration.service";
import {finalize, takeUntil} from "rxjs";
import {HttpResponse} from "@angular/common/http";
import {openFileInBlankWindow} from "../../../../../shared/utils/utils";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {TranslocoService} from "@ngneat/transloco";
import {AppInitService} from "../../../../../shared/service/app-init.service";


export interface DialogDettaglioDeliberaProgrammazioneI extends DialogWrapI {
    deliberaDettaglio: DeliberaDiProgrammazioneMobilitaInfoView;
    hideStudents?: boolean;
}

@Component({
    selector: 'app-dialog-dettaglio-delibera-programmazione',
    templateUrl: './dialog-dettaglio-delibera-programmazione.component.html',
    styleUrls: ['./dialog-dettaglio-delibera-programmazione.component.scss']
})
export class DialogDettaglioDeliberaProgrammazioneComponent extends AbstractDefaultComponent implements OnInit {

    protected readonly italianDateWithFormat = italianDateWithFormat;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDettaglioDeliberaProgrammazioneI,
                public dialogRef: MatDialogRef<DialogDettaglioDeliberaProgrammazioneComponent>,
                private fuseConfirmationService: FuseConfirmationService,
                private router: Router,
                private studentDetailsService: StudentDetailsService,
                protected cicloConfigurationService: CicloConfigurationService,
                protected translocoService: TranslocoService,
                protected appInitService: AppInitService,
                protected studentiCicloService: StudentiCicloService,
                protected periodiDiMobilitaService: PeriodiDiMobilitaService,
    ) {
        super();
    }

    ngOnInit(): void {

    }

    closeDialog() {
        this.dialogRef.close();
    }


    sortList(list): [] {
        return list.sort((a, b) => {
            if (a.cognome && b.cognome) {
                return a.cognome.localeCompare(b.cognome);
            } else {
                return list;
            }
        });
    }

    buildNomeCompleto(member: CollegioDocentiInfoView): string {
        return trim((member?.cognome || '') + ' ' + (member?.nome || ''));
    }

    protected readonly moment = moment;
    protected readonly AuthorityType = AuthorityType;

    showDelibera() {
        this.fuseConfirmationService.openDialogDelibera({
            delibera: this.data?.deliberaDettaglio?.delibera,
            codiceCorsoDiStudiEsse3: this.studentDetailsService.studentDetails?.codiceCorsoDiStudiEsse3,
        });
    }

    goToStudentPage(idUtente: string) {
        this.dialogRef.close();
        const url = this.router.serializeUrl(
            this.router.createUrlTree([PathEnum.STUDENTS, idUtente, PathEnum.MOBILITA])
        );
        window.open(url, '_blank');
    }

    protected readonly ChiaveOperazioneEnum = ChiaveOperazioneEnum;

    downloadDocument(nomeFile: string, deliberaData?: DeliberaInfoView) {
        if (deliberaData) {
            this.fuseConfirmationService.openDialogDelibera({
                delibera: deliberaData,
                codiceCorsoDiStudiEsse3: this.studentDetailsService.studentDetails?.codiceCorsoDiStudiEsse3,
            });
        } else {
            const activeLang = this.translocoService.getActiveLang();
            const translation = this.translocoService.getTranslation().get(activeLang);
            this.fuseConfirmationService.showLoader();
            const requestBody: GetAllegatoPreautorizzazioneMobilitaRequest = {
                nome_file: nomeFile
            };
            this.periodiDiMobilitaService.getAllegatoPreautorizzazioneMobilita(requestBody, this.data.deliberaDettaglio.id, 'response').pipe(
                takeUntil(this.destroy$),
                finalize(() => {
                    this.fuseConfirmationService.hideLoader();
                })
            ).subscribe({
                next: (fileResponse: HttpResponse<Blob>) => {
                    const fileName = fileResponse.headers?.get('Content-Disposition')?.split('=').pop();
                    //const fileNameToDownload = makeFilename(fileName);
                    openFileInBlankWindow(fileResponse?.body, fileName);
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'budget.file_show_success', null),
                        type: SnackbarTypes.Success,
                    });
                },
                error: (err) => {
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'student.file_download_error', null),
                        type: SnackbarTypes.Warning,
                    });
                }
            });
        }
    }

    getLabelForDoc(operation: ChiaveOperazioneEnum, chiave: string) {
        const a = this.cicloConfigurationService.getTranslatedDescription(
            ChiaveFlussoEnum.PreautorizzazioneRichiesteMobilita,
            operation,
            chiave,
        );
        return a;
    }

}
