import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ClickEvent} from "../../../../../shared/components/table/model/generic-table-model";
import {
    AuthorityType,
    ChiaveFlussoEnum,
    ChiaveOperazioneEnum,
    DeliberaInfoView,
    DeliberaInfoViewImpl,
    DelibereService,
    MobilitaRequestSelectFormValuesDTO,
    PeriodiDiMobilitaService,
    PeriodoDiMobilitaStudenteInfoView,
    TipoPeriodoEnum
} from "../../../../../../api-clients/generated/services";
import {BehaviorSubject, finalize, startWith, Subscription, switchMap, take, takeUntil, tap} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {TranslocoService} from "@ngneat/transloco";
import * as moment from "moment/moment";
import {formGroupConfigInterface} from "../../../../../layout/common/generic-components/generic-components.interface";
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApprovazioneRicercaMassivaDataI} from '../approvazione-ricerca-steps-interface';
import {CAN_GO_AHEAD$, CURRENT_PROFILE, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {MatDialog} from "@angular/material/dialog";
import {filter} from "rxjs/operators";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {CustomValidators} from "../../../../../shared/validators/custom-validators";
import {FormComponent} from "../../../../../layout/common/generic-components/form/form.component";
import {CicloConfigurationService, DocumentDataType} from "../../../../../shared/service/ciclo-configuration.service";
import {
    ApprovazioneMobilitaMassivaDataI
} from "../../approvazione-mobilita-steps/approvazione-mobilita-steps-interface";
import {StudentDetailsService} from "../../../student-details/student-details.service";


@Component({
    selector: 'app-info-approvazione-ricerca-stepper',
    templateUrl: './compila-info-approvazione-ricerca.component.html',
    styleUrls: ['./compila-info-approvazione-ricerca.component.scss']
})
export class CompilaInfoApprovazioneRicercaComponent extends AbstractDefaultComponent implements OnInit {

    loading: boolean;
    formConfig: formGroupConfigInterface[];
    valueForm: any;
    form: FormGroup;
    periodiSelected: PeriodoDiMobilitaStudenteInfoView[];
    delibere: DeliberaInfoViewImpl[];
    outputFormatData = 'DD/MM/YYYY';
    periodiDetailsFormArray: FormArray;
    formSelectValues: MobilitaRequestSelectFormValuesDTO;
    protected readonly AuthorityType = AuthorityType;
    protected readonly TipoPeriodoEnum = TipoPeriodoEnum;
    documentsFormConfig: formGroupConfigInterface[];
    documentsFormValue: DocumentDataType;
    _docsFormComponent: FormComponent;
    docsFormComponentSub: Subscription;
    @ViewChild(FormComponent) set docsFormComponent(docsFormComp: FormComponent) {
        this._docsFormComponent = docsFormComp;
        // subscribe to document form value change
        this.subscribeToDocsFormValueChange();
    };

    private subscribeToDocsFormValueChange() {
        this.canGoNext$.next(this._docsFormComponent.formGroup.valid && this.periodiDetailsFormArray.valid);
        this.docsFormComponentSub?.unsubscribe();
        this.docsFormComponentSub = this._docsFormComponent?.formGroup?.valueChanges
            ?.pipe(startWith(this._docsFormComponent?.formGroup?.value)).subscribe(docsFormValue => {
            this.canGoNext$.next(this._docsFormComponent.formGroup.valid && this.periodiDetailsFormArray.valid);
            const documents = this.cicloConfigurationService.prepareDocsSubmitObject(
                ChiaveFlussoEnum.RichiestaMobilita,
                this.currentProfile === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneSupervisore,
                docsFormValue,
            );
            this.operazioneMassivaData$.next({
                ...this.operazioneMassivaData$?.getValue(),
                //deliberaCollegio: this.delibere?.find(d => d.id === deliberaId),
                documentiAllegati: documents
            });
        });
    }

    constructor(private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private appInitService: AppInitService,
                private delibereService: DelibereService,
                private fb: FormBuilder,
                private dialog: MatDialog,
                private cicloConfigurationService: CicloConfigurationService,
                private periodiDiMobilitaService: PeriodiDiMobilitaService,
                private studentDetailsService: StudentDetailsService,
                @Inject(CURRENT_PROFILE) protected currentProfile: AuthorityType,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneRicercaMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneMobilitaMassivaDataI) => {
            // if confirm step force refresh set as not completed the step
            if(inputOutputData.refreshPeriodi){
                this.canGoNext$.next(false);
                this.periodiDetailsFormArray = undefined;
            }
            // if studenti selected changes reset delibera form and get plans for selected students
            if(inputOutputData.periodiSelected !== this.periodiSelected){
                this.periodiSelected = inputOutputData?.periodiSelected;
                //this.form?.get('deliberaCollegio')?.setValue(inputOutputData.deliberaCollegio?.id, {emitEvent: false});
                this._docsFormComponent?.formGroup?.reset({emitEvent: false});
                this.buildPeriodiDetailsFormGroup(this.periodiSelected);
            }
            this.buildCompileInfoComponent();
        });
        // get form select values
        this.getFormSelectValues();
    }

    private buildCompileInfoComponent() {
        if(!!this.documentsFormConfig){
            return
        }
        this.documentsFormConfig =  [
            ...this.cicloConfigurationService.getDocsFormConfig(
                ChiaveFlussoEnum.RichiestaMobilita,
                this.currentProfile === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneSupervisore,
                (fileUrl) => this.downloadDocument(fileUrl),
                undefined,
            )
        ];
        this.documentsFormValue = {
            ...this.cicloConfigurationService.getDocsFormValues(
                ChiaveFlussoEnum.RichiestaMobilita,
                this.currentProfile === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneSupervisore,
                [],
            )
        };
    }

    private getFormSelectValues() {
        this.fuseConfirmationService.showLoader();
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.periodiDiMobilitaService.getMobilitaRequestSelectFormValues()),
            tap(selectValues => this.formSelectValues = selectValues),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: () => {
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {},() => this.getFormSelectValues(),
                    'dialog.cancel',
                    err?.error?.message);
            }
        });
    }


    tableClickAction($event: ClickEvent) {

    }


    private buildPeriodiDetailsFormGroup(selectedPeriods: PeriodoDiMobilitaStudenteInfoView[]) {
        this.periodiDetailsFormArray = this.fb.array(selectedPeriods?.map(period =>
            this.getPeriodoDetailsForm(period)));
        // subscribe to form group change
        this.periodiDetailsFormArray.valueChanges?.pipe(startWith(this.periodiDetailsFormArray.value)).subscribe(fa => {
            console.log('details value changed', fa)
            this.canGoNext$.next(this.periodiDetailsFormArray.valid && this._docsFormComponent?.formGroup?.valid);
            this.operazioneMassivaData$.next({
                ...this.operazioneMassivaData$?.getValue(),
                compileInfo: fa?.map(value => ({
                    codiceMobilitaPeriodo: value.codiceMobilita,
                    idUtente: value.idUtente,
                    esito: {
                        approvato: true,
                        codice_tipologia_mobilita: value.tipologiaMobilita,
                        //numero_tipologia_bonus: formRawValue.tipologiaBonus,
                        doctor_europeaus: value.doctorEuropeaus.doctorEuropeausFlag || false,
                        sede_doctor_europeaus: value.doctorEuropeaus.sedeDoctorEuropeaus,
                        codice_tipo_intervento_supporto: value.tipoInterventoSupporto,
                        data_inizio: moment(value.dataInizio).format(this.outputFormatData),
                        data_fine: value.dataFine ? moment(value.dataFine).format(this.outputFormatData) : undefined,
                        erasmus: value.erasmus,
                        nome_referente_estero: value?.referenteEstero?.nome,
                        cognome_referente_estero: value?.referenteEstero?.cognome,
                        email_referente_estero: value?.referenteEstero?.email,
                    }
                }))
            });
        })
    }

    private getPeriodoDetailsForm(period: PeriodoDiMobilitaStudenteInfoView) {
        console.log(period)
        return this.fb.group({
            codiceMobilita: period?.codiceMobilita,
            idUtente: period?.studenteCiclo?.utente?.id,
            erasmus: period?.erasmus,
            dataInizio: this.fb.control(period?.dataInizio ? moment(period?.dataInizio, 'DD/MM/YYYY').toDate() : undefined),
            dataFine: this.fb.control(period?.dataFine ? moment(period?.dataFine, 'DD/MM/YYYY').toDate() : undefined),
            tipologiaMobilita: [period?.tipologiaMobilita?.codice, Validators.required],
            tipoInterventoSupporto: period?.tipoInterventoSupporto?.codice,
            doctorEuropeaus: this.fb.group({
                doctorEuropeausFlag: period?.doctorEuropeaus,
                sedeDoctorEuropeaus: period?.sedeDoctorEuropeaus,
            }, {validators: CustomValidators.DoctorEuropeausValidator()}),
            deliberaCollegio: period?.delibera?.id,
            referenteEstero: this.fb.group(
                {
                    nome: [period?.nomeReferenteEstero, [Validators.required, CustomValidators.noWhitespaceValidator]],
                    cognome: [period?.cognomeReferenteEstero, [Validators.required, CustomValidators.noWhitespaceValidator]],
                    email: [period?.emailReferenteEstero, [Validators.required, CustomValidators.noWhitespaceValidator, Validators.pattern(new RegExp('^([a-zA-Z0-9._%-+]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,})$'))]],
                }
            ),
            coordinatorAck: [undefined, this.currentProfile === AuthorityType.COORDINATORE ? Validators.requiredTrue : undefined],
        }, {validators: CustomValidators.dataAMaggioreDiDataA('dataInizio', 'dataFine', this.outputFormatData)});
    }

    clearDateField(fieldCtrl: AbstractControl) {
        fieldCtrl.setValue(undefined);
        fieldCtrl.setErrors(null);
    }

    downloadDocument(urlFile: string, deliberaData?: DeliberaInfoView) {
        if(deliberaData) {
            this.fuseConfirmationService.openDialogDelibera({
                delibera: deliberaData,
                codiceCorsoDiStudiEsse3: this.studentDetailsService.studentDetails?.codiceCorsoDiStudiEsse3,
            });
        }

    }




}
