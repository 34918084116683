import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {get, isEmpty} from "lodash";
import {DialogWrapI} from "../../../layout/common/dialog-wrap/dialog-wrap.component";
import {
    ChiaveFlussoEnum,
    ChiaveOperazioneEnum,
    DeliberaInfoView, ModalitaDiFirmaType,
    SuperamentoAnnoStudenteStatus
} from "../../../../api-clients/generated/services";
import {
    CicloConfigurationService,
    isDocumentSigned,
    isTargetDocumentSigned
} from "../../service/ciclo-configuration.service";
import {AbstractDefaultComponent} from "../../abstracts/abstract-default-component/abstract-default-component";

export interface DialogAttachmentsDocumentsSection {
    sectionTitle: string;
    requestAttachmentsListKey: string;
    operation: ChiaveOperazioneEnum;
}

export interface DialogAttachmentsInfoI extends DialogWrapI {
    documentSections: DialogAttachmentsDocumentsSection[];
    requestData: any;
    flusso: ChiaveFlussoEnum;
    downloadDocumentFn: (idAllegato: string, nomeFile: string, delibera: DeliberaInfoView) => void;
}

@Component({
    selector: 'app-dialog-attachments-info',
    templateUrl: './dialog-attachments-info.component.html',
    styleUrls: ['./dialog-attachments-info.component.scss']
})
export class DialogAttachmentsInfoComponent extends AbstractDefaultComponent implements OnInit {

    documentExists: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogAttachmentsInfoI,
                public dialogRef: MatDialogRef<DialogAttachmentsInfoComponent>,
                public cicloConfigurationService: CicloConfigurationService,
                ) {
        super();
        this.documentExists = this.data.documentSections?.map(section =>
            this.data.requestData[section.requestAttachmentsListKey])
            ?.some(docs => docs?.length > 0);
    }

    ngOnInit(): void {

    }

    closeDialog() {
        this.dialogRef.close();
    }


    protected readonly isEmpty = isEmpty;
    protected readonly SuperamentoAnnoStudenteStatus = SuperamentoAnnoStudenteStatus;
    protected readonly ChiaveOperazioneEnum = ChiaveOperazioneEnum;

    getLabelForDoc(operation: ChiaveOperazioneEnum, chiave: string) {
        const a = this.cicloConfigurationService.getTranslatedDescription(
            this.data.flusso,
            operation,
            chiave,
        );
        return a;
    }


    protected readonly isDocumentSigned = isDocumentSigned;

    protected readonly get = get;
    protected readonly getSignedWithFromKey = getSignedWithFromKey;
    protected readonly isTargetDocumentSigned = isTargetDocumentSigned;
}

export function getSignedWithFromKey(modalitaDiFirma: ModalitaDiFirmaType) {
    switch (modalitaDiFirma){
        case ModalitaDiFirmaType.FIRMAOTP:
            return 'document_sign.document_signed_otp_from';
        case ModalitaDiFirmaType.CARICAMENTOFILE:
            return 'document_sign.document_signed_file_from';
    }
}
