/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddDocumentiDottorandoSuperamentoAnnoStudenteDTO } from '../model/addDocumentiDottorandoSuperamentoAnnoStudenteDTO';
import { AggiornamentoSuperamentoAnnoStudenteInfoView } from '../model/aggiornamentoSuperamentoAnnoStudenteInfoView';
import { CaricamentoDocumentiSuperamentoAnnoCoordinatore } from '../model/caricamentoDocumentiSuperamentoAnnoCoordinatore';
import { CorrezioneCfuOreSuperamentoAnnoEseguitaByDottorando } from '../model/correzioneCfuOreSuperamentoAnnoEseguitaByDottorando';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GetAggiornamentiSuperamentoAnnoStudenteRequest } from '../model/getAggiornamentiSuperamentoAnnoStudenteRequest';
import { GetAllegatoSuperamentoAnnoStudenteRequest } from '../model/getAllegatoSuperamentoAnnoStudenteRequest';
import { GetCfuOreStudentePerAnnoRequest } from '../model/getCfuOreStudentePerAnnoRequest';
import { GetCfuOreStudentePerAnnoResponse } from '../model/getCfuOreStudentePerAnnoResponse';
import { SuperamentoAnnoStudenteInfoView } from '../model/superamentoAnnoStudenteInfoView';
import { VerificaCfuOreSuperamentoAnnoByCoordinatore } from '../model/verificaCfuOreSuperamentoAnnoByCoordinatore';
import { VerificaCfuOreSuperamentoAnnoBySupervisore } from '../model/verificaCfuOreSuperamentoAnnoBySupervisore';
import { VerificaDocumentiSuperamentoAnnoByCoordinatore } from '../model/verificaDocumentiSuperamentoAnnoByCoordinatore';
import { VerificaDocumentiSuperamentoAnnoBySupervisore } from '../model/verificaDocumentiSuperamentoAnnoBySupervisore';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SuperamentoAnnoStudentiCicloService {

    protected basePath = 'https://api-dev-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Permette lo studente di caricare i documenti di Superamento Anno.
     * 
     * @param id_utente id dell&#x27;utente
     * @param files 
     * @param requestDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addDocumentiDottorandoSuperamentoAnnoStudenteForm(id_utente: string, files?: Array<Blob>, requestDTO?: AddDocumentiDottorandoSuperamentoAnnoStudenteDTO, observe?: 'body', reportProgress?: boolean): Observable<Array<SuperamentoAnnoStudenteInfoView>>;
    public addDocumentiDottorandoSuperamentoAnnoStudenteForm(id_utente: string, files?: Array<Blob>, requestDTO?: AddDocumentiDottorandoSuperamentoAnnoStudenteDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SuperamentoAnnoStudenteInfoView>>>;
    public addDocumentiDottorandoSuperamentoAnnoStudenteForm(id_utente: string, files?: Array<Blob>, requestDTO?: AddDocumentiDottorandoSuperamentoAnnoStudenteDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SuperamentoAnnoStudenteInfoView>>>;
    public addDocumentiDottorandoSuperamentoAnnoStudenteForm(id_utente: string, files?: Array<Blob>, requestDTO?: AddDocumentiDottorandoSuperamentoAnnoStudenteDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling addDocumentiDottorandoSuperamentoAnnoStudente.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }
        if (requestDTO !== undefined) {
            formParams = formParams.append('requestDTO', new Blob([JSON.stringify(requestDTO)], { type: 'application/json'})) as any || formParams;
        }

        return this.httpClient.request<Array<SuperamentoAnnoStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/superamento-anno/add-documenti-dottorando`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al coordinatore di caricare i documenti di Superamento Anno del dottorando e completarlo o richiedere la verifica dei CFU e ore.
     * 
     * @param id_utente id dell&#x27;utente
     * @param files 
     * @param requestDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public caricamentoDocumentiSuperamentoAnnoCoordinatoreForm(id_utente: string, files?: Array<Blob>, requestDTO?: CaricamentoDocumentiSuperamentoAnnoCoordinatore, observe?: 'body', reportProgress?: boolean): Observable<Array<SuperamentoAnnoStudenteInfoView>>;
    public caricamentoDocumentiSuperamentoAnnoCoordinatoreForm(id_utente: string, files?: Array<Blob>, requestDTO?: CaricamentoDocumentiSuperamentoAnnoCoordinatore, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SuperamentoAnnoStudenteInfoView>>>;
    public caricamentoDocumentiSuperamentoAnnoCoordinatoreForm(id_utente: string, files?: Array<Blob>, requestDTO?: CaricamentoDocumentiSuperamentoAnnoCoordinatore, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SuperamentoAnnoStudenteInfoView>>>;
    public caricamentoDocumentiSuperamentoAnnoCoordinatoreForm(id_utente: string, files?: Array<Blob>, requestDTO?: CaricamentoDocumentiSuperamentoAnnoCoordinatore, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling caricamentoDocumentiSuperamentoAnnoCoordinatore.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }
        if (requestDTO !== undefined) {
            formParams = formParams.append('requestDTO', new Blob([JSON.stringify(requestDTO)], { type: 'application/json'})) as any || formParams;
        }

        return this.httpClient.request<Array<SuperamentoAnnoStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/superamento-anno/caricamento-documenti-coordinatore`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al dottorando di comunicare l&#x27;avvenuta correzione dei CFU e ore.
     * 
     * @param body 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public correzioneCfuOreSuperamentoAnnoEseguitaByDottorando(body: CorrezioneCfuOreSuperamentoAnnoEseguitaByDottorando, id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SuperamentoAnnoStudenteInfoView>>;
    public correzioneCfuOreSuperamentoAnnoEseguitaByDottorando(body: CorrezioneCfuOreSuperamentoAnnoEseguitaByDottorando, id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SuperamentoAnnoStudenteInfoView>>>;
    public correzioneCfuOreSuperamentoAnnoEseguitaByDottorando(body: CorrezioneCfuOreSuperamentoAnnoEseguitaByDottorando, id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SuperamentoAnnoStudenteInfoView>>>;
    public correzioneCfuOreSuperamentoAnnoEseguitaByDottorando(body: CorrezioneCfuOreSuperamentoAnnoEseguitaByDottorando, id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling correzioneCfuOreSuperamentoAnnoEseguitaByDottorando.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling correzioneCfuOreSuperamentoAnnoEseguitaByDottorando.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<SuperamentoAnnoStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/superamento-anno/correzione-cfu-ore-eseguita-by-dottorando`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce gli aggiornamenti del Superamento Anno di uno studente.
     * 
     * @param body 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAggiornamentiSuperamentoAnnoStudente(body: GetAggiornamentiSuperamentoAnnoStudenteRequest, id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AggiornamentoSuperamentoAnnoStudenteInfoView>>;
    public getAggiornamentiSuperamentoAnnoStudente(body: GetAggiornamentiSuperamentoAnnoStudenteRequest, id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AggiornamentoSuperamentoAnnoStudenteInfoView>>>;
    public getAggiornamentiSuperamentoAnnoStudente(body: GetAggiornamentiSuperamentoAnnoStudenteRequest, id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AggiornamentoSuperamentoAnnoStudenteInfoView>>>;
    public getAggiornamentiSuperamentoAnnoStudente(body: GetAggiornamentiSuperamentoAnnoStudenteRequest, id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getAggiornamentiSuperamentoAnnoStudente.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getAggiornamentiSuperamentoAnnoStudente.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<AggiornamentoSuperamentoAnnoStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/superamento-anno/get-aggiornamenti`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array di un allegato del Superamento Anno di uno studente.
     * 
     * @param body 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllegatoSuperamentoAnnoStudente(body: GetAllegatoSuperamentoAnnoStudenteRequest, id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getAllegatoSuperamentoAnnoStudente(body: GetAllegatoSuperamentoAnnoStudenteRequest, id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getAllegatoSuperamentoAnnoStudente(body: GetAllegatoSuperamentoAnnoStudenteRequest, id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getAllegatoSuperamentoAnnoStudente(body: GetAllegatoSuperamentoAnnoStudenteRequest, id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getAllegatoSuperamentoAnnoStudente.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getAllegatoSuperamentoAnnoStudente.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/studente-ciclo/superamento-anno/get-allegato`,
            {
             responseType: "blob" as "json",
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce i CFU ottenuti e le ore validate del registro attività di uno studente per un anno di riferimento.
     * 
     * @param body 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCfuOreStudentePerAnno(body: GetCfuOreStudentePerAnnoRequest, id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<GetCfuOreStudentePerAnnoResponse>;
    public getCfuOreStudentePerAnno(body: GetCfuOreStudentePerAnnoRequest, id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetCfuOreStudentePerAnnoResponse>>;
    public getCfuOreStudentePerAnno(body: GetCfuOreStudentePerAnnoRequest, id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetCfuOreStudentePerAnnoResponse>>;
    public getCfuOreStudentePerAnno(body: GetCfuOreStudentePerAnnoRequest, id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getCfuOreStudentePerAnno.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getCfuOreStudentePerAnno.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GetCfuOreStudentePerAnnoResponse>('post',`${this.basePath}/api/v1/studente-ciclo/superamento-anno/get-cfu-ottenuti-ore-validate-per-anno`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista dei Superamenti Anno di un dottorando ordinati per data richiesta decrescente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSuperamentiAnnoStudente(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SuperamentoAnnoStudenteInfoView>>;
    public getSuperamentiAnnoStudente(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SuperamentoAnnoStudenteInfoView>>>;
    public getSuperamentiAnnoStudente(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SuperamentoAnnoStudenteInfoView>>>;
    public getSuperamentiAnnoStudente(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getSuperamentiAnnoStudente.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SuperamentoAnnoStudenteInfoView>>('get',`${this.basePath}/api/v1/studente-ciclo/superamento-anno`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al coordinatore di verificare cfu e ore del dottorando.
     * 
     * @param body 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verificaCfuOreSuperamentoAnnoByCoordinatore(body: VerificaCfuOreSuperamentoAnnoByCoordinatore, id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SuperamentoAnnoStudenteInfoView>>;
    public verificaCfuOreSuperamentoAnnoByCoordinatore(body: VerificaCfuOreSuperamentoAnnoByCoordinatore, id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SuperamentoAnnoStudenteInfoView>>>;
    public verificaCfuOreSuperamentoAnnoByCoordinatore(body: VerificaCfuOreSuperamentoAnnoByCoordinatore, id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SuperamentoAnnoStudenteInfoView>>>;
    public verificaCfuOreSuperamentoAnnoByCoordinatore(body: VerificaCfuOreSuperamentoAnnoByCoordinatore, id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling verificaCfuOreSuperamentoAnnoByCoordinatore.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling verificaCfuOreSuperamentoAnnoByCoordinatore.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<SuperamentoAnnoStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/superamento-anno/verifica-cfu-ore-by-coordinatore`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al supervisore di verificare cfu e ore del dottorando.
     * 
     * @param body 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verificaCfuOreSuperamentoAnnoBySupervisore(body: VerificaCfuOreSuperamentoAnnoBySupervisore, id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SuperamentoAnnoStudenteInfoView>>;
    public verificaCfuOreSuperamentoAnnoBySupervisore(body: VerificaCfuOreSuperamentoAnnoBySupervisore, id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SuperamentoAnnoStudenteInfoView>>>;
    public verificaCfuOreSuperamentoAnnoBySupervisore(body: VerificaCfuOreSuperamentoAnnoBySupervisore, id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SuperamentoAnnoStudenteInfoView>>>;
    public verificaCfuOreSuperamentoAnnoBySupervisore(body: VerificaCfuOreSuperamentoAnnoBySupervisore, id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling verificaCfuOreSuperamentoAnnoBySupervisore.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling verificaCfuOreSuperamentoAnnoBySupervisore.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<SuperamentoAnnoStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/superamento-anno/verifica-cfu-ore-by-supervisore`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al coordinatore di verificare i documenti di Superamento Anno del dottorando e del supervisore.
     * 
     * @param id_utente id dell&#x27;utente
     * @param requestDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verificaDocumentiSuperamentoAnnoByCoordinatoreForm(id_utente: string, requestDTO?: VerificaDocumentiSuperamentoAnnoByCoordinatore, observe?: 'body', reportProgress?: boolean): Observable<Array<SuperamentoAnnoStudenteInfoView>>;
    public verificaDocumentiSuperamentoAnnoByCoordinatoreForm(id_utente: string, requestDTO?: VerificaDocumentiSuperamentoAnnoByCoordinatore, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SuperamentoAnnoStudenteInfoView>>>;
    public verificaDocumentiSuperamentoAnnoByCoordinatoreForm(id_utente: string, requestDTO?: VerificaDocumentiSuperamentoAnnoByCoordinatore, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SuperamentoAnnoStudenteInfoView>>>;
    public verificaDocumentiSuperamentoAnnoByCoordinatoreForm(id_utente: string, requestDTO?: VerificaDocumentiSuperamentoAnnoByCoordinatore, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling verificaDocumentiSuperamentoAnnoByCoordinatore.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (requestDTO !== undefined) {
            formParams = formParams.append('requestDTO', new Blob([JSON.stringify(requestDTO)], { type: 'application/json'})) as any || formParams;
        }

        return this.httpClient.request<Array<SuperamentoAnnoStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/superamento-anno/verifica-documenti-by-coordinatore`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al superivsore di verificare i documenti di Superamento Anno del dottorando.
     * 
     * @param id_utente id dell&#x27;utente
     * @param files 
     * @param requestDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verificaDocumentiSuperamentoAnnoBySupervisoreForm(id_utente: string, files?: Array<Blob>, requestDTO?: VerificaDocumentiSuperamentoAnnoBySupervisore, observe?: 'body', reportProgress?: boolean): Observable<Array<SuperamentoAnnoStudenteInfoView>>;
    public verificaDocumentiSuperamentoAnnoBySupervisoreForm(id_utente: string, files?: Array<Blob>, requestDTO?: VerificaDocumentiSuperamentoAnnoBySupervisore, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SuperamentoAnnoStudenteInfoView>>>;
    public verificaDocumentiSuperamentoAnnoBySupervisoreForm(id_utente: string, files?: Array<Blob>, requestDTO?: VerificaDocumentiSuperamentoAnnoBySupervisore, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SuperamentoAnnoStudenteInfoView>>>;
    public verificaDocumentiSuperamentoAnnoBySupervisoreForm(id_utente: string, files?: Array<Blob>, requestDTO?: VerificaDocumentiSuperamentoAnnoBySupervisore, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling verificaDocumentiSuperamentoAnnoBySupervisore.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }
        if (requestDTO !== undefined) {
            formParams = formParams.append('requestDTO', new Blob([JSON.stringify(requestDTO)], { type: 'application/json'})) as any || formParams;
        }

        return this.httpClient.request<Array<SuperamentoAnnoStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/superamento-anno/verifica-documenti-by-supervisore`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
