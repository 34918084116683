/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Algoritmi di checksum supportati.
 */
export type ChecksumAlgorithm = 'SHA_256';

export const ChecksumAlgorithm = {
    SHA256: 'SHA_256' as ChecksumAlgorithm
};