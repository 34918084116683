<div class="w-full flex flex-col gap-3 mt-1 divide-y" *transloco="let t">

    <div class="flex flex-col lg:flex-row gap-2 w-full justify-between" *ngIf="!anteprimaMode">

        <div class="lg:w-1/2 flex flex-col gap-1.5">

            <span class="text-start font-medium" [ngClass]="{'text-lg': !anteprimaMode}">
                {{t('mobility.periods')}}
            </span>

            <div class="flex flex-col gap-1 items-start">

                <div class="flex flex-row gap-1 items-start text-sm"
                     *ngFor="let importo of dettaglioImporto?.periodi">
                    <div class="flex flex-row items-center gap-1.5">
                        <span class="italic text-secondary">
                            {{t('common.from')}}
                        </span>
                        <span class="text-secondary">
                            {{importo.dataInizio}}
                        </span>
                    </div>
                    <div class="flex flex-row items-center gap-1.5 ml-1" *ngIf="importo.dataFine">
                        <span class="italic text-secondary">
                            {{t('common.to')}}
                        </span>
                        <span class="text-secondary">
                            {{importo.dataFine}}
                        </span>
                    </div>
                </div>

            </div>

        </div>

    </div>

    <div class="flex flex-col lg:flex-row gap-2 w-full justify-between pt-2.5">

        <div class="lg:w-1/2 flex flex-col gap-1.5">

            <span class="text-start font-medium" [ngClass]="{'text-lg': !anteprimaMode}">
                {{t(maggiorazioneMobilitaTemplateConfig.nameOfTheAmountPlural)}}
            </span>

            <div class="flex flex-col gap-1 items-start">

                <div class="flex flex-row gap-1 items-start text-sm"
                     *ngFor="let importo of dettaglioImporto?.importiUnitariDiMaggiorazionePerPeriodo">
                    <span class="mr-6 text-secondary">
                        {{importo.importoUnitario}}€
                    </span>
                    <div class="flex flex-row items-center gap-1.5">
                        <span class="italic text-secondary">
                            {{t('common.from')}}
                        </span>
                        <span class="text-secondary">
                            {{importo.dataInizio}}
                        </span>
                    </div>
                    <div class="flex flex-row items-center gap-1.5 ml-1" *ngIf="importo.dataFine">
                        <span class="italic text-secondary">
                            {{t('common.to')}}
                        </span>
                        <span class="text-secondary">
                            {{importo.dataFine}}
                        </span>
                    </div>
                </div>

                <div *ngIf="!dettaglioImporto?.importiUnitariDiMaggiorazionePerPeriodo || dettaglioImporto?.importiUnitariDiMaggiorazionePerPeriodo?.length == 0">
                    <span class="italic text-sm">
                        {{t('mobility.no_amount')}}
                    </span>
                </div>

            </div>

        </div>

        <div class="lg:w-1/2 flex flex-col gap-1.5">

        <span class="text-start font-medium" [ngClass]="{'text-lg': !anteprimaMode}">
            {{t('mobility.periods_to_exclude')}}
        </span>

            <div class="flex flex-col gap-1 items-start">

                <div class="flex flex-row gap-1 items-start text-sm"
                     *ngFor="let sospensione of dettaglioImporto?.periodiDiSospensioneDaEscludere">
                    <div class="flex flex-row items-center gap-1.5">
                        <span class="italic text-secondary">
                            {{t('common.from')}}
                        </span>
                        <span class="text-secondary">
                            {{sospensione.dataInizio}}
                        </span>
                    </div>
                    <div class="flex flex-row items-center gap-1.5 ml-2" *ngIf="sospensione.dataFine">
                        <span class="italic text-secondary">
                            {{t('common.to')}}
                        </span>
                        <span class="text-secondary">
                            {{sospensione.dataFine}}
                        </span>
                    </div>
                </div>

                <div *ngIf="!dettaglioImporto?.periodiDiSospensioneDaEscludere || dettaglioImporto?.periodiDiSospensioneDaEscludere?.length == 0">
                    <span class="italic text-sm">
                        {{t('mobility.no_periods_to_exclude')}}
                    </span>
                </div>

            </div>

        </div>

    </div>

    <div class="flex flex-col lg:flex-row gap-2 w-full justify-between pt-2.5">
        <div class="lg:w-1/2 flex flex-row gap-4 items-center">
            <span class="text-start font-semibold" [ngClass]="{'text-lg': !anteprimaMode}">
                {{t('mobility.effective_days')}}
            </span>
            <span class="text-secondary">
                {{dettaglioImporto?.numeroGiorni}}
            </span>
            <span *ngIf="!dettaglioImporto?.numeroGiorni" class="text-secondary">
                -
            </span>
        </div>
        <div class="lg:w-1/2 flex flex-row gap-4 items-center">
            <span class="text-start font-semibold" [ngClass]="{'text-lg': !anteprimaMode}">
                {{t('common.amount')}}
            </span>
            <span class="text-secondary">
                {{dettaglioImporto?.importoCalcolato}}€
            </span>
        </div>
    </div>

</div>


