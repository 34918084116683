/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddUpdatePianoDiStudiDTO } from '../model/addUpdatePianoDiStudiDTO';
import { AggiornamentoPianoDiStudiStatus } from '../model/aggiornamentoPianoDiStudiStatus';
import { AnnoRiferimentoValues } from '../model/annoRiferimentoValues';
import { AttivitaExtraPianoDiStudiStatus } from '../model/attivitaExtraPianoDiStudiStatus';
import { AttivitaTrasversaleStatus } from '../model/attivitaTrasversaleStatus';
import { ChiaveDocumentoEnum } from '../model/chiaveDocumentoEnum';
import { CorsoPianoDiStudiLightView } from '../model/corsoPianoDiStudiLightView';
import { ElementoOffertaFormativaType } from '../model/elementoOffertaFormativaType';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { PageCorsoPianoDiStudiPerExportViewImpl } from '../model/pageCorsoPianoDiStudiPerExportViewImpl';
import { PageStudenteCicloPianoDiStudiViewImpl } from '../model/pageStudenteCicloPianoDiStudiViewImpl';
import { PianoDiStudiInfoViewImpl } from '../model/pianoDiStudiInfoViewImpl';
import { PianoDiStudiStatus } from '../model/pianoDiStudiStatus';
import { StudenteCicloStatus } from '../model/studenteCicloStatus';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PianoDiStudiStudentiCicloService {

    protected basePath = 'https://api-dev-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Aggiunge o aggiorna il piano di studi di uno studente.
     * 
     * @param body 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrUpdatePianoDiStudiStudente(body: AddUpdatePianoDiStudiDTO, id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<PianoDiStudiInfoViewImpl>;
    public addOrUpdatePianoDiStudiStudente(body: AddUpdatePianoDiStudiDTO, id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PianoDiStudiInfoViewImpl>>;
    public addOrUpdatePianoDiStudiStudente(body: AddUpdatePianoDiStudiDTO, id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PianoDiStudiInfoViewImpl>>;
    public addOrUpdatePianoDiStudiStudente(body: AddUpdatePianoDiStudiDTO, id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addOrUpdatePianoDiStudiStudente.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling addOrUpdatePianoDiStudiStudente.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PianoDiStudiInfoViewImpl>('patch',`${this.basePath}/api/v1/studente-ciclo/piano-di-studi`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array del pdf del piano di studi di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPdfPianoDiStudiStudente(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getPdfPianoDiStudiStudente(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getPdfPianoDiStudiStudente(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getPdfPianoDiStudiStudente(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getPdfPianoDiStudiStudente.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/studente-ciclo/piano-di-studi/pdf`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce l&#x27;elenco dei dottorandi con lo stato e la validità del proprio piano di studi con i filtri applicati.
     * 
     * @param hasChiaveDocumentoAllegatoSuUltimoAggiornamentoPianoDiStudi 
     * @param hasPermessiApprovativiCosupervisore 
     * @param isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi 
     * @param isPianoNonValido 
     * @param nominativo 
     * @param notHasChiaveDocumentoAllegatoSuUltimoAggiornamentoPianoDiStudi 
     * @param statiPianoDiStudi 
     * @param statiStudente 
     * @param statiUltimoAggiornamentoPianoDiStudi 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPianiDiStudiStudentiForm(hasChiaveDocumentoAllegatoSuUltimoAggiornamentoPianoDiStudi?: ChiaveDocumentoEnum, hasPermessiApprovativiCosupervisore?: boolean, isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi?: boolean, isPianoNonValido?: boolean, nominativo?: string, notHasChiaveDocumentoAllegatoSuUltimoAggiornamentoPianoDiStudi?: ChiaveDocumentoEnum, statiPianoDiStudi?: Array<PianoDiStudiStatus>, statiStudente?: Array<StudenteCicloStatus>, statiUltimoAggiornamentoPianoDiStudi?: Array<AggiornamentoPianoDiStudiStatus>, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageStudenteCicloPianoDiStudiViewImpl>;
    public getPianiDiStudiStudentiForm(hasChiaveDocumentoAllegatoSuUltimoAggiornamentoPianoDiStudi?: ChiaveDocumentoEnum, hasPermessiApprovativiCosupervisore?: boolean, isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi?: boolean, isPianoNonValido?: boolean, nominativo?: string, notHasChiaveDocumentoAllegatoSuUltimoAggiornamentoPianoDiStudi?: ChiaveDocumentoEnum, statiPianoDiStudi?: Array<PianoDiStudiStatus>, statiStudente?: Array<StudenteCicloStatus>, statiUltimoAggiornamentoPianoDiStudi?: Array<AggiornamentoPianoDiStudiStatus>, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageStudenteCicloPianoDiStudiViewImpl>>;
    public getPianiDiStudiStudentiForm(hasChiaveDocumentoAllegatoSuUltimoAggiornamentoPianoDiStudi?: ChiaveDocumentoEnum, hasPermessiApprovativiCosupervisore?: boolean, isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi?: boolean, isPianoNonValido?: boolean, nominativo?: string, notHasChiaveDocumentoAllegatoSuUltimoAggiornamentoPianoDiStudi?: ChiaveDocumentoEnum, statiPianoDiStudi?: Array<PianoDiStudiStatus>, statiStudente?: Array<StudenteCicloStatus>, statiUltimoAggiornamentoPianoDiStudi?: Array<AggiornamentoPianoDiStudiStatus>, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageStudenteCicloPianoDiStudiViewImpl>>;
    public getPianiDiStudiStudentiForm(hasChiaveDocumentoAllegatoSuUltimoAggiornamentoPianoDiStudi?: ChiaveDocumentoEnum, hasPermessiApprovativiCosupervisore?: boolean, isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi?: boolean, isPianoNonValido?: boolean, nominativo?: string, notHasChiaveDocumentoAllegatoSuUltimoAggiornamentoPianoDiStudi?: ChiaveDocumentoEnum, statiPianoDiStudi?: Array<PianoDiStudiStatus>, statiStudente?: Array<StudenteCicloStatus>, statiUltimoAggiornamentoPianoDiStudi?: Array<AggiornamentoPianoDiStudiStatus>, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {













        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (hasChiaveDocumentoAllegatoSuUltimoAggiornamentoPianoDiStudi !== undefined) {
            formParams = formParams.append('hasChiaveDocumentoAllegatoSuUltimoAggiornamentoPianoDiStudi', <any>hasChiaveDocumentoAllegatoSuUltimoAggiornamentoPianoDiStudi) as any || formParams;
        }
        if (hasPermessiApprovativiCosupervisore !== undefined) {
            formParams = formParams.append('hasPermessiApprovativiCosupervisore', <any>hasPermessiApprovativiCosupervisore) as any || formParams;
        }
        if (isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi !== undefined) {
            formParams = formParams.append('isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi', <any>isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi) as any || formParams;
        }
        if (isPianoNonValido !== undefined) {
            formParams = formParams.append('isPianoNonValido', <any>isPianoNonValido) as any || formParams;
        }
        if (nominativo !== undefined) {
            formParams = formParams.append('nominativo', <any>nominativo) as any || formParams;
        }
        if (notHasChiaveDocumentoAllegatoSuUltimoAggiornamentoPianoDiStudi !== undefined) {
            formParams = formParams.append('notHasChiaveDocumentoAllegatoSuUltimoAggiornamentoPianoDiStudi', <any>notHasChiaveDocumentoAllegatoSuUltimoAggiornamentoPianoDiStudi) as any || formParams;
        }
        if (statiPianoDiStudi) {
            statiPianoDiStudi.forEach((element) => {
                formParams = formParams.append('statiPianoDiStudi', <any>element) as any || formParams;
            })
        }
        if (statiStudente) {
            statiStudente.forEach((element) => {
                formParams = formParams.append('statiStudente', <any>element) as any || formParams;
            })
        }
        if (statiUltimoAggiornamentoPianoDiStudi) {
            statiUltimoAggiornamentoPianoDiStudi.forEach((element) => {
                formParams = formParams.append('statiUltimoAggiornamentoPianoDiStudi', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<PageStudenteCicloPianoDiStudiViewImpl>('post',`${this.basePath}/api/v1/studente-ciclo/piano-di-studi/search`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le info del piano di studi di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPianoDiStudiStudente(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<PianoDiStudiInfoViewImpl>;
    public getPianoDiStudiStudente(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PianoDiStudiInfoViewImpl>>;
    public getPianoDiStudiStudente(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PianoDiStudiInfoViewImpl>>;
    public getPianoDiStudiStudente(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getPianoDiStudiStudente.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PianoDiStudiInfoViewImpl>('get',`${this.basePath}/api/v1/studente-ciclo/piano-di-studi`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le info del piano formativo del piano di studi di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPianoFormativoStudente(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CorsoPianoDiStudiLightView>>;
    public getPianoFormativoStudente(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CorsoPianoDiStudiLightView>>>;
    public getPianoFormativoStudente(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CorsoPianoDiStudiLightView>>>;
    public getPianoFormativoStudente(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getPianoFormativoStudente.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CorsoPianoDiStudiLightView>>('get',`${this.basePath}/api/v1/studente-ciclo/piano-di-studi/piano-formativo`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista paginata delle attività trasversali nei piani di studi degli studenti con i filtri applicati.
     * 
     * @param annoInsegnamento 
     * @param cfStudente 
     * @param denomDescrAttivita 
     * @param dettaglioAttivitaTrasversale 
     * @param emailStudente 
     * @param hasDelibera 
     * @param hasPermessiApprovativiCosupervisore 
     * @param isNotPresuntaNonDefinita 
     * @param matricolaStudente 
     * @param nominativoStudente 
     * @param statiAttivitaExtra 
     * @param statiAttivitaTrasversale 
     * @param tipo 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchAttivitaPianiFormativiForm(annoInsegnamento?: AnnoRiferimentoValues, cfStudente?: string, denomDescrAttivita?: string, dettaglioAttivitaTrasversale?: string, emailStudente?: string, hasDelibera?: boolean, hasPermessiApprovativiCosupervisore?: boolean, isNotPresuntaNonDefinita?: boolean, matricolaStudente?: string, nominativoStudente?: string, statiAttivitaExtra?: Array<AttivitaExtraPianoDiStudiStatus>, statiAttivitaTrasversale?: Array<AttivitaTrasversaleStatus>, tipo?: ElementoOffertaFormativaType, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageCorsoPianoDiStudiPerExportViewImpl>;
    public searchAttivitaPianiFormativiForm(annoInsegnamento?: AnnoRiferimentoValues, cfStudente?: string, denomDescrAttivita?: string, dettaglioAttivitaTrasversale?: string, emailStudente?: string, hasDelibera?: boolean, hasPermessiApprovativiCosupervisore?: boolean, isNotPresuntaNonDefinita?: boolean, matricolaStudente?: string, nominativoStudente?: string, statiAttivitaExtra?: Array<AttivitaExtraPianoDiStudiStatus>, statiAttivitaTrasversale?: Array<AttivitaTrasversaleStatus>, tipo?: ElementoOffertaFormativaType, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageCorsoPianoDiStudiPerExportViewImpl>>;
    public searchAttivitaPianiFormativiForm(annoInsegnamento?: AnnoRiferimentoValues, cfStudente?: string, denomDescrAttivita?: string, dettaglioAttivitaTrasversale?: string, emailStudente?: string, hasDelibera?: boolean, hasPermessiApprovativiCosupervisore?: boolean, isNotPresuntaNonDefinita?: boolean, matricolaStudente?: string, nominativoStudente?: string, statiAttivitaExtra?: Array<AttivitaExtraPianoDiStudiStatus>, statiAttivitaTrasversale?: Array<AttivitaTrasversaleStatus>, tipo?: ElementoOffertaFormativaType, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageCorsoPianoDiStudiPerExportViewImpl>>;
    public searchAttivitaPianiFormativiForm(annoInsegnamento?: AnnoRiferimentoValues, cfStudente?: string, denomDescrAttivita?: string, dettaglioAttivitaTrasversale?: string, emailStudente?: string, hasDelibera?: boolean, hasPermessiApprovativiCosupervisore?: boolean, isNotPresuntaNonDefinita?: boolean, matricolaStudente?: string, nominativoStudente?: string, statiAttivitaExtra?: Array<AttivitaExtraPianoDiStudiStatus>, statiAttivitaTrasversale?: Array<AttivitaTrasversaleStatus>, tipo?: ElementoOffertaFormativaType, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (annoInsegnamento !== undefined) {
            formParams = formParams.append('annoInsegnamento', <any>annoInsegnamento) as any || formParams;
        }
        if (cfStudente !== undefined) {
            formParams = formParams.append('cfStudente', <any>cfStudente) as any || formParams;
        }
        if (denomDescrAttivita !== undefined) {
            formParams = formParams.append('denomDescrAttivita', <any>denomDescrAttivita) as any || formParams;
        }
        if (dettaglioAttivitaTrasversale !== undefined) {
            formParams = formParams.append('dettaglioAttivitaTrasversale', <any>dettaglioAttivitaTrasversale) as any || formParams;
        }
        if (emailStudente !== undefined) {
            formParams = formParams.append('emailStudente', <any>emailStudente) as any || formParams;
        }
        if (hasDelibera !== undefined) {
            formParams = formParams.append('hasDelibera', <any>hasDelibera) as any || formParams;
        }
        if (hasPermessiApprovativiCosupervisore !== undefined) {
            formParams = formParams.append('hasPermessiApprovativiCosupervisore', <any>hasPermessiApprovativiCosupervisore) as any || formParams;
        }
        if (isNotPresuntaNonDefinita !== undefined) {
            formParams = formParams.append('isNotPresuntaNonDefinita', <any>isNotPresuntaNonDefinita) as any || formParams;
        }
        if (matricolaStudente !== undefined) {
            formParams = formParams.append('matricolaStudente', <any>matricolaStudente) as any || formParams;
        }
        if (nominativoStudente !== undefined) {
            formParams = formParams.append('nominativoStudente', <any>nominativoStudente) as any || formParams;
        }
        if (statiAttivitaExtra) {
            statiAttivitaExtra.forEach((element) => {
                formParams = formParams.append('statiAttivitaExtra', <any>element) as any || formParams;
            })
        }
        if (statiAttivitaTrasversale) {
            statiAttivitaTrasversale.forEach((element) => {
                formParams = formParams.append('statiAttivitaTrasversale', <any>element) as any || formParams;
            })
        }
        if (tipo !== undefined) {
            formParams = formParams.append('tipo', <any>tipo) as any || formParams;
        }

        return this.httpClient.request<PageCorsoPianoDiStudiPerExportViewImpl>('post',`${this.basePath}/api/v1/studente-ciclo/piano-di-studi/search-attivita-piani-formativi`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
