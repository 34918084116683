import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ThemePalette} from '@angular/material/core';


export enum ButtonType {
    CONFIRM = 'CONFIRM',
    SAVE = 'SAVE',
    DELETE = 'DELETE',
    NEXT = 'NEXT',
    PREVIOUS = 'PREVIOUS',
    ADD = 'ADD',
    SIGN = 'SIGN'
}

interface Config {
    textButton: string;
    icon:  string;
    colorButton: ThemePalette;
    directive?: string;
    iconSide?: ICONSIDE;
}

export enum ICONSIDE {
    LEFT = 'left',
    RIGHT = 'right'
}

@Component({
    selector: 'app-custom-button',
    templateUrl: './custom-button.component.html',
    styleUrls: ['./custom-button.component.scss']
})
export class CustomButtonComponent implements OnInit {
    @Output() clickHandler: EventEmitter<void> = new EventEmitter<void>();

    @Input() loading: boolean;
    @Input() disabled: boolean;
    @Input() type: ButtonType = ButtonType.CONFIRM;
    @Input() textButton: string;
    @Input() colorButton: ThemePalette;
    @Input() icon: string;
    @Input() colorSpinner: ThemePalette = 'accent';
    @Input() disableDirective: boolean
    @Input() iconSide: ICONSIDE;
    @Input() smallMode: boolean;

    directive: string;

    constructor() {
    }

    ngOnInit(): void {
        this.initButton();
    }

    onClick(): void {
        this.clickHandler.emit();
    }

    initButton(): void {
        const buttonConfig = {
            [ButtonType.CONFIRM]: {
                textButton: 'dialog.confirm',
                icon: 'save',
                colorButton: 'primary' as ThemePalette
            },
            [ButtonType.SAVE]: {
                textButton: 'common.save',
                icon: 'save',
                colorButton: 'primary' as ThemePalette
            },
            [ButtonType.DELETE]: {
                textButton: 'common.delete',
                icon: 'delete',
                colorButton: 'warn' as ThemePalette
            },
            [ButtonType.NEXT]: {
                textButton: 'common.next',
                icon: 'arrow_forward_ios',
                colorButton: 'primary' as ThemePalette,
                directive: 'matStepperNext',
                iconSide: ICONSIDE.RIGHT
            },
            [ButtonType.PREVIOUS]: {
                textButton: 'common.previous',
                icon: 'arrow_back_ios',
                colorButton: 'primary' as ThemePalette,
                directive: 'matStepperPrevious',
            },
            [ButtonType.ADD]: {
                textButton: 'common.add',
                icon: 'add',
                colorButton: 'primary' as ThemePalette,
            },
            [ButtonType.SIGN]: {
                textButton: 'document_sign.sign_document',
                icon: 'draw',
                colorButton: 'primary' as ThemePalette,
            }

        };

        const config: Config = buttonConfig[this.type];

        if (config) {
            this.textButton = this.textButton || config.textButton;
            this.icon = this.icon || config.icon;
            this.colorButton = this.colorButton || config.colorButton as ThemePalette;
            this.directive = this.disableDirective ? null : config.directive;
            this.iconSide = this.iconSide || config.iconSide || ICONSIDE.LEFT;
        }
    }

    protected readonly ICONSIDE = ICONSIDE;
}
