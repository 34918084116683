import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthorityType, ChiaveFunzionalitaEnum, FunzionalitaStatus} from "../../../api-clients/generated/services";
import {LocalStorageService} from "../../shared/service/local-storage.service";
import {PathEnum} from "../../app.routing";
import {CicloCorsoRuoloInterface} from "../../shared/interface/CicloCorsoRuoloInterface";
import {AppInitService} from "../../shared/service/app-init.service";
import {CicloConfigurationService} from "../../shared/service/ciclo-configuration.service";
import {checkFunctionalityState} from "./guards-utils";
import {CicloConfigDependantGuard} from "./ciclo-config-dependant.guard";


@Injectable({
    providedIn: 'root'
})
export class StudentsNoMatricolaListGuard extends CicloConfigDependantGuard {
    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        protected cicloConfigurationService: CicloConfigurationService,
    ) {
        super(cicloConfigurationService)
    }

    _canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const canActivate = StudentsNoMatricolaListGuard.accessCondition(this.cicloConfigurationService);
        if(!canActivate) {
            this._router.navigate([PathEnum.ADMINISTRATOR, PathEnum.HOME]);
        }
        return of(canActivate);
    }

    static accessCondition(cicloConfigurationService: CicloConfigurationService,
                           passingStates: FunzionalitaStatus[] = [FunzionalitaStatus.ABILITATA],) {
        return checkFunctionalityState(cicloConfigurationService, ChiaveFunzionalitaEnum.GestioneDottNoMatricola, passingStates);
    }


}
