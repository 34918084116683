import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    ClickEvent,
    GenericTableConfigurationModel,
    TableData,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../../../../shared/components/table/model/generic-table-model";
import {
    PageStudenteCicloUtenteViewImpl,
    StudenteCicloMotivoStatus,
    StudenteCicloStatus,
    StudenteCicloUtenteViewImpl,
    StudentiCicloService
} from "../../../../../../api-clients/generated/services";
import {filter, switchMap, tap} from "rxjs/operators";
import {BehaviorSubject, finalize, Observable, ObservedValueOf, of, OperatorFunction, takeUntil} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {TranslocoService} from "@ngneat/transloco";
import {get, isArray, isEmpty} from "lodash";
import * as moment from "moment/moment";
import {PageEvent} from "@angular/material/paginator";
import {FormGroup} from "@angular/forms";
import {LogoutService} from "../../../../../shared/service/logout.service";
import {LocalStorageService} from "../../../../../shared/service/local-storage.service";
import {
    getLanguagePathByStudenteCicloMotivoStatus,
    getLanguagePathByStudenteCicloStatus,
    getStatoAutorizzazione,
    missioniDaApprovareByRole,
    removeUnderscore
} from "../../../../../shared/utils/utils";
import {
    StudentiBarFilterContainerComponent
} from "../../../../../shared/components/studenti-topbar-filter-container/studenti-bar-filter-container.component";
import {AutorizzazioneMobilitaMassivaDataI} from "../autorizzazione-mobilita-steps-interface";
import {CAN_GO_AHEAD$, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {
    AutorizzazioniFilterManagerRequestService
} from "../../../requests/approvazione-periodi-di-mobilita-ricerca/autorizzazioni-sidebar-filter-container/autorizzazioni-filter-manager-request.service";
import {
    StatoAutorizzazione
} from "../../../requests/approvazione-periodi-di-mobilita-ricerca/tabs-periodi-mobilita-ricerca/tabs-periodi-mobilita-ricerca.component";
import {PathEnum} from "../../../../../app.routing";
import {Router} from "@angular/router";
import {CicloConfigurationService} from "../../../../../shared/service/ciclo-configuration.service";


@Component({
    selector: 'app-scegli-studenti-autorizzazione-mobilita-stepper',
    templateUrl: './scegli-studenti-autorizzazione-mobilita.component.html',
    styleUrls: ['./scegli-studenti-autorizzazione-mobilita.component.scss']
})
export class ScegliStudentiAutorizzazioneMobilitaComponent extends AbstractDefaultComponent implements OnInit, OnDestroy {

    loading: boolean;
    studentsListConfiguration: GenericTableConfigurationModel;
    private pageSize: number;
    private fieldsLabelMap: Map<string, string>;
    currentFilterListChipLabels: Array<string>;
    isFirstTime: boolean;
    showMiniLoader: boolean = false;
    filterContainerComponent: StudentiBarFilterContainerComponent;
    showTopbar: boolean;
    private hasDeliberaDiProgrammazioneMobilita: boolean = true;
    private hasAutorizzazioneMobilita: boolean = false;
    labelDefChip: StatoAutorizzazione;
    @ViewChild(StudentiBarFilterContainerComponent) set studentiBarFilterContainer(component: StudentiBarFilterContainerComponent) {
        this.filterContainerComponent = component;
    }

    constructor(private fuseConfirmationService: FuseConfirmationService,
                private translocoService: TranslocoService,
                private studentiCicloService: StudentiCicloService,
                private filterManagerService: AutorizzazioniFilterManagerRequestService,
                private logOutService: LogoutService,
                private localStorageService: LocalStorageService,
                private router: Router,
                private cicloConfigurationService: CicloConfigurationService,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<AutorizzazioneMobilitaMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.isFirstTime = true;
        this.fieldsLabelMap = this.filterManagerService.fieldsLabelMap;
        this.getDataAndFilterSubscribe();
        this.getLabelDefChip();
        this.operazioneMassivaData$?.subscribe(inputOutputData => {
            // when confirm step forces refresh get the data and subscribe to filters
            if(inputOutputData.refreshData) {
                console.log('REFRESHING STUDENTS IN SCEGLI STUDENTI')
                this.studentsListConfiguration = undefined;
                this.resetRefreshData();
                this.canGoNext$.next(false);
                this.getListaStudentiRequest$(false, this.getMainFormGroup().getRawValue());
            }
        });
    }

    getListaStudentiRequest$(isFirstTime?: boolean, filters?: any, page: number = 0, size: number = this.localStorageService.pageSize,
                        sort?: string ): void {
        this.getStudentiFormObs$(isFirstTime, filters, page, size, sort).subscribe({
            next: (value) => {},
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {this.logOutService.goToHome()},
                    () => this.getListaStudentiRequest$(isFirstTime, filters, page, size, sort),
                    'common.go_to_home',
                    err?.error?.message);
            }
        });
    }

    getStudentiFormObs$(isFirstTime?: boolean, filters?: any, page: number = 0, size: number = this.localStorageService.pageSize,
                        sort?: string ): Observable<PageStudenteCicloUtenteViewImpl> {
        if (isFirstTime) {
            this.loading = true;
        } else {
            this.fuseConfirmationService.showLoader();
        }
        return this.studentiCicloService.getStudentiForm(
            !!filters?.afferenzaOrganizzativaCosupervisore ? filters.afferenzaOrganizzativaCosupervisore : undefined,
            !!filters?.afferenzaOrganizzativaSupervisore ? filters.afferenzaOrganizzativaSupervisore : undefined,
            !!filters?.ateneoProvenienza ? filters?.ateneoProvenienza : undefined,
            !!filters?.codiceFiscale ? filters?.codiceFiscale : undefined,
            !!filters?.codiceFiscaleCosupervisore?.codiceFiscale ? filters.codiceFiscaleCosupervisore?.codiceFiscale : undefined,
            !!filters?.codiceFiscaleDocenteCorso?.codiceFiscale ? filters.codiceFiscaleDocenteCorso?.codiceFiscale : undefined,
            undefined,
            !!filters?.codiceFiscaleSupervisore?.codiceFiscale ? filters.codiceFiscaleSupervisore?.codiceFiscale : undefined,
            !!filters?.codiceTipoBorsaPerCiclo ? filters.codiceTipoBorsaPerCiclo : undefined,
            undefined,
            this.hasAutorizzazioneMobilita,
            this.hasDeliberaDiProgrammazioneMobilita,
            !!filters?.hasNotDeliberaInizioAttivita ? false : undefined,
            !!filters?.hasNotCosupervisore ? true : undefined,
            !!filters?.hasNotSupervisore ? true : undefined,
            !!filters?.hasNumGiorniMobilitaInferioreAlMinimoPrevisto ? true : undefined,
            !!filters?.hasNumGiorniMobilitaSuperioreAlPrevisto ? true : undefined,
            undefined,
            undefined,
            !!filters?.statoCarrieraMotivazione ? filters?.statoCarrieraMotivazione : undefined,
            !!filters?.nazionalita ? filters?.nazionalita : undefined,
            !!filters?.nominativo ? filters?.nominativo : undefined,
            !!filters?.ssdCosupervisore ? filters.ssdCosupervisore : undefined,
            !!filters?.ssdSupervisore ? filters.ssdSupervisore : undefined,
            !!filters?.hasMissioniDaApprovare ? missioniDaApprovareByRole(this.localStorageService) : undefined,
            undefined,
            !!filters?.statoCarriera ? filters?.statoCarriera : undefined,
            undefined,
            undefined,
            undefined,
            page, size, ).pipe(
            tap((pageStudente) => {
                this.studentsListConfiguration = this.buildListaStudentiConfiguration(pageStudente, size, page);
            }),
            takeUntil(this.destroy$),
            finalize(() => {
                this.currentFilterListChipLabels = this.getListChipsLabel();
                if (isFirstTime) {
                        this.isFirstTime = false;
                        this.loading = false;
                    } else {
                        this.fuseConfirmationService.hideLoader();
                    }
                }
            )
        );


    }

    formatDay(date: string) {
        return moment(new Date(date)).format('DD/MM/YYYY');
    }

    buildListaStudentiConfiguration(pageStudente: PageStudenteCicloUtenteViewImpl, pageSize: number, page: number): GenericTableConfigurationModel {
        return {
            configuration: {
                data: pageStudente?.content?.map(studenteContent => ({
                    ...studenteContent,
                    studente: studenteContent.cognome?.toUpperCase() + ' ' + studenteContent.nome?.toUpperCase(),
                    supervisore: (studenteContent?.supervisore?.cognome?.toUpperCase() || studenteContent?.supervisore?.nome?.toUpperCase() ) ?
                        ((studenteContent?.supervisore?.cognome?.toUpperCase() || '') + ' ' + (studenteContent?.supervisore?.nome?.toUpperCase() || '')).toUpperCase() : '',
                    tipologiaBorsa: (studenteContent?.tipoBorsaPerCiclo?.descrizioneTipoBorsa),
                    curriculum: (studenteContent?.percorsoDiStudi?.descrizionePercorsoDiStudi),
                    //dataIscrizione: this.formatDay(studenteContent?.dataIscrizione)
                })),
                sticky: true,
                pageSize: pageSize,
                pageBE: page,
                pageSizeOptions: [10, 20, 30, 40, 50],
                totalElements: pageStudente.totalElements,
                isPaginatedBE: true,
                messaggioDatiAssenti: 'custom_table.no_data_student',
                hidePaginator: false,
                selection: {
                    isSelectable: true,
                    selectedColumnKeyToShow: 'studente',
                    selectedColumnKeyPrimaryKey: 'idUtente',
                },
                configurazioneTabella: [
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'students_list.student',
                        colonnaKey: 'studente',
                        flex: 22,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'menu_filter.enrollment_number',
                        colonnaKey: 'matricola',
                        hideColonna: !this.cicloConfigurationService.showMatricola,
                        flex: 10,
                    },
                    // {
                    //     tipo: TipoColonnaEnum.STRING,
                    //     nomeColonna: 'students_list.date_of_registration',
                    //     colonnaKey: 'dataIscrizione',
                    //     flex: 8,
                    // },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'students_list.university_email',
                        colonnaKey: 'emailAteneo',
                        flex: 22,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'students_list.supervisor',
                        colonnaKey: 'supervisore',
                        flex: 14,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'menu_filter.type_of_study_grant',
                        colonnaKey: 'tipologiaBorsa',
                        flex: 16,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'students_list.curriculum',
                        colonnaKey: 'curriculum',
                        flex: 15,
                    },
                    {
                        nomeColonna: ' ',
                        colonnaKey: 'action',
                        flex: 9,
                        tipo: TipoColonnaEnum.ACTION_MENU,
                        valueCheck: 'l',
                        button: [
                            {
                                nameIconButton: 'event',
                                click: TipoClickEnum.GO_TO_MOBILITA_STUDENTE,
                                tooltip: 'common.go_to_mobility',
                                color: 'accent',
                                valueShow: 'stato'
                            },
                        ]
                    }
                ],
            }
        };
    }


    tableClickAction($event: ClickEvent) {
        switch ($event.tipoClick) {
            case TipoClickEnum.GO_TO_MOBILITA_STUDENTE:
                const studente: StudenteCicloUtenteViewImpl = $event?.value;
                const url = this.router.serializeUrl(this.router.createUrlTree([PathEnum.STUDENTS, studente?.idUtente, PathEnum.MOBILITA]));
                window.open(url, '_blank');
                break;
        }
    }



    onStudentDataTableChanged($event: TableData) {
        console.log('SELECTION CHANGED')
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$?.getValue(),
            studentiSelected: $event.selectedRows.length > 0 ? $event.selectedRows.map(row => row.data) as StudenteCicloUtenteViewImpl[] : undefined,
        });
        this.canGoNext$.next($event.selectedRows.length > 0);
    }

    pageAction($event: PageEvent): void {
        console.log('PageEvent', $event);
        const page = $event.pageIndex;
        this.localStorageService.pageSize = $event.pageSize;
        this.getListaStudentiRequest$(false, this.getMainFormGroup()?.value, page, this.localStorageService.pageSize);
    }

    getMainFormGroup(): FormGroup<any> {
        return this.filterManagerService?.mainFormGroup;
    }

    catchErrorInStudentList$(inputObs: Observable<any>): OperatorFunction<unknown, ObservedValueOf<Observable<any>> | unknown> {
        return this.fuseConfirmationService.catchErrorCustom$(inputObs, {openModalError: {goToHome: true}}, true, true, true, false);
    }

    getListChipsLabel(): Array<string> {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (!isEmpty(this.getMainFormGroup()?.value)) {
            const entries: [string, any][] = Object.entries(this.getMainFormGroup()?.value)?.filter(item => item[0] !== 'stato' &&  item[0] !== 'hasAutorizzazioneMobilita' &&  item[0] !== 'hasDeliberaDiProgrammazioneMobilita');
            console.log(entries, 'entries')
            const filteredEntries = entries?.filter(item => item.every(value => !!value));
            let labels = filteredEntries?.map(filteredItem => filteredItem?.map((element) => {
                    if (typeof element === 'string') {
                        if (!!this.fieldsLabelMap.get(element)) {

                            return get(translation, this.fieldsLabelMap.get(element), element);
                        }else {
                            return removeUnderscore(element);
                        }
                    }
                    if (typeof element === 'boolean') {
                        return '';
                    }
                    else if (!!element?.codiceFiscale) {
                        const completeName = (element?.cognome || '') + ' ' + (element?.nome || '');
                        return (!!element?.cognome ? completeName : '')?.toUpperCase();
                    }
                    else if (!!element?.id && !!element?.denominazione) {
                        return (!!element?.denominazione ? element.denominazione : '');
                    }
                    else if (!!element && isArray(element) && Object.values(StudenteCicloStatus)?.find(e => element.includes(e))) {
                        return element.map(e => get(translation, getLanguagePathByStudenteCicloStatus(e), e))?.join(', ');
                    }
                    else if (!!element && isArray(element) && Object.values(StudenteCicloMotivoStatus)?.find(e => element.includes(e))) {
                        return element.map(e => get(translation, getLanguagePathByStudenteCicloMotivoStatus(e), e))?.join(', ');
                    }
                    else {
                        return element;
                    }
                }
            ).join(': '));
            console.log('Lables chips', labels);
            labels = labels.map(label => label?.endsWith(': ')? label.slice(0, -2) : label);
            return labels;
        } else {
            return [];
        }
    }


    toogleFilterContainer(hasToogleInternalMenu: boolean): void {
        this.filterContainerComponent?.setFilterApplied();
        this.showTopbar = true;
    }


    private getSottoruolo() {
        return this.localStorageService.getSottoruoloCiclo();
    }

    getDataForFilters(): void {
        this.filterManagerService.openDrawer.next(true)
    }

    resetFilters(): void {
        this.getMainFormGroup()?.reset();
        this.filterManagerService.filterApplied = undefined;
        this.filterManagerService?.checkApplyFilterClick$.next(true);
    }

    closeBar() {
        this.showTopbar = false;
    }

    private resetRefreshData() {
        console.log(this.operazioneMassivaData$.getValue())
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$.getValue(),
            refreshData: false
        })
    }

    private getDataAndFilterSubscribe() {
        if (this.isFirstTime) {
            this.getListaStudentiRequest$(this.isFirstTime, this.getMainFormGroup().getRawValue());
        }
        this.filterManagerService.appliedFiltersObsevable$().pipe(
            filter(Boolean),
            switchMap(() => {
                if (!this.isFirstTime) {
                    return this.getStudentiFormObs$(this.isFirstTime,  this.getMainFormGroup().getRawValue(), 0, this.localStorageService.pageSize).pipe(
                        this.catchErrorInStudentList$(this.getStudentiFormObs$(this.isFirstTime,  this.getMainFormGroup().getRawValue(), 0, this.localStorageService.pageSize))
                    );
                }
                return of(null);
            }),
            takeUntil(this.destroy$)
        ).subscribe(
            {
                next: (value) => {
                    this.isFirstTime = false;
                    this.filterManagerService.filterApplied$.next( this.getMainFormGroup().getRawValue());
                }
            }
        );
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }


    private getLabelDefChip() {
        this.labelDefChip = getStatoAutorizzazione(this.hasDeliberaDiProgrammazioneMobilita, this.hasAutorizzazioneMobilita);
    }


}
