import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DocumentComponent} from "./document.component";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {TranslocoModule} from "@ngneat/transloco";
import {MatTooltipModule} from "@angular/material/tooltip";
import {SharedModule} from "../../shared.module";
import { DocumentNameFromUrlPipe } from './pipes/document-name-from-url.pipe';
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {CustomButtonModule} from "../custom-button/custom-button.module";
import {SignDocumentModule} from "../dialog-sign-document/sign-document.module";


@NgModule({
    declarations: [
        DocumentComponent,
        DocumentNameFromUrlPipe
    ],
    imports: [
        CommonModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        TranslocoModule,
        MatTooltipModule,
        SharedModule,
        MatOptionModule,
        MatSelectModule,
        CustomButtonModule,
        SignDocumentModule,
    ],
    exports: [
        DocumentComponent,
        DocumentNameFromUrlPipe
    ],
    providers: [DocumentNameFromUrlPipe]
})
export class DocumentModule {
}
