import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CustomButtonComponent} from "./custom-button.component";
import {TranslocoModule} from "@ngneat/transloco";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatIconModule} from "@angular/material/icon";
import {MatStepperModule} from "@angular/material/stepper";



@NgModule({
    declarations: [CustomButtonComponent],
    exports: [
        CustomButtonComponent
    ],
    imports: [
        CommonModule,
        TranslocoModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatStepperModule
    ]
})
export class CustomButtonModule { }
