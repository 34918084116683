/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddPeriodoRegistroAttivitaDTO } from '../model/addPeriodoRegistroAttivitaDTO';
import { AddRegistroAttivitaDTO } from '../model/addRegistroAttivitaDTO';
import { DettagliDocumentiAllegatiRequestDTO } from '../model/dettagliDocumentiAllegatiRequestDTO';
import { EsitoRichiestaApprovazione } from '../model/esitoRichiestaApprovazione';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GenericResponseDTO } from '../model/genericResponseDTO';
import { PeriodoRegistroAttivitaFilterForm } from '../model/periodoRegistroAttivitaFilterForm';
import { PeriodoRegistroAttivitaInfoView } from '../model/periodoRegistroAttivitaInfoView';
import { RegistroAttivitaFilterForm } from '../model/registroAttivitaFilterForm';
import { RegistroAttivitaInfoView } from '../model/registroAttivitaInfoView';
import { RegistroAttivitaInfoViewImpl } from '../model/registroAttivitaInfoViewImpl';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class RegistroStudentiCicloService {

    protected basePath = 'https://api-dev-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Aggiunge un periodo di registro attività di uno studente da validare.
     * 
     * @param id_utente id dell&#x27;utente
     * @param AddPeriodoRegistroAttivitaDTO 
     * @param dettagliDocumentiAllegatiRequestDTO 
     * @param fileAllegato 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addPeriodoRegistroAttivitaForm(id_utente: string, AddPeriodoRegistroAttivitaDTO?: AddPeriodoRegistroAttivitaDTO, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, fileAllegato?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoRegistroAttivitaInfoView>>;
    public addPeriodoRegistroAttivitaForm(id_utente: string, AddPeriodoRegistroAttivitaDTO?: AddPeriodoRegistroAttivitaDTO, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, fileAllegato?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoRegistroAttivitaInfoView>>>;
    public addPeriodoRegistroAttivitaForm(id_utente: string, AddPeriodoRegistroAttivitaDTO?: AddPeriodoRegistroAttivitaDTO, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, fileAllegato?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoRegistroAttivitaInfoView>>>;
    public addPeriodoRegistroAttivitaForm(id_utente: string, AddPeriodoRegistroAttivitaDTO?: AddPeriodoRegistroAttivitaDTO, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, fileAllegato?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling addPeriodoRegistroAttivita.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (AddPeriodoRegistroAttivitaDTO !== undefined) {
            formParams = formParams.append('AddPeriodoRegistroAttivitaDTO', new Blob([JSON.stringify(AddPeriodoRegistroAttivitaDTO)], { type: 'application/json'})) as any || formParams;
        }
        if (dettagliDocumentiAllegatiRequestDTO !== undefined) {
            formParams = formParams.append('dettagliDocumentiAllegatiRequestDTO', new Blob([JSON.stringify(dettagliDocumentiAllegatiRequestDTO)], { type: 'application/json'})) as any || formParams;
        }
        if (fileAllegato) {
            fileAllegato.forEach((element) => {
                formParams = formParams.append('fileAllegato', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<Array<PeriodoRegistroAttivitaInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/registro-attivita/periodi-registro-attivita/add`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiunge un elemento al registro attività di uno studente.
     * 
     * @param body 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addRegistroAttivita(body: AddRegistroAttivitaDTO, id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<RegistroAttivitaInfoView>;
    public addRegistroAttivita(body: AddRegistroAttivitaDTO, id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RegistroAttivitaInfoView>>;
    public addRegistroAttivita(body: AddRegistroAttivitaDTO, id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RegistroAttivitaInfoView>>;
    public addRegistroAttivita(body: AddRegistroAttivitaDTO, id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addRegistroAttivita.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling addRegistroAttivita.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<RegistroAttivitaInfoView>('post',`${this.basePath}/api/v1/studente-ciclo/registro-attivita/add`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check validità resgistro attività studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkRegistroAttivitaStudente(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public checkRegistroAttivitaStudente(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public checkRegistroAttivitaStudente(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public checkRegistroAttivitaStudente(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling checkRegistroAttivitaStudente.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('get',`${this.basePath}/api/v1/studente-ciclo/registro-attivita/check-registro-attivita`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al dottorando di eliminare un Periodo di Registro Attività in stato BOZZA.
     * 
     * @param id_utente id dell&#x27;utente
     * @param id_periodo_registro_attivita id del periodo registro attività
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePeriodoRegistroAttivitaStudente(id_utente: string, id_periodo_registro_attivita: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoRegistroAttivitaInfoView>>;
    public deletePeriodoRegistroAttivitaStudente(id_utente: string, id_periodo_registro_attivita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoRegistroAttivitaInfoView>>>;
    public deletePeriodoRegistroAttivitaStudente(id_utente: string, id_periodo_registro_attivita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoRegistroAttivitaInfoView>>>;
    public deletePeriodoRegistroAttivitaStudente(id_utente: string, id_periodo_registro_attivita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling deletePeriodoRegistroAttivitaStudente.');
        }

        if (id_periodo_registro_attivita === null || id_periodo_registro_attivita === undefined) {
            throw new Error('Required parameter id_periodo_registro_attivita was null or undefined when calling deletePeriodoRegistroAttivitaStudente.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PeriodoRegistroAttivitaInfoView>>('delete',`${this.basePath}/api/v1/studente-ciclo/registro-attivita/periodi-registro-attivita/${encodeURIComponent(String(id_periodo_registro_attivita))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rimuove un elemento del registro attività di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param id_attivita id dell&#x27;attività da modificare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteRegistroAttivita(id_utente: string, id_attivita: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public deleteRegistroAttivita(id_utente: string, id_attivita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public deleteRegistroAttivita(id_utente: string, id_attivita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public deleteRegistroAttivita(id_utente: string, id_attivita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling deleteRegistroAttivita.');
        }

        if (id_attivita === null || id_attivita === undefined) {
            throw new Error('Required parameter id_attivita was null or undefined when calling deleteRegistroAttivita.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('delete',`${this.basePath}/api/v1/studente-ciclo/registro-attivita/${encodeURIComponent(String(id_attivita))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array di un allegato presente nel periodo registro attività di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param id_periodo_registro_attivita id del periodo registro attività
     * @param nome_file nome del file allegato da scaricare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllegatoPeriodoRegistroAttivitaStudente(id_utente: string, id_periodo_registro_attivita: string, nome_file: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getAllegatoPeriodoRegistroAttivitaStudente(id_utente: string, id_periodo_registro_attivita: string, nome_file: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getAllegatoPeriodoRegistroAttivitaStudente(id_utente: string, id_periodo_registro_attivita: string, nome_file: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getAllegatoPeriodoRegistroAttivitaStudente(id_utente: string, id_periodo_registro_attivita: string, nome_file: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getAllegatoPeriodoRegistroAttivitaStudente.');
        }

        if (id_periodo_registro_attivita === null || id_periodo_registro_attivita === undefined) {
            throw new Error('Required parameter id_periodo_registro_attivita was null or undefined when calling getAllegatoPeriodoRegistroAttivitaStudente.');
        }

        if (nome_file === null || nome_file === undefined) {
            throw new Error('Required parameter nome_file was null or undefined when calling getAllegatoPeriodoRegistroAttivitaStudente.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }
        if (nome_file !== undefined && nome_file !== null) {
            queryParameters = queryParameters.set('nome_file', <any>nome_file);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/studente-ciclo/registro-attivita/periodi-registro-attivita/${encodeURIComponent(String(id_periodo_registro_attivita))}/get-allegato`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array del pdf del registro attività di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param data_inizio data inizio del registro (dd/MM/yyyy)
     * @param data_fine data fine del registro (dd/MM/yyyy)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPdfRegistroAttivitaStudente(id_utente: string, data_inizio: string, data_fine: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getPdfRegistroAttivitaStudente(id_utente: string, data_inizio: string, data_fine: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getPdfRegistroAttivitaStudente(id_utente: string, data_inizio: string, data_fine: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getPdfRegistroAttivitaStudente(id_utente: string, data_inizio: string, data_fine: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getPdfRegistroAttivitaStudente.');
        }

        if (data_inizio === null || data_inizio === undefined) {
            throw new Error('Required parameter data_inizio was null or undefined when calling getPdfRegistroAttivitaStudente.');
        }

        if (data_fine === null || data_fine === undefined) {
            throw new Error('Required parameter data_fine was null or undefined when calling getPdfRegistroAttivitaStudente.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }
        if (data_inizio !== undefined && data_inizio !== null) {
            queryParameters = queryParameters.set('data_inizio', <any>data_inizio);
        }
        if (data_fine !== undefined && data_fine !== null) {
            queryParameters = queryParameters.set('data_fine', <any>data_fine);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/studente-ciclo/registro-attivita/pdf`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array del pdf del registro attività del tutor di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param data_inizio data inizio del registro (dd/MM/yyyy)
     * @param data_fine data fine del registro (dd/MM/yyyy)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPdfRegistroTutorAttivitaStudente(id_utente: string, data_inizio: string, data_fine: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getPdfRegistroTutorAttivitaStudente(id_utente: string, data_inizio: string, data_fine: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getPdfRegistroTutorAttivitaStudente(id_utente: string, data_inizio: string, data_fine: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getPdfRegistroTutorAttivitaStudente(id_utente: string, data_inizio: string, data_fine: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getPdfRegistroTutorAttivitaStudente.');
        }

        if (data_inizio === null || data_inizio === undefined) {
            throw new Error('Required parameter data_inizio was null or undefined when calling getPdfRegistroTutorAttivitaStudente.');
        }

        if (data_fine === null || data_fine === undefined) {
            throw new Error('Required parameter data_fine was null or undefined when calling getPdfRegistroTutorAttivitaStudente.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }
        if (data_inizio !== undefined && data_inizio !== null) {
            queryParameters = queryParameters.set('data_inizio', <any>data_inizio);
        }
        if (data_fine !== undefined && data_fine !== null) {
            queryParameters = queryParameters.set('data_fine', <any>data_fine);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/studente-ciclo/registro-attivita/pdf-registro-tutor`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce l&#x27;elenco dei periodi registro attività di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPeriodiRegistroAttivitaStudente(id_utente: string, body?: PeriodoRegistroAttivitaFilterForm, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoRegistroAttivitaInfoView>>;
    public getPeriodiRegistroAttivitaStudente(id_utente: string, body?: PeriodoRegistroAttivitaFilterForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoRegistroAttivitaInfoView>>>;
    public getPeriodiRegistroAttivitaStudente(id_utente: string, body?: PeriodoRegistroAttivitaFilterForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoRegistroAttivitaInfoView>>>;
    public getPeriodiRegistroAttivitaStudente(id_utente: string, body?: PeriodoRegistroAttivitaFilterForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getPeriodiRegistroAttivitaStudente.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<PeriodoRegistroAttivitaInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/registro-attivita/periodi-registro-attivita`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le info del registro attività di uno studente.
     * 
     * @param body 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRegistroAttivitaStudente(body: RegistroAttivitaFilterForm, id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Array<RegistroAttivitaInfoViewImpl>>;
    public getRegistroAttivitaStudente(body: RegistroAttivitaFilterForm, id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RegistroAttivitaInfoViewImpl>>>;
    public getRegistroAttivitaStudente(body: RegistroAttivitaFilterForm, id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RegistroAttivitaInfoViewImpl>>>;
    public getRegistroAttivitaStudente(body: RegistroAttivitaFilterForm, id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getRegistroAttivitaStudente.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getRegistroAttivitaStudente.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<RegistroAttivitaInfoViewImpl>>('post',`${this.basePath}/api/v1/studente-ciclo/registro-attivita`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna un elemento del registro attività di uno studente.
     * 
     * @param body 
     * @param id_utente id dell&#x27;utente
     * @param id_attivita id dell&#x27;attività da modificare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateRegistroAttivita(body: AddRegistroAttivitaDTO, id_utente: string, id_attivita: string, observe?: 'body', reportProgress?: boolean): Observable<RegistroAttivitaInfoView>;
    public updateRegistroAttivita(body: AddRegistroAttivitaDTO, id_utente: string, id_attivita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RegistroAttivitaInfoView>>;
    public updateRegistroAttivita(body: AddRegistroAttivitaDTO, id_utente: string, id_attivita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RegistroAttivitaInfoView>>;
    public updateRegistroAttivita(body: AddRegistroAttivitaDTO, id_utente: string, id_attivita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateRegistroAttivita.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling updateRegistroAttivita.');
        }

        if (id_attivita === null || id_attivita === undefined) {
            throw new Error('Required parameter id_attivita was null or undefined when calling updateRegistroAttivita.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<RegistroAttivitaInfoView>('put',`${this.basePath}/api/v1/studente-ciclo/registro-attivita/${encodeURIComponent(String(id_attivita))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad un coordinatore di validare un periodo registro attività di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param id_periodo_registro_attivita id del periodo registro attività
     * @param dettagliDocumentiAllegatiRequestDTO 
     * @param esitoRichiestaApprovazione 
     * @param fileAllegato 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validaPeriodoRegistroAttivitaByCoordinatoreForm(id_utente: string, id_periodo_registro_attivita: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, fileAllegato?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoRegistroAttivitaInfoView>>;
    public validaPeriodoRegistroAttivitaByCoordinatoreForm(id_utente: string, id_periodo_registro_attivita: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, fileAllegato?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoRegistroAttivitaInfoView>>>;
    public validaPeriodoRegistroAttivitaByCoordinatoreForm(id_utente: string, id_periodo_registro_attivita: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, fileAllegato?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoRegistroAttivitaInfoView>>>;
    public validaPeriodoRegistroAttivitaByCoordinatoreForm(id_utente: string, id_periodo_registro_attivita: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, fileAllegato?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling validaPeriodoRegistroAttivitaByCoordinatore.');
        }

        if (id_periodo_registro_attivita === null || id_periodo_registro_attivita === undefined) {
            throw new Error('Required parameter id_periodo_registro_attivita was null or undefined when calling validaPeriodoRegistroAttivitaByCoordinatore.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (dettagliDocumentiAllegatiRequestDTO !== undefined) {
            formParams = formParams.append('dettagliDocumentiAllegatiRequestDTO', new Blob([JSON.stringify(dettagliDocumentiAllegatiRequestDTO)], { type: 'application/json'})) as any || formParams;
        }
        if (esitoRichiestaApprovazione !== undefined) {
            formParams = formParams.append('esitoRichiestaApprovazione', new Blob([JSON.stringify(esitoRichiestaApprovazione)], { type: 'application/json'})) as any || formParams;
        }
        if (fileAllegato) {
            fileAllegato.forEach((element) => {
                formParams = formParams.append('fileAllegato', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<Array<PeriodoRegistroAttivitaInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/registro-attivita/periodi-registro-attivita/${encodeURIComponent(String(id_periodo_registro_attivita))}/coordinatore`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad uno studente di caricate l&#x27;allegato in una richiesta di periodo registro attività.
     * 
     * @param id_utente id dell&#x27;utente
     * @param id_periodo_registro_attivita id del periodo registro attività
     * @param dettagliDocumentiAllegatiRequestDTO 
     * @param fileAllegato 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validaPeriodoRegistroAttivitaByStudenteForm(id_utente: string, id_periodo_registro_attivita: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, fileAllegato?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoRegistroAttivitaInfoView>>;
    public validaPeriodoRegistroAttivitaByStudenteForm(id_utente: string, id_periodo_registro_attivita: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, fileAllegato?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoRegistroAttivitaInfoView>>>;
    public validaPeriodoRegistroAttivitaByStudenteForm(id_utente: string, id_periodo_registro_attivita: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, fileAllegato?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoRegistroAttivitaInfoView>>>;
    public validaPeriodoRegistroAttivitaByStudenteForm(id_utente: string, id_periodo_registro_attivita: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, fileAllegato?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling validaPeriodoRegistroAttivitaByStudente.');
        }

        if (id_periodo_registro_attivita === null || id_periodo_registro_attivita === undefined) {
            throw new Error('Required parameter id_periodo_registro_attivita was null or undefined when calling validaPeriodoRegistroAttivitaByStudente.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (dettagliDocumentiAllegatiRequestDTO !== undefined) {
            formParams = formParams.append('dettagliDocumentiAllegatiRequestDTO', new Blob([JSON.stringify(dettagliDocumentiAllegatiRequestDTO)], { type: 'application/json'})) as any || formParams;
        }
        if (fileAllegato) {
            fileAllegato.forEach((element) => {
                formParams = formParams.append('fileAllegato', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<Array<PeriodoRegistroAttivitaInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/registro-attivita/periodi-registro-attivita/${encodeURIComponent(String(id_periodo_registro_attivita))}/studente`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad un supervisore di validare un periodo registro attività di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param id_periodo_registro_attivita id del periodo registro attività
     * @param dettagliDocumentiAllegatiRequestDTO 
     * @param esitoRichiestaApprovazione 
     * @param fileAllegato 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validaPeriodoRegistroAttivitaBySupervisoreForm(id_utente: string, id_periodo_registro_attivita: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, fileAllegato?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoRegistroAttivitaInfoView>>;
    public validaPeriodoRegistroAttivitaBySupervisoreForm(id_utente: string, id_periodo_registro_attivita: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, fileAllegato?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoRegistroAttivitaInfoView>>>;
    public validaPeriodoRegistroAttivitaBySupervisoreForm(id_utente: string, id_periodo_registro_attivita: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, fileAllegato?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoRegistroAttivitaInfoView>>>;
    public validaPeriodoRegistroAttivitaBySupervisoreForm(id_utente: string, id_periodo_registro_attivita: string, dettagliDocumentiAllegatiRequestDTO?: DettagliDocumentiAllegatiRequestDTO, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, fileAllegato?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling validaPeriodoRegistroAttivitaBySupervisore.');
        }

        if (id_periodo_registro_attivita === null || id_periodo_registro_attivita === undefined) {
            throw new Error('Required parameter id_periodo_registro_attivita was null or undefined when calling validaPeriodoRegistroAttivitaBySupervisore.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id_utente !== undefined && id_utente !== null) {
            queryParameters = queryParameters.set('id_utente', <any>id_utente);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (dettagliDocumentiAllegatiRequestDTO !== undefined) {
            formParams = formParams.append('dettagliDocumentiAllegatiRequestDTO', new Blob([JSON.stringify(dettagliDocumentiAllegatiRequestDTO)], { type: 'application/json'})) as any || formParams;
        }
        if (esitoRichiestaApprovazione !== undefined) {
            formParams = formParams.append('esitoRichiestaApprovazione', new Blob([JSON.stringify(esitoRichiestaApprovazione)], { type: 'application/json'})) as any || formParams;
        }
        if (fileAllegato) {
            fileAllegato.forEach((element) => {
                formParams = formParams.append('fileAllegato', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<Array<PeriodoRegistroAttivitaInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/registro-attivita/periodi-registro-attivita/${encodeURIComponent(String(id_periodo_registro_attivita))}/supervisore`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
