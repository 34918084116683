/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddOrUpdateCosupervisoreStudenteRequestDTO } from '../model/addOrUpdateCosupervisoreStudenteRequestDTO';
import { AggiornamentoBudgetStudenteStatus } from '../model/aggiornamentoBudgetStudenteStatus';
import { CosupervisoriStudenteResponseDTO } from '../model/cosupervisoriStudenteResponseDTO';
import { DettaglioBorsaStudente } from '../model/dettaglioBorsaStudente';
import { DettaglioBorsaStudenteUpdateDTO } from '../model/dettaglioBorsaStudenteUpdateDTO';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GetDettaglioBorsaStudenteDTO } from '../model/getDettaglioBorsaStudenteDTO';
import { GetInfoStudenteResponse } from '../model/getInfoStudenteResponse';
import { MissioneStatus } from '../model/missioneStatus';
import { PageStudenteCicloIdAndUtenteInfoView } from '../model/pageStudenteCicloIdAndUtenteInfoView';
import { PageStudenteCicloNoMatricolaViewImpl } from '../model/pageStudenteCicloNoMatricolaViewImpl';
import { PageStudenteCicloUtenteViewImpl } from '../model/pageStudenteCicloUtenteViewImpl';
import { PatchStudenteNoMatricolaDTO } from '../model/patchStudenteNoMatricolaDTO';
import { PeriodoDiMobilitaStudenteStatus } from '../model/periodoDiMobilitaStudenteStatus';
import { PeriodoRegistroAttivitaStatus } from '../model/periodoRegistroAttivitaStatus';
import { RemoveCosupervisoreStudenteRequestDTO } from '../model/removeCosupervisoreStudenteRequestDTO';
import { SpesaStudenteStatus } from '../model/spesaStudenteStatus';
import { StoricoMembriStudenteInfoView } from '../model/storicoMembriStudenteInfoView';
import { StudenteCicloMotivoStatus } from '../model/studenteCicloMotivoStatus';
import { StudenteCicloNoMatricolaViewImpl } from '../model/studenteCicloNoMatricolaViewImpl';
import { StudenteCicloStatus } from '../model/studenteCicloStatus';
import { UpdateSupervisoreStudenteRequestDTO } from '../model/updateSupervisoreStudenteRequestDTO';
import { UpdateSupervisoreStudenteResponseDTO } from '../model/updateSupervisoreStudenteResponseDTO';
import { UtenteIdsDTO } from '../model/utenteIdsDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class StudentiCicloService {

    protected basePath = 'https://api-dev-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Aggiunge o aggiorna i permessi approvativi di un Cosupervisore per uno studente.
     * 
     * @param body 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrUpdateCosupervisoreStudente(body: AddOrUpdateCosupervisoreStudenteRequestDTO, id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<CosupervisoriStudenteResponseDTO>;
    public addOrUpdateCosupervisoreStudente(body: AddOrUpdateCosupervisoreStudenteRequestDTO, id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CosupervisoriStudenteResponseDTO>>;
    public addOrUpdateCosupervisoreStudente(body: AddOrUpdateCosupervisoreStudenteRequestDTO, id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CosupervisoriStudenteResponseDTO>>;
    public addOrUpdateCosupervisoreStudente(body: AddOrUpdateCosupervisoreStudenteRequestDTO, id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addOrUpdateCosupervisoreStudente.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling addOrUpdateCosupervisoreStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CosupervisoriStudenteResponseDTO>('put',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/cosupervisore`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array di un allegato presente nel dettaglio borsa di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param nome_file nome del file allegato da scaricare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllegatoDettaglioBorsaStudente(id_utente: string, nome_file: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getAllegatoDettaglioBorsaStudente(id_utente: string, nome_file: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getAllegatoDettaglioBorsaStudente(id_utente: string, nome_file: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getAllegatoDettaglioBorsaStudente(id_utente: string, nome_file: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getAllegatoDettaglioBorsaStudente.');
        }

        if (nome_file === null || nome_file === undefined) {
            throw new Error('Required parameter nome_file was null or undefined when calling getAllegatoDettaglioBorsaStudente.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (nome_file !== undefined && nome_file !== null) {
            queryParameters = queryParameters.set('nome_file', <any>nome_file);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/dettaglio-borsa/get-allegato`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il dettaglio borsa e l&#x27;extra fields mapper di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDettaglioBorsaStudente(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<GetDettaglioBorsaStudenteDTO>;
    public getDettaglioBorsaStudente(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetDettaglioBorsaStudenteDTO>>;
    public getDettaglioBorsaStudente(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetDettaglioBorsaStudenteDTO>>;
    public getDettaglioBorsaStudente(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getDettaglioBorsaStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetDettaglioBorsaStudenteDTO>('get',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/dettaglio-borsa`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le info di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInfoStudente(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<GetInfoStudenteResponse>;
    public getInfoStudente(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetInfoStudenteResponse>>;
    public getInfoStudente(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetInfoStudenteResponse>>;
    public getInfoStudente(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getInfoStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetInfoStudenteResponse>('get',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array della lettera di ammissione di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLetteraDiAmmissioneStudente(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getLetteraDiAmmissioneStudente(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getLetteraDiAmmissioneStudente(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getLetteraDiAmmissioneStudente(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getLetteraDiAmmissioneStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/lettera-di-ammissione`,
            {
             responseType: "blob" as "json",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array del pdf dell&#x27;anagrafica di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPdfAnagraficaStudente(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getPdfAnagraficaStudente(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getPdfAnagraficaStudente(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getPdfAnagraficaStudente(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getPdfAnagraficaStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/anagrafica/pdf`,
            {
             responseType: "blob" as "json",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce gli storici membri studente per i supervisori e cosupervisori attuali di una lista di studenti.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStoriciCurrentSupervisoreCosupervisoreStudentiCiclo(body: UtenteIdsDTO, observe?: 'body', reportProgress?: boolean): Observable<Array<StoricoMembriStudenteInfoView>>;
    public getStoriciCurrentSupervisoreCosupervisoreStudentiCiclo(body: UtenteIdsDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StoricoMembriStudenteInfoView>>>;
    public getStoriciCurrentSupervisoreCosupervisoreStudentiCiclo(body: UtenteIdsDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StoricoMembriStudenteInfoView>>>;
    public getStoriciCurrentSupervisoreCosupervisoreStudentiCiclo(body: UtenteIdsDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getStoriciCurrentSupervisoreCosupervisoreStudentiCiclo.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<StoricoMembriStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/storici-current-supervisore-cosupervisore-studenti`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce i profili di tutti gli studenti con i filtri applicati.
     * 
     * @param afferenzaOrganizzativaCosupervisore 
     * @param afferenzaOrganizzativaSupervisore 
     * @param ateneoProvenienza 
     * @param codiceFiscale 
     * @param codiceFiscaleCosupervisore 
     * @param codiceFiscaleDocenteCorso 
     * @param codiceFiscaleRelatore 
     * @param codiceFiscaleSupervisore 
     * @param codiceTipoBorsaPerCiclo 
     * @param corsoSuperato 
     * @param hasAutorizzazioneMobilita 
     * @param hasDeliberaDiProgrammazioneMobilita 
     * @param hasDeliberaInizioAttivita 
     * @param hasNotCosupervisore 
     * @param hasNotSupervisore 
     * @param hasNumGiorniMobilitaInferioreAlMinimoPrevisto 
     * @param hasNumGiorniMobilitaSuperioreAlPrevisto 
     * @param idCorsoInOffertaFormativa 
     * @param isInRegolaTasse 
     * @param motiviStatoStudente 
     * @param nazionalita 
     * @param nominativo 
     * @param ssdCosupervisore 
     * @param ssdSupervisore 
     * @param statiMissioneDaApprovare 
     * @param statiPeriodiDiMobilita 
     * @param statiStudente 
     * @param statoPeriodoRegistroAttivita 
     * @param statoSpesaStudente 
     * @param statoUltimoAggiornamentoBudgetStudente 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStudentiForm(afferenzaOrganizzativaCosupervisore?: string, afferenzaOrganizzativaSupervisore?: string, ateneoProvenienza?: string, codiceFiscale?: string, codiceFiscaleCosupervisore?: string, codiceFiscaleDocenteCorso?: string, codiceFiscaleRelatore?: string, codiceFiscaleSupervisore?: string, codiceTipoBorsaPerCiclo?: string, corsoSuperato?: boolean, hasAutorizzazioneMobilita?: boolean, hasDeliberaDiProgrammazioneMobilita?: boolean, hasDeliberaInizioAttivita?: boolean, hasNotCosupervisore?: boolean, hasNotSupervisore?: boolean, hasNumGiorniMobilitaInferioreAlMinimoPrevisto?: boolean, hasNumGiorniMobilitaSuperioreAlPrevisto?: boolean, idCorsoInOffertaFormativa?: string, isInRegolaTasse?: boolean, motiviStatoStudente?: Array<StudenteCicloMotivoStatus>, nazionalita?: string, nominativo?: string, ssdCosupervisore?: string, ssdSupervisore?: string, statiMissioneDaApprovare?: Array<MissioneStatus>, statiPeriodiDiMobilita?: Array<PeriodoDiMobilitaStudenteStatus>, statiStudente?: Array<StudenteCicloStatus>, statoPeriodoRegistroAttivita?: PeriodoRegistroAttivitaStatus, statoSpesaStudente?: SpesaStudenteStatus, statoUltimoAggiornamentoBudgetStudente?: AggiornamentoBudgetStudenteStatus, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageStudenteCicloUtenteViewImpl>;
    public getStudentiForm(afferenzaOrganizzativaCosupervisore?: string, afferenzaOrganizzativaSupervisore?: string, ateneoProvenienza?: string, codiceFiscale?: string, codiceFiscaleCosupervisore?: string, codiceFiscaleDocenteCorso?: string, codiceFiscaleRelatore?: string, codiceFiscaleSupervisore?: string, codiceTipoBorsaPerCiclo?: string, corsoSuperato?: boolean, hasAutorizzazioneMobilita?: boolean, hasDeliberaDiProgrammazioneMobilita?: boolean, hasDeliberaInizioAttivita?: boolean, hasNotCosupervisore?: boolean, hasNotSupervisore?: boolean, hasNumGiorniMobilitaInferioreAlMinimoPrevisto?: boolean, hasNumGiorniMobilitaSuperioreAlPrevisto?: boolean, idCorsoInOffertaFormativa?: string, isInRegolaTasse?: boolean, motiviStatoStudente?: Array<StudenteCicloMotivoStatus>, nazionalita?: string, nominativo?: string, ssdCosupervisore?: string, ssdSupervisore?: string, statiMissioneDaApprovare?: Array<MissioneStatus>, statiPeriodiDiMobilita?: Array<PeriodoDiMobilitaStudenteStatus>, statiStudente?: Array<StudenteCicloStatus>, statoPeriodoRegistroAttivita?: PeriodoRegistroAttivitaStatus, statoSpesaStudente?: SpesaStudenteStatus, statoUltimoAggiornamentoBudgetStudente?: AggiornamentoBudgetStudenteStatus, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageStudenteCicloUtenteViewImpl>>;
    public getStudentiForm(afferenzaOrganizzativaCosupervisore?: string, afferenzaOrganizzativaSupervisore?: string, ateneoProvenienza?: string, codiceFiscale?: string, codiceFiscaleCosupervisore?: string, codiceFiscaleDocenteCorso?: string, codiceFiscaleRelatore?: string, codiceFiscaleSupervisore?: string, codiceTipoBorsaPerCiclo?: string, corsoSuperato?: boolean, hasAutorizzazioneMobilita?: boolean, hasDeliberaDiProgrammazioneMobilita?: boolean, hasDeliberaInizioAttivita?: boolean, hasNotCosupervisore?: boolean, hasNotSupervisore?: boolean, hasNumGiorniMobilitaInferioreAlMinimoPrevisto?: boolean, hasNumGiorniMobilitaSuperioreAlPrevisto?: boolean, idCorsoInOffertaFormativa?: string, isInRegolaTasse?: boolean, motiviStatoStudente?: Array<StudenteCicloMotivoStatus>, nazionalita?: string, nominativo?: string, ssdCosupervisore?: string, ssdSupervisore?: string, statiMissioneDaApprovare?: Array<MissioneStatus>, statiPeriodiDiMobilita?: Array<PeriodoDiMobilitaStudenteStatus>, statiStudente?: Array<StudenteCicloStatus>, statoPeriodoRegistroAttivita?: PeriodoRegistroAttivitaStatus, statoSpesaStudente?: SpesaStudenteStatus, statoUltimoAggiornamentoBudgetStudente?: AggiornamentoBudgetStudenteStatus, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageStudenteCicloUtenteViewImpl>>;
    public getStudentiForm(afferenzaOrganizzativaCosupervisore?: string, afferenzaOrganizzativaSupervisore?: string, ateneoProvenienza?: string, codiceFiscale?: string, codiceFiscaleCosupervisore?: string, codiceFiscaleDocenteCorso?: string, codiceFiscaleRelatore?: string, codiceFiscaleSupervisore?: string, codiceTipoBorsaPerCiclo?: string, corsoSuperato?: boolean, hasAutorizzazioneMobilita?: boolean, hasDeliberaDiProgrammazioneMobilita?: boolean, hasDeliberaInizioAttivita?: boolean, hasNotCosupervisore?: boolean, hasNotSupervisore?: boolean, hasNumGiorniMobilitaInferioreAlMinimoPrevisto?: boolean, hasNumGiorniMobilitaSuperioreAlPrevisto?: boolean, idCorsoInOffertaFormativa?: string, isInRegolaTasse?: boolean, motiviStatoStudente?: Array<StudenteCicloMotivoStatus>, nazionalita?: string, nominativo?: string, ssdCosupervisore?: string, ssdSupervisore?: string, statiMissioneDaApprovare?: Array<MissioneStatus>, statiPeriodiDiMobilita?: Array<PeriodoDiMobilitaStudenteStatus>, statiStudente?: Array<StudenteCicloStatus>, statoPeriodoRegistroAttivita?: PeriodoRegistroAttivitaStatus, statoSpesaStudente?: SpesaStudenteStatus, statoUltimoAggiornamentoBudgetStudente?: AggiornamentoBudgetStudenteStatus, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


































        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (afferenzaOrganizzativaCosupervisore !== undefined) {
            formParams = formParams.append('afferenzaOrganizzativaCosupervisore', <any>afferenzaOrganizzativaCosupervisore) as any || formParams;
        }
        if (afferenzaOrganizzativaSupervisore !== undefined) {
            formParams = formParams.append('afferenzaOrganizzativaSupervisore', <any>afferenzaOrganizzativaSupervisore) as any || formParams;
        }
        if (ateneoProvenienza !== undefined) {
            formParams = formParams.append('ateneoProvenienza', <any>ateneoProvenienza) as any || formParams;
        }
        if (codiceFiscale !== undefined) {
            formParams = formParams.append('codiceFiscale', <any>codiceFiscale) as any || formParams;
        }
        if (codiceFiscaleCosupervisore !== undefined) {
            formParams = formParams.append('codiceFiscaleCosupervisore', <any>codiceFiscaleCosupervisore) as any || formParams;
        }
        if (codiceFiscaleDocenteCorso !== undefined) {
            formParams = formParams.append('codiceFiscaleDocenteCorso', <any>codiceFiscaleDocenteCorso) as any || formParams;
        }
        if (codiceFiscaleRelatore !== undefined) {
            formParams = formParams.append('codiceFiscaleRelatore', <any>codiceFiscaleRelatore) as any || formParams;
        }
        if (codiceFiscaleSupervisore !== undefined) {
            formParams = formParams.append('codiceFiscaleSupervisore', <any>codiceFiscaleSupervisore) as any || formParams;
        }
        if (codiceTipoBorsaPerCiclo !== undefined) {
            formParams = formParams.append('codiceTipoBorsaPerCiclo', <any>codiceTipoBorsaPerCiclo) as any || formParams;
        }
        if (corsoSuperato !== undefined) {
            formParams = formParams.append('corsoSuperato', <any>corsoSuperato) as any || formParams;
        }
        if (hasAutorizzazioneMobilita !== undefined) {
            formParams = formParams.append('hasAutorizzazioneMobilita', <any>hasAutorizzazioneMobilita) as any || formParams;
        }
        if (hasDeliberaDiProgrammazioneMobilita !== undefined) {
            formParams = formParams.append('hasDeliberaDiProgrammazioneMobilita', <any>hasDeliberaDiProgrammazioneMobilita) as any || formParams;
        }
        if (hasDeliberaInizioAttivita !== undefined) {
            formParams = formParams.append('hasDeliberaInizioAttivita', <any>hasDeliberaInizioAttivita) as any || formParams;
        }
        if (hasNotCosupervisore !== undefined) {
            formParams = formParams.append('hasNotCosupervisore', <any>hasNotCosupervisore) as any || formParams;
        }
        if (hasNotSupervisore !== undefined) {
            formParams = formParams.append('hasNotSupervisore', <any>hasNotSupervisore) as any || formParams;
        }
        if (hasNumGiorniMobilitaInferioreAlMinimoPrevisto !== undefined) {
            formParams = formParams.append('hasNumGiorniMobilitaInferioreAlMinimoPrevisto', <any>hasNumGiorniMobilitaInferioreAlMinimoPrevisto) as any || formParams;
        }
        if (hasNumGiorniMobilitaSuperioreAlPrevisto !== undefined) {
            formParams = formParams.append('hasNumGiorniMobilitaSuperioreAlPrevisto', <any>hasNumGiorniMobilitaSuperioreAlPrevisto) as any || formParams;
        }
        if (idCorsoInOffertaFormativa !== undefined) {
            formParams = formParams.append('idCorsoInOffertaFormativa', <any>idCorsoInOffertaFormativa) as any || formParams;
        }
        if (isInRegolaTasse !== undefined) {
            formParams = formParams.append('isInRegolaTasse', <any>isInRegolaTasse) as any || formParams;
        }
        if (motiviStatoStudente) {
            motiviStatoStudente.forEach((element) => {
                formParams = formParams.append('motiviStatoStudente', <any>element) as any || formParams;
            })
        }
        if (nazionalita !== undefined) {
            formParams = formParams.append('nazionalita', <any>nazionalita) as any || formParams;
        }
        if (nominativo !== undefined) {
            formParams = formParams.append('nominativo', <any>nominativo) as any || formParams;
        }
        if (ssdCosupervisore !== undefined) {
            formParams = formParams.append('ssdCosupervisore', <any>ssdCosupervisore) as any || formParams;
        }
        if (ssdSupervisore !== undefined) {
            formParams = formParams.append('ssdSupervisore', <any>ssdSupervisore) as any || formParams;
        }
        if (statiMissioneDaApprovare) {
            statiMissioneDaApprovare.forEach((element) => {
                formParams = formParams.append('statiMissioneDaApprovare', <any>element) as any || formParams;
            })
        }
        if (statiPeriodiDiMobilita) {
            statiPeriodiDiMobilita.forEach((element) => {
                formParams = formParams.append('statiPeriodiDiMobilita', <any>element) as any || formParams;
            })
        }
        if (statiStudente) {
            statiStudente.forEach((element) => {
                formParams = formParams.append('statiStudente', <any>element) as any || formParams;
            })
        }
        if (statoPeriodoRegistroAttivita !== undefined) {
            formParams = formParams.append('statoPeriodoRegistroAttivita', <any>statoPeriodoRegistroAttivita) as any || formParams;
        }
        if (statoSpesaStudente !== undefined) {
            formParams = formParams.append('statoSpesaStudente', <any>statoSpesaStudente) as any || formParams;
        }
        if (statoUltimoAggiornamentoBudgetStudente !== undefined) {
            formParams = formParams.append('statoUltimoAggiornamentoBudgetStudente', <any>statoUltimoAggiornamentoBudgetStudente) as any || formParams;
        }

        return this.httpClient.request<PageStudenteCicloUtenteViewImpl>('post',`${this.basePath}/api/v1/studente-ciclo/search`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce i profili di tutti gli studenti con i filtri applicati.
     * 
     * @param ateneoProvenienza 
     * @param codiceFiscale 
     * @param codiceTipoBorsaPerCiclo 
     * @param hasPostaAttenzione 
     * @param isInRegolaTasse 
     * @param motiviStatoStudente 
     * @param nazionalita 
     * @param nominativo 
     * @param statiStudente 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStudentiNoMatricolaForm(ateneoProvenienza?: string, codiceFiscale?: string, codiceTipoBorsaPerCiclo?: string, hasPostaAttenzione?: boolean, isInRegolaTasse?: boolean, motiviStatoStudente?: Array<StudenteCicloMotivoStatus>, nazionalita?: string, nominativo?: string, statiStudente?: Array<StudenteCicloStatus>, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageStudenteCicloNoMatricolaViewImpl>;
    public getStudentiNoMatricolaForm(ateneoProvenienza?: string, codiceFiscale?: string, codiceTipoBorsaPerCiclo?: string, hasPostaAttenzione?: boolean, isInRegolaTasse?: boolean, motiviStatoStudente?: Array<StudenteCicloMotivoStatus>, nazionalita?: string, nominativo?: string, statiStudente?: Array<StudenteCicloStatus>, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageStudenteCicloNoMatricolaViewImpl>>;
    public getStudentiNoMatricolaForm(ateneoProvenienza?: string, codiceFiscale?: string, codiceTipoBorsaPerCiclo?: string, hasPostaAttenzione?: boolean, isInRegolaTasse?: boolean, motiviStatoStudente?: Array<StudenteCicloMotivoStatus>, nazionalita?: string, nominativo?: string, statiStudente?: Array<StudenteCicloStatus>, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageStudenteCicloNoMatricolaViewImpl>>;
    public getStudentiNoMatricolaForm(ateneoProvenienza?: string, codiceFiscale?: string, codiceTipoBorsaPerCiclo?: string, hasPostaAttenzione?: boolean, isInRegolaTasse?: boolean, motiviStatoStudente?: Array<StudenteCicloMotivoStatus>, nazionalita?: string, nominativo?: string, statiStudente?: Array<StudenteCicloStatus>, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {













        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (ateneoProvenienza !== undefined) {
            formParams = formParams.append('ateneoProvenienza', <any>ateneoProvenienza) as any || formParams;
        }
        if (codiceFiscale !== undefined) {
            formParams = formParams.append('codiceFiscale', <any>codiceFiscale) as any || formParams;
        }
        if (codiceTipoBorsaPerCiclo !== undefined) {
            formParams = formParams.append('codiceTipoBorsaPerCiclo', <any>codiceTipoBorsaPerCiclo) as any || formParams;
        }
        if (hasPostaAttenzione !== undefined) {
            formParams = formParams.append('hasPostaAttenzione', <any>hasPostaAttenzione) as any || formParams;
        }
        if (isInRegolaTasse !== undefined) {
            formParams = formParams.append('isInRegolaTasse', <any>isInRegolaTasse) as any || formParams;
        }
        if (motiviStatoStudente) {
            motiviStatoStudente.forEach((element) => {
                formParams = formParams.append('motiviStatoStudente', <any>element) as any || formParams;
            })
        }
        if (nazionalita !== undefined) {
            formParams = formParams.append('nazionalita', <any>nazionalita) as any || formParams;
        }
        if (nominativo !== undefined) {
            formParams = formParams.append('nominativo', <any>nominativo) as any || formParams;
        }
        if (statiStudente) {
            statiStudente.forEach((element) => {
                formParams = formParams.append('statiStudente', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<PageStudenteCicloNoMatricolaViewImpl>('post',`${this.basePath}/api/v1/studente-ciclo/no-matricola/search`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rimuove un Cosupervisore di uno studente.
     * 
     * @param body 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeCosupervisoreStudente(body: RemoveCosupervisoreStudenteRequestDTO, id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<CosupervisoriStudenteResponseDTO>;
    public removeCosupervisoreStudente(body: RemoveCosupervisoreStudenteRequestDTO, id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CosupervisoriStudenteResponseDTO>>;
    public removeCosupervisoreStudente(body: RemoveCosupervisoreStudenteRequestDTO, id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CosupervisoriStudenteResponseDTO>>;
    public removeCosupervisoreStudente(body: RemoveCosupervisoreStudenteRequestDTO, id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling removeCosupervisoreStudente.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling removeCosupervisoreStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CosupervisoriStudenteResponseDTO>('delete',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/cosupervisore`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce una proiezione leggera degli studenti ricercati con i filtri applicati.
     * 
     * @param afferenzaOrganizzativaCosupervisore 
     * @param afferenzaOrganizzativaSupervisore 
     * @param ateneoProvenienza 
     * @param codiceFiscale 
     * @param codiceFiscaleCosupervisore 
     * @param codiceFiscaleDocenteCorso 
     * @param codiceFiscaleRelatore 
     * @param codiceFiscaleSupervisore 
     * @param codiceTipoBorsaPerCiclo 
     * @param corsoSuperato 
     * @param hasAutorizzazioneMobilita 
     * @param hasDeliberaDiProgrammazioneMobilita 
     * @param hasDeliberaInizioAttivita 
     * @param hasNotCosupervisore 
     * @param hasNotSupervisore 
     * @param hasNumGiorniMobilitaInferioreAlMinimoPrevisto 
     * @param hasNumGiorniMobilitaSuperioreAlPrevisto 
     * @param idCorsoInOffertaFormativa 
     * @param isInRegolaTasse 
     * @param motiviStatoStudente 
     * @param nazionalita 
     * @param nominativo 
     * @param ssdCosupervisore 
     * @param ssdSupervisore 
     * @param statiMissioneDaApprovare 
     * @param statiPeriodiDiMobilita 
     * @param statiStudente 
     * @param statoPeriodoRegistroAttivita 
     * @param statoSpesaStudente 
     * @param statoUltimoAggiornamentoBudgetStudente 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchStudentiViewForm(afferenzaOrganizzativaCosupervisore?: string, afferenzaOrganizzativaSupervisore?: string, ateneoProvenienza?: string, codiceFiscale?: string, codiceFiscaleCosupervisore?: string, codiceFiscaleDocenteCorso?: string, codiceFiscaleRelatore?: string, codiceFiscaleSupervisore?: string, codiceTipoBorsaPerCiclo?: string, corsoSuperato?: boolean, hasAutorizzazioneMobilita?: boolean, hasDeliberaDiProgrammazioneMobilita?: boolean, hasDeliberaInizioAttivita?: boolean, hasNotCosupervisore?: boolean, hasNotSupervisore?: boolean, hasNumGiorniMobilitaInferioreAlMinimoPrevisto?: boolean, hasNumGiorniMobilitaSuperioreAlPrevisto?: boolean, idCorsoInOffertaFormativa?: string, isInRegolaTasse?: boolean, motiviStatoStudente?: Array<StudenteCicloMotivoStatus>, nazionalita?: string, nominativo?: string, ssdCosupervisore?: string, ssdSupervisore?: string, statiMissioneDaApprovare?: Array<MissioneStatus>, statiPeriodiDiMobilita?: Array<PeriodoDiMobilitaStudenteStatus>, statiStudente?: Array<StudenteCicloStatus>, statoPeriodoRegistroAttivita?: PeriodoRegistroAttivitaStatus, statoSpesaStudente?: SpesaStudenteStatus, statoUltimoAggiornamentoBudgetStudente?: AggiornamentoBudgetStudenteStatus, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageStudenteCicloIdAndUtenteInfoView>;
    public searchStudentiViewForm(afferenzaOrganizzativaCosupervisore?: string, afferenzaOrganizzativaSupervisore?: string, ateneoProvenienza?: string, codiceFiscale?: string, codiceFiscaleCosupervisore?: string, codiceFiscaleDocenteCorso?: string, codiceFiscaleRelatore?: string, codiceFiscaleSupervisore?: string, codiceTipoBorsaPerCiclo?: string, corsoSuperato?: boolean, hasAutorizzazioneMobilita?: boolean, hasDeliberaDiProgrammazioneMobilita?: boolean, hasDeliberaInizioAttivita?: boolean, hasNotCosupervisore?: boolean, hasNotSupervisore?: boolean, hasNumGiorniMobilitaInferioreAlMinimoPrevisto?: boolean, hasNumGiorniMobilitaSuperioreAlPrevisto?: boolean, idCorsoInOffertaFormativa?: string, isInRegolaTasse?: boolean, motiviStatoStudente?: Array<StudenteCicloMotivoStatus>, nazionalita?: string, nominativo?: string, ssdCosupervisore?: string, ssdSupervisore?: string, statiMissioneDaApprovare?: Array<MissioneStatus>, statiPeriodiDiMobilita?: Array<PeriodoDiMobilitaStudenteStatus>, statiStudente?: Array<StudenteCicloStatus>, statoPeriodoRegistroAttivita?: PeriodoRegistroAttivitaStatus, statoSpesaStudente?: SpesaStudenteStatus, statoUltimoAggiornamentoBudgetStudente?: AggiornamentoBudgetStudenteStatus, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageStudenteCicloIdAndUtenteInfoView>>;
    public searchStudentiViewForm(afferenzaOrganizzativaCosupervisore?: string, afferenzaOrganizzativaSupervisore?: string, ateneoProvenienza?: string, codiceFiscale?: string, codiceFiscaleCosupervisore?: string, codiceFiscaleDocenteCorso?: string, codiceFiscaleRelatore?: string, codiceFiscaleSupervisore?: string, codiceTipoBorsaPerCiclo?: string, corsoSuperato?: boolean, hasAutorizzazioneMobilita?: boolean, hasDeliberaDiProgrammazioneMobilita?: boolean, hasDeliberaInizioAttivita?: boolean, hasNotCosupervisore?: boolean, hasNotSupervisore?: boolean, hasNumGiorniMobilitaInferioreAlMinimoPrevisto?: boolean, hasNumGiorniMobilitaSuperioreAlPrevisto?: boolean, idCorsoInOffertaFormativa?: string, isInRegolaTasse?: boolean, motiviStatoStudente?: Array<StudenteCicloMotivoStatus>, nazionalita?: string, nominativo?: string, ssdCosupervisore?: string, ssdSupervisore?: string, statiMissioneDaApprovare?: Array<MissioneStatus>, statiPeriodiDiMobilita?: Array<PeriodoDiMobilitaStudenteStatus>, statiStudente?: Array<StudenteCicloStatus>, statoPeriodoRegistroAttivita?: PeriodoRegistroAttivitaStatus, statoSpesaStudente?: SpesaStudenteStatus, statoUltimoAggiornamentoBudgetStudente?: AggiornamentoBudgetStudenteStatus, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageStudenteCicloIdAndUtenteInfoView>>;
    public searchStudentiViewForm(afferenzaOrganizzativaCosupervisore?: string, afferenzaOrganizzativaSupervisore?: string, ateneoProvenienza?: string, codiceFiscale?: string, codiceFiscaleCosupervisore?: string, codiceFiscaleDocenteCorso?: string, codiceFiscaleRelatore?: string, codiceFiscaleSupervisore?: string, codiceTipoBorsaPerCiclo?: string, corsoSuperato?: boolean, hasAutorizzazioneMobilita?: boolean, hasDeliberaDiProgrammazioneMobilita?: boolean, hasDeliberaInizioAttivita?: boolean, hasNotCosupervisore?: boolean, hasNotSupervisore?: boolean, hasNumGiorniMobilitaInferioreAlMinimoPrevisto?: boolean, hasNumGiorniMobilitaSuperioreAlPrevisto?: boolean, idCorsoInOffertaFormativa?: string, isInRegolaTasse?: boolean, motiviStatoStudente?: Array<StudenteCicloMotivoStatus>, nazionalita?: string, nominativo?: string, ssdCosupervisore?: string, ssdSupervisore?: string, statiMissioneDaApprovare?: Array<MissioneStatus>, statiPeriodiDiMobilita?: Array<PeriodoDiMobilitaStudenteStatus>, statiStudente?: Array<StudenteCicloStatus>, statoPeriodoRegistroAttivita?: PeriodoRegistroAttivitaStatus, statoSpesaStudente?: SpesaStudenteStatus, statoUltimoAggiornamentoBudgetStudente?: AggiornamentoBudgetStudenteStatus, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


































        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (afferenzaOrganizzativaCosupervisore !== undefined) {
            formParams = formParams.append('afferenzaOrganizzativaCosupervisore', <any>afferenzaOrganizzativaCosupervisore) as any || formParams;
        }
        if (afferenzaOrganizzativaSupervisore !== undefined) {
            formParams = formParams.append('afferenzaOrganizzativaSupervisore', <any>afferenzaOrganizzativaSupervisore) as any || formParams;
        }
        if (ateneoProvenienza !== undefined) {
            formParams = formParams.append('ateneoProvenienza', <any>ateneoProvenienza) as any || formParams;
        }
        if (codiceFiscale !== undefined) {
            formParams = formParams.append('codiceFiscale', <any>codiceFiscale) as any || formParams;
        }
        if (codiceFiscaleCosupervisore !== undefined) {
            formParams = formParams.append('codiceFiscaleCosupervisore', <any>codiceFiscaleCosupervisore) as any || formParams;
        }
        if (codiceFiscaleDocenteCorso !== undefined) {
            formParams = formParams.append('codiceFiscaleDocenteCorso', <any>codiceFiscaleDocenteCorso) as any || formParams;
        }
        if (codiceFiscaleRelatore !== undefined) {
            formParams = formParams.append('codiceFiscaleRelatore', <any>codiceFiscaleRelatore) as any || formParams;
        }
        if (codiceFiscaleSupervisore !== undefined) {
            formParams = formParams.append('codiceFiscaleSupervisore', <any>codiceFiscaleSupervisore) as any || formParams;
        }
        if (codiceTipoBorsaPerCiclo !== undefined) {
            formParams = formParams.append('codiceTipoBorsaPerCiclo', <any>codiceTipoBorsaPerCiclo) as any || formParams;
        }
        if (corsoSuperato !== undefined) {
            formParams = formParams.append('corsoSuperato', <any>corsoSuperato) as any || formParams;
        }
        if (hasAutorizzazioneMobilita !== undefined) {
            formParams = formParams.append('hasAutorizzazioneMobilita', <any>hasAutorizzazioneMobilita) as any || formParams;
        }
        if (hasDeliberaDiProgrammazioneMobilita !== undefined) {
            formParams = formParams.append('hasDeliberaDiProgrammazioneMobilita', <any>hasDeliberaDiProgrammazioneMobilita) as any || formParams;
        }
        if (hasDeliberaInizioAttivita !== undefined) {
            formParams = formParams.append('hasDeliberaInizioAttivita', <any>hasDeliberaInizioAttivita) as any || formParams;
        }
        if (hasNotCosupervisore !== undefined) {
            formParams = formParams.append('hasNotCosupervisore', <any>hasNotCosupervisore) as any || formParams;
        }
        if (hasNotSupervisore !== undefined) {
            formParams = formParams.append('hasNotSupervisore', <any>hasNotSupervisore) as any || formParams;
        }
        if (hasNumGiorniMobilitaInferioreAlMinimoPrevisto !== undefined) {
            formParams = formParams.append('hasNumGiorniMobilitaInferioreAlMinimoPrevisto', <any>hasNumGiorniMobilitaInferioreAlMinimoPrevisto) as any || formParams;
        }
        if (hasNumGiorniMobilitaSuperioreAlPrevisto !== undefined) {
            formParams = formParams.append('hasNumGiorniMobilitaSuperioreAlPrevisto', <any>hasNumGiorniMobilitaSuperioreAlPrevisto) as any || formParams;
        }
        if (idCorsoInOffertaFormativa !== undefined) {
            formParams = formParams.append('idCorsoInOffertaFormativa', <any>idCorsoInOffertaFormativa) as any || formParams;
        }
        if (isInRegolaTasse !== undefined) {
            formParams = formParams.append('isInRegolaTasse', <any>isInRegolaTasse) as any || formParams;
        }
        if (motiviStatoStudente) {
            motiviStatoStudente.forEach((element) => {
                formParams = formParams.append('motiviStatoStudente', <any>element) as any || formParams;
            })
        }
        if (nazionalita !== undefined) {
            formParams = formParams.append('nazionalita', <any>nazionalita) as any || formParams;
        }
        if (nominativo !== undefined) {
            formParams = formParams.append('nominativo', <any>nominativo) as any || formParams;
        }
        if (ssdCosupervisore !== undefined) {
            formParams = formParams.append('ssdCosupervisore', <any>ssdCosupervisore) as any || formParams;
        }
        if (ssdSupervisore !== undefined) {
            formParams = formParams.append('ssdSupervisore', <any>ssdSupervisore) as any || formParams;
        }
        if (statiMissioneDaApprovare) {
            statiMissioneDaApprovare.forEach((element) => {
                formParams = formParams.append('statiMissioneDaApprovare', <any>element) as any || formParams;
            })
        }
        if (statiPeriodiDiMobilita) {
            statiPeriodiDiMobilita.forEach((element) => {
                formParams = formParams.append('statiPeriodiDiMobilita', <any>element) as any || formParams;
            })
        }
        if (statiStudente) {
            statiStudente.forEach((element) => {
                formParams = formParams.append('statiStudente', <any>element) as any || formParams;
            })
        }
        if (statoPeriodoRegistroAttivita !== undefined) {
            formParams = formParams.append('statoPeriodoRegistroAttivita', <any>statoPeriodoRegistroAttivita) as any || formParams;
        }
        if (statoSpesaStudente !== undefined) {
            formParams = formParams.append('statoSpesaStudente', <any>statoSpesaStudente) as any || formParams;
        }
        if (statoUltimoAggiornamentoBudgetStudente !== undefined) {
            formParams = formParams.append('statoUltimoAggiornamentoBudgetStudente', <any>statoUltimoAggiornamentoBudgetStudente) as any || formParams;
        }

        return this.httpClient.request<PageStudenteCicloIdAndUtenteInfoView>('post',`${this.basePath}/api/v1/studente-ciclo/view/search`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna il dettaglio borsa di uno studente.
     * 
     * @param id_utente id dell&#x27;utente
     * @param dettaglioBorsaStudente 
     * @param multipartFile1 
     * @param multipartFile10 
     * @param multipartFile2 
     * @param multipartFile3 
     * @param multipartFile4 
     * @param multipartFile5 
     * @param multipartFile6 
     * @param multipartFile7 
     * @param multipartFile8 
     * @param multipartFile9 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDettaglioBorsaStudenteForm(id_utente: string, dettaglioBorsaStudente?: DettaglioBorsaStudenteUpdateDTO, multipartFile1?: Blob, multipartFile10?: Blob, multipartFile2?: Blob, multipartFile3?: Blob, multipartFile4?: Blob, multipartFile5?: Blob, multipartFile6?: Blob, multipartFile7?: Blob, multipartFile8?: Blob, multipartFile9?: Blob, observe?: 'body', reportProgress?: boolean): Observable<DettaglioBorsaStudente>;
    public updateDettaglioBorsaStudenteForm(id_utente: string, dettaglioBorsaStudente?: DettaglioBorsaStudenteUpdateDTO, multipartFile1?: Blob, multipartFile10?: Blob, multipartFile2?: Blob, multipartFile3?: Blob, multipartFile4?: Blob, multipartFile5?: Blob, multipartFile6?: Blob, multipartFile7?: Blob, multipartFile8?: Blob, multipartFile9?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DettaglioBorsaStudente>>;
    public updateDettaglioBorsaStudenteForm(id_utente: string, dettaglioBorsaStudente?: DettaglioBorsaStudenteUpdateDTO, multipartFile1?: Blob, multipartFile10?: Blob, multipartFile2?: Blob, multipartFile3?: Blob, multipartFile4?: Blob, multipartFile5?: Blob, multipartFile6?: Blob, multipartFile7?: Blob, multipartFile8?: Blob, multipartFile9?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DettaglioBorsaStudente>>;
    public updateDettaglioBorsaStudenteForm(id_utente: string, dettaglioBorsaStudente?: DettaglioBorsaStudenteUpdateDTO, multipartFile1?: Blob, multipartFile10?: Blob, multipartFile2?: Blob, multipartFile3?: Blob, multipartFile4?: Blob, multipartFile5?: Blob, multipartFile6?: Blob, multipartFile7?: Blob, multipartFile8?: Blob, multipartFile9?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling updateDettaglioBorsaStudente.');
        }












        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (dettaglioBorsaStudente !== undefined) {
            formParams = formParams.append('dettaglioBorsaStudente', new Blob([JSON.stringify(dettaglioBorsaStudente)], { type: 'application/json'})) as any || formParams;
        }
        if (multipartFile1 !== undefined) {
            formParams = formParams.append('multipartFile1', <any>multipartFile1) as any || formParams;
        }
        if (multipartFile10 !== undefined) {
            formParams = formParams.append('multipartFile10', <any>multipartFile10) as any || formParams;
        }
        if (multipartFile2 !== undefined) {
            formParams = formParams.append('multipartFile2', <any>multipartFile2) as any || formParams;
        }
        if (multipartFile3 !== undefined) {
            formParams = formParams.append('multipartFile3', <any>multipartFile3) as any || formParams;
        }
        if (multipartFile4 !== undefined) {
            formParams = formParams.append('multipartFile4', <any>multipartFile4) as any || formParams;
        }
        if (multipartFile5 !== undefined) {
            formParams = formParams.append('multipartFile5', <any>multipartFile5) as any || formParams;
        }
        if (multipartFile6 !== undefined) {
            formParams = formParams.append('multipartFile6', <any>multipartFile6) as any || formParams;
        }
        if (multipartFile7 !== undefined) {
            formParams = formParams.append('multipartFile7', <any>multipartFile7) as any || formParams;
        }
        if (multipartFile8 !== undefined) {
            formParams = formParams.append('multipartFile8', <any>multipartFile8) as any || formParams;
        }
        if (multipartFile9 !== undefined) {
            formParams = formParams.append('multipartFile9', <any>multipartFile9) as any || formParams;
        }

        return this.httpClient.request<DettaglioBorsaStudente>('put',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/dettaglio-borsa`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di aggiungere o rimuovere il flag di posta attenzione sul dottorando senza matricola.
     * 
     * @param body 
     * @param cf_studente_no_matricola cf dello studente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStudenteNoMatricola(body: PatchStudenteNoMatricolaDTO, cf_studente_no_matricola: string, observe?: 'body', reportProgress?: boolean): Observable<StudenteCicloNoMatricolaViewImpl>;
    public updateStudenteNoMatricola(body: PatchStudenteNoMatricolaDTO, cf_studente_no_matricola: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StudenteCicloNoMatricolaViewImpl>>;
    public updateStudenteNoMatricola(body: PatchStudenteNoMatricolaDTO, cf_studente_no_matricola: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StudenteCicloNoMatricolaViewImpl>>;
    public updateStudenteNoMatricola(body: PatchStudenteNoMatricolaDTO, cf_studente_no_matricola: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateStudenteNoMatricola.');
        }

        if (cf_studente_no_matricola === null || cf_studente_no_matricola === undefined) {
            throw new Error('Required parameter cf_studente_no_matricola was null or undefined when calling updateStudenteNoMatricola.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (cf_studente_no_matricola !== undefined && cf_studente_no_matricola !== null) {
            queryParameters = queryParameters.set('cf_studente_no_matricola', <any>cf_studente_no_matricola);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<StudenteCicloNoMatricolaViewImpl>('patch',`${this.basePath}/api/v1/studente-ciclo/no-matricola/update`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna il Supervisore di uno studente.
     * 
     * @param body 
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSupervisoreStudente(body: UpdateSupervisoreStudenteRequestDTO, id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<UpdateSupervisoreStudenteResponseDTO>;
    public updateSupervisoreStudente(body: UpdateSupervisoreStudenteRequestDTO, id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdateSupervisoreStudenteResponseDTO>>;
    public updateSupervisoreStudente(body: UpdateSupervisoreStudenteRequestDTO, id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdateSupervisoreStudenteResponseDTO>>;
    public updateSupervisoreStudente(body: UpdateSupervisoreStudenteRequestDTO, id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateSupervisoreStudente.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling updateSupervisoreStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UpdateSupervisoreStudenteResponseDTO>('put',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/supervisore`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
