import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {get} from "lodash";
import {SnackbarTypes} from "../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {FuseConfirmationService} from "../../../../../@fuse/services/confirmation";
import {TranslocoService} from "@ngneat/transloco";
import {AbstractDefaultComponent} from "../../../abstracts/abstract-default-component/abstract-default-component";
import {delay, finalize, of, takeUntil} from "rxjs";
import {ButtonType} from "../../custom-button/custom-button.component";
import {DocumentToSignI} from "../dialog-sign-document.component";
import {
    ChiaveDocumentoEnum, ChiaveFlussoEnum, ChiaveOperazioneEnum,
    DocumentoService, EsitoOperazione, FirmaDocumentiConOTPRequest, FirmaDocumentiConOTPResponse,
    RichiediCodiceOTPRequest
} from "../../../../../api-clients/generated/services";

@Component({
    selector: 'app-sign-with-otp',
    templateUrl: './sign-with-otp.component.html',
    styleUrls: ['./sign-with-otp.component.scss']
})
export class SignWithOTPComponent extends AbstractDefaultComponent implements OnInit {

    static SECONDS_UNTIL_NEW_OTP_REQUEST = 60;

    otpComponentConfig = undefined;
    otp: string;
    otpSent: boolean;
    showResendOtp: boolean;
    showResendOtpInterval: NodeJS.Timeout;
    signLoading = false;
    requireSignLoading = false;
    errors: string[];
    esiti: FirmaDocumentiConOTPResponse;
    remainingSecondsToNewOtpRequest: number;

    @Input() documentsData: DocumentToSignI[];
    @Input() loading: boolean;
    @Output() loadingChange = new EventEmitter<boolean>();
    @Output() hideChooseMethod = new EventEmitter<boolean>();
    @Output() documentsSignedSuccessfully = new EventEmitter<{closeDialog: boolean, errorsForAllDocuments: boolean}>();
    @Input() chiaveDocumento: ChiaveDocumentoEnum;
    @Input() chiaveOperazione: ChiaveOperazioneEnum;
    @Input() chiaveFlusso: ChiaveFlussoEnum;

    constructor(
        private fuseConfirmationService: FuseConfirmationService,
        private translocoService: TranslocoService,
        private documentoService: DocumentoService
    ) {
        super();
        this.otpComponentConfig = {
            allowNumbersOnly: true,
            length: 6,
            isPasswordInput: false,
            disableAutoFocus: false,
            placeholder: '',
            inputStyles: {
                'width': '50px',
                'height': '50px',
            },
        };
    }

    ngOnInit(): void {
    }

    protected readonly undefined = undefined;

    onOtpChange(otp) {
        this.otp = otp;
    }

    sign() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (this.otp?.length === this.otpComponentConfig?.length) {
            this.showResendOtp = false;
            clearTimeout(this.showResendOtpInterval);
            this.signLoading = true;
            this.loadingChange.emit(this.signLoading);
            const requestBody: FirmaDocumentiConOTPRequest = {
                chiaveDocumento: this.chiaveDocumento,
                chiaveFlusso: this.chiaveFlusso,
                chiaveOperazione: this.chiaveOperazione,
                userIdAndDocumentoInfoIdDTOList: this.documentsData?.map(docData => ({
                    documentoInfoId: docData?.documentoInfo?.id,
                    userId: docData.userId,
                })),
                codiceOTP: this.otp
            }
            this.documentoService.firmaDocumentiConOPT(requestBody).pipe(
                finalize(() => {
                    this.signLoading = false;
                    this.loadingChange.emit(this.signLoading);
                }),
                takeUntil(this.destroy$)
            ).subscribe({
                next: (esiti) => {
                    this.esiti = esiti;
                    this.errors = esiti.esiti_operazioni
                        ?.filter(esito => esito.esito === EsitoOperazione.ERROR)
                        ?.map(esito =>
                            `${this.documentsData.find(doc => doc.documentoInfo?.id === esito.user_id_and_documento_info_id_DTO?.documentoInfoId)?.formattedDocName} - ${esito.causa_errore}`);
                    if(this.errors?.length == 0){
                        this.fuseConfirmationService.openSnackBar({
                            message: get(translation, 'document_sign.document_signed_successfully', null),
                            type: SnackbarTypes.Success,
                        });
                    }
                    this.documentsSignedSuccessfully.emit({
                        closeDialog: this.errors?.length == 0,
                        errorsForAllDocuments: this?.errors?.length == esiti?.esiti_operazioni?.length
                    });
                    this.hideChooseMethod.emit(this?.errors?.length != esiti?.esiti_operazioni?.length);
                },
                error: err => {
                    this.hideChooseMethod.emit(false);
                    this.startRequestNewOtpCountdown();
                    this.fuseConfirmationService.openSnackBar({
                        message: `${get(translation, 'document_sign.error_during_sign', null)}: ${err.error.message}`,
                        error: err, type:
                        SnackbarTypes.Error,
                    });
                },
            });
        }

    }

    protected readonly ButtonType = ButtonType;

    requireSign() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        clearTimeout(this.showResendOtpInterval);
        this.requireSignLoading = true;
        this.loadingChange.emit(this.requireSignLoading);
        const requestBody: RichiediCodiceOTPRequest = {
            chiaveDocumento: this.chiaveDocumento,
            chiaveFlusso: this.chiaveFlusso,
            chiaveOperazione: this.chiaveOperazione,
            userIdAndDocumentoInfoIdDTOList: this.documentsData?.map(docData => ({
                documentoInfoId: docData?.documentoInfo?.id,
                userId: docData.userId,
            }))
        }
        this.documentoService.richiediCodiceOPT(requestBody).pipe(
            finalize(() => {
                this.requireSignLoading = false;
                this.loadingChange.emit(this.requireSignLoading);
            }),
            takeUntil(this.destroy$)
        ).subscribe({
            next: () => {
                this.showResendOtp = false;
                this.startRequestNewOtpCountdown();
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'document_sign.otp_sent_successfully', null),
                    type: SnackbarTypes.Success,
                });
                this.otpSent = true;
            },
            error: err => {
                this.fuseConfirmationService.openSnackBar({
                    message: `${get(translation, 'document_sign.error_during_otp_send', null)}: ${err.error.message}`,
                    error: err,
                    type: SnackbarTypes.Error,
                });
            },
        });
    }


    private startRequestNewOtpCountdown() {
        this.remainingSecondsToNewOtpRequest = SignWithOTPComponent.SECONDS_UNTIL_NEW_OTP_REQUEST;
        this.showResendOtpInterval = setInterval(() => {
            if (this.remainingSecondsToNewOtpRequest == 1) {
                this.showResendOtp = true;
                clearTimeout(this.showResendOtpInterval)
            } else {
                this.remainingSecondsToNewOtpRequest -= 1;
            }
        }, 1000);
    }
}
