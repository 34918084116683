<ng-container *transloco="let t ">
    <mat-tab-group appScrollRetainer
                   [(selectedIndex)]="mobilitaFilterManagerService.selectedIndex"
                   (selectedIndexChange)="onTabChanged($event)">

        <mat-tab
            *ngFor="let periodType of tabs"
            [label]="t(periodType?.tabLabel) | titlecase"
            [aria-label]="periodType?.tabAriaLabel"
            class="overflow-x-hidden">

            <ng-template matTabContent>
                <div class="flex flex-col w-full h-full overflow-auto px-2 gap-0">
                <app-loading-screen *ngIf="loading; else tmp" class="w-full h-full"
                                    style="min-height: 40vh"></app-loading-screen>
                <ng-template #tmp>
                    <div class="flex flex-col w-full h-full overflow-auto px-2 gap-0">

                        <div class="flex flex-col md:flex-row gap-4 justify-between w-full items-center"
                             *ngIf="!!periodType.tableConfiguration">

                            <div class="flex flex-col md:flex-row gap-4 justify-start md:w-1/2 items-center ">

                                <button
                                    mat-flat-button
                                    *ngIf="currentProfile === AuthorityType.COORDINATORE && selectedTabTipo === TipoMobilitaDashboardTabRequest.AUTORIZZAZIONI"
                                    (click)=" setOperazioneMassiva.emit()"
                                    [color]="'primary'">
                                    <mat-icon class="mr-2">{{ 'check_circle' }}</mat-icon>
                                    <span>{{ t('common.authorizes_phd_students') }}</span>
                                </button>

                                <button
                                    [matMenuTriggerFor]="menu"
                                    mat-flat-button
                                    *ngIf="(currentProfile === AuthorityType.COORDINATORE ||
                                        currentProfile === AuthorityType.SUPERVISORE ||
                                        currentProfile === AuthorityType.COSUPERVISORE) &&
                                    (selectedTabTipo === TipoMobilitaDashboardTabRequest.SOGGIORNOESTERO || selectedTabTipo === TipoMobilitaDashboardTabRequest.RICERCA)"
                                    [color]="'primary'">
                                    <mat-icon class="mr-2">{{ 'check_circle' }}</mat-icon>
                                    <span>{{ t( 'common.approval_mode_text' ) }}</span>
                                </button>

<!--
                                <button
                                    mat-flat-button
                                    *ngIf="currentProfile === AuthorityType.COORDINATORE && selectedTabTipo === TipoMobilitaDashboardTabRequest.SOGGIORNOESTERO"
                                    (click)=" setOperazioneMassiva.emit({validation: true, conclusion: false})"
                                    [color]="'primary'">
                                    <mat-icon class="mr-2">{{ 'check_circle' }}</mat-icon>
                                    <span>{{ t('common.approval_mode_validation' ) }}</span>
                                </button>

                                <button
                                    mat-flat-button
                                    *ngIf="currentProfile === AuthorityType.COORDINATORE && selectedTabTipo === TipoMobilitaDashboardTabRequest.SOGGIORNOESTERO"
                                    (click)=" setOperazioneMassiva.emit({validation: false, conclusion: true})"
                                    [color]="'primary'">
                                    <mat-icon class="mr-2">{{ 'check_circle' }}</mat-icon>
                                    <span>{{ t('common.conclusion_mode_validation' ) }}</span>
                                </button>-->

                                <mat-menu #menu="matMenu" class="custom-mat-menu">
                                    <button mat-menu-item (click)=" setOperazioneMassiva.emit({validation: false, conclusion: false})">
                                        <mat-icon class="mr-2">{{ 'check_circle' }}</mat-icon>
                                        <span>{{ t('common.approval_periods_mode_text' ) }}</span>
                                    </button>
                                    <button mat-menu-item
                                            (click)="setOperazioneMassiva.emit({validation: true, conclusion: false})">
                                        <mat-icon class="mr-2">{{ 'check_circle' }}</mat-icon>
                                        <span>{{ t('common.approval_mode_validation' ) }}</span>
                                    </button>
                                    <button mat-menu-item
                                            (click)="setOperazioneMassiva.emit({validation: false, conclusion: true})">
                                        <mat-icon class="mr-2">{{ 'check_circle' }}</mat-icon>
                                        <span>{{ t('common.conclusion_mode_validation' ) }}</span>
                                    </button>
                                </mat-menu>

                            </div>

                            <div class="flex flex-col md:flex-row gap-4 justify-end md:w-1/2 items-center">
                                <button mat-flat-button
                                        class="max-w-60"
                                        color="primary"
                                        [disabled]="showMiniLoader"
                                        (click)="getDataForFilters()">
                                    <mat-spinner *ngIf="showMiniLoader" [diameter]="20" color="accent"
                                                 class="mr-2"></mat-spinner>
                                    <mat-icon class="mr-2" *ngIf="!showMiniLoader">manage_search
                                    </mat-icon>
                                    {{ t('menu_filter.advanced_search') }}
                                </button>

                                <button mat-flat-button
                                        class="multi-line-button"
                                        type="submit"
                                        *ngIf="periodType.tipologiaPeriodi !== TipoMobilitaDashboardTabRequest.AUTORIZZAZIONI"
                                        (click)="openExportExcelDialog()"
                                        [color]="'primary'">
                                    <mat-icon class="mr-2">download</mat-icon>
                                    <span>{{ t('mobility.export_excel_dialog') }}</span>
                                </button>
                            </div>

                            <!--<button mat-flat-button
                                                                    class="max-w-60"
                                                                    type="submit"
                                                                    (click)="openExportPDFDialog()"
                                                                    [color]="'primary'">
                                                                <mat-icon class="mr-2">download</mat-icon>
                                                                <span>{{t('mobility.export_pdf')}}</span>
                                                            </button>-->
                        </div>

                        <div class="flex flex-col flex-auto w-full h-full overflow-auto p-2 gap-4"
                             *ngIf="!!periodType.tableConfiguration">

                            <div class="flex flex-row justify-between w-full">

                                <div class="mt-0.5 px-2 py-4 flex flex-row gap-4"
                                     *ngIf="currentFilterListChipLabels.get(periodType.tipologiaPeriodi)?.length > 0">
                                    <!-- TESTO FILTRI APPLICATI -->
                                    <div
                                        class=" text-2xl font-semibold mt-[0.35rem]">{{ t('students_list.applied_filters') }}
                                    </div>
                                    <!--RESET BUTTON -->
                                    <button mat-flat-button
                                            (click)="reset(periodType.tipologiaPeriodi)"
                                            [color]="'accent'">
                                        <mat-icon class="mr-2">delete</mat-icon>
                                        <span>{{ t('menu_filter.reset') }}</span>
                                    </button>
                                </div>


                            </div>

                            <div class="w-full flex flex-row justify-center items-center"
                                 *ngIf="currentFilterListChipLabels.get(periodType.tipologiaPeriodi)?.length > 0">
                                <!-- CHIPS -->
                                <div class="w-full flex items-center">

                                    <div class="flex flex-col gap-1.5">

                                        <mat-chip-list class="w-full">
                                            <div class="w-full flex flex-row gap-1.5 p-2 flex-wrap">
                                                <ng-container
                                                    *ngFor="let chip of currentFilterListChipLabels.get(periodType.tipologiaPeriodi)">
                                                    <mat-chip color="primary"
                                                              class="justify-between height-unset">
                                                        {{ chip | titlecase | removeUnderscores }}
                                                    </mat-chip>
                                                </ng-container>
                                            </div>
                                        </mat-chip-list>
                                    </div>
                                </div>


                            </div>

                            <div class="w-full p-y-2.5" *ngIf="periodType.tableConfiguration">
                                <app-generic-table *ngIf="!!periodType.tableConfiguration"
                                                   [configuration]="periodType.tableConfiguration"
                                                   (clickAction)="tableClickAction($event)"
                                                   (pageAction)="pageAction($event)">
                                </app-generic-table>
                            </div>
                        </div>

                    </div>
                </ng-template>
                </div>

            </ng-template>

        </mat-tab>

    </mat-tab-group>

</ng-container>
