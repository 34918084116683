import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogSignDocumentComponent} from "./dialog-sign-document.component";
import {SignWithOTPComponent} from "./sign-with-otp/sign-with-otp.component";
import {DialogWrapModule} from "../../../layout/common/dialog-wrap/dialog-wrap.module";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "../../shared.module";
import {TranslocoModule} from "@ngneat/transloco";
import {CustomButtonModule} from "../custom-button/custom-button.module";
import {NgOtpInputModule} from "ng-otp-input";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatInputModule} from "@angular/material/input";
import {SignWithFileUploadComponent} from "./sign-with-file-upload/sign-with-file-upload.component";
import {FormModule} from "../../../layout/common/generic-components/form/form.module";
import {InputFileModule} from "../../../layout/common/generic-components/input-file/input-file.module";
import {DocumentModule} from "../document/document.module";


@NgModule({
    declarations: [
        DialogSignDocumentComponent,
        SignWithOTPComponent,
        SignWithFileUploadComponent
    ],
    imports: [
        CommonModule,
        DialogWrapModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatOptionModule,
        MatSelectModule,
        ReactiveFormsModule,
        SharedModule,
        TranslocoModule,
        CustomButtonModule,
        NgOtpInputModule,
        MatProgressSpinnerModule,
        MatInputModule,
        InputFileModule,
    ],
    exports: [
        DialogSignDocumentComponent,
        SignWithOTPComponent,
        SignWithFileUploadComponent
    ]
})
export class SignDocumentModule {
}
