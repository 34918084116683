<ng-container *transloco="let t">


    <div class="flex flex-col w-full min-h-[20rem] items-center justify-center"
         *ngIf="loading; else stepTemplate">
        <app-loading-screen class="w-full h-full">
        </app-loading-screen>
    </div>


    <ng-template #stepTemplate>

        <div class="mt-4 mb-6">
            <fuse-alert class="w-full"
                        [showIcon]="true"
                        [appearance]="'outline'"
            >{{t(documentsFormConfig.length > 0 ?
                'massive_operations.autorizzazione_mobilita.hint_compile_info' : 'massive_operations.autorizzazione_mobilita.hint_compile_no_info')}}</fuse-alert>
        </div>

        <div class="w-full flex flex-col items-center">

<!--            &lt;!&ndash; DELIBERA &ndash;&gt;-->
<!--            <div class="w-full flex flex-col md:flex-row gap-3 items-start my-2" *ngIf="currentProfile === AuthorityType.COORDINATORE">-->

<!--                <form [formGroup]="form" class="w-full">-->

<!--                    <mat-form-field appearance="outline"-->
<!--                                    class="w-full add-mobilita-mat-form-field"-->
<!--                                    *ngIf="form?.get('deliberaCollegio') as fieldCtrl">-->
<!--                        <mat-label><strong>{{t('mobility.collegio_approval')}}</strong></mat-label>-->
<!--                        <mat-select formControlName='deliberaCollegio'-->
<!--                                    [disabled]="!delibere">-->
<!--                            <mat-option [value]="undefined">-</mat-option>-->
<!--                            <mat-option *ngFor="let delibera of delibere" [value]="delibera.id">-->
<!--                                {{t('mobility.delibera_name')}} {{delibera.numero}} - {{formatDeliberaDate(delibera?.data)}}-->
<!--                            </mat-option>-->
<!--                        </mat-select>-->
<!--                        <mat-error *ngIf="fieldCtrl.hasError('required')">-->
<!--                            {{t('form.required')}}-->
<!--                        </mat-error>-->

<!--                    </mat-form-field>-->

<!--                </form>-->

<!--                <button [color]="'primary'"-->
<!--                        mat-raised-button-->
<!--                        type="button"-->
<!--                        class="add-delibera-button"-->
<!--                        [disabled]="loading"-->
<!--                        (click)="this.openAddNewDeliberaDialog()">-->
<!--                    <mat-icon class="mr-2">add</mat-icon>-->
<!--                    {{t('common.add_delibera')}}-->
<!--                </button>-->

<!--            </div>-->

            <app-form class="w-full"
                      [formGroupConfig]="documentsFormConfig"
                      [valueForm]="documentsFormValue"
            ></app-form>

<!--            <div class="flex flex-col gap-4 mt-6 w-full mb-2" *ngIf="periodiDetailsFormArray?.length > 0">-->
<!--                <ng-container *ngFor="let period of studentiSelected; let i = index" class="w-full">-->

<!--                    <div class="flex flex-col gap-3 bg-gray-100 rounded-xl px-4 pb-4 pt-2">-->
<!--                        <div class="font-semibold text-lg mt-3">-->
<!--                            {{period?.codiceMobilita}}-->
<!--                            ({{period?.studenteCiclo?.utente?.cognome + ' ' + period?.studenteCiclo?.utente?.nome}})</div>-->
<!--                        <div class="w-full" *ngIf="periodiDetailsFormArray.at(i) as form">-->

<!--                            <div class="flex flex-col pt-2 pb-1 gap-2 w-full" [formGroup]="form">-->

<!--                                &lt;!&ndash; DATA INIZIO &ndash;&gt;-->
<!--                                <mat-form-field class="w-full mat-button-datapicker add-mobilita-mat-form-field" appearance="outline" *ngIf="form?.get('dataInizio') as fieldCtrl">-->
<!--                                    <mat-label>{{t('mobility.start_date')}}</mat-label>-->
<!--                                    <input matInput-->
<!--                                           [placeholder]="t('mobility.start_date')"-->
<!--                                           required-->
<!--                                           readonly-->
<!--                                           [max]="form?.get('dataFine')?.value"-->
<!--                                           formControlName='dataInizio'-->
<!--                                           [matDatepicker]="picker1"-->
<!--                                    >-->
<!--                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>-->
<!--                                    <mat-datepicker #picker1></mat-datepicker>-->
<!--                                    <mat-error *ngIf="fieldCtrl.hasError('required')">-->
<!--                                        {{t('form.required')}}-->
<!--                                    </mat-error>-->
<!--                                    <mat-error *ngIf="fieldCtrl.hasError('matDatepickerFilter')">-->
<!--                                        {{t('form.retribution_period_error')}}-->
<!--                                    </mat-error>-->
<!--                                </mat-form-field>-->

<!--                                &lt;!&ndash; DATA FINE &ndash;&gt;-->
<!--                                <mat-form-field class="w-full mat-button-datapicker add-mobilita-mat-form-field" appearance="outline" *ngIf="form?.get('dataFine') as fieldCtrl">-->
<!--                                    <mat-label>{{t('mobility.end_date')}}</mat-label>-->
<!--                                    <input matInput-->
<!--                                           [placeholder]="t('mobility.end_date')"-->
<!--                                           readonly-->
<!--                                           [min]="form?.get('dataInizio')?.value"-->
<!--                                           formControlName='dataFine'-->
<!--                                           [matDatepicker]="picker1"-->
<!--                                    >-->
<!--                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>-->
<!--                                    <mat-icon matSuffix class="cursor-pointer pr-1"-->
<!--                                              (click)="clearDateField(fieldCtrl)"-->
<!--                                              *ngIf="fieldCtrl.value !== '' && !!fieldCtrl.value"-->
<!--                                              color="warn"-->
<!--                                              svgIcon="mat_outline:cancel"></mat-icon>-->
<!--                                    <mat-datepicker #picker1></mat-datepicker>-->
<!--                                    <mat-error *ngIf="fieldCtrl.hasError('required')">-->
<!--                                        {{t('form.required')}}-->
<!--                                    </mat-error>-->
<!--                                    <mat-error *ngIf="fieldCtrl.hasError('dataAMaggioreDiDataA')">-->
<!--                                        {{t('form.error_data_range')}}-->
<!--                                    </mat-error>-->
<!--                                    <mat-error *ngIf="fieldCtrl.hasError('LessThanToday')">-->
<!--                                        {{t('form.date_not_less_than_today')}}-->
<!--                                    </mat-error>-->
<!--                                    <mat-error *ngIf="fieldCtrl.hasError('matDatepickerFilter')">-->
<!--                                        {{t('form.retribution_period_error')}}-->
<!--                                    </mat-error>-->
<!--                                </mat-form-field>-->

<!--                                &lt;!&ndash; REFERENTE ESTERO &ndash;&gt;-->
<!--                                <div class="flex flex-col gap-1 items-center mb-2">-->

<!--                                    <mat-label class="text-base font-semibold">{{t('mobility.foreign_teacher')}}</mat-label>-->

<!--                                    <div class="flex flex-col w-full mobilita-outlined-field-darker gap-2 pt-4 px-4" formGroupName="referenteEstero"-->
<!--                                         *ngIf="form?.get('referenteEstero') as fieldGroup">-->

<!--                                        <mat-form-field appearance="outline" class="w-full add-mobilita-mat-form-field"-->
<!--                                                        *ngIf="fieldGroup.get('nome') as fieldCtrl">-->
<!--                                            <mat-label><strong>{{t('common.name')}}</strong></mat-label>-->
<!--                                            <input matInput-->
<!--                                                   [formControlName]="'nome'">-->
<!--                                            <mat-error  *ngIf="fieldCtrl.hasError('required') && (form?.dirty || form?.touched)" class="pb-2">-->
<!--                                                {{t('form.required')}}-->
<!--                                            </mat-error>-->
<!--                                            <mat-error *ngIf="fieldCtrl.hasError('whitespace') && (form?.dirty || form?.touched)" class="pb-2">-->
<!--                                                {{t('form.whitespace')}}-->
<!--                                            </mat-error>-->
<!--                                        </mat-form-field>-->

<!--                                        <mat-form-field appearance="outline" class="w-full add-mobilita-mat-form-field"-->
<!--                                                        *ngIf="fieldGroup.get('cognome') as fieldCtrl">-->
<!--                                            <mat-label><strong>{{t('common.surname')}}</strong></mat-label>-->
<!--                                            <input matInput-->
<!--                                                   [formControlName]="'cognome'">-->
<!--                                            <mat-error  *ngIf="fieldCtrl.hasError('required') && (form?.dirty || form?.touched)" class="pb-2">-->
<!--                                                {{t('form.required')}}-->
<!--                                            </mat-error>-->
<!--                                            <mat-error *ngIf="fieldCtrl.hasError('whitespace') && (form?.dirty || form?.touched)" class="pb-2">-->
<!--                                                {{t('form.whitespace')}}-->
<!--                                            </mat-error>-->
<!--                                        </mat-form-field>-->

<!--                                        <mat-form-field appearance="outline" class="w-full add-mobilita-mat-form-field"-->
<!--                                                        *ngIf="fieldGroup.get('email') as fieldCtrl">-->
<!--                                            <mat-label><strong>{{t('common.email')}}</strong></mat-label>-->
<!--                                            <input matInput-->
<!--                                                   [formControlName]="'email'">-->
<!--                                            <mat-error  *ngIf="fieldCtrl.hasError('required') && (form?.dirty || form?.touched)" class="pb-2">-->
<!--                                                {{t('form.required')}}-->
<!--                                            </mat-error>-->
<!--                                            <mat-error *ngIf="fieldCtrl.hasError('whitespace') && (form?.dirty || form?.touched)" class="pb-2">-->
<!--                                                {{t('form.whitespace')}}-->
<!--                                            </mat-error>-->
<!--                                            <mat-error *ngIf="fieldCtrl.hasError('pattern')">-->
<!--                                                {{t('form.email')}}-->
<!--                                            </mat-error>-->
<!--                                        </mat-form-field>-->

<!--                                    </div>-->

<!--                                </div>-->

<!--                                &lt;!&ndash; TIPOLOGIA MOBILITA &ndash;&gt;-->
<!--                                <mat-form-field appearance="outline" class="add-mobilita-mat-form-field"-->
<!--                                                *ngIf="form?.get('tipologiaMobilita') as fieldCtrl">-->
<!--                                    <mat-label><strong>{{t('mobility.mobility_type')}}</strong></mat-label>-->
<!--                                    <mat-select formControlName='tipologiaMobilita'-->
<!--                                                required>-->
<!--                                        <mat-option *ngFor="let tipologiaMobilita of formSelectValues?.tipologieMobilita" [value]="tipologiaMobilita?.codice">-->
<!--                                            {{tipologiaMobilita?.descrizione | removeUnderscores | uppercase}}-->
<!--                                        </mat-option>-->
<!--                                    </mat-select>-->
<!--                                    <mat-error *ngIf="fieldCtrl.hasError('required')">-->
<!--                                        {{t('form.required')}}-->
<!--                                    </mat-error>-->

<!--                                </mat-form-field>-->

<!--                                &lt;!&ndash;TIPO INTERVENTO SUPPORTO&ndash;&gt;-->
<!--                                <div *ngIf="period?.tipoPeriodoEnum === TipoPeriodoEnum.RICERCA"-->
<!--                                     class="w-full">-->
<!--                                    <mat-form-field appearance="outline" class="add-mobilita-mat-form-field mb-1 w-full"-->
<!--                                                    *ngIf="form?.get('tipoInterventoSupporto') as fieldCtrl">-->
<!--                                        <mat-label><strong>{{t('mobility.support_tipology')}}</strong></mat-label>-->
<!--                                        <mat-select formControlName='tipoInterventoSupporto'-->
<!--                                                    required>-->
<!--                                            <mat-option>{{t('mobility.no_support')}}</mat-option>-->
<!--                                            <mat-option *ngFor="let tipoInterventoSupporto of formSelectValues?.tipiInterventoSupporto" [value]="tipoInterventoSupporto?.codice">-->
<!--                                                {{tipoInterventoSupporto?.descrizione | removeUnderscores | uppercase}}-->
<!--                                            </mat-option>-->
<!--                                        </mat-select>-->
<!--                                    </mat-form-field>-->
<!--                                </div>-->

<!--                                &lt;!&ndash; ERASMUS &ndash;&gt;-->
<!--                                <div *ngIf="period?.tipoPeriodoEnum === TipoPeriodoEnum.SOGGIORNOESTERO || form?.get('strutturaEsteraOspitante')?.get('nazionalita')?.value !== 'IT'"-->
<!--                                     class="w-full">-->

<!--                                    <div class="flex flex-row items-center mb-2 mobilita-outlined-field" *ngIf="form?.get('erasmus') as fieldCtrl">-->
<!--                                        <mat-checkbox formControlName='erasmus'-->
<!--                                                      color="primary">-->
<!--                                        </mat-checkbox>-->
<!--                                        <mat-label class="mt-0.5">{{t('mobility.erasmus')}}</mat-label>-->
<!--                                    </div>-->

<!--                                </div>-->

<!--                                &lt;!&ndash; DOCTOR EUROPEAUS &ndash;&gt;-->
<!--                                <div class="flex flex-col w-full mobilita-outlined-field gap-4 mb-2" formGroupName="doctorEuropeaus" *ngIf="form?.get('doctorEuropeaus') as fieldGroup">-->

<!--                                    <div class="flex flex-row items-center my-0" *ngIf="fieldGroup.get('doctorEuropeausFlag') as fieldCtrl">-->
<!--                                        <mat-checkbox formControlName='doctorEuropeausFlag'-->
<!--                                                      color="primary">-->
<!--                                        </mat-checkbox>-->
<!--                                        <mat-label class="mt-0.5">{{t('mobility.doctor_europeaus')}}</mat-label>-->
<!--                                    </div>-->

<!--                                    <ng-container *ngIf="!!fieldGroup.get('doctorEuropeausFlag')?.value">-->

<!--                                        <mat-form-field appearance="outline" class="w-full add-mobilita-mat-form-field"-->
<!--                                                        *ngIf="fieldGroup.get('sedeDoctorEuropeaus') as fieldCtrl">-->
<!--                                            <mat-label><strong>{{t('mobility.place_doctor_europeaus')}}</strong></mat-label>-->
<!--                                            <input matInput-->
<!--                                                   [formControlName]="'sedeDoctorEuropeaus'">-->
<!--                                            <mat-error  *ngIf="fieldCtrl.hasError('sedeDoctorEuropeausRequired') && (form?.dirty || form?.touched)" class="pb-2">-->
<!--                                                {{t('form.required')}}-->
<!--                                            </mat-error>-->
<!--                                            <mat-error *ngIf="fieldCtrl.hasError('sedeDoctorEuropeausWhitespace') && (form?.dirty || form?.touched)" class="pb-2">-->
<!--                                                {{t('form.whitespace')}}-->
<!--                                            </mat-error>-->
<!--                                        </mat-form-field>-->

<!--                                    </ng-container>-->

<!--                                </div>-->

<!--                                &lt;!&ndash; ALERT DI PRESA VISIONE COORDINATORE &ndash;&gt;-->
<!--                                <div class="w-full flex flex-row mt-1" *ngIf="currentProfile === AuthorityType.COORDINATORE">-->
<!--                                    <fuse-alert-->
<!--                                        class="w-full"-->
<!--                                        [appearance]="'outline'"-->
<!--                                        [type]="'info'"-->
<!--                                        [showIcon]="false">-->
<!--                                        <div class="flex flex-row w-full gap-4">-->
<!--                                            <span>-->
<!--                                                {{ t('mobility.coordinator_ack') }}-->
<!--                                            </span>-->
<!--                                            <mat-checkbox color="primary" formControlName="coordinatorAck">-->
<!--                                            </mat-checkbox>-->
<!--                                        </div>-->
<!--                                        <mat-error class="w-full text-sm mt-1"-->
<!--                                                   *ngIf="form?.get('coordinatorAck')?.hasError('requiredTrue')">-->
<!--                                            {{t('form.required')}}-->
<!--                                        </mat-error>-->
<!--                                    </fuse-alert>-->
<!--                                </div>-->

<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->


<!--                </ng-container>-->

<!--            </div>-->

        </div>



    </ng-template>



</ng-container>
