<ng-container *transloco="let t">

    <div class="flex flex-col gap-6 items-center px-20">

        <ng-container *ngIf="!errors && !otpSent">

            <div class="text-center" [innerHTML]="t('document_sign.require_otp_message')"></div>

            <app-custom-button
                [disabled]="loading"
                [loading]="requireSignLoading"
                (clickHandler)="requireSign()"
                [textButton]="'document_sign.require_otp'"
                [icon]="'pin'"
                [type]="ButtonType.SIGN">
            </app-custom-button>

        </ng-container>

        <ng-container *ngIf="!errors && otpSent">

            <div [innerHTML]="t('document_sign.otp_sent_message')"></div>

            <ng-otp-input [class.pointer-events-none]="signLoading || loading"
                          (onInputChange)="onOtpChange($event)"
                          [config]="otpComponentConfig">
            </ng-otp-input>

            <app-custom-button
                [disabled]="(otp?.length !== otpComponentConfig?.length) || requireSignLoading || loading"
                [loading]="signLoading"
                (clickHandler)="sign()"
                [type]="ButtonType.SIGN">
            </app-custom-button>

            <div class="flex flex-row gap-1 items-center text-sm"
                 *ngIf="!signLoading && (!loading || requireSignLoading)">

                {{t('document_sign.otp_not_received_message')}}

                <span *ngIf="!requireSignLoading && showResendOtp"
                      class="cursor-pointer underline text-blue-700"
                      (click)="requireSign()">
                    {{t('document_sign.require_another_otp')}}
                </span>

                <span *ngIf="!requireSignLoading && !showResendOtp"
                      [innerHTML]="t('document_sign.wait_other_seconds_until_new_otp_request', {remainingSecondsToNewOtpRequest})">
                </span>

                <mat-spinner *ngIf="requireSignLoading"
                             [diameter]="20"
                             [mode]="'indeterminate'"
                             [strokeWidth]="3"
                             class="ml-2">
                </mat-spinner>

            </div>

        </ng-container>

        <ng-container *ngIf="errors">
            <div class="p-4 flex flex-col gap-2 w-full items-center">
                <div class="w-full text-center text-lg mb-2">{{t('document_sign.some_errors')}}</div>
                <div class="p-2 px-3 bg-red-100 rounded-lg text-red-800 w-full text-start text-sm mb-4"
                     *ngFor="let error of errors">
                    {{error}}
                </div>
                <app-custom-button
                    *ngIf="errors.length == esiti.esiti_operazioni?.length"
                    [disabled]="loading"
                    [loading]="requireSignLoading"
                    (clickHandler)="errors = undefined; otpSent = undefined;"
                    [textButton]="'document_sign.try_again'"
                    [icon]="'refresh'"
                    [type]="ButtonType.SIGN">
                </app-custom-button>
            </div>
        </ng-container>

    </div>



</ng-container>
