<ng-container *transloco="let t ">
    <div class="w-full flex flex-col">

        <div class="w-full flex md:flex-row flex-col items-start gap-2" *ngFor="let ctrl of form.controls; let index = index; trackBy: trackByFn">
            <!---remove-padding-form-field-->

            <ng-container *ngIf="!isDelibera && !signModes">
                <mat-form-field class="w-full" [appearance]="'outline'">
                    <mat-label>
                        {{ labelNoTranslate || t(label)}}
                    </mat-label>
                    <mat-hint *ngIf="hint">
                        {{ hint }}
                    </mat-hint>
                    <input #inputFile hidden type="file"
                           (change)="onFileSelected($event, index)" placeholder="Carica file"
                           [accept]=acceptedFileList>

                    <input matInput
                           readonly
                           hidden
                           [required]="index < (numeroMinimoDocumenti ?? 0)"
                           [formControl]="ctrl"
                    >

                    <input matInput
                           *ngIf="!ctrl?.value"
                           readonly
                           class="cursor-pointer"
                           (click)="open(inputFile)"
                    >

                    <input matInput
                           *ngIf="ctrl?.value"
                           readonly
                           class="cursor-pointer"
                           (click)="open(inputFile)"
                           [value]="(!pipe || (pipe && isFile(ctrl?.value))) ? getFormattedDocumentName(ctrl?.value) : (getFormattedDocumentName(ctrl?.value) | dynamicPipe: pipe)"
                    >

                    <button
                        mat-icon-button
                        type="button"
                        [disabled]="form.disabled"
                        (click)="open(inputFile)"
                        matSuffix>
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'mat_outline:file_upload'"></mat-icon>
                    </button>
                    <button
                        *ngIf="ctrl?.value"
                        mat-icon-button
                        type="button"
                        [disabled]="form.disabled"
                        (click)="download(index)"
                        matSuffix>
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'mat_outline:download'"></mat-icon>
                    </button>
                    <button
                        *ngIf="ctrl?.value"
                        mat-icon-button
                        type="button"
                        [disabled]="form.disabled"
                        (click)="clear(index)"
                        matSuffix>
                        <mat-icon
                            class="icon-size-5 text-warn"
                            [svgIcon]="'mat_outline:delete'"></mat-icon>
                    </button>
                    <ng-content></ng-content>

                    <mat-error *ngIf="ctrl.hasError('required')">
                        {{t('form.required')}}
                    </mat-error>

                </mat-form-field>
            </ng-container>

            <ng-container *ngIf="isDelibera">
                <div class="w-full flex flex-col md:flex-row gap-3 items-start mb-4">

                    <mat-form-field appearance="outline"
                                    class="w-full add-mobilita-mat-form-field">
                        <mat-label>
                            {{ labelNoTranslate || t(label)}}
                        </mat-label>
                        <mat-select [formControl]="ctrl"
                                    [required]="index < (numeroMinimoDocumenti ?? 0)">
                            <mat-option [value]="undefined">-</mat-option>
                            <ng-container *ngIf="delibere">
                                <mat-option *ngFor="let delibera of delibere" [value]="delibera.id">
                                    {{t('mobility.delibera_name')}} {{delibera.numero}} - {{formatDeliberaDate(delibera?.data)}}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                        <mat-error *ngIf="ctrl.hasError('required')">
                            {{t('form.required')}}
                        </mat-error>

                    </mat-form-field>

                    <button [color]="'primary'"
                            mat-raised-button
                            type="button"
                            class="add-delibera-button"
                            [disabled]="form.disabled"
                            (click)="openAddNewDeliberaDialog(ctrl)">
                        <mat-icon class="mr-2">add</mat-icon>
                        {{t('common.add_delibera')}}
                    </button>

                </div>
            </ng-container>

            <!-- DOCUMENT TO SIGN -->
            <ng-container *ngIf="signModes">

                <mat-form-field class="w-full my-2 mat-form-field-no-padding" [appearance]="'outline'">
                    <mat-label>
                        {{ labelNoTranslate || t(label)}}
                    </mat-label>
                    <mat-hint *ngIf="hint">
                        {{ hint }}
                    </mat-hint>

                    <input matInput
                           readonly
                           hidden
                           [formControl]="ctrl"
                    >

                    <input matInput
                           *ngIf="ctrl?.value"
                           readonly
                           class="cursor-pointer"
                           [value]="(!pipe || (pipe && isFile(ctrl?.value))) ? getFormattedDocumentName(ctrl?.value) : (getFormattedDocumentName(ctrl?.value) | dynamicPipe: pipe)">

                    <button
                        *ngIf="ctrl?.value"
                        mat-icon-button
                        type="button"
                        [disabled]="form.disabled"
                        (click)="download(index)"
                        matSuffix>
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'mat_outline:download'"></mat-icon>
                    </button>

                    <ng-content></ng-content>

                    <mat-error *ngIf="ctrl.hasError('required')">
                        {{t('form.required')}}
                    </mat-error>

                </mat-form-field>

                <div class="bg-gray-100 rounded-lg flex flex-col gap-1 p-2 pt-1 items-center mb-1 min-w-56 md:w-auto w-full">
                    <ng-container *ngIf="isDocumentSigned(signableDocumentsData); else documentToSignTmplt">
                        <div class="flex flex-row gap-2 p-2">
                            <mat-icon class="text-slate-700">verified</mat-icon>
                            <div class="flex flex-col min-w-36 text-start">
                                <ng-container *ngIf="signableDocumentsData?.documentoInfo?.utenteFirmatario as utenteFirmatario">
                                    <div class="text-sm text-start">
                                        {{t(getSignedWithFromKey(signableDocumentsData?.documentoInfo?.modalitaDiFirma))}}
                                    </div>
                                    <div class="font-semibold text-start">
                                        {{utenteFirmatario.cognome | uppercase}} {{utenteFirmatario.nome | uppercase}}
                                    </div>
                                </ng-container>

                                <div class="text-xs mt-0.5 text-start" *ngIf="signableDocumentsData?.documentoInfo?.dataFirma as dataFirma">
                                    {{t('document_sign.document_signed_on')}} {{dataFirma | date: 'dd/MM/yyyy HH:mm': 'UTC+2'}}
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-template #documentToSignTmplt>
                        <div class="text-sm flex flex-col w-full items-center" [class.text-red-600]="formTouched">
                            <span>{{t('document_sign.document_to_sign')}}</span>
                            <span class="text-xs font-medium">
                                ({{t(signableDocumentsData?.signRequired ? 'document_sign.mandatory' : 'document_sign.optional') | uppercase}})
                            </span>
                        </div>
                        <app-custom-button
                            [disabled]="form.disabled"
                            (clickHandler)="openSignDocumentDialog(index, ctrl, signModes)"
                            [smallMode]="true"
                            [type]="ButtonType.SIGN">
                        </app-custom-button>
                    </ng-template>
                </div>
            </ng-container>

            <div class="w-24 mt-1 bg-slate-50 flex flex-row items-center justify-start px-1 h-[55px] rounded-lg"
                *ngIf="!signModes">
                <button
                    mat-icon-button
                    [disabled]="(form.controls.length <= numeroMinimoDocumenti) || form.controls.length <= 1 || form.disabled"
                    [matTooltip]="(form.controls.length <= numeroMinimoDocumenti) ? (t('form.min_number_docs') + ' ' + numeroMinimoDocumenti) : t('form.remove_document')"
                    type="button"
                    (click)="deleteDocument(index)"
                    matSuffix>
                    <mat-icon
                        class="icon-size-5 text-warn"
                        [svgIcon]="'mat_outline:remove'"></mat-icon>
                </button>
                <button
                    *ngIf="index === (form.controls.length - 1)"
                    [disabled]="(form.controls.length >= numeroMassimoDocumenti) || form.disabled"
                    [matTooltip]="(form.controls.length >= numeroMassimoDocumenti) ? (t('form.max_number_docs') + ' ' + numeroMassimoDocumenti) : t('form.add_document_same_type')"
                    mat-icon-button
                    type="button"
                    (click)="addDocument()"
                    matSuffix>
                    <mat-icon
                        class="icon-size-6 text-blue-600"
                        [svgIcon]="'mat_outline:add'"></mat-icon>
                </button>
            </div>





        </div>



    </div>


</ng-container>
