/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddAttivitaACategoriaOffertaFormativaRequest } from '../model/addAttivitaACategoriaOffertaFormativaRequest';
import { AddCategoriaOffertaFormativaDTO } from '../model/addCategoriaOffertaFormativaDTO';
import { AddDeliberaOffertaFormativaDTO } from '../model/addDeliberaOffertaFormativaDTO';
import { AttivitaOffertaFormativaOfCurrentDocenteCorsoCountDTO } from '../model/attivitaOffertaFormativaOfCurrentDocenteCorsoCountDTO';
import { BooleanDTO } from '../model/booleanDTO';
import { ClonaOffertaFormativaResponse } from '../model/clonaOffertaFormativaResponse';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GenericResponseDTO } from '../model/genericResponseDTO';
import { OffertaFormativaInfoViewImpl } from '../model/offertaFormativaInfoViewImpl';
import { SpostaAttivitaInCategoriaOffertaFormativaRequest } from '../model/spostaAttivitaInCategoriaOffertaFormativaRequest';
import { UpdateAnniDisponibilitaAttivitaPerCategoriaRequest } from '../model/updateAnniDisponibilitaAttivitaPerCategoriaRequest';
import { UpdateAttivitaExtraOffertaFormativaDTO } from '../model/updateAttivitaExtraOffertaFormativaDTO';
import { UpdateAttivitaTrasversaleOffertaFormativaDTO } from '../model/updateAttivitaTrasversaleOffertaFormativaDTO';
import { UpdateCategoriaOffertaFormativaDTO } from '../model/updateCategoriaOffertaFormativaDTO';
import { UpdateCorsoOffertaFormativaDTO } from '../model/updateCorsoOffertaFormativaDTO';
import { UpdateCorsoOffertaFormativaPubblicaDTO } from '../model/updateCorsoOffertaFormativaPubblicaDTO';
import { UpdateDelibereOffertaFormativaDTO } from '../model/updateDelibereOffertaFormativaDTO';
import { UpdateGuidaCoordinatoreOffertaFormativaLinguaDTO } from '../model/updateGuidaCoordinatoreOffertaFormativaLinguaDTO';
import { UpdateOffertaFormativaDTO } from '../model/updateOffertaFormativaDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OfferteFormativeService {

    protected basePath = 'https://api-dev-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Aggiunge un Attività ad una Categoria dell&#x27;offerta formativa di un ciclo corso di studi.
     * 
     * @param body 
     * @param idCategoriaOffertaFormativa id della categoria offerta formativa
     * @param forza boolean per forzare la modifica
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addAttivitaACategoriaOffertaFormativa(body: AddAttivitaACategoriaOffertaFormativaRequest, idCategoriaOffertaFormativa: string, forza?: boolean, observe?: 'body', reportProgress?: boolean): Observable<OffertaFormativaInfoViewImpl>;
    public addAttivitaACategoriaOffertaFormativa(body: AddAttivitaACategoriaOffertaFormativaRequest, idCategoriaOffertaFormativa: string, forza?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OffertaFormativaInfoViewImpl>>;
    public addAttivitaACategoriaOffertaFormativa(body: AddAttivitaACategoriaOffertaFormativaRequest, idCategoriaOffertaFormativa: string, forza?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OffertaFormativaInfoViewImpl>>;
    public addAttivitaACategoriaOffertaFormativa(body: AddAttivitaACategoriaOffertaFormativaRequest, idCategoriaOffertaFormativa: string, forza?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addAttivitaACategoriaOffertaFormativa.');
        }

        if (idCategoriaOffertaFormativa === null || idCategoriaOffertaFormativa === undefined) {
            throw new Error('Required parameter idCategoriaOffertaFormativa was null or undefined when calling addAttivitaACategoriaOffertaFormativa.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (forza !== undefined && forza !== null) {
            queryParameters = queryParameters.set('forza', <any>forza);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OffertaFormativaInfoViewImpl>('put',`${this.basePath}/api/v1/offerta-formativa/categoria/${encodeURIComponent(String(idCategoriaOffertaFormativa))}/attivita`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiunge una Categoria all&#x27;offerta formativa di un ciclo corso di studi.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCategoriaOffertaFormativa(body: AddCategoriaOffertaFormativaDTO, observe?: 'body', reportProgress?: boolean): Observable<OffertaFormativaInfoViewImpl>;
    public addCategoriaOffertaFormativa(body: AddCategoriaOffertaFormativaDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OffertaFormativaInfoViewImpl>>;
    public addCategoriaOffertaFormativa(body: AddCategoriaOffertaFormativaDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OffertaFormativaInfoViewImpl>>;
    public addCategoriaOffertaFormativa(body: AddCategoriaOffertaFormativaDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addCategoriaOffertaFormativa.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OffertaFormativaInfoViewImpl>('post',`${this.basePath}/api/v1/offerta-formativa/categoria`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di aggiungere una delibera a quelle associate a un offerta formativa.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addDelibereOffertaFormativa(body: AddDeliberaOffertaFormativaDTO, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public addDelibereOffertaFormativa(body: AddDeliberaOffertaFormativaDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public addDelibereOffertaFormativa(body: AddDeliberaOffertaFormativaDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public addDelibereOffertaFormativa(body: AddDeliberaOffertaFormativaDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addDelibereOffertaFormativa.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/offerta-formativa/add-delibera`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verifica se è possibile clonare l&#x27;offerta formativa di un ciclo corso di studi prendendola dal ciclo precedente.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkClonaOffertaFormativa(observe?: 'body', reportProgress?: boolean): Observable<BooleanDTO>;
    public checkClonaOffertaFormativa(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BooleanDTO>>;
    public checkClonaOffertaFormativa(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BooleanDTO>>;
    public checkClonaOffertaFormativa(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BooleanDTO>('get',`${this.basePath}/api/v1/offerta-formativa/clona-offerta-formativa/check`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di clonare l&#x27;offerta formativa di un ciclo corso di studi prendendola dal ciclo precedente.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clonaOffertaFormativaDaCicloPrecedente(observe?: 'body', reportProgress?: boolean): Observable<ClonaOffertaFormativaResponse>;
    public clonaOffertaFormativaDaCicloPrecedente(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClonaOffertaFormativaResponse>>;
    public clonaOffertaFormativaDaCicloPrecedente(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClonaOffertaFormativaResponse>>;
    public clonaOffertaFormativaDaCicloPrecedente(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ClonaOffertaFormativaResponse>('post',`${this.basePath}/api/v1/offerta-formativa/clona-offerta-formativa`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rimuove una attività da una categoria dell&#x27;offerta formativa
     * 
     * @param idCategoriaOffertaFormativa id della categoria
     * @param idAttivitaOffertaFormativa id dell&#x27;attività offerta formativa
     * @param confirm 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAttivitaACategoriaOffertaFormativa(idCategoriaOffertaFormativa: string, idAttivitaOffertaFormativa: string, confirm?: boolean, observe?: 'body', reportProgress?: boolean): Observable<OffertaFormativaInfoViewImpl>;
    public deleteAttivitaACategoriaOffertaFormativa(idCategoriaOffertaFormativa: string, idAttivitaOffertaFormativa: string, confirm?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OffertaFormativaInfoViewImpl>>;
    public deleteAttivitaACategoriaOffertaFormativa(idCategoriaOffertaFormativa: string, idAttivitaOffertaFormativa: string, confirm?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OffertaFormativaInfoViewImpl>>;
    public deleteAttivitaACategoriaOffertaFormativa(idCategoriaOffertaFormativa: string, idAttivitaOffertaFormativa: string, confirm?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idCategoriaOffertaFormativa === null || idCategoriaOffertaFormativa === undefined) {
            throw new Error('Required parameter idCategoriaOffertaFormativa was null or undefined when calling deleteAttivitaACategoriaOffertaFormativa.');
        }

        if (idAttivitaOffertaFormativa === null || idAttivitaOffertaFormativa === undefined) {
            throw new Error('Required parameter idAttivitaOffertaFormativa was null or undefined when calling deleteAttivitaACategoriaOffertaFormativa.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (confirm !== undefined && confirm !== null) {
            queryParameters = queryParameters.set('confirm', <any>confirm);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<OffertaFormativaInfoViewImpl>('delete',`${this.basePath}/api/v1/offerta-formativa/categoria/${encodeURIComponent(String(idCategoriaOffertaFormativa))}/attivita/${encodeURIComponent(String(idAttivitaOffertaFormativa))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rimuove una categoria dell&#x27;offerta formativa se non pubblica oppure se non associata a nessun corso
     * 
     * @param idCategoriaOffertaFormativa id della categoria
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCategoriaOffertaFormativa(idCategoriaOffertaFormativa: string, observe?: 'body', reportProgress?: boolean): Observable<OffertaFormativaInfoViewImpl>;
    public deleteCategoriaOffertaFormativa(idCategoriaOffertaFormativa: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OffertaFormativaInfoViewImpl>>;
    public deleteCategoriaOffertaFormativa(idCategoriaOffertaFormativa: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OffertaFormativaInfoViewImpl>>;
    public deleteCategoriaOffertaFormativa(idCategoriaOffertaFormativa: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idCategoriaOffertaFormativa === null || idCategoriaOffertaFormativa === undefined) {
            throw new Error('Required parameter idCategoriaOffertaFormativa was null or undefined when calling deleteCategoriaOffertaFormativa.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<OffertaFormativaInfoViewImpl>('delete',`${this.basePath}/api/v1/offerta-formativa/categoria/${encodeURIComponent(String(idCategoriaOffertaFormativa))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce tutti le tipologie di documento censite nelle attività trasversali
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllDistinctTipiDocumentoAttivitaTrasversale(observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public getAllDistinctTipiDocumentoAttivitaTrasversale(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public getAllDistinctTipiDocumentoAttivitaTrasversale(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public getAllDistinctTipiDocumentoAttivitaTrasversale(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<string>>('get',`${this.basePath}/api/v1/offerta-formativa/tipi-documento`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce l&#x27;offerta formativa di un ciclo corso di studi.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOffertaFormativa(observe?: 'body', reportProgress?: boolean): Observable<OffertaFormativaInfoViewImpl>;
    public getOffertaFormativa(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OffertaFormativaInfoViewImpl>>;
    public getOffertaFormativa(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OffertaFormativaInfoViewImpl>>;
    public getOffertaFormativa(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<OffertaFormativaInfoViewImpl>('get',`${this.basePath}/api/v1/offerta-formativa/info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le attivita dell&#x27;offerta formativa di cui l&#x27;utente loggato è DOCENTE_CORSO.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOffertaFormativaOfCurrentDocenteCorso(observe?: 'body', reportProgress?: boolean): Observable<Array<AttivitaOffertaFormativaOfCurrentDocenteCorsoCountDTO>>;
    public getOffertaFormativaOfCurrentDocenteCorso(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AttivitaOffertaFormativaOfCurrentDocenteCorsoCountDTO>>>;
    public getOffertaFormativaOfCurrentDocenteCorso(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AttivitaOffertaFormativaOfCurrentDocenteCorsoCountDTO>>>;
    public getOffertaFormativaOfCurrentDocenteCorso(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AttivitaOffertaFormativaOfCurrentDocenteCorsoCountDTO>>('get',`${this.basePath}/api/v1/offerta-formativa/for-docente-corso`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di scaricare il pdf dell&#x27;offerta formativa da deliberare.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPdfOffertaFormativaDaDeliberare(observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getPdfOffertaFormativaDaDeliberare(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getPdfOffertaFormativaDaDeliberare(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getPdfOffertaFormativaDaDeliberare(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/offerta-formativa/pdf`,
            {
             responseType: "blob" as "json",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sposta un Attività da una Categoria ad un&#x27;altra.
     * 
     * @param body 
     * @param idCategoriaOffertaFormativa id della categoria offerta formativa di origine
     * @param idAttivitaOffertaFormativa id dell&#x27;attività offerta formativa
     * @param forza boolean per forzare la modifica
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public spostaAttivitaInCategoriaOffertaFormativa(body: SpostaAttivitaInCategoriaOffertaFormativaRequest, idCategoriaOffertaFormativa: string, idAttivitaOffertaFormativa: string, forza?: boolean, observe?: 'body', reportProgress?: boolean): Observable<OffertaFormativaInfoViewImpl>;
    public spostaAttivitaInCategoriaOffertaFormativa(body: SpostaAttivitaInCategoriaOffertaFormativaRequest, idCategoriaOffertaFormativa: string, idAttivitaOffertaFormativa: string, forza?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OffertaFormativaInfoViewImpl>>;
    public spostaAttivitaInCategoriaOffertaFormativa(body: SpostaAttivitaInCategoriaOffertaFormativaRequest, idCategoriaOffertaFormativa: string, idAttivitaOffertaFormativa: string, forza?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OffertaFormativaInfoViewImpl>>;
    public spostaAttivitaInCategoriaOffertaFormativa(body: SpostaAttivitaInCategoriaOffertaFormativaRequest, idCategoriaOffertaFormativa: string, idAttivitaOffertaFormativa: string, forza?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling spostaAttivitaInCategoriaOffertaFormativa.');
        }

        if (idCategoriaOffertaFormativa === null || idCategoriaOffertaFormativa === undefined) {
            throw new Error('Required parameter idCategoriaOffertaFormativa was null or undefined when calling spostaAttivitaInCategoriaOffertaFormativa.');
        }

        if (idAttivitaOffertaFormativa === null || idAttivitaOffertaFormativa === undefined) {
            throw new Error('Required parameter idAttivitaOffertaFormativa was null or undefined when calling spostaAttivitaInCategoriaOffertaFormativa.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (forza !== undefined && forza !== null) {
            queryParameters = queryParameters.set('forza', <any>forza);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OffertaFormativaInfoViewImpl>('post',`${this.basePath}/api/v1/offerta-formativa/categoria/${encodeURIComponent(String(idCategoriaOffertaFormativa))}/attivita/${encodeURIComponent(String(idAttivitaOffertaFormativa))}/sposta`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna gli anni di disponibilità di un Attività per una Categoria dell&#x27;offerta formativa.
     * 
     * @param body 
     * @param idCategoriaOffertaFormativa id della categoria offerta formativa
     * @param idAttivitaOffertaFormativa id dell&#x27;attività offerta formativa
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAnniDisponibilitaAttivitaPerCategoria(body: UpdateAnniDisponibilitaAttivitaPerCategoriaRequest, idCategoriaOffertaFormativa: string, idAttivitaOffertaFormativa: string, observe?: 'body', reportProgress?: boolean): Observable<OffertaFormativaInfoViewImpl>;
    public updateAnniDisponibilitaAttivitaPerCategoria(body: UpdateAnniDisponibilitaAttivitaPerCategoriaRequest, idCategoriaOffertaFormativa: string, idAttivitaOffertaFormativa: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OffertaFormativaInfoViewImpl>>;
    public updateAnniDisponibilitaAttivitaPerCategoria(body: UpdateAnniDisponibilitaAttivitaPerCategoriaRequest, idCategoriaOffertaFormativa: string, idAttivitaOffertaFormativa: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OffertaFormativaInfoViewImpl>>;
    public updateAnniDisponibilitaAttivitaPerCategoria(body: UpdateAnniDisponibilitaAttivitaPerCategoriaRequest, idCategoriaOffertaFormativa: string, idAttivitaOffertaFormativa: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateAnniDisponibilitaAttivitaPerCategoria.');
        }

        if (idCategoriaOffertaFormativa === null || idCategoriaOffertaFormativa === undefined) {
            throw new Error('Required parameter idCategoriaOffertaFormativa was null or undefined when calling updateAnniDisponibilitaAttivitaPerCategoria.');
        }

        if (idAttivitaOffertaFormativa === null || idAttivitaOffertaFormativa === undefined) {
            throw new Error('Required parameter idAttivitaOffertaFormativa was null or undefined when calling updateAnniDisponibilitaAttivitaPerCategoria.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OffertaFormativaInfoViewImpl>('put',`${this.basePath}/api/v1/offerta-formativa/categoria/${encodeURIComponent(String(idCategoriaOffertaFormativa))}/attivita/${encodeURIComponent(String(idAttivitaOffertaFormativa))}/anni-disponibilita`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modifica un attività extra dell&#x27;offerta formativa di un ciclo corso di studi.
     * 
     * @param body 
     * @param idCorsoInOffertaFormativa id del corso in offerta formativa
     * @param forza boolean per forzare la modifica
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAttivitaExtraOffertaFormativa(body: UpdateAttivitaExtraOffertaFormativaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe?: 'body', reportProgress?: boolean): Observable<OffertaFormativaInfoViewImpl>;
    public updateAttivitaExtraOffertaFormativa(body: UpdateAttivitaExtraOffertaFormativaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OffertaFormativaInfoViewImpl>>;
    public updateAttivitaExtraOffertaFormativa(body: UpdateAttivitaExtraOffertaFormativaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OffertaFormativaInfoViewImpl>>;
    public updateAttivitaExtraOffertaFormativa(body: UpdateAttivitaExtraOffertaFormativaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateAttivitaExtraOffertaFormativa.');
        }

        if (idCorsoInOffertaFormativa === null || idCorsoInOffertaFormativa === undefined) {
            throw new Error('Required parameter idCorsoInOffertaFormativa was null or undefined when calling updateAttivitaExtraOffertaFormativa.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (forza !== undefined && forza !== null) {
            queryParameters = queryParameters.set('forza', <any>forza);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OffertaFormativaInfoViewImpl>('put',`${this.basePath}/api/v1/offerta-formativa/attivita-extra/${encodeURIComponent(String(idCorsoInOffertaFormativa))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modifica un attività trasversale dell&#x27;offerta formativa di un ciclo corso di studi.
     * 
     * @param body 
     * @param idCorsoInOffertaFormativa id del corso in offerta formativa
     * @param forza boolean per forzare la modifica
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAttivitaTrasversaleOffertaFormativa(body: UpdateAttivitaTrasversaleOffertaFormativaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe?: 'body', reportProgress?: boolean): Observable<OffertaFormativaInfoViewImpl>;
    public updateAttivitaTrasversaleOffertaFormativa(body: UpdateAttivitaTrasversaleOffertaFormativaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OffertaFormativaInfoViewImpl>>;
    public updateAttivitaTrasversaleOffertaFormativa(body: UpdateAttivitaTrasversaleOffertaFormativaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OffertaFormativaInfoViewImpl>>;
    public updateAttivitaTrasversaleOffertaFormativa(body: UpdateAttivitaTrasversaleOffertaFormativaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateAttivitaTrasversaleOffertaFormativa.');
        }

        if (idCorsoInOffertaFormativa === null || idCorsoInOffertaFormativa === undefined) {
            throw new Error('Required parameter idCorsoInOffertaFormativa was null or undefined when calling updateAttivitaTrasversaleOffertaFormativa.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (forza !== undefined && forza !== null) {
            queryParameters = queryParameters.set('forza', <any>forza);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OffertaFormativaInfoViewImpl>('put',`${this.basePath}/api/v1/offerta-formativa/attivita-trasversale/${encodeURIComponent(String(idCorsoInOffertaFormativa))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modifica una Categoria dell&#x27;offerta formativa di un ciclo corso di studi.
     * 
     * @param body 
     * @param idCategoriaOffertaFormativa id della categoria offerta formativa
     * @param restituisciEccezionePianiNonValidi 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCategoriaOffertaFormativa(body: UpdateCategoriaOffertaFormativaDTO, idCategoriaOffertaFormativa: string, restituisciEccezionePianiNonValidi?: boolean, observe?: 'body', reportProgress?: boolean): Observable<OffertaFormativaInfoViewImpl>;
    public updateCategoriaOffertaFormativa(body: UpdateCategoriaOffertaFormativaDTO, idCategoriaOffertaFormativa: string, restituisciEccezionePianiNonValidi?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OffertaFormativaInfoViewImpl>>;
    public updateCategoriaOffertaFormativa(body: UpdateCategoriaOffertaFormativaDTO, idCategoriaOffertaFormativa: string, restituisciEccezionePianiNonValidi?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OffertaFormativaInfoViewImpl>>;
    public updateCategoriaOffertaFormativa(body: UpdateCategoriaOffertaFormativaDTO, idCategoriaOffertaFormativa: string, restituisciEccezionePianiNonValidi?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateCategoriaOffertaFormativa.');
        }

        if (idCategoriaOffertaFormativa === null || idCategoriaOffertaFormativa === undefined) {
            throw new Error('Required parameter idCategoriaOffertaFormativa was null or undefined when calling updateCategoriaOffertaFormativa.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (restituisciEccezionePianiNonValidi !== undefined && restituisciEccezionePianiNonValidi !== null) {
            queryParameters = queryParameters.set('restituisciEccezionePianiNonValidi', <any>restituisciEccezionePianiNonValidi);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OffertaFormativaInfoViewImpl>('put',`${this.basePath}/api/v1/offerta-formativa/categoria/${encodeURIComponent(String(idCategoriaOffertaFormativa))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modifica un corso dell&#x27;offerta formativa di un ciclo corso di studi.
     * 
     * @param body 
     * @param idCorsoInOffertaFormativa id del corso in offerta formativa
     * @param forza boolean per forzare la modifica
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCorsoOffertaFormativa(body: UpdateCorsoOffertaFormativaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe?: 'body', reportProgress?: boolean): Observable<OffertaFormativaInfoViewImpl>;
    public updateCorsoOffertaFormativa(body: UpdateCorsoOffertaFormativaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OffertaFormativaInfoViewImpl>>;
    public updateCorsoOffertaFormativa(body: UpdateCorsoOffertaFormativaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OffertaFormativaInfoViewImpl>>;
    public updateCorsoOffertaFormativa(body: UpdateCorsoOffertaFormativaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateCorsoOffertaFormativa.');
        }

        if (idCorsoInOffertaFormativa === null || idCorsoInOffertaFormativa === undefined) {
            throw new Error('Required parameter idCorsoInOffertaFormativa was null or undefined when calling updateCorsoOffertaFormativa.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (forza !== undefined && forza !== null) {
            queryParameters = queryParameters.set('forza', <any>forza);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OffertaFormativaInfoViewImpl>('put',`${this.basePath}/api/v1/offerta-formativa/corso/${encodeURIComponent(String(idCorsoInOffertaFormativa))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di modificare le delibere associate a un offerta formativa.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDelibereOffertaFormativa(body: UpdateDelibereOffertaFormativaDTO, observe?: 'body', reportProgress?: boolean): Observable<OffertaFormativaInfoViewImpl>;
    public updateDelibereOffertaFormativa(body: UpdateDelibereOffertaFormativaDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OffertaFormativaInfoViewImpl>>;
    public updateDelibereOffertaFormativa(body: UpdateDelibereOffertaFormativaDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OffertaFormativaInfoViewImpl>>;
    public updateDelibereOffertaFormativa(body: UpdateDelibereOffertaFormativaDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateDelibereOffertaFormativa.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OffertaFormativaInfoViewImpl>('put',`${this.basePath}/api/v1/offerta-formativa/update-delibere`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modifica solo dei docenti per un corso di un offerta formativa.
     * 
     * @param body 
     * @param idCorsoInOffertaFormativa id del corso in offerta formativa
     * @param forza boolean per forzare la modifica
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDocentiInCorsoOffertaFormativa(body: UpdateCorsoOffertaFormativaPubblicaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe?: 'body', reportProgress?: boolean): Observable<OffertaFormativaInfoViewImpl>;
    public updateDocentiInCorsoOffertaFormativa(body: UpdateCorsoOffertaFormativaPubblicaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OffertaFormativaInfoViewImpl>>;
    public updateDocentiInCorsoOffertaFormativa(body: UpdateCorsoOffertaFormativaPubblicaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OffertaFormativaInfoViewImpl>>;
    public updateDocentiInCorsoOffertaFormativa(body: UpdateCorsoOffertaFormativaPubblicaDTO, idCorsoInOffertaFormativa: string, forza?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateDocentiInCorsoOffertaFormativa.');
        }

        if (idCorsoInOffertaFormativa === null || idCorsoInOffertaFormativa === undefined) {
            throw new Error('Required parameter idCorsoInOffertaFormativa was null or undefined when calling updateDocentiInCorsoOffertaFormativa.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (forza !== undefined && forza !== null) {
            queryParameters = queryParameters.set('forza', <any>forza);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OffertaFormativaInfoViewImpl>('put',`${this.basePath}/api/v1/offerta-formativa/corso/${encodeURIComponent(String(idCorsoInOffertaFormativa))}/update-docenti`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna la Guida Coordinatore su offerta formativa.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateGuidaCoordinatoreLinguaOffertaFormativa(body: UpdateGuidaCoordinatoreOffertaFormativaLinguaDTO, observe?: 'body', reportProgress?: boolean): Observable<OffertaFormativaInfoViewImpl>;
    public updateGuidaCoordinatoreLinguaOffertaFormativa(body: UpdateGuidaCoordinatoreOffertaFormativaLinguaDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OffertaFormativaInfoViewImpl>>;
    public updateGuidaCoordinatoreLinguaOffertaFormativa(body: UpdateGuidaCoordinatoreOffertaFormativaLinguaDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OffertaFormativaInfoViewImpl>>;
    public updateGuidaCoordinatoreLinguaOffertaFormativa(body: UpdateGuidaCoordinatoreOffertaFormativaLinguaDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateGuidaCoordinatoreLinguaOffertaFormativa.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OffertaFormativaInfoViewImpl>('put',`${this.basePath}/api/v1/offerta-formativa/guida-coordinatore`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di modificare lo stato, la configurazione e le delibere associate a un offerta formativa.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOffertaFormativa(body: UpdateOffertaFormativaDTO, observe?: 'body', reportProgress?: boolean): Observable<OffertaFormativaInfoViewImpl>;
    public updateOffertaFormativa(body: UpdateOffertaFormativaDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OffertaFormativaInfoViewImpl>>;
    public updateOffertaFormativa(body: UpdateOffertaFormativaDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OffertaFormativaInfoViewImpl>>;
    public updateOffertaFormativa(body: UpdateOffertaFormativaDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateOffertaFormativa.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OffertaFormativaInfoViewImpl>('put',`${this.basePath}/api/v1/offerta-formativa/update`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
