import {Component, Inject, OnInit} from '@angular/core';
import {
    ApprovazioneMassivaSpeseStudentiByGruppoPTARequest, ApprovazioneMassivaSpeseStudentiByGruppoPTASpecifiche,
    DeliberaApprovaPeriodiDiRicercaResponse,
    DeliberaApprovaPeriodiDiSoggiornoEsteroRequest, DeliberaApprovaSpeseStudentiResponse,
    EsitoOperazione, EsitoSingolaApprovazioneSpesa,
    EsitoSingolaOperazioneInOperazioneMassiva,
    MediaService,
    OperazioniMassiveService,
    PeriodoDiMobilitaStudenteInfoView, SpesaStudentePerApprovMassivaInfoView,
    StudenteCicloIdAndUtenteInfoView
} from "../../../../../../api-clients/generated/services";
import {BehaviorSubject, catchError, finalize, map, of, switchMap, take, takeUntil, tap} from "rxjs";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {
    ApprovazioneSpesaPTAMassivaDataI,
} from "../approvazione-spesa-pta-steps-interface";
import {CAN_GO_AHEAD$, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {filter} from "rxjs/operators";
import {cloneDeep} from "lodash-es";
import {get} from "lodash";
import {TranslocoService} from "@ngneat/transloco";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {DialogInfoComponent, DialogInfoI} from "../../../../../shared/components/dialog-info/dialog-info.component";
import {UpperCasePipe} from "@angular/common";
import {LogoutService} from "../../../../../shared/service/logout.service";
import {MatDialog} from "@angular/material/dialog";
import {DialogInfoManagerService} from "../../../../../shared/service/dialog-info-manager.service";


@Component({
    selector: 'app-confirm-approvazione-spesa-pta-stepper',
    templateUrl: './confirm-approvazione-spesa-pta.component.html',
    styleUrls: ['./confirm-approvazione-spesa-pta.component.scss']
})
export class ConfirmApprovazioneSpesaPtaComponent extends AbstractDefaultComponent implements OnInit {

    approvazioneSpeseData: ApprovazioneSpesaPTAMassivaDataI;
    operationSucceed: boolean;
    operationError: boolean;
    operationEsiti: Array<EsitoSingolaApprovazioneSpesa> | undefined;
    resultsSpeseSelected: SpesaStudentePerApprovMassivaInfoView[];
    resultsCompileInfo: ApprovazioneMassivaSpeseStudentiByGruppoPTASpecifiche[];

    constructor(private appInitService: AppInitService,
                private operazioniMassiveService: OperazioniMassiveService,
                private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private mediaService: MediaService,
                private sanitizer: DomSanitizer,
                private logoutService: LogoutService,
                private dialog: MatDialog,
                private dialogInfoManagerService: DialogInfoManagerService,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneSpesaPTAMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneSpesaPTAMassivaDataI) => {
            if(inputOutputData?.compileInfo) {
                this.approvazioneSpeseData = inputOutputData;
                // when there are studenti selected reset succeed and error flags
                if(this.approvazioneSpeseData?.speseSelected?.length > 0) {
                    this.operationSucceed = false;
                    this.operationError = false;
                }
            }
        })
    }


    confirmOperation() {
        this.operationError = undefined;
        this.operationSucceed = undefined;
        this.fuseConfirmationService.showLoader();
        console.log('confirm compile info', this.approvazioneSpeseData?.compileInfo)
        const requestBody: ApprovazioneMassivaSpeseStudentiByGruppoPTARequest = {
            specifiche: this.approvazioneSpeseData?.compileInfo?.map(ci => ({
                id_utente: ci.id_utente,
                codice_spesa: ci.codice_spesa,
                importo_approvato: ci?.importo_approvato,
                importo_approvato_fondo_primo_anno: ci?.importo_approvato_fondo_primo_anno,
                importo_approvato_fondo_secondo_anno: ci?.importo_approvato_fondo_secondo_anno,
                importo_approvato_fondo_terzo_anno: ci?.importo_approvato_fondo_terzo_anno,
                importo_approvato_altro_fondo: ci?.importo_approvato_altro_fondo,
                descrizione_altro_fondo: ci?.descrizione_altro_fondo,
            }))
        }
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.operazioniMassiveService.approvazioneMassivaSpeseStudentiByGruppoPTA(requestBody)),
            takeUntil(this.destroy$),
            tap((approvaSpeseStudentiResponse: DeliberaApprovaSpeseStudentiResponse) => {
                this.resultsSpeseSelected  = cloneDeep(this.approvazioneSpeseData?.speseSelected);
                this.resultsCompileInfo = cloneDeep(this.approvazioneSpeseData?.compileInfo);
                // forcing the refresh of studenti
                this.operazioneMassivaData$.next({
                    speseSelected: undefined,
                    refreshSpese: true,
                });
            }),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (approvaSpeseStudentiResponse: DeliberaApprovaSpeseStudentiResponse) => {
                    this.operationSucceed = true;
                    this.operationEsiti = approvaSpeseStudentiResponse?.esiti_operazioni;
                },
                error: (err) => {
                    this.operationError = true;
                    console.log(err)
                }
            }
        );

    }

    protected readonly EsitoOperazione = EsitoOperazione;

    openConfirmOperationDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: get(translation, 'dialog.attention', null),
                message: get(translation, 'massive_operations.confirm_operation_attention_message', null),
                icon: {
                    name: 'mat_outline:warning',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'common.confirm', null), icon: 'check',
                        function: () => this.confirmOperation()
                    }]
            }
        );
    }

    private openInfoStudent(studente: StudenteCicloIdAndUtenteInfoView) {
        if (!!studente?.utente?.urlImmagineProfilo && !!studente?.utente?.codiceFiscale) {
            this.fuseConfirmationService.showLoader();
            this.mediaService.getImmagineProfiloForm(studente?.utente?.codiceFiscale, studente?.utente?.urlImmagineProfilo)
                .pipe(
                    catchError(err => of(undefined)),
                    map((image) => {
                        if (image) {
                            const objectURL = URL.createObjectURL(image);
                            return this.sanitizer.bypassSecurityTrustUrl(objectURL);
                        } else {
                            return undefined;
                        }
                    }),
                    takeUntil(this.destroy$),
                    finalize(() => this.fuseConfirmationService.hideLoader())
                ).subscribe({
                next: (image: SafeUrl) => {
                    this.dialogInfoManagerService.openStudenteInfoDialogSCIUIV(studente, image);
                },
                error: (error) => {
                    this.fuseConfirmationService.openErrorDialog({error: error},
                        this.translocoService,
                        () => {
                            this.logoutService.goToHome();
                        },
                        () => this.openInfoStudent(studente),
                        'dialog.close',
                        error?.error?.message);
                }
            });
        } else {
            this.dialogInfoManagerService.openStudenteInfoDialogSCIUIV(studente);
        }
    }

    getStudenteById(id_utente: string) {
        const studente = this.resultsSpeseSelected?.find(s => s.budgetStudente?.studenteCiclo?.utente?.id === id_utente);
        return studente?.budgetStudente?.studenteCiclo?.utente?.cognome + ' ' + studente?.budgetStudente?.studenteCiclo?.utente?.nome;
    }

    getImportoForSpesaCode(codiceSpesa: string) {
        return this.approvazioneSpeseData.compileInfo?.find(s => s.codice_spesa === codiceSpesa)?.importo_approvato;
    }

    getImportoForEsito(esito: EsitoSingolaApprovazioneSpesa) {
        return this.resultsCompileInfo.find(s => s.codice_spesa === esito.codice_spesa)?.importo_approvato || 0;
    }




}
