/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddManualePiattaformaDTO } from '../model/addManualePiattaformaDTO';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GenericResponseDTO } from '../model/genericResponseDTO';
import { GetManualePiattaformaDTO } from '../model/getManualePiattaformaDTO';
import { ManualePiattaformaInfoView } from '../model/manualePiattaformaInfoView';
import { ManualePiattaformaInfoViewImpl } from '../model/manualePiattaformaInfoViewImpl';
import { UpdateManualePiattaformaDTO } from '../model/updateManualePiattaformaDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AteneoService {

    protected basePath = 'https://api-dev-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Permette di aggiungere una nuovo manuale.
     * 
     * @param addManualePiattaformaDTO 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addManualePiattaformaForm(addManualePiattaformaDTO?: AddManualePiattaformaDTO, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<Array<ManualePiattaformaInfoView>>;
    public addManualePiattaformaForm(addManualePiattaformaDTO?: AddManualePiattaformaDTO, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ManualePiattaformaInfoView>>>;
    public addManualePiattaformaForm(addManualePiattaformaDTO?: AddManualePiattaformaDTO, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ManualePiattaformaInfoView>>>;
    public addManualePiattaformaForm(addManualePiattaformaDTO?: AddManualePiattaformaDTO, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (addManualePiattaformaDTO !== undefined) {
            formParams = formParams.append('addManualePiattaformaDTO', <any>addManualePiattaformaDTO) as any || formParams;
        }
        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<Array<ManualePiattaformaInfoView>>('post',`${this.basePath}/api/v1/ateneo/manuale/add`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di eliminare un manuale.
     * 
     * @param idManuale id del manuale da eliminare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteManualePiattaforma(idManuale: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public deleteManualePiattaforma(idManuale: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public deleteManualePiattaforma(idManuale: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public deleteManualePiattaforma(idManuale: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idManuale === null || idManuale === undefined) {
            throw new Error('Required parameter idManuale was null or undefined when calling deleteManualePiattaforma.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('delete',`${this.basePath}/api/v1/ateneo/manuale/${encodeURIComponent(String(idManuale))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array di un manuale.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadManualePiattaforma(body: GetManualePiattaformaDTO, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadManualePiattaforma(body: GetManualePiattaformaDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadManualePiattaforma(body: GetManualePiattaformaDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadManualePiattaforma(body: GetManualePiattaformaDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling downloadManualePiattaforma.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/ateneo/manuale/download`,
            {
             responseType: "blob" as "json",
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista dei manuali filtrari per il ruolo dell&#x27;utente loggato.
     * 
     * @param localeCountry 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getManualiPiattaforma(localeCountry?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ManualePiattaformaInfoViewImpl>>;
    public getManualiPiattaforma(localeCountry?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ManualePiattaformaInfoViewImpl>>>;
    public getManualiPiattaforma(localeCountry?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ManualePiattaformaInfoViewImpl>>>;
    public getManualiPiattaforma(localeCountry?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (localeCountry !== undefined && localeCountry !== null) {
            queryParameters = queryParameters.set('localeCountry', <any>localeCountry);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ManualePiattaformaInfoViewImpl>>('get',`${this.basePath}/api/v1/ateneo/manuali`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di modificare una manuale.
     * 
     * @param idManuale id del manuale da modificare
     * @param file 
     * @param updateManualePiattaformaDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateManualePiattaformaForm(idManuale: string, file?: Blob, updateManualePiattaformaDTO?: UpdateManualePiattaformaDTO, observe?: 'body', reportProgress?: boolean): Observable<Array<ManualePiattaformaInfoView>>;
    public updateManualePiattaformaForm(idManuale: string, file?: Blob, updateManualePiattaformaDTO?: UpdateManualePiattaformaDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ManualePiattaformaInfoView>>>;
    public updateManualePiattaformaForm(idManuale: string, file?: Blob, updateManualePiattaformaDTO?: UpdateManualePiattaformaDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ManualePiattaformaInfoView>>>;
    public updateManualePiattaformaForm(idManuale: string, file?: Blob, updateManualePiattaformaDTO?: UpdateManualePiattaformaDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idManuale === null || idManuale === undefined) {
            throw new Error('Required parameter idManuale was null or undefined when calling updateManualePiattaforma.');
        }



        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }
        if (updateManualePiattaformaDTO !== undefined) {
            formParams = formParams.append('updateManualePiattaformaDTO', <any>updateManualePiattaformaDTO) as any || formParams;
        }

        return this.httpClient.request<Array<ManualePiattaformaInfoView>>('put',`${this.basePath}/api/v1/ateneo/manuale/${encodeURIComponent(String(idManuale))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
