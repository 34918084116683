import {Injectable} from '@angular/core';
import {BehaviorSubject, catchError, finalize, Observable, of, Subject, take, takeUntil} from 'rxjs';
import {
    AddDocumentoDottorandoAttivitaTrasversaleRequest,
    AggiornamentoPianoDiStudiStatus,
    AuthorityType,
    CategoriaInPianoDiStudiInfoView,
    ChiaveFlussoEnum,
    ChiaveOperazioneEnum,
    CicliCorsiDiStudiService,
    CorsoPianoDiStudiInfoView,
    DeliberaInfoView,
    DelibereService,
    EsitoRichiestaApprovazionePianoDiStudi,
    EsitoRichiestaApprovazionePianoDiStudiByCoordinatore,
    GetAllegatoSuperamentoAnnoStudenteRequest,
    MediaService,
    PianiDiStudiService,
    PianoDiStudiInfoView,
    PianoDiStudiStatus,
    RichiestaSuperamentoAttivitaTrasversaleRequest,
    SuperamentoAttivitaTrasversalePianoDiStudiRequest,
    UserCodiceFiscaleNomeCognomeView,
    UserRoleBasedInfoView,
    UsersService,
    VerificaDocumentoDottorandoAttivitaTrasversaleRequest,
} from '../../../api-clients/generated/services';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {filter, switchMap, tap} from 'rxjs/operators';
import {DialogInfoComponent, DialogInfoI} from '../components/dialog-info/dialog-info.component';
import {FuseConfirmationService} from '../../../@fuse/services/confirmation';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Translation, TranslocoService} from '@ngneat/transloco';
import {Router} from '@angular/router';
import {docente} from '../../modules/landing/cycle/cycle-information/cycle-information.component';
import {get, isEmpty, isNil} from 'lodash';
import {DialogApprovalComponent} from '../dialogs/dialog-approval/dialog-approval.component';
import {DialogApprovalConfiguration} from '../interface/dialog-approval-configuration';
import {
    formGroupConfigInterface,
    GenericComponentDialogConfig
} from '../../layout/common/generic-components/generic-components.interface';
import {GenericDialogComponent} from '../../layout/common/generic-components/generic-dialog/generic-dialog.component';
import {TypeDialogFormEnum} from '../../layout/common/generic-components/generic-components-enum';
import {optionFile} from '../costants/app-constants';
import {FormGroup} from '@angular/forms';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {SnackbarTypes} from '../../../@fuse/services/confirmation/snackbar/snackbar.component';
import * as moment from "moment";
import {ClickEvent} from "../components/table/model/generic-table-model";
import {makeFilename, openFileInBlankWindow} from "../utils/utils";
import {
    DialogCoordinatorAddDeliberaI,
    DialogCoordinatorAddDeliberaRequestComponent
} from "../../modules/landing/student-details/study-plan/transversal-activities/dialog-coordinator-add-delibera-request/dialog-coordinator-add-delibera-request.component";
import {StudyPlanAggiornamentoDataUI} from "../interface/piano-di-studi-data-u-i";
import {UIActivityI} from "../../modules/landing/student-details/study-plan/study-plans-categories-utils";
import {CicloConfigurationService} from "./ciclo-configuration.service";
import {CicloCorsoRuoloInterface} from "../interface/CicloCorsoRuoloInterface";
import {StudentDetailsService} from "../../modules/landing/student-details/student-details.service";

export interface PianoDiStudiStatusAndValidity {
    status: PianoDiStudiStatus;
    lastEditStatus?: AggiornamentoPianoDiStudiStatus;
    validity?: boolean;
    reasons?: string[];
}


@Injectable({
    providedIn: 'root'
})
export class StudentDetailManagerService {
    checkCurrentStudyPlanStatusAndValidity$ = new BehaviorSubject(this.getCurrentStudyPlanStatusAndValidity());
    private currentStudyPlanStatusAndValidity: PianoDiStudiStatusAndValidity | undefined;
    dataMaxSuperamentoCorso: Date = moment().toDate();
    private minValHoursErrorMessage: string;
    private maxValHoursErrorMessage: string;

    constructor(private fuseConfirmationService: FuseConfirmationService,
                private usersService: UsersService,
                private mediaService: MediaService,
                private sanitizer: DomSanitizer,
                private dialog: MatDialog,
                private cicliCorsiDiStudiService: CicliCorsiDiStudiService,
                private _translocoService: TranslocoService,
                private router: Router,
                private pianiDiStudiService: PianiDiStudiService,
                private delibereService: DelibereService,
                private studentDetailsService: StudentDetailsService,
                private cicloConfigurationService: CicloConfigurationService,) {
        this._translocoService.selectTranslate('message.max_val_hours_error_message').subscribe(value => this.maxValHoursErrorMessage = value);
        this._translocoService.selectTranslate('message.min_val_hours_error_message').subscribe(value => this.minValHoursErrorMessage = value);
    }


    getCurrentStudyPlanStatusAndValidity(): PianoDiStudiStatusAndValidity {
        return this.currentStudyPlanStatusAndValidity;
    }

    setCurrentStudyPlanStatusAndValidity(status: PianoDiStudiStatus, validity?: boolean, reasons?: string[], lastEditStatus?: AggiornamentoPianoDiStudiStatus): void {
        this.currentStudyPlanStatusAndValidity = {
            status: status, validity: validity, reasons: reasons, lastEditStatus: lastEditStatus
        };
        this.checkCurrentStudyPlanStatusAndValidity$.next(this.currentStudyPlanStatusAndValidity);
    }


    openInfoMember(user: UserCodiceFiscaleNomeCognomeView): void {
        let userResponse: UserRoleBasedInfoView;
        let imageResponse: SafeUrl;
        this.fuseConfirmationService.showLoader();
        this.usersService.getUserInfoRoleBased(user?.codiceFiscale).pipe(
            tap((value: UserRoleBasedInfoView) => userResponse = value),
            switchMap((userResp: UserRoleBasedInfoView) => {
                    if (!!userResp.urlImmagineProfiloThumb && !!userResp.codiceFiscale) {
                        return this.mediaService.getImmagineDocenteOrPtaForm(userResp.codiceFiscale, userResp.urlImmagineProfiloThumb).pipe(
                            catchError(err => of(undefined)),
                            tap((image) => {
                                if (image) {
                                    const objectURL = URL.createObjectURL(image);
                                    imageResponse = this.sanitizer.bypassSecurityTrustUrl(objectURL);
                                } else {
                                    imageResponse = undefined;
                                }
                            })
                        );
                    } else {
                        return of(user);
                    }
                }
            ),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe({
            next: () => {
                const data: DialogInfoI = this.buildDataForDIalog(userResponse, imageResponse);
                this.dialog.open(DialogInfoComponent, {
                    data,
                    panelClass: 'dialog-responsive-full-screen',
                });
            },
            error: (error) => {
                this.fuseConfirmationService.openErrorDialog({error: error},
                    this._translocoService,
                    () => {
                    },
                    () => this.openInfoMember(user),
                    'dialog.close',
                    error?.error?.message);
            }
        });
    }

    buildDataForDIalog(user?: UserRoleBasedInfoView, image?: SafeUrl): DialogInfoI {
        return {
            imageSafeUrl: image,
            showImage: true,
            title: (user.cognome || '') + ' ' + (user.nome || ''),
            icon: {
                name: 'mat_outline:info',
                color: 'primary'
            },
            ...docente(user, this._translocoService.getTranslation().get(this._translocoService.getActiveLang()), this.cicloConfigurationService)
        };
    }

    openDescriptionDialog(description: string): void {
        const data: DialogInfoI = this.buildDataForDescriptionDIalog(description);
        this.dialog.open(DialogInfoComponent, {
            data,
            panelClass: 'dialog-responsive-full-screen',
        });
    }

    buildDataForDescriptionDIalog(description: string): DialogInfoI {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        return {
            title: 'common.description',
            icon: {
                name: 'mat_outline:info',
                color: 'primary'
            },
            isTextArea: true,
            text: description
        };
    }

    openApprovalModal$(pianoDaApprovare: StudyPlanAggiornamentoDataUI, sottoruolo: AuthorityType,
                       numberOfArchivedRemovableActivities: number, destroy$: Subject<boolean>): Observable<any> {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const data: DialogApprovalConfiguration = {
            title: get(translation, 'student.add_study_plan', null),
            message: get(translation, 'study_plan_status.approve_message', null),
            pianoDaApprovare: pianoDaApprovare,
            sottoruolo: sottoruolo,
            icon: {
                show: true,
                name: 'edit',
                color: 'primary'
            },
            onBackdrop: {
                show: false,
                backdrop: false
            },
            actions: [
                {
                    color: 'accent',
                    label: get(translation, 'common.close', null), icon: 'close',
                },
                {
                    color: 'primary',
                    label: get(translation, 'dialog.confirm', null), icon: 'check',
                }],
            numberOfArchivedRemovableActivities: numberOfArchivedRemovableActivities,
            documentsFormConfig: [
                ...this.cicloConfigurationService.getDocsFormConfig(
                    pianoDaApprovare.isNuovaPresentazione ? ChiaveFlussoEnum.PrimaPresentazionePianoFormativo : ChiaveFlussoEnum.ModificaPianoFormativo,
                    sottoruolo === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneSupervisore,
                    (fileUrl) => this.downloadDocument(fileUrl),
                )
            ],
            documentsFormValue: {
                ...this.cicloConfigurationService.getDocsFormValues(
                    pianoDaApprovare.isNuovaPresentazione ? ChiaveFlussoEnum.PrimaPresentazionePianoFormativo : ChiaveFlussoEnum.ModificaPianoFormativo,
                    sottoruolo === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneSupervisore,
                    pianoDaApprovare.allegatiAggiornamentoPianoDiStudi,
                )
            },
            onConfirm: (docsForm, dialogRef) => {
                this.approvePlanRequest(docsForm, dialogRef, sottoruolo, destroy$, pianoDaApprovare);
            }
        };
        return this.dialog.open(DialogApprovalComponent, {
            data: data,
            hasBackdrop: true,
            autoFocus: false,
            panelClass: 'dialog-responsive-full-screen',
            minWidth: '50vw',
            disableClose: true
        }).afterClosed();
    }



    /* NOT USED
        //---- MARK: CARICAMENTO ATTESTATO DA PARTE DELLO STUDENTE --//
        openInserisciAttestatoForAttivitaStudenteDialog$(corso: UIActivityI,
                                                         currentPianoInfoView: PianoDiStudiInfoView): Observable<any> {
            const activeLang = this._translocoService.getActiveLang();
            const translation = this._translocoService.getTranslation().get(activeLang);
            const data: GenericComponentDialogConfig = {
                title: corso?.denominazione,
                message: get(translation, 'study_plan_status.inserisci_documento_dottorando', null),
                icon: {
                    show: true,
                    name: 'upload',
                    color: 'primary'
                },
                actions: {
                    confirm: {
                        show: true,
                        label: get(translation, 'dialog.confirm', null), icon: 'check',
                        color: 'primary',
                        function: (form, dialogRef) =>
                            this.inserisciAttestatoAttivitaStudente(form, dialogRef, corso, currentPianoInfoView)
                    },
                    cancel: {
                        show: true,
                        label: get(translation, 'dialog.cancel', null),
                    }
                },
                dismissible: true,
                formConfig: this.formGroupSInserisciAttestato(corso),
                valueForm: {
                    file: undefined
                }
            };
            return this.dialog.open(GenericDialogComponent, {
                data: data,
                panelClass: 'dialog-responsive-full-screen',
                hasBackdrop: data.dismissible,
                disableClose: true,
            }).afterClosed();
        }
    */

    formGroupSInserisciAttestato(user): formGroupConfigInterface[] {
        return [
            {
                show: true,
                name: 'file',
                transloco: 'study_plan_status.student_document_input_name',
                required: true,
                type: TypeDialogFormEnum.FILE,
                optionFile: optionFile
            },
        ];
    }

    /*   NOT USED
        inserisciAttestatoAttivitaStudente(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>,
                                           corso: UIActivityI, currentPianoInfoView: PianoDiStudiInfoView): void {

          /!*  if (form.enabled) {
                form.disable({emitEvent: false});
                this.pianiDiStudiService.addAttestatoAttivitaExtraCustomPianoDiStudiForm(
                    currentPianoInfoView?.id,
                    corso?.idAttivitaExtraCustom,
                    form.getRawValue().file).pipe(
                    finalize(() => form.enable())
                ).subscribe({
                    next: (pianoDiStudi: PianoDiStudiInfoView) => {
                        dialogRef.close(pianoDiStudi);
                        const activeLang = this._translocoService.getActiveLang();
                        const translation = this._translocoService.getTranslation().get(activeLang);
                        const message = get(translation, 'common.operation_success', null);
                        this.fuseConfirmationService.openSnackBar({message: message});
                    },
                    error: (err: HttpErrorResponse) => {
                        const activeLang = this._translocoService.getActiveLang();
                        const translation = this._translocoService.getTranslation().get(activeLang);
                        const message = get(translation, 'common.not_loading_data', null);
                        this.fuseConfirmationService.openSnackBar({
                            message: err?.error?.message || message,
                            error: err, type: SnackbarTypes.Error
                        });
                    }
                });
            }*!/
        }*/

    //---- ENDMARK: CARICAMENTO ATTESTATO DA PARTE DELLO STUDENTE--//

    //---- MARK: CARICAMENTO DELIBERA DA PARTE DEL SUPERVISORE--//
    /*    NOT USED
        openInserisciDeliberaForAttivitaStudenteDialog$(corso: UIActivityI,
                                                        currentPianoInfoView: PianoDiStudiInfoView): Observable<any> {
            const activeLang = this._translocoService.getActiveLang();
            const translation = this._translocoService.getTranslation().get(activeLang);
            const data: GenericComponentDialogConfig = {
                title: corso?.denominazione,
                message: get(translation, 'study_plan_status.confirm_passed_student_activity_message', null),
                icon: {
                    show: true,
                    name: 'done',
                    color: 'primary'
                },
                actions: {
                    confirm: {
                        show: true,
                        label: get(translation, 'dialog.confirm', null), icon: 'check',
                        color: 'primary',
                        function: (form, dialogRef) =>
                            this.inserisciDeliberaAttivitaStudente(form, dialogRef, corso, currentPianoInfoView)
                    },
                    cancel: {
                        show: true,
                        label: get(translation, 'dialog.cancel', null),
                    }
                },
                dismissible: true,
                formConfig: this.formGroupSInserisciDelibera(corso, translation),
                valueForm: {
                    file: undefined,
                    nota_superamento: null,
                    data_superamento: (moment().format('DD/MM/YYYY')),
                    cfu_ottenuti: corso.cfuPrevisti,
                }
            };
            return this.dialog.open(GenericDialogComponent, {
                data: data,
                panelClass: 'dialog-responsive-full-screen',
                hasBackdrop: data.dismissible,
                disableClose: true,
            }).afterClosed();
        }*/

    formGroupSInserisciDelibera(corso, translation: Translation): formGroupConfigInterface[] {
        return [
            {
                show: true,
                name: 'file',
                transloco: 'student.approval',
                required: true,
                type: TypeDialogFormEnum.FILE,
                optionFile: optionFile
            },
            {
                show: true,
                name: 'nota_superamento',
                required: false,
                transloco: 'study_plan_status.successful_pass_note',
                type: TypeDialogFormEnum.TEXTAREA
            },
            {
                show: true,
                name: 'data_superamento',
                required: true,
                transloco: 'study_plan_status.date_of_successful_pass',
                type: TypeDialogFormEnum.DATA,
                dateMax: this.dataMaxSuperamentoCorso,
            },
            {
                show: true,
                name: 'cfu_ottenuti',
                required: true,
                transloco: 'common.cfu_obtained',
                type: TypeDialogFormEnum.NUMBER,
                max: {
                    number: corso?.cfuPrevisti,
                    text: get(translation, 'form.cfu_max', null)
                },
                min: {
                    number: 0.1,
                    text: get(translation, 'form.cfu_min', null)
                },
                onlyPositiveWithOneDecimal: true,

            },
        ];
    }


    /*   NOT USED
    inserisciDeliberaAttivitaStudente(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>,
                                         corso: UIActivityI, currentPianoInfoView: PianoDiStudiInfoView): void {

           /!*if (form.enabled) {
               form.disable({emitEvent: false});
               const bodyRequest: CorsoPianoDiStudiInfoViewSuperamentoRequest = {
                   data_superamento: form?.get('data_superamento')?.value,
                   cfu_ottenuti: form?.get('cfu_ottenuti')?.value,
                   nota_superamento: form?.get('nota_superamento')?.value,
               }
               this.pianiDiStudiService.addDeliberaAttivitaExtraCustomPianoDiStudiForm(
                   currentPianoInfoView?.id,
                   corso?.idAttivitaExtraCustom,
                   bodyRequest,
                   form.getRawValue().file).pipe(
                   finalize(() => form.enable())
               ).subscribe({
                   next: (pianoDiStudi: PianoDiStudiInfoView) => {
                       dialogRef.close(pianoDiStudi);
                       const activeLang = this._translocoService.getActiveLang();
                       const translation = this._translocoService.getTranslation().get(activeLang);
                       const message = get(translation, 'common.operation_success', null);
                       this.fuseConfirmationService.openSnackBar({message: message});
                   },
                   error: (err: HttpErrorResponse) => {
                       const activeLang = this._translocoService.getActiveLang();
                       const translation = this._translocoService.getTranslation().get(activeLang);
                       const message = get(translation, 'common.not_loading_data', null);
                       this.fuseConfirmationService.openSnackBar({
                           message: err?.error?.message || message,
                           error: err, type: SnackbarTypes.Error
                       });
                   }
               });
           }*!/
       }*/

    //---- ENDMARK: CARICAMENTO DELIBERA DA PARTE DEL SUPERVISORE --//

    //---- MARK: RIFIUTO ATTIVITA STUDENTE DA PARTE DEL SUPERVISORE --//

    /*    NOT USED
         openModaleRifiutoAttivitaStudente$(corso: UIActivityI,
                                           currentPianoInfoView: PianoDiStudiInfoView): Observable<any> {
            const activeLang = this._translocoService.getActiveLang();
            const translation = this._translocoService.getTranslation().get(activeLang);
            const data: GenericComponentDialogConfig = {
                title: corso?.denominazione,
                message: get(translation, 'study_plan_status.refuse_student_activity_message', null),
                icon: {
                    show: true,
                    name: 'delete',
                    color: 'primary'
                },
                readOnly: false,
                actions: {
                    confirm: {
                        show: true,
                        label: get(translation, 'dialog.confirm', null), icon: 'check',
                        color: 'primary',
                        function: (form, dialogRef) =>
                            this.rifiutaAttestatoAttivitaStudente(form, dialogRef, corso, currentPianoInfoView)
                    },
                    cancel: {
                        show: true,
                        label: get(translation, 'dialog.cancel', null),
                    }
                },
                dismissible: true,
                formConfig: [
                    {
                        show: true,
                        name: 'motivazione_rifiuto',
                        required: true,
                        transloco: 'study_plan_status.reasons_for_refusal',
                        type: TypeDialogFormEnum.TEXTAREA
                    },
                    {
                        show: false,
                        name: 'corso',
                        required: true,
                    }],
                valueForm: {
                    corso: corso,
                    motivazione_rifiuto: null
                }
            };
            return this.dialog.open(GenericDialogComponent, {
                data: data,
                panelClass: 'dialog-responsive',
                hasBackdrop: data.dismissible,
                disableClose: true,
            }).afterClosed();
        }*/


    /*    NOT USED
    rifiutaAttestatoAttivitaStudente(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>,
                                         corso: UIActivityI, currentPianoInfoView: PianoDiStudiInfoView): void {

           /!* if (form.enabled) {
                form.disable({emitEvent: false});
                const requestBody: CorsoPianoDiStudiInfoViewRifiutoAttestatoRequest = {
                    motivazione_rifiuto: form?.get('motivazione_rifiuto')?.value
                }
                this.pianiDiStudiService.rifiutaAttestatoAttivitaExtraCustomPianoDiStudi(
                    requestBody,
                    currentPianoInfoView?.id,
                    corso?.idAttivitaExtraCustom).pipe(
                    finalize(() => form.enable())
                ).subscribe({
                    next: (pianoDiStudi: PianoDiStudiInfoView) => {
                        dialogRef.close(pianoDiStudi);
                        const activeLang = this._translocoService.getActiveLang();
                        const translation = this._translocoService.getTranslation().get(activeLang);
                        const message = get(translation, 'common.operation_success', null);
                        this.fuseConfirmationService.openSnackBar({message: message});
                    },
                    error: (err: HttpErrorResponse) => {
                        const activeLang = this._translocoService.getActiveLang();
                        const translation = this._translocoService.getTranslation().get(activeLang);
                        const message = get(translation, 'common.not_loading_data', null);
                        this.fuseConfirmationService.openSnackBar({
                            message: err?.error?.message || message,
                            error: err, type: SnackbarTypes.Error
                        });
                    }
                });
            }*!/
        }*/


    //---- ENDMARK: RIFIUTO ATTIVITA STUDENTE DA PARTE DEL SUPERVISORE --//

    //---- MARK: REGISTRA SUPERAMENTO ATTIVITA STUDENTE DA PARTE DEL SUPERVISORE --//


    /*
    NOT USED
    openModaleConfermaSuperamentoAttivitaStudente$(corso: UIActivityI,
                                                       currentPianoInfoView: PianoDiStudiInfoView): Observable<any> {
            const activeLang = this._translocoService.getActiveLang();
            const translation = this._translocoService.getTranslation().get(activeLang);
            const data: GenericComponentDialogConfig = {
                title: corso?.denominazione,
                message: get(translation, 'study_plan_status.confirm_passed_student_activity_message', null),
                icon: {
                    show: true,
                    name: 'done',
                    color: 'primary'
                },
                readOnly: false,
                actions: {
                    confirm: {
                        show: true,
                        label: get(translation, 'dialog.confirm', null), icon: 'check',
                        color: 'primary',
                        function: (form, dialogRef) =>
                            this.confermaSuperamentoAttivitaStudente(form, dialogRef, corso, currentPianoInfoView)
                    },
                    cancel: {
                        show: true,
                        label: get(translation, 'dialog.cancel', null),
                    }
                },
                dismissible: true,
                formConfig: [
                    {
                        show: true,
                        name: 'nota_superamento',
                        required: false,
                        transloco: 'student.activity_executed_note',
                        type: TypeDialogFormEnum.TEXTAREA
                    },
                    {
                        show: true,
                        name: 'data_superamento',
                        required: true,
                        transloco: 'student.activity_executed_date',
                        type: TypeDialogFormEnum.DATA,
                        dateMax: this.dataMaxSuperamentoCorso,
                    },
                    {
                        show: true,
                        name: 'cfu_ottenuti',
                        required: true,
                        transloco: 'common.cfu_obtained',
                        type: TypeDialogFormEnum.NUMBER,
                        max: {
                            number: corso?.cfuPrevisti,
                            text: get(translation, 'form.cfu_max', null)
                        },
                        min: {
                            number: 0.1,
                            text: get(translation, 'form.cfu_min', null)
                        },
                        onlyPositiveWithOneDecimal: true,

                    },
                    {
                        show: false,
                        name: 'corso',
                        required: true,
                    }],
                valueForm: {
                    corso: corso,
                    nota_superamento: null,
                    data_superamento: (moment().format('DD/MM/YYYY')),
                    cfu_ottenuti: corso.cfuPrevisti,
                }
            };
            return this.dialog.open(GenericDialogComponent, {
                data: data,
                panelClass: 'dialog-responsive',
                hasBackdrop: data.dismissible,
                disableClose: true,
            }).afterClosed();
        }*/


    /* NOT USED
        confermaSuperamentoAttivitaStudente(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>,
                                            corso: UIActivityI, currentPianoInfoView: PianoDiStudiInfoView): void {

       /!*     if (form.enabled) {
                form.disable({emitEvent: false});
                const requestBody: CorsoPianoDiStudiInfoViewSuperamentoRequest = {
                    data_superamento: form?.get('data_superamento')?.value,
                    cfu_ottenuti: form?.get('cfu_ottenuti')?.value,
                    nota_superamento: form?.get('nota_superamento')?.value,
                };
                this.pianiDiStudiService.registraSuperamentoAttivitaExtraCustomPianoDiStudi(
                    requestBody,
                    currentPianoInfoView?.id,
                    corso?.idAttivitaExtraCustom).pipe(
                    finalize(() => form.enable())
                ).subscribe({
                    next: (pianoDiStudi: PianoDiStudiInfoView) => {
                        dialogRef.close(pianoDiStudi);
                        const activeLang = this._translocoService.getActiveLang();
                        const translation = this._translocoService.getTranslation().get(activeLang);
                        const message = get(translation, 'common.operation_success', null);
                        this.fuseConfirmationService.openSnackBar({message: message});
                    },
                    error: (err: HttpErrorResponse) => {
                        const activeLang = this._translocoService.getActiveLang();
                        const translation = this._translocoService.getTranslation().get(activeLang);
                        const message = get(translation, 'common.not_loading_data', null);
                        this.fuseConfirmationService.openSnackBar({
                            message: err?.error?.message || message,
                            error: err, type: SnackbarTypes.Error
                        });
                    }
                });
            }*!/
        }
    */


    /*    NOT USED //---- ENDMARK: REGISTRA SUPERAMENTO ATTIVITA STUDENTE DA PARTE DEL SUPERVISORE --//
        scaricaDocumento(idPianoDiStudi: string, attivitaStudente: UIActivityI, isAttestatoNotDelibera): void {
            /!*const activeLang = this._translocoService.getActiveLang();
            const translation = this._translocoService.getTranslation().get(activeLang);
            const nomeFile = !!isAttestatoNotDelibera ? attivitaStudente.attestatoAttivitaExtra : attivitaStudente.deliberaAttivitaExtra;
            const ext = nomeFile.substr(nomeFile.lastIndexOf('.') + 1);
            const imageExt= ['png', 'jpeg', 'jpg'];
            const textExt= ['pdf'];
            const typeBlob= imageExt.includes(ext)? ('image/'+ext): textExt.includes(ext)?('application/'+ext): '';
            if(isEmpty(typeBlob)){
                this.fuseConfirmationService.openSnackBar({
                    message: 'student.file_download_error',
                    type: SnackbarTypes.Error
                });
            } else {
                this.fuseConfirmationService.showLoader();
                this.pianiDiStudiService.getAllegatoAttivitaExtraCustomPianoDiStudi(
                    idPianoDiStudi, attivitaStudente.idAttivitaExtraCustom, nomeFile
                ).pipe(
                    finalize(() => {
                        this.fuseConfirmationService.hideLoader();
                    })
                ).subscribe({
                    next: (file: Blob) => {
                        const fileNameToDownload = makeFilename(nomeFile);
                        openFileInBlankWindow(file, fileNameToDownload);
                    },
                    error: (err) => {
                        console.error(err);
                        this.fuseConfirmationService.openSnackBar({
                            message: get(translation, 'student.file_download_error', null),
                            type: SnackbarTypes.Warning,
                        });
                    }
                });
            }*!/
        }

        openInfoForExtraActivityCustom(event: ClickEvent): void {
            const data: DialogInfoI = this.buildInfoExtraActivityCustomForDIalog(event.value);
            this.dialog.open(DialogInfoComponent, {
                data,
                panelClass: 'dialog-responsive-full-screen',
                minWidth: '80vw'
            });
        }*/

    openInfoForTransversalActivityCustom(event: ClickEvent, currentRuolo: AuthorityType): void {
        const data: DialogInfoI = this.buildInfoTransversalActivityForDIalog(event.value, currentRuolo);
        this.dialog.open(DialogInfoComponent, {
            data,
            panelClass: 'dialog-responsive-full-screen',
            minWidth: '80vw'
        });
    }

    buildInfoTransversalActivityForDIalog(transversalActivity: UIActivityI, currentRuolo: AuthorityType): DialogInfoI {
        return {
            showImage: false,
            title: transversalActivity?.denominazione,
            icon: {
                name: 'mat_outline:info',
                color: 'primary'
            },
            ...this.infoAggiuntiveTransversal(transversalActivity, currentRuolo)
        };
    }

    /*    NOT USED
        buildInfoExtraActivityCustomForDIalog(attivitaExtraCustom: UIActivityI): DialogInfoI {
            return {
                showImage: false,
                title: attivitaExtraCustom?.denominazione,
                icon: {
                    name: 'mat_outline:info',
                    color: 'primary'
                },
                ...this.infoAggiuntiveCorso(attivitaExtraCustom)
            };
        }*/


    infoAggiuntiveTransversal(corso: UIActivityI, currentRuolo: AuthorityType) {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const yesString = get(translation, 'common.yes', null);
        const noString = get(translation, 'common.no', null);
        const data = {
            readonlyData: [
                {
                    key: 'common.detail',
                    value: corso?.dettaglioAttivitaTrasversale,
                },
                {
                    key: 'common.description',
                    value: corso?.descrizione,
                    hide: !corso?.descrizione
                },
                {
                    key: 'common.note',
                    value: corso?.note,
                    hide: !corso?.note,
                },
                {
                    key: 'cycle_doctorate.activity_typology',
                    value: corso?.denominazione,
                },
                {
                    key: 'study_plan_status.number_of_credits_obtained_and_required',
                    value: (!!corso?.cfuOttenuti ? corso?.cfuOttenuti : 0) + '/' +
                        (corso?.cfuPrevisti || 0)
                },
                {
                    key: 'common.hours',
                    value: corso?.ore,
                    hide: !corso?.ore,
                },
                /*{
                    key: 'cycle_doctorate.student_document_required',
                    value: corso?.presenzaDocumentoDottorando ? yesString : noString,
                },*/
                {
                    key: 'cycle_doctorate.student_document',
                    value: corso?.tipoDocumentoDottorando || '-',
                },
                /*                {
                                    key: 'cycle_doctorate.supervisor_document_required',
                                    value: corso?.presenzaDocumentoSupervisore ? yesString : noString,
                                },*/
                {
                    key: 'cycle_doctorate.supervisor_document',
                    value: corso?.tipoDocumentoSupervisore || '-',
                },
                {
                    key: 'cycle_doctorate.coordinator_required',
                    value: corso?.presenzaDeliberaCoordinatore ? yesString : noString,
                },
                {
                    key: 'student.executed',
                    value: corso?.superato ? yesString : noString,
                },
                {
                    key: 'student.activity_executed_date',
                    value: corso?.dataSuperamento,
                    hide: !corso?.superato,
                },
                {
                    key: 'student.activity_executed_note',
                    value: corso?.notaSuperamento,
                    hide: !corso?.superato,
                },
                {
                    key: 'study_plan_status.reasons_for_refusal',
                    value: corso?.motivazioneRifiuto,
                    hide: !corso?.motivazioneRifiuto
                },
                {
                    key: 'budget.approval_date',
                    value: !!corso?.deliberaCoordinatoreAttivitaTrasversale?.data ?
                        moment(corso?.deliberaCoordinatoreAttivitaTrasversale?.data, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
                    hide: !corso?.deliberaCoordinatoreAttivitaTrasversale?.data || currentRuolo === AuthorityType.STUDENTE
                },
                {
                    key: 'budget.approval_number',
                    value: corso?.deliberaCoordinatoreAttivitaTrasversale?.numero,
                    hide: !corso?.deliberaCoordinatoreAttivitaTrasversale?.numero || currentRuolo === AuthorityType.STUDENTE
                },
            ],
        };
        return data;
    };


    // -- FLUSSO APPROVAZIONE ATTIVITA TRASVERSALI --//
    openInserisciDocDottorandoForAttivitaTrasversaleDialog$(corso: UIActivityI,
                                                            currentPianoInfoView: PianoDiStudiInfoView,
                                                            category: CategoriaInPianoDiStudiInfoView): Observable<any> {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const messageLoadDoc = get(translation, 'study_plan_status.inserisci_documento', null);
        const documentType = corso.tipoDocumentoDottorando || '';
        const data: GenericComponentDialogConfig = {
            title: corso?.denominazione,
            message: messageLoadDoc + ' ' + documentType,
            icon: {
                show: true,
                name: 'upload',
                color: 'primary'
            },
            actions: {
                confirm: {
                    show: true,
                    label: get(translation, 'dialog.confirm', null), icon: 'check',
                    color: 'primary',
                    function: (form, dialogRef) =>
                        this.inserisciDocDottorandoAttivitaTrasversale(form, dialogRef, corso, currentPianoInfoView, category.idCategoriaOffertaFormativa)
                },
                cancel: {
                    show: true,
                    label: get(translation, 'dialog.cancel', null),
                }
            },
            dismissible: true,
            formConfig: this.formGroupDocDottorandoAttivitaTrasversale(corso),
            valueForm: {
                file: undefined,
                ore: corso?.ore,
                cfuPrevisti: corso?.cfuPrevisti
            }
        };
        return this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        }).afterClosed();
    }

    formGroupDocDottorandoAttivitaTrasversale(user): formGroupConfigInterface[] {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        return [
            {
                show: true,
                name: 'file',
                transloco: 'study_plan_status.document',
                required: true,
                type: TypeDialogFormEnum.FILE,
                optionFile: optionFile
            },
            {
                show: true,
                name: 'ore',
                transloco: 'common.hours',
                type: TypeDialogFormEnum.NUMBER,
                min: {number: 1, text: this.minValHoursErrorMessage},
                onlyPositiveIntegers: true,
            },
            {
                show: true,
                name: 'cfuPrevisti',
                transloco: 'cycle_doctorate.number_of_credits_required',
                type: TypeDialogFormEnum.NUMBER,
                onlyPositiveWithOneDecimal: true,
                max: {
                    number: 60,
                    text: get(translation, 'form.cfu_max_60', null)
                },
                min: {
                    number: 0.1,
                    text: get(translation, 'form.cfu_min', null)
                },
            },
        ];
    }

    inserisciDocDottorandoAttivitaTrasversale(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>,
                                              corso: UIActivityI, currentPianoInfoView: PianoDiStudiInfoView,
                                              idCategoriaOffertaFormativa: string): void {
        if (form.enabled) {
            const docDottorandoRequestBody: AddDocumentoDottorandoAttivitaTrasversaleRequest = {
                ore: form.getRawValue().ore,
                cfu_previsti: form.getRawValue().cfuPrevisti,
                id_categoria_offerta_formativa: idCategoriaOffertaFormativa
            }
            form.disable({emitEvent: false});
            this.pianiDiStudiService.addDocumentoDottorandoAttivitaTrasversaleForm(
                currentPianoInfoView?.id,
                docDottorandoRequestBody,
                form.getRawValue().file,
                corso?.idAttivitaTrasversale,
                corso?.idAttivitaPresunta,
                corso?.idAttivitaProposta,
            ).pipe(
                finalize(() => form.enable())
            ).subscribe({
                next: (pianoDiStudi: PianoDiStudiInfoView) => {
                    dialogRef.close(pianoDiStudi);
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.operation_success', null);
                    this.fuseConfirmationService.openSnackBar({message: message});
                },
                error: (err: HttpErrorResponse) => {
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.not_loading_data', null);
                    this.fuseConfirmationService.openSnackBar({
                        message: err?.error?.message || message,
                        error: err, type: SnackbarTypes.Error
                    });
                }
            });
        }
    }


    openInserisciDeliberaForAttivitaTrasversaleDialog$(corso: UIActivityI,
                                                       currentPianoInfoView: PianoDiStudiInfoView): Observable<PianoDiStudiInfoView> {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const data: DialogCoordinatorAddDeliberaI = {
            title: corso?.denominazione,
            message: get(translation, 'study_plan_status.inserisci_delibera', null),
            icon: {
                name: 'upload',
                color: 'primary'
            },
            currentPiano: currentPianoInfoView,
            corso: corso
        };
        const dialogRef = this.dialog.open(DialogCoordinatorAddDeliberaRequestComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            autoFocus: false
        });
        return dialogRef.afterClosed();
    }


    openModaleRifiutoDocStudenteAttivitaTrasversale$(corso: UIActivityI,
                                                     currentPianoInfoView: PianoDiStudiInfoView,
                                                     category: CategoriaInPianoDiStudiInfoView): Observable<any> {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const message = get(translation, 'budget.refuse', null);
        const data: GenericComponentDialogConfig = {
            title: corso?.denominazione,
            message: message,
            icon: {
                show: true,
                name: 'close',
                color: 'warn'
            },
            actions: {
                confirm: {
                    show: true,
                    label: get(translation, 'dialog.confirm', null), icon: 'check',
                    color: 'primary',
                    function: (form, dialogRef) =>
                        this.rifiutaDocStudenteAttivitaTrasversale(form, dialogRef, corso, currentPianoInfoView, category.idCategoriaOffertaFormativa)
                },
                cancel: {
                    show: true,
                    label: get(translation, 'dialog.cancel', null),
                }
            },
            dismissible: true,
            formConfig: this.formGroupRifiutoDocStudenteAttivitaTrasversale(corso),
            valueForm: {
                refuseReason: undefined
            }
        };
        return this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        }).afterClosed();
    }

    formGroupRifiutoDocStudenteAttivitaTrasversale(user): formGroupConfigInterface[] {
        return [
            {
                show: true,
                name: 'motivazioneRifiuto',
                required: true,
                transloco: 'budget.refuse_reason',
                type: TypeDialogFormEnum.TEXT,
            },
        ];
    }

    rifiutaDocStudenteAttivitaTrasversale(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>,
                                          corso: CorsoPianoDiStudiInfoView, currentPianoInfoView: PianoDiStudiInfoView,
                                          idCategoriaOffertaFormativa: string): void {
        if (form.enabled) {
            const rifiutaDocStudenteBody: VerificaDocumentoDottorandoAttivitaTrasversaleRequest = {
                approvato: false,
                motivazione_rifiuto: form.getRawValue().motivazioneRifiuto,
                id_categoria_offerta_formativa: idCategoriaOffertaFormativa
            }
            form.disable({emitEvent: false});
            this.pianiDiStudiService.verificaDocumentoDottorandoAttivitaTrasversale(
                rifiutaDocStudenteBody,
                currentPianoInfoView?.id,
                corso?.idAttivitaTrasversale,
                corso?.idAttivitaPresunta,
                corso?.idAttivitaProposta,
            ).pipe(
                finalize(() => form.enable())
            ).subscribe({
                next: (pianoDiStudi: PianoDiStudiInfoView) => {
                    dialogRef.close(pianoDiStudi);
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.operation_success', null);
                    this.fuseConfirmationService.openSnackBar({message: message});
                },
                error: (err: HttpErrorResponse) => {
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.not_loading_data', null);
                    this.fuseConfirmationService.openSnackBar({
                        message: err?.error?.message || message,
                        error: err, type: SnackbarTypes.Error
                    });
                }
            });
        }
    }


    openModaleAccettaDocStudenteAttivitaTrasversale$(corso: UIActivityI,
                                                     currentPianoInfoView: PianoDiStudiInfoView,
                                                     category?: CategoriaInPianoDiStudiInfoView): Observable<any> {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const message = get(translation, 'study_plan_status.accept_document_transversal', null);
        const data: GenericComponentDialogConfig = {
            title: corso?.denominazione,
            message: message,
            icon: {
                show: true,
                name: 'info',
                color: 'info'
            },
            actions: {
                confirm: {
                    show: true,
                    label: get(translation, 'dialog.confirm', null),
                    icon: 'check',
                    color: 'primary',
                    function: (form, dialogRef) =>
                        this.accettaDocStudenteAttivitaTrasversale(form, dialogRef, corso, currentPianoInfoView, category.idCategoriaOffertaFormativa)
                },
                cancel: {
                    show: true,
                    label: get(translation, 'dialog.cancel', null),
                }
            },
            dismissible: true,
            formConfig: this.formGroupAccettaDocStudenteAttivitaTrasversale(corso),
            valueForm: {
                hiddenField: undefined
            }
        };
        return this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        }).afterClosed();
    }

    formGroupAccettaDocStudenteAttivitaTrasversale(user): formGroupConfigInterface[] {
        return [
            {
                show: false,
                name: 'hiddenField',
                type: TypeDialogFormEnum.TEXT,
            },
        ];
    }

    accettaDocStudenteAttivitaTrasversale(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>,
                                          corso: CorsoPianoDiStudiInfoView, currentPianoInfoView: PianoDiStudiInfoView, idCcategoriaOffertaFormativa: string): void {
        if (form.enabled) {
            const accettaDocStudenteBody: VerificaDocumentoDottorandoAttivitaTrasversaleRequest = {
                approvato: true,
                id_categoria_offerta_formativa: idCcategoriaOffertaFormativa
            }
            form.disable({emitEvent: false});
            this.pianiDiStudiService.verificaDocumentoDottorandoAttivitaTrasversale(
                accettaDocStudenteBody,
                currentPianoInfoView?.id,
                corso?.idAttivitaTrasversale,
                corso?.idAttivitaPresunta,
                corso?.idAttivitaProposta,
            ).pipe(
                finalize(() => form.enable())
            ).subscribe({
                next: (pianoDiStudi: PianoDiStudiInfoView) => {
                    dialogRef.close(pianoDiStudi);
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.operation_success', null);
                    this.fuseConfirmationService.openSnackBar({message: message});
                },
                error: (err: HttpErrorResponse) => {
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.not_loading_data', null);
                    this.fuseConfirmationService.openSnackBar({
                        message: err?.error?.message || message,
                        error: err, type: SnackbarTypes.Error
                    });
                }
            });
        }
    }

    openModaleRichiediSuperamentoAttivitaTrasversale$(corso: CorsoPianoDiStudiInfoView & {
                                                          idCategoriaOffertaFormativa: string
                                                      },
                                                      currentPianoInfoView: PianoDiStudiInfoView): Observable<any> {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const message = get(translation, 'study_plan_status.require_approval', null);
        const data: GenericComponentDialogConfig = {
            title: corso?.denominazione,
            message: message,
            icon: {
                show: true,
                name: 'info',
                color: 'info'
            },
            actions: {
                confirm: {
                    show: true,
                    label: get(translation, 'dialog.confirm', null), icon: 'check',
                    color: 'primary',
                    function: (form, dialogRef) =>
                        this.richiediSuperamentoAttivitaTrasversale(form, dialogRef, corso, currentPianoInfoView)
                },
                cancel: {
                    show: true,
                    label: get(translation, 'dialog.cancel', null),
                }
            },
            dismissible: true,
            formConfig: this.formGroupRichiediSuperamentoAttivitaTrasversale(corso),
            valueForm: {
                ore: corso?.ore,
                cfuPrevisti: corso?.cfuPrevisti,
            }
        };
        return this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        }).afterClosed();
    }

    formGroupRichiediSuperamentoAttivitaTrasversale(user): formGroupConfigInterface[] {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        return [
            {
                show: true,
                name: 'ore',
                transloco: 'common.hours',
                type: TypeDialogFormEnum.NUMBER,
                min: {number: 1, text: this.minValHoursErrorMessage},
                onlyPositiveIntegers: true,
            },
            {
                show: true,
                name: 'cfuPrevisti',
                transloco: 'cycle_doctorate.number_of_credits_required',
                type: TypeDialogFormEnum.NUMBER,
                onlyPositiveWithOneDecimal: true,
                max: {
                    number: 60,
                    text: get(translation, 'form.cfu_max_60', null)
                },
                min: {
                    number: 0.1,
                    text: get(translation, 'form.cfu_min', null)
                },
            },
        ];
    }

    richiediSuperamentoAttivitaTrasversale(form: FormGroup,
                                           dialogRef: MatDialogRef<GenericDialogComponent>,
                                           corso: CorsoPianoDiStudiInfoView & { idCategoriaOffertaFormativa: string },
                                           currentPianoInfoView: PianoDiStudiInfoView): void {
        if (form.enabled) {
            const richiediSuperamentoBody: RichiestaSuperamentoAttivitaTrasversaleRequest = {
                ore: form.getRawValue().ore,
                cfu_previsti: form.getRawValue().cfuPrevisti,
                id_categoria_offerta_formativa: corso.idCategoriaOffertaFormativa
            }
            form.disable({emitEvent: false});
            this.pianiDiStudiService.richiediSuperamentoAttivitaTrasversale(
                richiediSuperamentoBody,
                currentPianoInfoView?.id,
                corso?.idAttivitaTrasversale,
                corso?.idAttivitaPresunta,
                corso?.idAttivitaProposta,
            ).pipe(
                finalize(() => form.enable())
            ).subscribe({
                next: (pianoDiStudi: PianoDiStudiInfoView) => {
                    dialogRef.close(pianoDiStudi);
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.operation_success', null);
                    this.fuseConfirmationService.openSnackBar({message: message});
                },
                error: (err: HttpErrorResponse) => {
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.not_loading_data', null);
                    this.fuseConfirmationService.openSnackBar({
                        message: err?.error?.message || message,
                        error: err, type: SnackbarTypes.Error
                    });
                }
            });
        }
    }

    openModaleRegistraSuperamentoAttivitaTrasversale$(corso: UIActivityI,
                                                      currentPianoInfoView: PianoDiStudiInfoView,
                                                      category?: CategoriaInPianoDiStudiInfoView): Observable<any> {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const message = get(translation, 'study_plan_status.register_passed', null);
        const data: GenericComponentDialogConfig = {
            title: corso?.denominazione,
            message: message,
            icon: {
                show: true,
                name: 'done',
                color: 'success'
            },
            actions: {
                confirm: {
                    show: true,
                    label: get(translation, 'dialog.confirm', null),
                    icon: 'check',
                    color: 'primary',
                    function: (form, dialogRef) =>
                        this.registraSuperamentoAttivitaTrasversale(form, dialogRef, corso, currentPianoInfoView, category.idCategoriaOffertaFormativa)
                },
                cancel: {
                    show: true,
                    label: get(translation, 'dialog.cancel', null),
                }
            },
            dismissible: true,
            formConfig: this.formGroupRegistraSuperamentoAttivitaTrasversale(corso),
            valueForm: {
                ore: corso?.ore,
                cfuOttenuti: corso?.cfuPrevisti,
                dataSuperamento: moment().format('DD/MM/YYYY'),
                notaSuperamento: undefined
            }
        };
        return this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        }).afterClosed();
    }

    formGroupRegistraSuperamentoAttivitaTrasversale(corso: UIActivityI): formGroupConfigInterface[] {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        return [
            {
                show: true,
                name: 'ore',
                transloco: 'common.hours',
                type: TypeDialogFormEnum.NUMBER,
                min: {number: 1, text: this.minValHoursErrorMessage},
                onlyPositiveIntegers: true,
            },
            {
                show: true,
                name: 'cfuOttenuti',
                required: true,
                transloco: 'common.cfu_obtained',
                type: TypeDialogFormEnum.NUMBER,
                onlyPositiveWithOneDecimal: true,
                max: {
                    number: corso?.cfuPrevisti,
                    text: get(translation, 'form.cfu_max', null)
                },
                min: {
                    number: 0.1,
                    text: get(translation, 'form.cfu_min', null)
                },
            },
            {
                show: true,
                name: 'dataSuperamento',
                required: true,
                transloco: 'student.activity_executed_date',
                type: TypeDialogFormEnum.DATA,
            },
            {
                show: true,
                name: 'notaSuperamento',
                transloco: 'student.activity_executed_note',
                type: TypeDialogFormEnum.TEXT,
            },
        ];
    }

    registraSuperamentoAttivitaTrasversale(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>,
                                           corso: UIActivityI, currentPianoInfoView: PianoDiStudiInfoView,
                                           idCategoriaOffertaFormativa: string): void {
        if (form.enabled) {
            const superamentoBody: SuperamentoAttivitaTrasversalePianoDiStudiRequest = {
                ore: form.getRawValue().ore,
                cfu_ottenuti: form.getRawValue().cfuOttenuti,
                data_superamento: form.getRawValue().dataSuperamento,
                nota_superamento: form.getRawValue().notaSuperamento,
                id_categoria_offerta_formativa: idCategoriaOffertaFormativa
            }
            form.disable({emitEvent: false});
            this.pianiDiStudiService.registraSuperamentoAttivitaTrasversalePianoDiStudi(
                superamentoBody,
                currentPianoInfoView?.id,
                corso?.idAttivitaTrasversale,
                corso?.idAttivitaPresunta,
                corso?.idAttivitaProposta,
            ).pipe(
                finalize(() => form.enable())
            ).subscribe({
                next: (pianoDiStudi: PianoDiStudiInfoView) => {
                    dialogRef.close(pianoDiStudi);
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.operation_success', null);
                    this.fuseConfirmationService.openSnackBar({message: message});
                },
                error: (err: HttpErrorResponse) => {
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.not_loading_data', null);
                    this.fuseConfirmationService.openSnackBar({
                        message: err?.error?.message || message,
                        error: err, type: SnackbarTypes.Error
                    });
                }
            });
        }
    }

    openModaleRegistraSuperamentoConDocAttivitaTrasversale$(corso: UIActivityI,
                                                            currentPianoInfoView: PianoDiStudiInfoView,
                                                            category: CategoriaInPianoDiStudiInfoView): Observable<any> {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const message = get(translation, 'study_plan_status.register_passed_with_doc', null);
        const data: GenericComponentDialogConfig = {
            title: corso?.denominazione,
            message: message,
            icon: {
                show: true,
                name: 'done',
                color: 'success'
            },
            actions: {
                confirm: {
                    show: true,
                    label: get(translation, 'dialog.confirm', null),
                    icon: 'check',
                    color: 'primary',
                    function: (form, dialogRef) =>
                        this.registraSuperamentoAttivitaConDocTrasversale(form, dialogRef, corso, currentPianoInfoView, category.idCategoriaOffertaFormativa)
                },
                cancel: {
                    show: true,
                    label: get(translation, 'dialog.cancel', null),
                }
            },
            dismissible: true,
            formConfig: this.formGroupRegistraSuperamentoConDocAttivitaTrasversale(corso),
            valueForm: {
                ore: corso?.ore,
                cfuOttenuti: corso?.cfuPrevisti,
                dataSuperamento: moment().format('DD/MM/YYYY'),
                notaSuperamento: undefined
            }
        };
        return this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        }).afterClosed();
    }

    formGroupRegistraSuperamentoConDocAttivitaTrasversale(corso: UIActivityI): formGroupConfigInterface[] {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        return [
            {
                show: true,
                name: 'ore',
                transloco: 'common.hours',
                type: TypeDialogFormEnum.NUMBER,
                min: {number: 1, text: this.minValHoursErrorMessage},
                onlyPositiveIntegers: true,
            },
            {
                show: true,
                name: 'cfuOttenuti',
                required: true,
                transloco: 'common.cfu_obtained',
                type: TypeDialogFormEnum.NUMBER,
                onlyPositiveWithOneDecimal: true,
                max: {
                    number: corso?.cfuPrevisti,
                    text: get(translation, 'form.cfu_max', null)
                },
                min: {
                    number: 0.1,
                    text: get(translation, 'form.cfu_min', null)
                },
            },
            {
                show: true,
                name: 'dataSuperamento',
                required: true,
                transloco: 'student.activity_executed_date',
                type: TypeDialogFormEnum.DATA,
            },
            {
                show: true,
                name: 'notaSuperamento',
                transloco: 'student.activity_executed_note',
                type: TypeDialogFormEnum.TEXT,
            },
            {
                show: true,
                name: 'file',
                transloco: 'study_plan_status.document',
                required: true,
                type: TypeDialogFormEnum.FILE,
                optionFile: optionFile
            },
        ];
    }

    registraSuperamentoAttivitaConDocTrasversale(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>,
                                                 corso: CorsoPianoDiStudiInfoView, currentPianoInfoView: PianoDiStudiInfoView,
                                                 idCategoriaOffertaFormativa: string): void {
        if (form.enabled) {
            const superamentoBody: SuperamentoAttivitaTrasversalePianoDiStudiRequest = {
                ore: form.getRawValue().ore,
                cfu_ottenuti: form.getRawValue().cfuOttenuti,
                data_superamento: form.getRawValue().dataSuperamento,
                nota_superamento: form.getRawValue().notaSuperamento,
                id_categoria_offerta_formativa: idCategoriaOffertaFormativa
            }
            form.disable({emitEvent: false});
            this.pianiDiStudiService.addDocumentoSupervisoreAttivitaTrasversaleForm(
                currentPianoInfoView?.id,
                form.getRawValue().file,
                superamentoBody,
                corso?.idAttivitaTrasversale,
                corso?.idAttivitaPresunta,
                corso?.idAttivitaProposta,
            ).pipe(
                finalize(() => form.enable())
            ).subscribe({
                next: (pianoDiStudi: PianoDiStudiInfoView) => {
                    dialogRef.close(pianoDiStudi);
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.operation_success', null);
                    this.fuseConfirmationService.openSnackBar({message: message});
                },
                error: (err: HttpErrorResponse) => {
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.not_loading_data', null);
                    this.fuseConfirmationService.openSnackBar({
                        message: err?.error?.message || message,
                        error: err, type: SnackbarTypes.Error
                    });
                }
            });
        }
    }

    scaricaAllegatoAttivitaTrasversale(idPianoDiStudi: string, attivitaStudente: CorsoPianoDiStudiInfoView,
                                       tipoFile: 'delibera' | 'documentoSupervisore' | 'documentoDottorando',
                                       idCategoriaOffertaFormativa: string): void {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        let nomeFile = '';
        let request;
        switch (tipoFile) {
            case "documentoSupervisore":
                nomeFile = attivitaStudente.documentoSupervisoreAttivitaTrasversale;
                request = this.pianiDiStudiService.getAllegatoAttivitaTrasversalePianoDiStudi(
                    idPianoDiStudi, idCategoriaOffertaFormativa, nomeFile, attivitaStudente.idAttivitaTrasversale, attivitaStudente.idAttivitaPresunta, attivitaStudente.idAttivitaProposta
                );
                break;
            case "delibera":
                this.fuseConfirmationService.openDialogDelibera({
                    delibera: attivitaStudente.deliberaCoordinatoreAttivitaTrasversale,
                    subtitle: attivitaStudente.denominazione || '',
                    codiceCorsoDiStudiEsse3: attivitaStudente.deliberaCoordinatoreAttivitaTrasversale?.codiceCorsoDiStudiEsse3,
                });
                break;
            case "documentoDottorando":
            default:
                nomeFile = attivitaStudente.documentoDottorandoAttivitaTrasversale;
                request = this.pianiDiStudiService.getAllegatoAttivitaTrasversalePianoDiStudi(
                    idPianoDiStudi, idCategoriaOffertaFormativa, nomeFile, attivitaStudente.idAttivitaTrasversale, attivitaStudente.idAttivitaPresunta, attivitaStudente.idAttivitaProposta
                );
                break;
        }
        if (request) {
            this.fuseConfirmationService.showLoader();
            request.pipe(
                finalize(() => {
                    this.fuseConfirmationService.hideLoader();
                })
            ).subscribe({
                next: (file: Blob) => {
                    const fileNameToDownload = makeFilename(nomeFile, true);
                    openFileInBlankWindow(file, fileNameToDownload);
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'budget.file_show_success', null),
                        type: SnackbarTypes.Success,
                    });
                },
                error: (err) => {
                    console.error(err);
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'student.file_download_error', null),
                        type: SnackbarTypes.Warning,
                    });
                }
            });
        }

    }

    private approvePlanRequest(docsForm: FormGroup, dialogRef: MatDialogRef<DialogApprovalComponent>, profile: AuthorityType,
                               destroy$: Subject<boolean>, pianoDaApprovare: StudyPlanAggiornamentoDataUI) {
        let http$;
        const documents = this.cicloConfigurationService.prepareDocsSubmitObject(
            pianoDaApprovare.isNuovaPresentazione ? ChiaveFlussoEnum.PrimaPresentazionePianoFormativo : ChiaveFlussoEnum.ModificaPianoFormativo,
            profile === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneSupervisore,
            docsForm.getRawValue(),
        );
        if (profile === AuthorityType.COORDINATORE) {
            const esito: EsitoRichiestaApprovazionePianoDiStudiByCoordinatore = {
                approvato: true,
                // id_delibera: isNil(this.deliberaForm?.get('deliberaCollegio')?.value) ? undefined : this.deliberaForm?.get('deliberaCollegio')?.value,
                dettagli_documenti_allegati: documents.specifications
            };
            http$ = this.pianiDiStudiService.approvaRifiutaAggiornamentoByCoordinatoreForm(
                pianoDaApprovare.idPiano,
                pianoDaApprovare.idAggiornamento,
                esito,
                documents.files
            );
        }
        if (profile === AuthorityType.SUPERVISORE || profile === AuthorityType.COSUPERVISORE) {
            const esito: EsitoRichiestaApprovazionePianoDiStudi = {
                approvato: true,
                //dettagli_documenti_allegati: documents.specifications
            };
            http$ = this.pianiDiStudiService.approvaRifiutaAggiornamentoBySupervisore(
                esito,
                pianoDaApprovare.idPiano,
                pianoDaApprovare.idAggiornamento,
                //documents.files
            );
        }
        docsForm.disable({emitEvent: false});
        http$?.pipe(
            takeUntil(destroy$),
            finalize(() => {
                docsForm.enable({emitEvent: false});
            })
        ).subscribe({
            next: (value) => {
                dialogRef.close(value);
                const activeLang = this._translocoService.getActiveLang();
                const translation = this._translocoService.getTranslation().get(activeLang);
                const message = get(translation, 'common.operation_success', null);
                this.fuseConfirmationService.openSnackBar({message: message});
            }, error: (err: HttpErrorResponse) => {
                const activeLang = this._translocoService.getActiveLang();
                const translation = this._translocoService.getTranslation().get(activeLang);
                const message = get(translation, 'common.not_loading_data', null);
                this.fuseConfirmationService.openSnackBar({
                    message: err?.error?.message || message,
                    error: err, type: SnackbarTypes.Error
                });
            }
        });
    }

    downloadDocument(urlFile: string, deliberaData?: DeliberaInfoView) {
        if(deliberaData) {
            this.fuseConfirmationService.openDialogDelibera({
                delibera: deliberaData,
                codiceCorsoDiStudiEsse3: this.studentDetailsService.studentDetails?.codiceCorsoDiStudiEsse3,
            });
        }
    }
}
