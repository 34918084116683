import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {
    ClickEvent,
    GenericTableConfigurationModel,
    TableData,
    TipoClickEnum
} from "../../../../../shared/components/table/model/generic-table-model";
import {
    AggiornamentoPianoDiStudiStatus,
    AttivitaExtraPianoDiStudiStatus,
    AuthorityType,
    CorsoPianoDiStudiPerExportViewImpl,
    ElementoOffertaFormativaType,
    EsitoRichiestaApprovazioneAttivitaExtra,
    GetAllegatoAttivitaExtraPianoDiStudiRequest,
    MediaService,
    PageCorsoPianoDiStudiPerExportViewImpl,
    PeriodoDiMobilitaStudenteStatus,
    PianiDiStudiService,
    PianoDiStudiInfoView, PianoDiStudiStudentiCicloService,
    SpesaStudenteStatus,
    StudentiCicloService
} from "../../../../../../api-clients/generated/services";
import {filter, switchMap, tap} from "rxjs/operators";
import {
    BehaviorSubject,
    catchError,
    finalize,
    map,
    Observable,
    ObservedValueOf,
    of,
    OperatorFunction,
    takeUntil
} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {TranslocoService} from "@ngneat/transloco";
import {get, isEmpty} from "lodash";
import * as moment from "moment/moment";
import {PageEvent} from "@angular/material/paginator";
import {FormGroup} from "@angular/forms";
import {LogoutService} from "../../../../../shared/service/logout.service";
import {LocalStorageService} from "../../../../../shared/service/local-storage.service";
import {ApprovazioneExtraMassivaDataI} from "../approvazione-extra-steps-interface";
import {CAN_GO_AHEAD$, CURRENT_PROFILE, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {makeFilename, openFileInBlankWindow, removeUnderscore} from "../../../../../shared/utils/utils";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
    PlanOpType
} from "../../common-steps/show-with-delibera-for-plan-step/show-with-delibera-for-plan-step.component";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {DialogInfoComponent, DialogInfoI} from "../../../../../shared/components/dialog-info/dialog-info.component";
import {Router} from "@angular/router";
import {
    AttivitaExtraFilters,
    AttivitaExtraFilterService
} from "../../../requests/superamento-attivita-extra/attivita-extra-sidebar-filter-container-request/attivita-extra-filter.service";
import {buildAttivitaExtraConfiguration, buildIdExtraForTable} from "../../../requests/requests-utils";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {HttpErrorResponse} from "@angular/common/http";
import {PathEnum} from "../../../../../app.routing";
import {NoopScrollStrategy} from "@angular/cdk/overlay";
import {
    formGroupConfigInterface,
    GenericComponentDialogConfig
} from "../../../../../layout/common/generic-components/generic-components.interface";
import {TypeDialogFormEnum} from "../../../../../layout/common/generic-components/generic-components-enum";
import {
    GenericDialogComponent
} from "../../../../../layout/common/generic-components/generic-dialog/generic-dialog.component";
import {DialogInfoManagerService} from "../../../../../shared/service/dialog-info-manager.service";


@Component({
    selector: 'app-scegli-extra-stepper',
    templateUrl: './scegli-extra.component.html',
    styleUrls: ['./scegli-extra.component.scss']
})
export class ScegliExtraComponent extends AbstractDefaultComponent implements OnInit, OnDestroy {

    @Output() toogleFilterContainer$ = new EventEmitter<{ hasToogleInternalMenu: boolean}>();

    loading: boolean;
    attivitaListConfiguration: GenericTableConfigurationModel;
    private fieldsLabelMap: Map<string, string>;
    currentFilterListChipLabels: Array<string>;
    isFirstTime: boolean;
    showMiniLoader: boolean = false;
    showTopbar: boolean;
    idRigheDaDisabilitare: string[] = [];
    private dialogButtonConfirm: any;
    private dialogButtonCancel: any;
    private dialogButtonClose: any;
    private rifiutaAttestatoTitle: any;
    private idRigheDaDisabilitareContainer: { idRigheSolaLettura: string[] } = { idRigheSolaLettura: []};


    constructor(private fuseConfirmationService: FuseConfirmationService,
                private translocoService: TranslocoService,
                private studentiCicloService: StudentiCicloService,
                private pianoDiStudiStudentiCicloService: PianoDiStudiStudentiCicloService,
                private logOutService: LogoutService,
                private filterManagerService: AttivitaExtraFilterService,
                private pianiDiStudiService: PianiDiStudiService,
                private dialog: MatDialog,
                protected mediaService: MediaService,
                protected sanitizer: DomSanitizer,
                private dialogInfoManagerService: DialogInfoManagerService,
                private router: Router,
                private localStorageService: LocalStorageService,
                @Inject(CURRENT_PROFILE) protected currentProfile: AuthorityType,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneExtraMassivaDataI>,) {
        super();
        this.translocoService.selectTranslate('dialog.confirm').subscribe(value => this.dialogButtonConfirm = value);
        this.translocoService.selectTranslate('dialog.cancel').subscribe(value => this.dialogButtonCancel = value);
        this.translocoService.selectTranslate('dialog.close').subscribe(value => this.dialogButtonClose = value);
        this.translocoService.selectTranslate('study_plan_status.rejects_activities').subscribe(value => this.rifiutaAttestatoTitle = value);
    }

    ngOnInit(): void {
        this.fieldsLabelMap = this.filterManagerService.fieldsLabelMap;
        this.isFirstTime = true;
        this.getDataAndFilterSubscribe();
        this.operazioneMassivaData$?.subscribe(inputOutputData => {
            if(inputOutputData.refreshData) {
                console.log('REFRESHING ATT IN SCEGLI ATT')
                this.attivitaListConfiguration = undefined;
                this.idRigheDaDisabilitare = [];
                this.resetRefreshData();
                this.canGoNext$.next(false);
                this.getAttRequest$(false, this.filterManagerService?.filterApplied);
            }
        });
    }

    openFilters(){
        this.filterManagerService.openDrawer.next(true)
    }

    getAttRequest$(isFirstTime?: boolean, filters?: any, page: number = 0, size: number = this.localStorageService.pageSize): void {
        this.getAttFormObs$(isFirstTime, filters, page, size).subscribe({
            next: (value) => {},
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {this.logOutService.goToHome()},
                    () => this.getAttRequest$(isFirstTime, filters, page, size),
                    'common.go_to_home',
                    err?.error?.message);
            }
        });
    }

    getAttFormObs$(isFirstTime?: boolean, filters?: AttivitaExtraFilters, page: number = 0, size: number = this.localStorageService.pageSize): Observable<PageCorsoPianoDiStudiPerExportViewImpl> {
        if (isFirstTime) {
            this.loading = true;
        } else {
            this.fuseConfirmationService.showLoader();
        }
        return this.pianoDiStudiStudentiCicloService.searchAttivitaPianiFormativiForm(
            !!filters?.annoInsegnamento ? filters?.annoInsegnamento : undefined,
            !!filters?.cfStudente ? filters?.cfStudente : undefined,
            !!filters?.denomDescrAttivita ? filters?.denomDescrAttivita : undefined,
            !!filters?.dettaglioAttivitaTrasversale ? filters?.dettaglioAttivitaTrasversale : undefined,
            !!filters?.emailStudente ? filters?.emailStudente : undefined,
            undefined,
            this.currentProfile === AuthorityType.COSUPERVISORE ? true : undefined,
            true, // is not presunta non definita
            !!filters?.nominativoStudente ? filters?.nominativoStudente : undefined,
            !!filters?.matricolaStudente ? filters?.matricolaStudente : undefined,
            [AttivitaExtraPianoDiStudiStatus.DAAPPROVARE, AttivitaExtraPianoDiStudiStatus.ATTESTATOPRESENTATO],
            undefined,
            ElementoOffertaFormativaType.ATTIVITAEXTRA,
            page, size, undefined).pipe(
            tap((pageAtt: PageCorsoPianoDiStudiPerExportViewImpl) => {
                this.attivitaListConfiguration = buildAttivitaExtraConfiguration(pageAtt, size, page, true, this.idRigheDaDisabilitareContainer);
            }),
            takeUntil(this.destroy$),
            finalize(() => {
                    if (isFirstTime) {
                        this.isFirstTime = false;
                        this.loading = false;
                    } else {
                        this.fuseConfirmationService.hideLoader();
                    }
                }
            )
        );


    }

    formatDay(date: string) {
        return moment(new Date(date)).format('DD/MM/YYYY');
    }


    tableClickAction($event: ClickEvent) {
        switch ($event?.tipoClick) {
            case TipoClickEnum.SHOW:
                this.openDescriptionDialog($event.value.descrizione);
                break;
            case TipoClickEnum.CHIP_DETAIL:
                this.openInfoStudent($event.value);
                break;
            case TipoClickEnum.GO_TO:
                const corso: CorsoPianoDiStudiPerExportViewImpl = $event?.value;
                const url = this.router.serializeUrl(
                    this.router.createUrlTree([PathEnum.STUDENTS, corso.utenteId, PathEnum.STUDY_PLAN])
                );
                window.open(url, '_blank');
                break;
            case TipoClickEnum.SHOW_ATTESTATO_ATTIVITA_EXTRA:
                this.showAttestatoAttivitaExtra($event?.value);
                break;
            case TipoClickEnum.SHOW_DELIBERA_ATTIVITA_EXTRA:
                this.showDeliberaAttivitaExtra($event?.value);
                break;
            case TipoClickEnum.REFUSE_REQUEST:
                this.tryOpenRefuseDialog($event);
                break;
        }
    }

    private tryOpenRefuseDialog(event: ClickEvent) {
        if(this.operazioneMassivaData$.getValue().attivitaSelected?.find(
            a => a.idCategoriaOffertaFormativa === event.value.idCategoriaOffertaFormativa &&
               (a.idCorsoInOffertaFormativa === event.value.idCorsoInOffertaFormativa
                   || a.idAttivitaProposta === event.value.idAttivitaProposta
                   || a.idAttivitaPresunta === event.value.idAttivitaPresunta))){
            this.openDeselectFirstDialog();
        } else {
            this.openRifiutaAttivitaExtraDialog(event?.value)
        }
    }

    openRifiutaAttivitaExtraDialog(corso: CorsoPianoDiStudiPerExportViewImpl): void {
        const data: GenericComponentDialogConfig = {
            title: corso?.denominazione,
            message: this.rifiutaAttestatoTitle,
            icon: {
                show: true,
                name: 'close',
                color: 'primary'
            },
            actions: {
                confirm: {
                    show: true,
                    label: this.dialogButtonConfirm,
                    color: 'primary',
                    function: (form, dialogRef) =>
                        this.rifiutaAttivitaExtra(form, dialogRef, corso, corso.idCategoriaOffertaFormativa)
                },
                cancel: {
                    show: true,
                    label: this.dialogButtonCancel,
                }
            },
            dismissible: true,
            formConfig: this.formGroupRifiutaAttestato(corso),
            valueForm: {
                motivazione_rifiuto: ''
            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }

    formGroupRifiutaAttestato(corso: CorsoPianoDiStudiPerExportViewImpl): formGroupConfigInterface[] {
        return [
            {
                show: true,
                name: 'motivazione_rifiuto',
                required: true,
                transloco: 'study_plan_status.reasons_for_refusal',
                type: TypeDialogFormEnum.TEXTAREA
            },

        ];
    }

    rifiutaAttivitaExtra(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>, corso: CorsoPianoDiStudiPerExportViewImpl, idCategoriaOffertaFormativa: string): void {
        if (form.enabled) {
            form.disable({emitEvent: false});
            const body: EsitoRichiestaApprovazioneAttivitaExtra = {
                approvato: false,
                motivazione_rifiuto: form.getRawValue()?.motivazione_rifiuto,
                id_categoria_offerta_formativa: idCategoriaOffertaFormativa
            }
            this.pianiDiStudiService.verificaRichiestaApprovazioneAttivitaExtraPianoDiStudiForm(
                corso.idPianoDiStudi,
                {
                    id_corso_in_offerta_formativa: corso?.idCorsoInOffertaFormativa,
                    id_attivita_presunta: corso?.idAttivitaPresunta,
                    id_attivita_proposta: corso?.idAttivitaProposta,
                },
                null,
                body
            ).pipe(
                takeUntil(this.destroy$),
                finalize(() => form.enable())
            ).subscribe({
                next: (pianoDiStudi: PianoDiStudiInfoView) => {
                    this.idRigheDaDisabilitare = [...(this.idRigheDaDisabilitare ?? []), buildIdExtraForTable(corso)];
                    this.idRigheDaDisabilitareContainer.idRigheSolaLettura = this.idRigheDaDisabilitare;
                    dialogRef.close();
                    const activeLang = this.translocoService.getActiveLang();
                    const translation = this.translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.operation_success', null);
                    this.fuseConfirmationService.openSnackBar({message: message});
                },
                error: (err: HttpErrorResponse) => {
                    const activeLang = this.translocoService.getActiveLang();
                    const translation = this.translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.not_loading_data', null);
                    this.fuseConfirmationService.openSnackBar({
                        message: err?.error?.message || message,
                        error: err, type: SnackbarTypes.Error
                    });
                }
            });
        }
    }


    private openDeselectFirstDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
            title: get(translation, 'dialog.attention', null),
            message: get(translation, 'common.deselect_first', null),
            icon: {
                name: 'mat_solid:error_outline',
                color: 'error'
            },
            onBackdrop: {
                show: true,
                backdrop: false
            },
            actions: [
                {
                    color: 'accent',
                    label: get(translation, 'common.close', null), icon: 'close',
                },
            ]
        });
    }

    openDescriptionDialog(description: string): void {
        const data: DialogInfoI = this.buildDataForDescriptionDIalog(description);
        this.dialog.open(DialogInfoComponent, {
            data,
            panelClass: 'dialog-responsive-full-screen',
            scrollStrategy: new NoopScrollStrategy()
        });
    }

    buildDataForDescriptionDIalog(description: string): DialogInfoI {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        return {
            title: 'common.description',
            icon: {
                name: 'mat_outline:info',
                color: 'primary'
            },
            isTextArea: true,
            text: description
        };
    }

    showDeliberaAttivitaExtra(corso: CorsoPianoDiStudiPerExportViewImpl): void {
        this.fuseConfirmationService.showLoader();
        const requestBody: GetAllegatoAttivitaExtraPianoDiStudiRequest = {
            id_categoria_in_offerta_formativa: corso.idCategoriaOffertaFormativa,
            nome_file: corso.deliberaAttivitaExtra,
            attivita_extra_ids: {
                id_attivita_proposta: corso.idAttivitaProposta,
                id_attivita_presunta: corso.idAttivitaPresunta,
                id_corso_in_offerta_formativa: corso.idCorsoInOffertaFormativa,
            }
        };
        this.pianiDiStudiService.getAllegatoAttivitaExtraPianoDiStudi(requestBody, corso?.idPianoDiStudi)
            .pipe(
                takeUntil(this.destroy$),
                finalize(() => this.fuseConfirmationService.hideLoader())
            ).subscribe(
            {
                next: (value) => {
                    const activeLang = this.translocoService.getActiveLang();
                    const translation = this.translocoService.getTranslation().get(activeLang);
                    const fileNameToDownload = makeFilename(corso?.deliberaAttivitaExtra, true);
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'budget.file_show_success', null),
                        type: SnackbarTypes.Success,
                    });
                    openFileInBlankWindow(value, fileNameToDownload);
                    this.fuseConfirmationService.hideLoader();
                }, error: (err: HttpErrorResponse) => {
                    const activeLang = this.translocoService.getActiveLang();
                    const translation = this.translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.not_loading_data', null);
                    this.fuseConfirmationService.openSnackBar({
                        message: err?.error?.message || message,
                        error: err, type: SnackbarTypes.Error
                    });
                }
            });
    }


    showAttestatoAttivitaExtra(corso: CorsoPianoDiStudiPerExportViewImpl ): void {
        this.fuseConfirmationService.showLoader();
        const requestBody: GetAllegatoAttivitaExtraPianoDiStudiRequest = {
            id_categoria_in_offerta_formativa: corso.idCategoriaOffertaFormativa,
            nome_file: corso.attestatoAttivitaExtra,
            attivita_extra_ids: {
                id_attivita_proposta: corso.idAttivitaProposta,
                id_attivita_presunta: corso.idAttivitaPresunta,
                id_corso_in_offerta_formativa: corso.idCorsoInOffertaFormativa,
            }
        };
        this.pianiDiStudiService.getAllegatoAttivitaExtraPianoDiStudi(requestBody, corso.idPianoDiStudi).pipe(
                takeUntil(this.destroy$),
                finalize(() => this.fuseConfirmationService.hideLoader())
            ).subscribe(
            {
                next: (value) => {
                    const activeLang = this.translocoService.getActiveLang();
                    const translation = this.translocoService.getTranslation().get(activeLang);
                    const fileNameToDownload = makeFilename(corso?.attestatoAttivitaExtra, true);
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'budget.file_show_success', null),
                        type: SnackbarTypes.Success,
                    });
                    openFileInBlankWindow(value, fileNameToDownload);
                    this.fuseConfirmationService.hideLoader();
                }, error: (err: HttpErrorResponse) => {
                    const activeLang = this.translocoService.getActiveLang();
                    const translation = this.translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.not_loading_data', null);
                    this.fuseConfirmationService.openSnackBar({
                        message: err?.error?.message || message,
                        error: err, type: SnackbarTypes.Error
                    });
                }
            });
    }


    private openInfoStudent(studente: CorsoPianoDiStudiPerExportViewImpl) {
        if (!!studente.urlImmagineProfilo && !!studente.studenteCodiceFiscale) {
            this.fuseConfirmationService.showLoader();
            this.mediaService.getImmagineProfiloForm(studente.studenteCodiceFiscale, studente.urlImmagineProfilo)
                .pipe(
                    catchError(err => of(undefined)),
                    map((image) => {
                        if (image) {
                            const objectURL = URL.createObjectURL(image);
                            return this.sanitizer.bypassSecurityTrustUrl(objectURL);
                        } else {
                            return undefined;
                        }
                    }),
                    takeUntil(this.destroy$),
                    finalize(() => this.fuseConfirmationService.hideLoader())
                ).subscribe({
                next: (image: SafeUrl) => {
                    this.dialogInfoManagerService.openStudenteInfoDialogCPSEVI(studente, image);
                },
                error: (error) => {
                    this.fuseConfirmationService.openErrorDialog({error: error},
                        this.translocoService,
                        () => {
                        },
                        () => this.openInfoStudent(studente),
                        'dialog.close',
                        error?.error?.message);
                }
            });
        } else {
            this.dialogInfoManagerService.openStudenteInfoDialogCPSEVI(studente);
        }
    }




    onAttivitaDataTableChanged($event: TableData) {
        console.log('SELECTION CHANGED')
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$?.getValue(),
            attivitaSelected: $event.selectedRows.length > 0 ? $event.selectedRows.map(row => row.data) as CorsoPianoDiStudiPerExportViewImpl[] : undefined,
        });
        this.canGoNext$.next($event.selectedRows.length > 0);
    }

    pageAction($event: PageEvent): void {
        console.log('PageEvent', $event);
        const page = $event.pageIndex;
        this.localStorageService.pageSize = $event.pageSize;
        this.getAttRequest$(false, this.getMainFormGroup()?.value, page, this.localStorageService.pageSize);
    }

    getMainFormGroup(): FormGroup<any> {
        return this.filterManagerService?.mainFormGroup;
    }

    catchErrorInAttList$(inputObs: Observable<any>): OperatorFunction<unknown, ObservedValueOf<Observable<any>> | unknown> {
        return this.fuseConfirmationService.catchErrorCustom$(inputObs, {openModalError: {goToHome: true}}, true, true, true, false);
    }

    getListChipsLabel(): Array<string> {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (!isEmpty(this.getMainFormGroup()?.value)) {
            const entries: [string, any][] = Object.entries(this.getMainFormGroup()?.value)?.filter(item =>
                item[0] !== 'statiAttivitaExtra' && item[0] !== 'showOnlyApprovableRequests');
            const filteredEntries = entries?.filter(item => item.every(value => !!value));
            let labels = filteredEntries?.map(filteredItem => filteredItem?.map((element) => {
                    if (typeof element === 'string') {
                        if (!!this.fieldsLabelMap.get(element)) {

                            return get(translation, this.fieldsLabelMap.get(element), element);
                        }else {
                            return removeUnderscore(element);
                        }
                    }
                    if (typeof element === 'boolean') {
                        return '';
                    }
                    else if (!!element?.codiceFiscale) {
                        const completeName = (element?.cognome || '') + ' ' + (element?.nome || '');
                        return (!!element?.cognome ? completeName : '')?.toUpperCase();
                    }
                    else if (!!element?.id && !!element?.denominazione) {
                        return (!!element?.denominazione ? element.denominazione : '');
                    }
                    else {
                        return element;
                    }
                }
            ).join(': '));
            console.log('Lables chips', labels);
            labels = labels.map(label => label?.endsWith(': ')? label.slice(0, -2) : label);
            return labels;
        } else {
            return [];
        }
    }


    toogleFilterContainer(hasToogleInternalMenu: boolean): void {
        this.showTopbar = true;
    }


    resetFilters(): void {
        this.filterManagerService.restore();
    }

    closeBar() {
        this.showTopbar = false;
    }

    private resetRefreshData() {
        console.log(this.operazioneMassivaData$.getValue())
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$.getValue(),
            refreshData: false
        })
    }

    private getDataAndFilterSubscribe() {
        if(this.isFirstTime){
            this.getAttRequest$(this.isFirstTime, this.filterManagerService?.filterApplied);
        }

        this.filterManagerService.checkApplyFilterClick$.asObservable().pipe(
            filter(Boolean),
            switchMap(value => {
                if(!this.isFirstTime){
                    return this.getAttFormObs$(this.isFirstTime,  this.filterManagerService?.filterApplied, 0, this.localStorageService.pageSize).pipe(
                        this.catchErrorInAttList$(this.getAttFormObs$(this.isFirstTime,  this.filterManagerService?.filterApplied, 0, this.localStorageService.pageSize))
                    );
                }
                return of(null);
            }),
            takeUntil(this.destroy$)
        ).subscribe(
            {
                next: (value) => {
                    this.isFirstTime = false;
                    this.filterManagerService.filterApplied$.next( this.getMainFormGroup().getRawValue());
                    this.currentFilterListChipLabels = this.getListChipsLabel();
                }
            }
        );
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    protected readonly AggiornamentoPianoDiStudiStatus = AggiornamentoPianoDiStudiStatus;
    protected readonly StudyPlanWithDeliberaMode = PlanOpType;
    protected readonly SpesaStudenteStatus = SpesaStudenteStatus;
    protected readonly PeriodoDiMobilitaStudenteStatus = PeriodoDiMobilitaStudenteStatus;
    protected readonly AuthorityType = AuthorityType;
    protected readonly AttivitaExtraPianoDiStudiStatus = AttivitaExtraPianoDiStudiStatus;
}
