<ng-container *transloco="let t">
    <app-dialog-wrap class="w-full" [data]="data">

        <mat-dialog-content>
            <div class="w-full flex flex-col gap-4 mt-2">
<!--                <div class="flex flex-col gap-4 items-center justify-between w-full">-->
<!--                    <div class="flex flex-col items-center">-->
<!--                        <div class="font-medium"-->
<!--                             *ngIf="data?.deliberaDettaglio?.utenteCaricamentoDelibera as utenteCaricamentoDelibera">-->
<!--                            {{t('mobility.did_by')}} {{utenteCaricamentoDelibera.cognome | uppercase}} {{utenteCaricamentoDelibera.nome | uppercase}}-->
<!--                        </div>-->
<!--                        <span class="text-secondary text-sm" *ngIf="data?.deliberaDettaglio?.dataCaricamentoDelibera">-->
<!--                            {{italianDateWithFormat(data?.deliberaDettaglio?.dataCaricamentoDelibera)}}-->
<!--                        </span>-->
<!--                    </div>-->
<!--                    <button *ngIf="data?.deliberaDettaglio?.delibera"-->
<!--                            [color]="'primary'"-->
<!--                            mat-raised-button-->
<!--                            class="max-w-fit"-->
<!--                            type="button"-->
<!--                            (click)="showDelibera()">-->
<!--                        <mat-icon class="mr-2">visibility</mat-icon>-->
<!--                        {{t('documents_dashboard.approval_download')}}-->
<!--                    </button>-->
<!--                </div>-->
                <!-- DOCUMENTAZIONE  -->
                <ng-container *ngIf="data.deliberaDettaglio.allegatiPreautorizzazioneMobilita?.length > 0">
                    <div class="flex flex-col w-full items-center justify-center justify-items-center">
                        <div class="w-full text-center text-lg">{{t('year_passing.docs_coord')}}</div>
                        <div class="mt-1 w-full mb-2 flex flex-wrap rounded-2xl bg-slate-50">
                            <div class="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-4 m-4 mb-4">
                                <ng-container *ngIf="data.deliberaDettaglio.allegatiPreautorizzazioneMobilita as docs">
                                    <app-input-value-key *ngFor="let doc of docs"
                                                         [label]="getLabelForDoc(ChiaveOperazioneEnum.PreautorizzazionePta, doc.documentoInfo?.chiave)"
                                                         [labelClasses]="'text-sm'"
                                                         [showVisualizzaButton]="true"
                                                         [useLargerLabel]="true"
                                                         (onDownloadButtonPressed)="downloadDocument(doc?.url, doc.deliberaCollegioDocenti)"
                                                         [value]="doc?.url | documentNameFromUrl">
                                        <!-- [forceShowValue]="true"-->
                                    </app-input-value-key>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="flex w-full flex-col gap-4 my-2" *ngIf="!data?.hideStudents">
                    <div class="text-lg font-medium">{{t('mobility.students_in_delibera')}}</div>
                    <div *ngIf="data?.deliberaDettaglio?.studentiCiclo?.length > 0; else noStudents"
                         class="w-full border-t-2 border-slate-200 border-solid">
                        <ng-container *ngFor="let studente of data?.deliberaDettaglio?.studentiCiclo">
                            <div class="w-full flex flex-row items-center justify-between py-2 px-0.5 border-b-2 border-slate-200 border-solid">
                                <div class="grid grid-cols-1 grid-rows-3 lg:grid-rows-1 lg:grid-cols-3 w-full gap-2 items-center">
                                    <div class="font-medium">
                                        {{(studente.utente?.cognome + ' ' + studente.utente?.nome) | uppercase}}
                                    </div>
                                    <div class="font-normal flex flex-col">
                                        <span class="font-semibold text-sm">{{t('sign-up.fiscal_code')}}</span>
                                        <span>{{studente.utente?.codiceFiscale}}</span>
                                    </div>
                                    <div class="font-normal flex flex-col" *ngIf="cicloConfigurationService.showMatricola">
                                        <span class="font-semibold text-sm">{{t('students_list.enrollment_number')}}</span>
                                        <span>{{studente.matricola}}</span>
                                    </div>
                                </div>
                                <button mat-mini-fab
                                        (click)="goToStudentPage(studente?.utente?.id)"
                                        [matTooltip]="t('common.go_to_mobility')">
                                    <mat-icon>person</mat-icon>
                                </button>
                            </div>
                        </ng-container>
                    </div>
                    <ng-template #noStudents>
                        <div class="mt-4 text-lg font-medium">
                            <fuse-alert
                                    class="w-full"
                                    [showIcon]="true"
                                    [appearance]="'outline'"
                                    [type]="'warning'"
                            >{{t('custom_table.no_data')}}</fuse-alert>
                        </div>
                    </ng-template>
                </div>
            </div>
        </mat-dialog-content>

    </app-dialog-wrap>

    <div
        class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">
        <button color="accent" mat-flat-button [matDialogClose]="'cancelled'">
            <mat-icon class="mr-2">close</mat-icon>
            {{t('common.close')}}
        </button>
    </div>
</ng-container>
