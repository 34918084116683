import {Injectable} from '@angular/core';
import {Alignment, Font, Workbook} from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';
import {Observable} from "rxjs";
import {TranslocoService} from "@ngneat/transloco";
import {get} from "lodash";

export interface ExcelConfig {
    nameSheet: string;
    config: ConfigCol[];
    configTopHeader?: ConfigColTopHeader[];
    data?: any;
}


export interface ConfigCol {
    translocoHeader?: string;
    translatedHeader?: string;
    key: string;
    width: number;
    numFmt?: string;
    style?: {
        numFmt?: string;
    };
}

export interface ConfigColTopHeader {
    translatedHeader?: string;
    colSpan?: number;
}

export interface Options {
    header: {
        cellColor: string;
        alignment: Partial<Alignment>;
        font: Partial<Font>;
        height: number;
    };
    topHeader?: {
        cellColor: string;
    };
}

export const numFmtEuro = '#,##0.00"€";[Red]\\-#,##0.00"€"\''

@Injectable({
    providedIn: 'root'
})
export class ExcelService {

    constructor(private translocoService: TranslocoService) {
    }

    options: Options = {
        header: {
            cellColor: '0e3651',
            alignment: {
                horizontal: 'center',
                vertical: 'middle',
                wrapText: true
            },
            font: {
                name: 'Calibri',
                bold: true,
                color: {argb: 'ffffff'}
            },
            height: 60
        },
        topHeader: {
            cellColor: '215b82',
        }
    };

    generateExcel(header: ExcelConfig[], autoFilterRowIndex = undefined, optionsHeader = this.options): Observable<Blob> {
        return new Observable<Blob>((observer) => {

            const activeLang = this.translocoService.getActiveLang()
            const translation = this.translocoService.getTranslation().get(activeLang)

            const workbook = new Workbook();
            header.forEach(conf => {
                const worksheet = workbook.addWorksheet(conf.nameSheet);

                if (autoFilterRowIndex) {
                    worksheet.autoFilter = {
                        from: {row: autoFilterRowIndex, column: 1},
                        to: {row: autoFilterRowIndex, column: conf.config.length}
                    };
                }

                worksheet.columns = conf.config?.map(col => ({
                    ...col,
                    //header: get(translation, col.translocoHeader, '')
                }));

                // Add top header
                if(conf.configTopHeader){
                    worksheet.addRow(conf.configTopHeader?.map(col => col.translatedHeader))
                }

                // Add header
                worksheet.addRow(conf.config?.map(col => col.translocoHeader ?
                    get(translation, col.translocoHeader, '') : col.translatedHeader))

                // Add data
                const dataRows = worksheet.addRows(conf.data);

                // filling data row
                dataRows?.forEach((row, rowIndex) => {
                    const dataRow = conf.data?.[rowIndex];
                    if(dataRow && dataRow.fill) {
                        for(let cellIndex = 1; cellIndex <= row.cellCount; cellIndex++) {
                            row.getCell(cellIndex).fill = dataRow.fill;
                        }
                    }
                })


                worksheet.eachRow(function(row, rowNumber){
                    row.eachCell( function(cell, colNumber){
                        if(cell.value && (cell.value as any).hyperlink)
                            row.getCell(colNumber).font = {color: {argb: "000e3651"}, underline: 'single'};
                    });
                });

                if (!!optionsHeader && !!optionsHeader.header) {
                    const columnMax = worksheet.columns.length;
                    if (!!optionsHeader.header.cellColor) {
                        if(conf.configTopHeader) {
                            this.applyExtraStylesForMultiHeaderMode(worksheet, optionsHeader.topHeader.cellColor, columnMax, conf.configTopHeader);
                            this.fillRowCellsColor(2, worksheet, optionsHeader.header.cellColor, columnMax);
                        } else {
                            this.fillRowCellsColor(1, worksheet, optionsHeader.header.cellColor, columnMax);
                        }
                    }
                    if (!!optionsHeader.header.font) {
                        this.setRowCellsFont(1, worksheet, optionsHeader.header.font, columnMax);
                        if(conf.configTopHeader) {
                            this.setRowCellsFont(2, worksheet, optionsHeader.header.font, columnMax);
                        }
                    }
                    if (!!optionsHeader.header.alignment) {
                        this.setRowCellsAlignment(1, worksheet, optionsHeader.header.alignment, columnMax,);
                        if(conf.configTopHeader) {
                            this.setRowCellsAlignment(2, worksheet, optionsHeader.header.alignment, columnMax);
                        }
                    }
                    if (!!optionsHeader.header?.height) {
                        ExcelService.setRowCellsHeight(1, worksheet, optionsHeader.header.height);
                        if(conf.configTopHeader) {
                            ExcelService.setRowCellsHeight(2, worksheet, optionsHeader.header.height);
                        }
                    }

                }

                let rowIndex = 1;
                for (rowIndex; rowIndex <= worksheet.rowCount; rowIndex++) {
                    worksheet.getRow(rowIndex).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                }
            })


            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                observer.next(blob);
                observer.complete();
            }).catch((error) => {
                observer.error(error);
            });

        });
    }

    /***
     * setta il colore del header
     * @param worksheet
     * @param color
     * @param columnMax
     */
    private fillRowCellsColor(index: number, worksheet, color: string, columnMax: number): void {
        worksheet.getRow(index).eachCell((cell, colNumber) => {
            if (colNumber <= columnMax) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: color}
                };

            }
        });
    }

    private setRowCellsFont(index: number, worksheet, font: Partial<Font>, columnMax: number): void {
        worksheet.getRow(index).eachCell((cell, colNumber) => {
            if (!cell.font) {
                cell.font = {};
            }
            if (colNumber <= columnMax) {
                if (!!font.name) {
                    cell.font.name = font.name;
                }
                if (!!font.size) {
                    cell.font.size = font.size;
                }
                if (!!font.family) {
                    cell.font.family = font.family;
                }
                if (!!font.italic) {
                    cell.font.italic = font.italic;
                }
                if (!!font.bold) {
                    cell.font.bold = font.bold;
                }
                if (!!font.color) {
                    cell.font.color = {argb: font.color.argb};
                }
                if (!!font.underline) {
                    cell.font.underline = font.underline;
                }

            }
        });
    }

    private setRowCellsAlignment(index: number, worksheet, alignment: Partial<Alignment>, columnMax: number): void {
        worksheet.getRow(index).eachCell((cell, colNumber) => {
            if (!cell.alignment) {
                cell.alignment = {};
            }
            if (colNumber <= columnMax) {
                if (!!alignment.horizontal) {
                    cell.alignment.horizontal = alignment.horizontal;
                }
                if (!!alignment.vertical) {
                    cell.alignment.vertical = alignment.vertical;
                }
                if (!!alignment.wrapText) {
                    cell.alignment.wrapText = alignment.wrapText;
                }
            }
        });
    }

    private static setRowCellsHeight(index: number, worksheet, height: number): void {
        worksheet.getRow(index).height = height;
    }


    private applyExtraStylesForMultiHeaderMode(worksheet, color: string, columnMax: number, configTopHeader: ConfigColTopHeader[]): void {
        // computing the styles to Apply
        const colNumberToColor = [];
        const colNumberToBorderLeft = [];
        const colNumberToBorderRight = [];
        configTopHeader.forEach((col, index) => {
            if(col.translatedHeader !== ''){
                colNumberToColor.push(...[index, index + 1, index + 2]);
                colNumberToBorderLeft.push(...[index]);
                colNumberToBorderRight.push(...[index + 2]);
            }
        });
        // applying format
        worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
            //for loop with the number of columns
            for (let colNumber = 1; colNumber <= columnMax; colNumber++) {
                const cell = worksheet.getRow(rowNumber).getCell(colNumber);
                // if it's the first row, apply the color
                if (rowNumber == 1) {
                    if (colNumberToColor.includes(colNumber)) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {argb: color}
                        };
                    }
                }
                // apply the border
                if (colNumberToBorderLeft.includes(colNumber)) {
                    cell.border = {
                        ...cell.border,
                        left: {style: 'medium', color: {argb: '000000'}}
                    };
                }
                if (colNumberToBorderRight.includes(colNumber)) {
                    cell.border = {
                        ...cell.border,
                        right: {style: 'medium', color: {argb: '000000'}}
                    };
                }
            }
        });
    }


}

